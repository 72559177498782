<div class="right__panel">
  <main class="container-fluid">
    <div class="row">
      <div [ngClass]="showHideOrder ? 'col-md-8' : 'col-md-12'">
        <div class="video-player">
          <vg-player (onPlayerReady)="onPlayerReady($event)">
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>

              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

              <vg-mute></vg-mute>
              <vg-volume></vg-volume>

              <vg-fullscreen></vg-fullscreen>
            </vg-controls>
            <video
              #media
              [vgMedia]="media"
              [src]="selectedVideo?.url"
              id="singleVideo"
              preload="auto"
              autoplay
              crossorigin
            ></video>
          </vg-player>
        </div>

        <div class="page-title__bar">
          <div>
            <h2 class="title">Video Dictations</h2>
          </div>
          <div>
            <div class="filter__panel mb-1 btn-demo" ngbTooltip="Filter" (click)="openFilterModal()">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="selectedProvider && selectedProvider.id ? 'filtered' : ''"
                alt="filters"
              >
                <path
                  d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
                  stroke="#001737"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="archived__panel mb-1 btn-demo">
              <div class="d-flex align-items-center">
                <mat-slide-toggle color="cyan" #attachmentSwitch (change)="onChangeSwitch()">Archived</mat-slide-toggle>
              </div>
            </div>
            <div class="search__panel btn-demo">
              <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteria">
                <option value="patient_name">Patient Name</option>
                <option value="mrn">MRN</option>
                <option value="provider_name">Provider Name</option>
              </select>
              <div class="input-group">
                <input
                  #searchBar
                  type="search"
                  (keyup.enter)="searchRecords($event)"
                  [(ngModel)]="searchKeyword"
                  (search)="searchRecords($event, true)"
                  class="form-control change"
                  placeholder="Search"
                />
                <div class="input-group-append" (click)="searchRecords($event)">
                  <span class="input-group-text">
                    <img src="../../../assets/icons/daily-screening.svg" alt="search" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
            <div class="right__panel calendar-data__wrapper">
              <div class="table-data__wrapper table-responsive calendar-data--table">
                <app-table-pagination-client
                  [headers]="videoTableHeaders"
                  [data]="videoDictationTableData"
                  [hasActions]="true"
                  [deleteFunction]="userRole === 'practice_admin' ? deleteVideoDictation : ''"
                  [archivedFunction]="archivedFunction"
                  [downloadFunction]="downloadVideoDictation"
                  [playContent]="playVideoDictation"
                  [pauseContent]="pauseVideoDictation"
                  [createContent]="!showHideOrder && userRole === 'practice_admin' && createOrder"
                  [createContentButtonText]="'Create Order'"
                  [pageSize]="pageSize"
                  [currentPage]="1"
                  [style]="this.style"
                  [createContentButtonOnCondition]="true"
                  [orderByFunction]="orderBy"
                  [paginationFirstPage]="firstPage"
                  [paginationPrevPage]="prevPage"
                  [paginationNextPage]="nextPage"
                  [paginationLastPage]="lastPage"
                  [paginationCustomPage]="customPage"
                  [serverSidePagination]="tablePages"
                >
                </app-table-pagination-client>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showHideOrder" class="col-md-4 order_panel">
        <app-order
          (showHideOrder)="toggleOrder($event)"
          [practice_id]="CreateOrderData.practice_id"
          [provider_id]="CreateOrderData.provider_id"
          [patient_id]="CreateOrderData.patient_id"
        ></app-order>
      </div>
    </div>
  </main>
</div>
