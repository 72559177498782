<div class="modal-body">
  <div
    #formResponse
    id="formResponse"
    class="screen screen-6 screen-3 container-md"
    [ngStyle]="{ height: outerHeight }"
  >
    <div class="order-otr container-md" style="margin: -20px 0 24px 0 !important">
      <p class="form-title" *ngIf="renderPreview || renderResponse">{{ formTitle }}</p>
    </div>

    <div
      #respDetail
      id="respDetail"
      class="client-otr ml-4 container-md"
      style="
        margin-left: 10% !important;
        margin-top: -1px !important;
        width: 80% !important;
        position: absolute !important;
        overflow-y: auto;
        overflow-x: hidden;
      "
      [ngStyle]="{ height: innerHeight }"
    >
      <div class="input-otr child container-md" style="width: 95% !important" *ngIf="renderPreview || renderResponse">
        <lib-vsaPreviewForms
          [formFields]="formFields"
          [renderPreview]="renderPreview"
          [renderResponse]="renderResponse"
          [patientData]="patientData"
        ></lib-vsaPreviewForms>
        <div class="action-otr login-btn" style="padding-top: 22px !important" *ngIf="renderPreview">
          <button id="task-detail-btn" disabled class="btn-login hand-cursor mt-3 ml-4" type="button">SUBMIT</button>
        </div>
        <p class="disclaimer" *ngIf="renderPreview">
          This is just a preview, actual render may not be the same.
        </p>
      </div>
    </div>
  </div>
</div>
