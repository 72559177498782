import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Assistant } from 'src/app/shared/models';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatementsService } from 'src/app/shared/services/statements.service';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.css'],
})
export class StatementsComponent implements OnInit {
  tableHeaders = ['No', 'File_Name'];
  autopayTableHeaders = ['Id', 'File_Name', 'Created_AT', 'File_Url'];
  attachmentCompleteTableData: any[];
  autopayCompleteTableData: any[];
  assistantInfo: Assistant = null;
  paginationPageSize = 8;
  userRole: string;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private statementService: StatementsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init();
  }
  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    this.spinnerService.show();
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getStatements();
    this.getAutopayFiles();
  }

  getStatements(): void {
    this.spinnerService.show();
    this.statementService
      .getAllClientStatement(this.assistantInfo.practiceId.toLowerCase(), this.assistantInfo.client_id)
      .then((data: any) => {
        this.spinnerService.hide();
        this.attachmentCompleteTableData = data.data.map((res: any, index: number) => {
          return {
            No: index + 1,
            File_Name: res.file_name,
          };
        });
      });
  }

  getAutopayFiles(): void {
    this.spinnerService.show();
    this.statementService.getAutopayFiles().subscribe((data: any) => {
      this.spinnerService.hide();
      this.autopayCompleteTableData = data.data.map((res: any) => {
        return {
          Id: res.id,
          File_Name: res.file_name,
          Created_AT: res.created_at,
          File_Url: res.file_url.split('?')[0],
        };
      });
    });
  }

  uploadCSV(): void {
    let fileChooserAcceptType = '.csv';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.spinnerService.show();
        this.statementService.uploadCSV(file).subscribe(
          (resp) => {
            this.spinnerService.hide();
            this.toastr.success('File has been uploaded successfully');
            this.getStatements();
            this.getAutopayFiles();
          },
          (error) => {
            this.spinnerService.hide();
            this.toastr.error(error);
          }
        );
      }
    });
    fileChooser.click();
  }

  uploadAttachment(): void {
    let fileChooserAcceptType = '.txt';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.spinnerService.show();
        this.statementService
          .uploadImage(file, this.assistantInfo.practiceId.toLowerCase(), this.assistantInfo.client_id)
          .subscribe(
            (resp) => {
              if (resp.data) {
                this.spinnerService.hide();
                this.toastr.success('File has been uploaded successfully');
                this.getStatements();
              }
            },
            (error) => {
              this.spinnerService.hide();
              this.toastr.error(error);
            }
          );
      }
    });
    fileChooser.click();
  }
}
