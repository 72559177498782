<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Add CSV file for bulk messages</h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div>
  <fieldset class="form-group mt-3 mb-4 p-3">
    <div class="text-area">
      <div *ngIf="csvData" class="uploaded-csv" [ngClass]="{ scrollable: csvRows.length > 5 }">
        <div *ngIf="selectedFile">
          <h6>Uploaded CSV File: {{ selectedFile.name }}</h6>
        </div>
        <div class="csv-data">
          <table>
            <thead>
              <tr>
                <th *ngFor="let header of csvHeaders">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of csvRows.slice(1)">
                <td *ngFor="let cell of row">{{ cell }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="csvHeadersList" style="max-height: 200px; overflow-y: auto">
        <button
          *ngFor="let header of csvHeaders"
          type="button"
          class="btn btn-secondary mr-1 header-button"
          (click)="insertSelectedHeader(header)"
        >
          {{ header }}
        </button>
      </div>
      <h6>Phone Number Column</h6>
      <input
        type="text"
        #selectedField
        ngbAutofocus
        class="form-control"
        style="height: 50px"
        placeholder="Enter Phone Number"
        (focus)="onSelectedFieldFocus()"
        readonly
      />
      <textarea
        #message
        *ngIf="showMessageBox"
        ngbAutofocus
        class="form-control"
        placeholder="Enter new Message"
        (focus)="onMessageFieldFocus()"
      ></textarea>
      <div class="d-flex justify-content-between mt-3">
        <div *ngIf="!selectedFile">
          <button type="button" class="btn btn-primary mr-2" (click)="uploadCsvFile()">Upload Csv File</button>
          <button type="button" class="btn btn-primary" (click)="downloadCsvTemplate()">Download Template</button>
        </div>
        <div class="button-container">
          <button type="button" class="btn btn-primary" (click)="sendMessage()">Send Message</button>
        </div>
      </div>
    </div>
  </fieldset>
</div>
