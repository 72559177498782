import { AppService } from './../../shared/services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivityLogsService } from './../../shared/services/activity-logs.service';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Assistant } from 'src/app/shared/models';
import * as moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss'],
})
export class ActivityLogsComponent implements OnInit, OnDestroy {
  tableHeaders = [
    'No',
    'Device_ID',
    'Practice_ID',
    'Room',
    'Event',
    'Event_Time',
  ];
  activityLogs: [] = [];
  assistantInfo: Assistant = null;
  refreshInterval: any;
  practiceInfo: any = null;
  userRole: string;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private activityLogsService: ActivityLogsService,
    private appService: AppService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.spinnerService.show();
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.assistantInfo = await this.firebaseService.getAssistant(
      loggedInUser.uid,
      loggedInUser.displayName
    );
    this.practiceInfo = await this.appService.getPracticeById(
      this.assistantInfo.practiceId.toLowerCase()
    );
    this.getActivityLogs();
    this.refreshInterval = setInterval(() => {
      this.getActivityLogs();
    }, 30 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  getActivityLogs(): void {
    const self = this;
    let date;
    const dbConfig = this.appService.formatDbObj(
      this.practiceInfo.dbUsername,
      this.practiceInfo.dbPassword,
      this.practiceInfo.dbName,
      this.practiceInfo.dbHost,
      this.practiceInfo.dbDialect,
      this.practiceInfo.dbPort
    );
    this.activityLogsService
      .getActivityLog(this.assistantInfo.practiceId, dbConfig)
      .subscribe({
        next(res) {
          self.spinnerService.hide();
          self.activityLogs = res[0].map((n: any, index: number) => {
            date = n.create_timestamp.toLocaleString();
            date = date.substring(0, 19);
            return {
              No: index + 1,
              Device_ID: n.device_id,
              Practice_ID: n.practice_id,
              Room: n.room,
              Event: n.event,
              Event_Time: moment(date).format('MM/DD/YYYY, hh:mm A'),
            };
          });
        },
        error(err) {
          self.spinnerService.hide();
        },
      });
  }
}
