<form>
  <ng-container *ngIf="patientData"
    ><div class="patient-details">
      <div class="patient-data">
        <label class="description mr-1">First Name</label>
        <div>
          <input
            class="input"
            type="text"
            [value]="patientData?.first_name || ''"
            readonly
          />
        </div>
      </div>
      <div class="patient-data">
        <label class="description mr-1">Last Name</label>
        <div>
          <input
            class="input"
            type="text"
            [value]="patientData?.last_name || ''"
            readonly
          />
        </div>
      </div>
      <div class="patient-data">
        <label class="description mr-1">Date Of Birth</label>
        <div>
          <input
            class="input"
            type="text"
            [value]="patientData?.date_of_birth || ''"
            readonly
          />
        </div>
      </div>
      <div class="patient-data">
        <label class="description mr-1">Med Record Number</label>
        <div>
          <input
            class="input"
            type="text"
            [value]="patientData?.med_rec_nbr || ''"
            readonly
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let field of formFields; let i = index">
    <!-- /* Text Field */ -->
    <ng-container *ngIf="field.field_type == 3">
      <div class="field">
        <label class="description" [ngStyle]="textStyle(field.id, i)"
          >{{ field.description }}
        </label>
        <div>
          <input
            class="input form-control"
            type="text"
            [disabled]="!isPWAForm"
            [value]="(renderResponse && field?.field_responses[0]?.response) || ''"
            [mask]="getMaskPattern(field.id, i)"
            (change)="
                    setValues(
                      field.field_type,
                      field.id,
                      '',
                      $event.target.value
                    )
                  "
          />
        </div>
      </div>
    </ng-container>

    <!-- /* Checkbox */ -->
    <ng-container *ngIf="field.field_type == 5">
      <div class="field">
        <p class="description" [ngStyle]="textStyle(field.id, i)">
          {{ field.description }}
        </p>
        <ng-container *ngFor="let opt of field.options" class="mt-2">
          <div class="options">
            <label class="c-pointer">
              <input
                class="checkbox-round"
                type="checkbox"
                [disabled]="!isPWAForm"
                [checked]="renderResponse && checkMultiOptions(field, opt)"
                id="{{ opt.id }}"
                value="{{ opt.option }}"
                style="width: 14px; height: 14px"
                (change)="setValues(field.field_type, field.id, '', opt.option, $event)"
              />
              {{ opt.option }}
            </label>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- /* Radio Button */ -->
    <ng-container *ngIf="field.field_type == 1">
      <div style="margin-top: 6%">
        <p class="description" [ngStyle]="textStyle(field.id, i)">
          {{ field.description }}
        </p>
        <ng-container *ngFor="let opt of field.options" class="mt-2">
          <div class="options">
            <label class="c-pointer"
              ><input
                type="radio"
                name="{{field.id}}"
                [disabled]="!isPWAForm"
                [checked]="renderResponse && checkMultiOptions(field, opt)"
                id="{{ opt.id }}"
                value="{{ opt.option }}"
                (change)="
                  setValues(
                    field.field_type,
                    field.id,
                    opt.id,
                    opt.option
                  )
                "
              />
              {{ opt.option }}</label
            >
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- /* Text View */ -->
    <ng-container *ngIf="field.field_type == 7">
      <div class="field" style="margin-right: 14px">
        <p class="text-view" [ngStyle]="textStyle(field.id, i) || ''">
          {{ field.description }}
        </p>

        <ul class="mt-2" *ngFor="let opt of field.options">
          <li class="bullet-points">
            <div>
              •
              <p>{{ opt.option }}</p>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>

    <!-- /* Image */ -->
    <ng-container *ngIf="field.field_type == 4">
      <div class="field">
        <label for="4" class="description" [ngStyle]="textStyle(field.id, i)"
          >{{ field.description }}
        </label>
        <div *ngIf="field?.field_responses?.length > 0; else elseImg">
          <img
            [src]="field.field_responses[0].response"
            class="imgResp"
            alt="Image Selected"
            id="{{ field.id }}"
          />
        </div>
        <ng-template #elseImg>
          <input
            [disabled]="!isPWAForm"
            type="file"
            accept="image/*"
            (change)="imageChangeEvent(field.id, field.field_type, $event)"
            style="padding: 10px 65px 0 0"
          />
          <div *ngIf="findImageWithMask(field.id) === 0">
            <img class="imgResp" [src]="getImage(field.id)" />
          </div>
          <div *ngIf="findImageWithMask(field.id) === 1">
            <p class="card-guidelines">
              (Please allign your insurance card in the outline shown
              underneath)
            </p>
            <!-- <image-cropper
              [imageChangedEvent]="getImageForCropper(field.id)"
              [maintainAspectRatio]="false"
              [aspectRatio]="4 / 3"
              format="png"
              (imageCropped)="setCroppedImage(field.id, field.field_type, $event)"
            ></image-cropper> -->
            <p class="card-guidelines">
              (The image below should only contain your insurance card)
            </p>
            <img
              class="mt-2 cropped__image"
              [src]="getCroppedImage(field.id)"
            />
          </div>
        </ng-template>
      </div>
    </ng-container>

    <!-- /* Location */ -->
    <ng-container *ngIf="field.field_type == 2">
      <div class="field">
        <label
          for="{{ field.id }}"
          class="description"
          [ngStyle]="textStyle(field.id, i)"
          >{{ field.description }}
        </label>
        <div *ngIf="isPWAForm">
          <input
            class="input"
            (onAddressChange)="handleAddressChange($event, field.field_type, field.id)"
          />
        </div>
        <div *ngIf="renderPreview || renderResponse">
          <input
            class="input"
            [value]="(renderResponse && field?.field_responses[0]?.response) || ''"
            [disabled]="!isPWAForm"
          />
        </div>
      </div>
    </ng-container>

    <!-- /* Signature */ -->
    <ng-container *ngIf="field.field_type == 6">
      <div class="field">
        <p class="description" [ngStyle]="textStyle(field.id, i)">
          {{ field.description }}
        </p>
        <div
          class="signature-container"
          *ngIf="renderResponse && field?.field_responses?.length > 0; else elseSign"
        >
          <img
            [src]="field?.field_responses[0].response"
            class="imgResp"
            alt="Document Signed"
            id="signatureCanvas"
          />
        </div>
        <ng-template #elseSign>
          <div class="signature-container">
            <signature-pad
              [options]="signaturePadOptions"
              id="{{ field.id }}"
              (onEndEvent)="saveSignature(field.id, field.field_type)"
            ></signature-pad>
          </div>
          <div class="signature-button">
            <button
              class="clear-signature button"
              type="button"
              (click)="clearSignature(field.id)"
            >
              Clear Signature
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <!-- /* Data Grid */ -->
    <ng-container *ngIf="field.field_type == 8">
      <span style="margin-top: 10px">
        <span style="margin-top: 10px; padding-left: 10px">
          <p
            class="description"
            style="font-family: 'Gilroy-Light'; font-size: 14px"
            [ngStyle]="textStyle(field.id, i)"
          >
            {{ field.description }}
          </p>
          <button
            type="button"
            (click)="addNewRow(field)"
            title="Add new row"
            style="
              all: unset;
              cursor: pointer;
              display: block;
              margin-left: auto;
              margin-right: 40px;
              margin-bottom: 5px;
            "
          >
            <i class="fa fa-plus" style="font-size: 16px; color: #073786"> </i>
          </button>

          <div class="wrapper" style="padding-right: 30px">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <ng-container *ngFor="let opt of field.options">
                    <th
                      style="font-size: small"
                      *ngIf="opt.is_deleted === 0"
                      scope="col"
                    >
                      {{ opt.option }}
                    </th>
                    <th
                      style="font-size: small"
                      *ngIf="isPWAForm || renderPreview"
                      scope="col"
                    >
                      {{ opt.option }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngIf="field?.responseArray && field?.responseArray.length >0; else inputGrid "
                >
                  <tr *ngFor="let response of field?.responseArray">
                    <td *ngFor="let item of response" style="font-size: small">
                      {{ item }}
                    </td>
                  </tr>
                </ng-container>

                <ng-template #inputGrid>
                  <tr
                    *ngFor="let _ of [].constructor(field.rows); let i = index"
                  >
                    <td *ngFor="let opt of field.options">
                      <input
                        class="input textField"
                        type="text"
                        id="opt.description"
                        (change)="
                              setDatagridValue(
                                field.field_type,
                                field.id,
                                opt.id,
                                opt.option,
                                field.options,
                                i,
                                $event
                              )
                            "
                      />
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </span>
      </span>
    </ng-container>
  </ng-container>
  <div
    class="action-otr login-btn"
    *ngIf="isPWAForm"
    style="padding-top: 22px !important"
  >
    <button
      id="task-detail-btn"
      [ngClass]="!formArray.length ? 'btn-disabled' : 'btn-login hand-cursor'"
      type="button"
      [disabled]="!formArray.length"
      (click)="handleSaveForm()"
    >
      SUBMIT
    </button>
  </div>
</form>
