<span ngbDropdown display="static" open="true" [autoClose]="true" class="dropdown-tab">
  <div ngbDropdownMenu class="dropdown-menu">
    <div ngbDropdownItem>
      <b>Notifications</b>
      <hr />
    </div>
    <a ngbDropdownItem [href]="navbarComponents[10].activePath">
      <div class="row">
        <div class="col col-sm-2"><i class="fa fa-comment-o" aria-hidden="true"></i></div>
        <div class="col col-sm-7">Unread Messages</div>
        <div class="col col-sm-3">
          <span #unreadMessages id="unreadMessages" class="badge">{{ this.msgLength }}</span>
        </div>
      </div>
    </a>
    <div ngbDropdownItem *ngIf="this.msgLength > 0">
      <hr />
      <div [routerLink]="navbarComponents[8].activePath" class="row" *ngFor="let record of this.msg; let i = index">
        <div class="col col-sm-6 sender">{{ record.lastMessage.sender.name }}</div>
        <div class="col col-sm-6 msg-notify">{{ record.lastMessage.body }}</div>
      </div>
      <hr />
    </div>
    <a ngbDropdownItem [href]="navbarComponents[4].activePath">
      <div class="row">
        <div class="col col-sm-2"><i class="fa fa-play" aria-hidden="true"></i></div>
        <div class="col col-sm-7">Video Dictation</div>
        <div class="col col-sm-3">
          <span #videoDictation class="badge" id="videoDictation">{{ this.video }}</span>
        </div>
      </div>
    </a>
    <a ngbDropdownItem [href]="navbarComponents[5].activePath">
      <div class="row">
        <div class="col col-sm-2"><i class="fa fa-microphone" aria-hidden="true"></i></div>
        <div class="col col-sm-7">Audio Dictation</div>
        <div class="col col-sm-3">
          <span class="badge">{{ this.audio }}</span>
        </div>
      </div>
    </a>
    <a ngbDropdownItem [routerLink]="['/dashboard']">
      <div class="row">
        <div class="col col-sm-2"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
        <div class="col col-sm-7">Live Session</div>
        <div class="col col-sm-3"><span class="badge">0</span></div>
      </div>
    </a>
  </div>
</span>
