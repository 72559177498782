<div class="modal-header">
    <h4 class="modal-title">{{detail === 'phone' ? "Edit Phone without country code" : "Edit Name"}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  <div *ngIf="detail === 'name'" class="modal-body" style="text-align:center;">
    <fieldset class="form-inline justify-content-end">
      <ng-container>
        <div class="form-group mt-2" style="margin-right:65px;">
          <label for="first_name">First Name:</label>
          <input id="first_name" type="text" class="form-control mx-sm-3" minlength="2" [(ngModel)]="first_name"/>
        </div>
        <div class="form-group mt-2" style="margin-right:65px;">
          <label for="last_name">Last Name:</label>
          <input id="last_name" type="text" class="form-control mx-sm-3" minlength="2" [(ngModel)]="last_name" />
        </div>
      </ng-container>
    </fieldset>
  </div>
  <div *ngIf="detail === 'phone'" class="modal-body" style="text-align:center;">
    <fieldset class="form-inline justify-content-end">
      <ng-container>
        <div class="form-group mt-2" style="margin-right:65px;">
          <label for="cell_phone">Phone:</label>
          <input id="cell_phone" type="text" class="form-control mx-sm-3" minlength="2" [(ngModel)]="cell_phone"/>
        </div>
      </ng-container>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary action-btn" (click)="EditBotton()">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
  