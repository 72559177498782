<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Add New Order</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body row">
  <div [ngClass]="showHideOrder ? 'col-md-7' : 'col-md-12'">
    <div class="body-align">
      
      <div class="row modal-body" *ngIf="appointment_date">
        <div class="column col-md-6">Appointment Date: {{ appointment_date }}</div>
        <!-- <div class="column col-md-6">Room Time: {{room_time}}</div> -->
      </div>

      <div class="row modal-body" *ngIf="name || date_of_birth">
        <div class="column col-md-6">Name: {{ name }}</div>
        <div class="column col-md-6">Date of Birth: {{ date_of_birth }}</div>
      </div>
      <div class="row modal-body align-items-center">
        <div class="col-md-3">
          <select class="form-control" [(ngModel)]="selectedOption" >
            <option value="" disabled selected>Please select a provider to create an order against.</option>
            <option *ngFor="let description of providerDescriptions" [value]="description">
              {{ description }}
            </option>
          </select>
        </div>
        <div class="col-md-5">
          <div class="input-group" (keyup.enter)="filterUsers(searchBar.value)">
            <input
              #searchBar
              class="form-control change"
              type="date"
              ngbTooltip="Search by appointment date"
              [(ngModel)]="searchKeyword"
              placeholder="Search by Date"
            />
            <div class="input-group-append" (click)="filterUsers(searchBar.value)">
              <span class="input-group-text">
                <img src="../../../assets/icons/daily-screening.svg" alt="search" />
              </span>
            </div>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="input-group" (keyup.enter)="filterUserByName(searchKeywordName)">
            <input
              #searchBar
              class="form-control"
              ngbTooltip="Search by patient name"
              type="text"
              [(ngModel)]="searchKeywordName"
              placeholder="Search by name"
            />
            <div class="input-group-append" (click)="filterUserByName(searchKeywordName)">
              <span class="input-group-text">
                <img src="../../../assets/icons/daily-screening.svg" alt="search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-table-pagination-client
      [headers]="orderResults"
      [data]="userPatientsTableData"
      [hasActions]="true"
      [paginateId]="1"
      [selectContent]="selectPatient"
      [selectedContent]="selectedPatient"
      [createContent]="addOrder"
      [createContentButtonText]="'Create Order'"
      [createContentButtonOnCondition]="true"
      [paginationPrevPage]="prevPage"
      [paginationNextPage]="nextPage"
      [serverSidePagination]="tablePages"
      [pageSize]="pageSize"
      [currentPage]="1"
    >
    </app-table-pagination-client>
  </div>

  <div *ngIf="showHideOrder" class="col-md-5 borders">
    <app-order
      (showHideOrder)="toggleOrder($event)"
      [practice_id]="CreateOrderData.practice_id"
      [patient_id]="CreateOrderData.uniq_id"
      [provider_id]="CreateOrderData.provider_id"
    ></app-order>
  </div>
</div>
