import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() headers: [];
  @Input() data: [];
  @Input() hasActions: boolean = false;
  @Input() deleteFunction: (args: any) => void = null;
  @Input() editFunction: (args: any) => void = null;
  @Input() paginationNextPage: () => void;
  @Input() paginationPrevPage: () => void;
  @Input() prevKeys: any;
  @Input() nextKey: any;

  constructor() {}

  ngOnInit(): void {}
}
