<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "Download User Activity Report" }}</h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="mb-4 modal-body">
  <div class="border-bottom">
    <div class="form-group">
      <label for="startDate">Start Date</label>
      <input id="startDate" class="form-control" type="date" [(ngModel)]="startDate" />
    </div>
    <div class="form-group">
      <label for="endDate">End Date</label>
      <input id="endDate" class="form-control" type="date" [(ngModel)]="endDate" />
    </div>
    <button class="btn btn-primary mb-2" (click)="downloadReport()">Download Report</button>
  </div>
</div>
