<div class="branding-screen">
  <h2 class="title">Branding</h2>

  <div class="col-md-12 branding-container">
    <div class="body-align">
      <div class="row modal-body">
        <div class="column col-md-6">Primary Color :</div>
        <div class="column col-md-6">
          <input
            type="color"
            class="color-picker"
            id="colorPicker"
            [(ngModel)]="PrimaryColor"
          />
        </div>
      </div>
      <hr class="hr" />
      <div class="row modal-body">
        <div class="column col-md-6">Secondary Color :</div>
        <div class="column col-md-6">
          <input
            type="color"
            class="color-picker"
            id="fname"
            [(ngModel)]="SecondaryColor"
          />
        </div>
      </div>
      <hr class="hr" />
      <div class="row modal-body">
        <div class="column col-md-6">Change Logo File :</div>
        <div class="column col-md-6">
          <input type="file" id="fname" (change)="onLogoChange($event)" />
          <img
            [src]="logo_url"
            style="border-radius: 50%"
            alt="Paris"
            width="200"
            height="200"
          />
        </div>
      </div>
      <hr class="hr" />
      <div class="row modal-body">
        <div class="column col-md-6"></div>
        <div class="column col-md-6">
          <button
            class="btn btn-primary branding-submit"
            (click)="setBranding()"
            [disabled]="logo_url === undefined || logo_url === null"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
