import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormFieldsDetails, FormDetails } from 'src/app/shared/models';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-form-modal',
  templateUrl: './preview-form-modal.component.html',
  styleUrls: ['./preview-form-modal.component.scss'],
})
export class PreviewFormModalComponent implements OnInit {
  formFields: FormFieldsDetails[] = [];
  formTitle = '';
  innerHeight = '540px';
  outerHeight = '700px';
  @Input() renderPreview: FormDetails;
  @Input() renderResponse: FormDetails;
  @Input() patientData: [];

  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.renderResponse) {
      try {
        this.formTitle = this.renderResponse.title;
        this.formFields = this.renderResponse.fields;
      } catch (err) {
        () => this.toastr.error('Something went wrong. Please try again');
      }
    }

    if (this.renderPreview) {
      try {
        this.formTitle = this.renderPreview.title;
        this.formFields = this.renderPreview.fields;
        this.spinnerService.hide();
      } catch (err) {
        this.spinnerService.hide();
        () => this.toastr.error('Something went wrong. Please try again');
      }
    }
  }

  checkMultiOptions(field: any, opt: any) {
    return !!field.field_responses.filter((r) => r.response === opt.option).length;
  }
}
