<!-- Banner -->
<ng-container class="banner">
  <div
    *ngIf="!emailVerified && (userRole === 'location_admin' || userRole === 'location_user')"
    class="verification-banner"
  >
    <span
      >We have sent you an email to verify your account, please verify as soon as possible. If u want us to resend the
      email. click here: &nbsp;<a (click)="resendVerificationLink()">Resend Email</a>
    </span>
  </div>
</ng-container>

<main class="navbar-main">
  <!-- Navbar -->
  <app-navbar [showHideToggle]="showHideToggle"></app-navbar>
  <div class="dashboard">
    <app-header (showHideToggle)="toggle($event)"></app-header>
    <!-- Header -->
    <router-outlet></router-outlet>
  </div>
</main>
