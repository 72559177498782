import { MessageService } from 'src/app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-notify-button',
  templateUrl: './notify-button.component.html',
  styleUrls: ['./notify-button.component.scss'],
})
export class NotifyButtonComponent implements OnInit {
  userData: any;
  mainMenu: boolean = true;
  notifyConfirm: boolean = false;
  statusConfirm: boolean = false;
  resetConfirm: boolean = false;
  statusSuccess: boolean = false;
  statusFail: boolean = false;
  notifySuccess: boolean = false;
  notifyFail: boolean = false;
  noUnread: boolean = false;
  resetSuccess: boolean = false;
  resetFail: boolean = false;
  userNotFound: boolean = false;
  progress: boolean = false;
  sendConfirm: boolean = false;

  constructor(
    private messageService: MessageService,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(this.route.snapshot.queryParams['data']);
  }

  confirmReset(): void {
    this.resetConfirm = true;
    this.mainMenu = false;
  }

  confirmSendPin(): void {
    this.sendConfirm = true;
    this.mainMenu = false;
  }

  confirmNotify(): void {
    this.notifyConfirm = true;
    this.mainMenu = false;
  }

  confirmStatus(): void {
    this.statusConfirm = true;
    this.mainMenu = false;
  }

  return(): void {
    this.resetConfirm = false;
    this.notifyConfirm = false;
    this.statusConfirm = false;
    this.resetSuccess = false;
    this.statusSuccess = false;
    this.resetFail = false;
    this.statusFail = false;
    this.notifyFail = false;
    this.userNotFound = false;
    this.notifySuccess = false;
    this.noUnread = false;
    this.sendConfirm = false;
    this.mainMenu = true;
  }

  notifyUser(): void {
    this.notifyConfirm = false;
    this.progress = true;

    if (!this.userData) {
      this.userNotFound = true;
      return;
    }

    const data = {
      patient_id: this.userData.id,
      client_id: this.userData.custom.clientId,
      practice_id: this.userData.custom.practiceId,
    };

    this.messageService
      .notifyUserOfNewMessages(this.userData.conversation.id, data)
      .then(() => {
        this.progress = false;
        this.notifySuccess = true;
      })
      .catch((err) => {
        if (err.error.code === 400) {
          this.progress = false;
          this.noUnread = true;
        } else {
          this.progress = false;
          this.notifyFail = true;
        }
      });
  }

  resetPIN(): void {
    this.resetConfirm = false;
    this.progress = true;

    if (!this.userData) {
      this.userNotFound = true;
      return;
    }

    const patient_id = this.userData.id,
      client_id = this.userData.custom.clientId;

    this.messageService
      .resetPIN(client_id, patient_id)
      .then(() => {
        this.progress = false;
        this.resetSuccess = true;
      })
      .catch((err) => {
        if (err.error.code === 400) {
          this.progress = false;
          this.userNotFound = true;
        } else {
          this.progress = false;
          this.resetFail = true;
        }
      });
  }

  changeRestrictedStatus(restrict = false): void {
    this.statusConfirm = false;
    this.progress = true;

    if (!this.userData) {
      this.userNotFound = true;
      return;
    }

    const patient_id = this.userData.id,
      data = {
        first_name: this.userData.custom.first_name,
        last_name: this.userData.custom.last_name,
        is_sms_restricted: restrict,
      };

    this.patientService
      .updateUserDetails(patient_id, data)
      .then(() => {
        this.progress = false;
        this.statusSuccess = true;
        if (restrict) {
          this.userData.is_sms_restricted = true;
          this.messageService.setRestrictedStatus(true);
        } else {
          this.userData.is_sms_restricted = false;
          this.messageService.setRestrictedStatus(false);
        }
      })
      .catch((err) => {
        if (err.error.code === 400) {
          this.progress = false;
          this.userNotFound = true;
        } else {
          this.progress = false;
          this.statusFail = true;
        }
      });
  }
}
