<div class="modal-header" style="background: #073786; color: white">
  <h4 class="modal-title" id="modal-basic-title">
    Form Responses: <strong>{{ title }}</strong>
  </h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="body-heading mb-1">
    <div class="archive-switch">
      <div class="d-flex align-items-center">
        <mat-slide-toggle color="cyan" size="small" #attachmentSwitch (change)="onChangeSwitch()">Archived</mat-slide-toggle>
      </div>
    </div>
    <div class="search-panel">
      <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteria">
        <option value="patient_name">Patient Name</option>
        <option value="mrn">MRN</option>
      </select>
      <div class="input-group">
        <input
          #searchBar
          type="search"
          (keyup.enter)="searchRecords($event)"
          [(ngModel)]="searchKeyword"
          (search)="searchRecords($event, true)"
          class="form-control change"
          placeholder="Search"
        />
        <div class="input-group-append" (click)="searchRecords($event)">
          <span class="input-group-text">
            <img src="../../../assets/icons/daily-screening.svg" alt="search" />
          </span>
        </div>
      </div>
    </div>
    <div class="date-picker" ngbTooltip="Filter" (click)="openDateModal()">
      Filter By Date
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [ngClass]="startDate && endDate ? 'filtered' : ''"
        alt="filters"
      >
        <path
          d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
          stroke="#001737"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div
    class="table-data__wrapper table-responsive calendar-data--table"
    *ngIf="formResponseTableData?.length > 0; else emptyList"
  >
    <app-table-pagination-client
      [headers]="tableHeaders"
      [data]="formResponseTableData"
      [pageSize]="pageSize"
      [currentPage]="1"
      [hasActions]="true"
      [downloadFunction]="downloadFormResponse"
      [previewFunction]="renderFormResponse"
      [archivedFunction]="archivedFunction"
      [paginateId]="2"
      [orderByFunction]="orderBy"
      [paginationFirstPage]="firstPage"
      [paginationPrevPage]="prevPage"
      [paginationNextPage]="nextPage"
      [paginationLastPage]="lastPage"
      [serverSidePagination]="tablePages"
    >
    </app-table-pagination-client>
  </div>
  <ng-template #emptyList>
    <div class="d-flex justify-content-center" style="margin-top: 5%; margin-bottom: 7%">
      <h6>
        <img src="../../../../assets/no-result-search.png" class="emptyPic" />
        <p class="no-results">No responses found.</p>
      </h6>
    </div>
  </ng-template>
</div>
