import { AuthService } from './../../shared/services/auth.service';
import { ConversationSelectedEvent } from 'talkjs/all';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/shared/services/order.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from './../../shared/services/firebase.service';
import Talk from 'talkjs';
import { TalkService } from '../../shared/services/talk.service';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {
  PatientOrder,
  GET_PATIENT_ORDERS,
  PatientOrdersData,
  PatientOrdersTableData,
  GET_PATIENT_APPOINTMENTS_RESP,
  appointmentsTableData,
  AppointmentsData,
  Assistant,
  documentsTableData,
} from 'src/app/shared/models';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/shared/services/patient.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetUserMessageActivityReportComponent } from 'src/app/shared/components/get-user-message-activity-report/get-user-message-activity-report.component';
import { QuickResponseModalComponent } from 'src/app/shared/components/quick-response-modal/quick-response-modal.component';
import { MessagesResponseModalComponent } from 'src/app/shared/components/messages-response-modal/messages-response-modal.component';
import { SendMessageModalComponent } from 'src/app/shared/components/send-message-modal/send-message-modal.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class MessagesComponent implements OnInit {
  private inbox: Talk.Inbox;
  assistantInfo: Assistant = null;
  assistantPractice: string;
  assistantUID: string;
  orderTableHeaders: string[] = [
    'provider_name',
    'order_summary',
    'created_at',
    'date',
    'is_benefit_done',
    'order_facility',
  ];
  appointmentTableHeaders: string[] = [
    'provider_name',
    'description',
    'location',
    'date',
    'time',
    'mrn',
  ];
  documentTableHeaders: string[] = [
    'name',
    'category',
    'date_of_service',
  ];
  documentsTableData: documentsTableData[];
  selectedUser: Talk.UserData;
  selectedPatient : any;
  selectedUserOrders: PatientOrdersData[];
  selectedUserOrdersTableData: PatientOrdersTableData[];
  appointmetsTableData: appointmentsTableData[];
  selectedOrder: PatientOrder;
  patient_enrolled: boolean;
  conversation: any;
  selectedConversation: any;
  userRole: string;
  showChat: boolean = true;
  blockFlage: boolean = false;
  talkjsAdmin = false;
  bulkMessageAdmin = false;
  webUsers: any;
  emails: any;
  userSelected: string;
  filteredEmails: any;
  currentTab = 0;
  oldVersion = false;
  archive = false;
  archiveSearch = false;
  dateForSearchArchiveConversation: string | null;
  addMRN = false;
  MRN: string;
  updatedMRN: string;
  signupButton = false;
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  @ViewChild('talkjsContainerAssignedUser')
  talkjsContainerAssignedUser!: ElementRef;
  @ViewChild('talkjsContainerArchived') talkjsContainerArchived!: ElementRef;
  @ViewChild('talkjsContainerAllChats') talkjsContainerAllChats!: ElementRef;
  @ViewChild('talkjsContainerBlockedUsers')
  talkjsContainerBlockedUsers!: ElementRef;
  responseUrl: any;
  documentCategories = [
    'Office Notes',
    'Imaging',
    'Lab Results',
    'Clearances',
    'Work/School Notes',
  ];
  documentCategory: string = '';
  selectedFile: File[] = [];
  public files: Array<{ file_name: string; file_url: string }> = [];
  public selectedOrderDate: string | null = null;

  constructor(
    private talkService: TalkService,
    private firebaseService: FirebaseService,
    private orderService: OrderService,
    private patientService: PatientService,
    private messageService: MessageService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    this.userRole = await this.authService.getUserRole();
    const data = JSON.parse(localStorage.getItem('user'));
    if (!!!data) this.authService.logout();
    this.assistantInfo = await this.firebaseService.getAssistant(
      data.uid,
      data.displayName
    );
    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      // case 'practice_sante_message_admin': {
      //   this.talkjsAdmin = true;
      //   break;
      // }
      // case 'practice_sante_message_user': {
      //   this.talkjsAdmin = false;
      //   break;
      // }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
      case 'practice_admin': {
        this.talkjsAdmin = true;
        this.bulkMessageAdmin = true;
        break;
      }
      case 'practice_message_admin': {
        this.talkjsAdmin = true;
        break;
      }
      case 'practice_user_sante_community': {
        this.talkjsAdmin = false;
        // this.oldVersion = true;
        break;
      }
      case 'practice_user_sante_community_order': {
        this.talkjsAdmin = false;
        break;
      }
      case 'practice_admin_sante_community': {
        this.talkjsAdmin = true;
        // this.oldVersion = true;
        break;
      }
      case 'practice_admin_sante_community_order': {
        this.talkjsAdmin = true;
        // this.oldVersion = true;
        break;
      }
    }
    if (
      this.userRole === 'practice_admin' ||
      this.userRole === 'practice_message_admin' ||
      this.userRole === 'practice_admin_sante_community' ||
      this.userRole === 'practice_admin_sante_community_order'
    ) {
      this.webUsers = await this.getUsers();
    }

    this.emails = this.webUsers?.data.map((user) => user.email);
    this.filteredEmails = this.emails?.filter((email) => email !== data.email);
    this.filteredEmails = this.filteredEmails
      ? this.filteredEmails?.sort((a, b) => a.localeCompare(b))
      : this.filteredEmails;
    this.emails = this.emails
      ? this.emails?.sort((a, b) => a.localeCompare(b))
      : this.emails;
    this.assistantUID = data.uid;
    this.assistantPractice = data.displayName;
    this.createInbox();
  }

  private async createInbox() {
    let practice = localStorage.getItem('PRACTICE_ID');
    let practiceName = localStorage.getItem('practiceName');
    const data = JSON.parse(localStorage.getItem('user'));
    const name = data.email.split('@')[0];
    // const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (this.talkjsAdmin === false) {
      if (this.oldVersion === true) {
        const session = await this.talkService.createCurrentSession(
          practice.toLowerCase(),
          practiceName
        );
        this.inbox = await this.talkService.createInbox(session);
        this.inbox.mount(this.talkjsContainer.nativeElement);
      } else if (this.oldVersion === false) {
        const session = await this.talkService.createCurrentSession(
          practice.toLowerCase(),
          practiceName
        );
        this.inbox = await this.talkService.createInbox(session);
        this.inbox.setFeedFilter({
          custom: {
            assignedTo: ['==', data.email],
            archived: ['!=', 'true'],
            blocked: ['!=', 'true'],
          },
        });
        this.inbox.mount(this.talkjsContainer.nativeElement);
      }
    }

    if (this.talkjsAdmin === true) {
      if (this.currentTab === 0) {
        const session = await this.talkService.createCurrentSession(
          practice.toLowerCase(),
          practiceName
        );
        this.inbox = await this.talkService.createInbox(session);
        this.inbox.setFeedFilter({
          custom: {
            assignedTo: ['==', data.email],
            archived: ['!=', 'true'],
            blocked: ['!=', 'true'],
          },
        });
        this.talkjsContainer.nativeElement.innerHTML = '';
        this.inbox.mount(this.talkjsContainer.nativeElement);
      } else if (this.currentTab === 1) {
        if (this.filteredEmails && this.filteredEmails.length > 0) {
          const session = await this.talkService.createCurrentSession(
            practice.toLowerCase(),
            practiceName
          );
          this.inbox = await this.talkService.createInbox(session);
          this.inbox.setFeedFilter({
            custom: {
              archived: ['!=', 'true'],
              assignedTo: ['!oneOf', this.filteredEmails],
              blocked: ['!=', 'true'],
            },
          });
          this.talkjsContainerAllChats.nativeElement.innerHTML = '';
          this.inbox.mount(this.talkjsContainerAllChats.nativeElement);
        }
      } else if (
        this.currentTab === 2 &&
        this.userSelected?.length > 1 &&
        this.talkjsContainerAssignedUser
      ) {
        if (this.filteredEmails && this.filteredEmails.length > 0) {
          const session = await this.talkService.createCurrentSession(
            practice.toLowerCase(),
            practiceName
          );
          this.inbox = await this.talkService.createInbox(session);
          if (this.userSelected === 'All') {
            this.inbox.setFeedFilter({
              custom: {
                archived: ['!=', 'true'],
                assignedTo: ['oneOf', this.filteredEmails],
                blocked: ['!=', 'true'],
              },
            });
          } else if (this.userSelected.length > 3) {
            this.inbox.setFeedFilter({
              custom: {
                archived: ['!=', 'true'],
                assignedTo: ['==', this.userSelected],
                blocked: ['!=', 'true'],
              },
            });
          }
          this.inbox.mount(this.talkjsContainerAssignedUser.nativeElement);
        } 
      } else if (this.currentTab === 2) {
        this.signupButton = false;
      } else if (this.currentTab === 3) {
        this.signupButton = false;
        const session = await this.talkService.createCurrentSession(
          practice.toLowerCase(),
          practiceName
        );

        this.inbox = await this.talkService.createInbox(session);
        if (this.archiveSearch === true) {
          const currentDate = new Date(this.dateForSearchArchiveConversation);
          const previousDate = new Date(currentDate);
          previousDate.setDate(currentDate.getDate() - 1);
          const nextDate = new Date(currentDate);
          nextDate.setDate(currentDate.getDate() + 1);
          this.inbox.setFeedFilter({
            lastMessageTs: [
              'between',
              [previousDate.getTime(), nextDate.getTime()],
            ],

            custom: { archived: ['==', 'true'], blocked: ['!=', 'true'] },
          });
        } else if (this.archiveSearch === false) {
          this.inbox.setFeedFilter({
            custom: { archived: ['==', 'true'], blocked: ['!=', 'true'] },
          });
        }

        this.inbox.mount(this.talkjsContainerArchived.nativeElement);
      } else if (this.currentTab === 4) {
        this.signupButton = false;
        const session = await this.talkService.createCurrentSession(
          practice.toLowerCase(),
          practiceName
        );

        this.inbox = await this.talkService.createInbox(session);
        this.inbox.setFeedFilter({ custom: { blocked: ['==', 'true'] } });
        this.inbox.mount(this.talkjsContainerBlockedUsers.nativeElement);
      }
    }

    this.inbox.on('sendMessage', (data) => {
      const modifiedCustomData = {
        web_user_name: name,
        practice_id: practice.toLocaleLowerCase(),
      };

      data.override({
        custom: modifiedCustomData,
      });
    });

    this.inbox.onCustomConversationAction((event) => {
      if (event.action === 'Quick Response') {
        const quickResponseModal = this.modalService.open(
          QuickResponseModalComponent,
          {
            size: 'md',
            centered: true,
          }
        );
        quickResponseModal.result.then((result) => {
          this.inbox.messageField.setText(result.toString());
        });
      }
    });

    this.inbox.on('conversationSelected', async (data: ConversationSelectedEvent | any) => {
      this.signupButton = false;
      this.selectedUser = data.other;
      this.conversation = data.conversation;
      this.selectedConversation = data;
      this.MRN = this.selectedConversation?.conversation?.custom?.MRN;
      if (this.conversation?.id) {
        this.updateConversation(this.conversation.id, name);
      }
      if (this.selectedUser?.id.toString().length > 15) {
        this.spinnerService.show();
        this.signupButton = true;
        this.patientService
          .getPatientDetails(this.selectedUser.id)
          .then((resp) => {
            this.spinnerService.hide()
            this.selectedPatient = resp.data;
            this.messageService.setRestrictedStatus(resp.data.is_sms_restricted);
            this.inbox.createHtmlPanel({
              url: `message/notify-button?data=${JSON.stringify({
                conversation: data.conversation,
                is_sms_restricted: resp.data.is_sms_restricted,
                ...this.selectedUser,
              })}`,
              height: 27,
              show: true,
            });
          })
          .catch(() => {
            this.inbox.createHtmlPanel({
              url: `message/notify-button?data=${JSON.stringify({
                conversation: data.conversation,
                is_sms_restricted: false,
                ...this.selectedUser,
              })}`,
              height: 27,
              show: true,
            });
          })
          .finally(() => {
            this.spinnerService.hide();
          });

        this.selectedUser.custom.dob = DateTime.fromISO(this.selectedUser.custom.date_of_birth).toLocaleString(
          DateTime.DATE_SHORT
        );
        this.getPatientAppointments();
        this.getPendingOrders();
        this.getPatientDocuments();
      } else if (this.selectedUser?.id.toString().length < 15) {
        this.inbox.createHtmlPanel({
          url: `message/notify-button?data=${JSON.stringify({
            conversation: data.conversation,
            is_sms_restricted: false,
            ...this.selectedUser,
          })}`,
          height: 27,
          show: true,
        });
      } else this.resetOrdersState();
    });
  }

  selectedTalkjsUser(email) {
    this.userSelected = email;
    if (this.userSelected.length > 1) {
      this.currentTab = 2;
      this.createInbox();
    }
  }

  tabChanged = async (event) => {
    this.currentTab = event.index;
    this.userSelected = null;
    // this.inbox.destroy();
    if (event.index === 0) {
      this.blockFlage = false;
      this.archive = false;
      this.conversation = null;
      this.selectedConversation = null;
      this.archiveSearch = false;
      this.createInbox();
    }
    if (event.index === 1) {
      this.blockFlage = false;
      this.archive = false;
      this.conversation = null;
      this.selectedConversation = null;
      this.archiveSearch = false;
      this.createInbox();
    }
    if (event.index === 2) {
      this.blockFlage = false;
      this.archive = false;
      this.conversation = null;
      this.selectedConversation = null;
      this.archiveSearch = false;
      this.createInbox();
    }
    if (event.index === 3) {
      this.blockFlage = false;
      this.archive = true;
      this.archiveSearch = false;
      this.dateForSearchArchiveConversation = null;
      this.createInbox();
    }
    if (event.index === 4) {
      this.blockFlage = true;
      this.archive = false;
      this.archiveSearch = false;
      this.createInbox();
    }
  };

  sendMessage = () => {
    this.modalService.open(SendMessageModalComponent, {
      size: 'lg',
      centered: true,
    });
  };

  downloadUserActivityReport = async () => {
    this.modalService.open(GetUserMessageActivityReportComponent, {
      size: 'md',
      centered: true,
    });
  };

  clearSearch = () => {
    this.archive = true;
    this.dateForSearchArchiveConversation = null;
    this.archiveSearch = false;
    this.createInbox();
  };

  searchArchiveConversations = async () => {
    if (this.dateForSearchArchiveConversation) {
      this.userSelected = null;
      this.inbox.destroy();
      this.archive = true;
      this.archiveSearch = true;
      this.createInbox();
    }
  };

  archiveTalkjsConversation = async (conversationId) => {
    Swal.fire({
      title: 'Archive ?',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        await this.talkService
          .archiveTalkjsConversation(conversationId, this.conversation)
          .then((dta) => {
            this.toastr.success('Conversation Archived');
          })
          .catch((error) => {
            this.toastr.error(error);
            this.spinnerService.hide();
          })
          .finally(() => {
            this.spinnerService.hide();
            this.selectedUser = null;
            this.conversation = null;
            this.selectedConversation = null;
            this.ngOnInit();
          });
      }
    });
  };

  downloadTalkjsConversation = async (conversationId) => {
    this.spinnerService.show();
    this.talkService.downloadTalkjsConversation(conversationId).subscribe(
      (data) => {
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `messages.csv`;
        link.click();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
        this.selectedUser = null;
        this.conversation = null;
        this.selectedConversation = null;
        this.ngOnInit();
      }
    );
  };

  updateConversation = async (conversationId, name) => {
    this.showChat = false;
    await this.talkService
      .updateConversation(conversationId, {
        web_user_first_name: name,
      })
      .catch((error) => {
        this.toastr.error(error);
        this.spinnerService.hide();
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  };

  updateMrnInConversation = async (conversationId: any) => {
    this.addMRN = false;
    this.showChat = false;
    this.spinnerService.show();
    await this.talkService
      .ConnectMrnWithGuest(conversationId, this.updatedMRN)
      .then((res) => {
        (this.MRN = this.updatedMRN), this.toastr.info('MRN updated');
      })
      .catch((error) => {
        this.toastr.error(error);
        this.spinnerService.hide();
        this.addMRN = false;
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  };

  toggleAddMRN = async (toggleStatus) => {
    if (toggleStatus !== 'close') {
      this.updatedMRN = this.MRN;
    }
    this.addMRN = !this.addMRN;
  };

  blockUser = async (conversationId, block) => {
    this.showChat = false;
    Swal.fire({
      title: 'Block / Unblock ?',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (block) {
          await this.talkService
            .updateConversation(conversationId, {
              blocked: 'true',
            })
            .then((res) => {
              this.toastr.info('User Blocked');
            })
            .catch((error) => {
              this.toastr.error(error);
              this.spinnerService.hide();
            })
            .finally(() => {
              this.spinnerService.hide();
              this.selectedUser = null;
              this.conversation = null;
              this.selectedConversation = null;
              this.ngOnInit();
            });
        } else if (!block) {
          await this.talkService
            .updateConversation(conversationId, {
              blocked: 'false',
            })
            .then((res) => {
              this.toastr.info('User Unblocked');
            })
            .catch((error) => {
              this.toastr.error(error);
              this.spinnerService.hide();
            })
            .finally(() => {
              this.spinnerService.hide();
              this.selectedUser = null;
              this.conversation = null;
              this.selectedConversation = null;
              this.ngOnInit();
            });
        }
      }
    });
  };

  async getLink(): Promise<void> {
    const patient_id = this.selectedUser.id.toString();

    try {
      const response = await this.messageService.sendLinkToPatient(patient_id);
      const url = response.data.short_url;
      this.responseUrl = url;
      this.toastr.success('Patient link generated successfully');

      if (this.inbox) {
        this.addUrlToMessage(this.responseUrl);
      }
    } catch (error) {
      this.toastr.error('Failed to get the link.');
    }
  }

  private async addUrlToMessage(url: string) {
    if (this.inbox) {
      let userMessage = await this.inbox.messageField.getText();
      this.inbox.messageField.setText(`${userMessage} ${url}`);
    }
  }

  handleReload(reload: boolean) {
    this.spinnerService.hide();
    this.selectedUser = null;
    this.conversation = null;
    this.selectedConversation = null;
    this.ngOnInit();
  }

  async getUsers() {
    this.spinnerService.show();
    return await this.talkService
      .getUsers()
      .catch((error) => {
        this.toastr.error(error);
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  getPendingOrders(): void {
    // this.spinnerService.show();
    const date = new Date();
    const currentDate = moment(date).startOf('days');
    const { practiceId, clientId } = this.selectedUser?.custom;
    this.orderService
      .getPatientOrders(this.selectedUser.id, clientId, practiceId)
      .then((resp: GET_PATIENT_ORDERS) => {
        this.spinnerService.hide();
        this.selectedUserOrders = resp.data.data || [];
        this.patient_enrolled = resp.data.enrolled;

        if (this.selectedUserOrders.length > 0) {
          const firstOrder = this.selectedUserOrders[0];
          this.selectedOrderDate = firstOrder.order_date
            ? moment.parseZone(firstOrder.order_date).format('MM/DD/YYYY')
            : null;
        }

        this.selectedUserOrdersTableData = this.selectedUserOrders.map(
          (order: PatientOrdersData) => ({
            contract_description: order.contract_description,
            id: order.order_id,
            order_id: order.order_id,
            provider_name: order.provider.name,
            order_summary: order.order_summary,
            patient_phone: order.patient_phone,
            date:
              (order.order_date &&
                moment.parseZone(order.order_date).format('MM/DD/YYYY')) ||
              '',
            is_benefit_done: order.is_benefit_done ? 'Yes' : 'No',
            order_facility: order.order_facility,
            order_date:
              (order.order_date &&
                moment.parseZone(order.order_date).format('MM/DD/YYYY')) ||
              '',
            created_at:
              moment.parseZone(order.created_at).format('MM/DD/YYYY') +
                '(' +
                currentDate.diff(
                  moment(order.created_at).startOf('days'),
                  'days'
                ) +
                ' days ago)' || '',
            provider: order.provider,
            patient: {
              ...order.patient,
              date_of_birth: DateTime.fromISO(
                order.patient.date_of_birth
              ).toLocaleString(DateTime.DATE_SHORT),
            },
          })
        );
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  getPatientAppointments(): void {
    // this.spinnerService.show();
    this.appointmentService
      .getPatientAppointments(this.selectedUser.id.toString())
      .then((resp: GET_PATIENT_APPOINTMENTS_RESP) => {
        this.spinnerService.hide();
        this.appointmetsTableData = resp.data.map(
          (appointment: AppointmentsData) => ({
            description: appointment.event,
            date:
              (appointment.convert_date &&
                moment
                  .parseZone(appointment.convert_date)
                  .format('MM/DD/YYYY')) ||
              '',
            time: moment(appointment.convert_time, 'HH:mm:ss').format('h:mm A'),
            provider_name: appointment.provider.description,
            location: appointment.location.description,
            mrn: appointment.unique_patient.med_rec_nbr,
          })
        );
        this.appointmetsTableData = this.appointmetsTableData.sort((a, b) =>
          moment(b.date, 'DD-MM-YYYY').diff(moment(a.date, 'DD-MM-YYYY'))
        );
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  private resetOrdersState(): void {
    this.selectedUserOrders = [];
    this.appointmetsTableData = [];
    this.selectedUserOrdersTableData = [];
    this.selectedOrder = null;
  }

  selectOrder = (order: PatientOrder): void => {
    if (!order) {
      this.selectedOrder = null;
      return;
    }

    this.selectedOrder = order;
  };

  uploadFile = () => {
    this.modalService.open(MessagesResponseModalComponent, {
      size: 'lg',
      centered: true,
    });
  };

  getPatientDocuments(): void {
    const patientId = this.selectedUser?.id;
    if (!patientId) {
      console.error('Patient ID is missing');
      return;
    }

    this.orderService.getPatientDocuments(patientId.toString()).subscribe(
      (response: any) => {
        console.log("RESP",response);
        this.documentsTableData = response.data.map((doc: any) => ({
          name: doc.file_name || 'N/A',
          category: doc.category || 'N/A',
          date_of_service: doc.date_of_service || 'N/A',
          created_by: doc.created_by || 'N/A',
          file_url: doc.file_url,
          id: doc.id,
          order_id: doc.order_id,
        }));
        
      },
      (error) => {
        console.error('Error fetching patient documents:', error);
        this.toastr.error('Failed to load documents.');
      }
    );
  }

  onFileSelected(event: any) {
    this.selectedFile = Array.from(event.target.files) as File[];
  }

  uploadDocument() {
    this.spinnerService.show();
    if (!this.selectedFile || !this.documentCategory) return;
    this.orderService
      .uploadAttachment(
        this.selectedFile,
        this.selectedUser.custom.practiceId,
        this.selectedUser.custom.clientId,
        '-1',
        'false',
        '',
        this.documentCategory,
        this.selectedOrderDate,
        this.selectedUser.id.toString(),
        false,
       
      )
      .subscribe(
        (resp) => {
          if (resp.data) {
            this.spinnerService.hide();
            this.toastr.success('File has been uploaded successfully');
            (resp.data || []).forEach((file) => {
              const fileObj = {
                file_name: file.file_name,
                file_url: file.file_url,
              };
              this.files.push(fileObj);
            });
            this.getPatientDocuments();
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.toastr.error('Document upload failed.');
          console.error('Error uploading document:', error);
        }
      );
  }

  previewDocument(file: any): void {
    if (!file.file_url) {
      console.error('No file URL provided for preview.');
      return;
    }
    window.open(file.file_url, '_blank');
  }

  deleteDocument = (document: any): void => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinnerService.show();
        this.orderService
          .deleteOrderAttachment('-1', document.id, !!result.value)
          .then((resp: any) => {
            if (resp.code !== 200)
              return self.toastr.error("Could't complete request due to error");

            self.toastr.success('Attachment Deleted.', 'Success.');

            const index = self.documentTableHeaders.indexOf(document);
            self.documentsTableData.splice(index, 1);
            self.spinnerService.hide();
          });
      }
    });
  };

  // checkRestriction(): void {
  //   this.spinnerService.show();

  //   this.patientService
  //     .getPatientDetails(this.selectedUser.id)
  //     .then((resp) => {
  //       this.spinnerService.hide();
  //       if (resp.data.is_sms_restricted) {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'SMS Restricted!',
  //           text: `Failed to send SMS, SMS feature has been blocked for this user. Unblock messages in order to use this feature.`,
  //           showConfirmButton: false,
  //           timer: 4000,
  //         });
  //       } else this.sendSMS();
  //     })
  //     .catch(() => {
  //       if (this.messageService.getRestrictedStatus())
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'SMS Restricted!',
  //           text: `Failed to send SMS, SMS feature has been blocked for this user. Unblock messages in order to use this feature.`,
  //           showConfirmButton: false,
  //           timer: 4000,
  //         });
  //       else this.sendSMS();
  //     });
  // }

  // sendSMS(body: string = ''): void {
  //   Swal.fire({
  //     title: 'Send SMS',
  //     icon: 'info',
  //     html: `@<b>${this.selectedUser.name}</b>`,
  //     input: 'textarea',
  //     inputValue: body,
  //     inputAttributes: {
  //       required: 'true',
  //     },
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return 'Please Enter the Message First.';
  //       }
  //     },
  //     inputPlaceholder: `Enter Message`,
  //     showCancelButton: true,
  //     confirmButtonColor: '#073786',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Send',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let message = result.value;
  //       Swal.fire({
  //         title: 'Confirmation',
  //         icon: 'info',
  //         html: `Are you sure you want to send this SMS?
  //         <div class="mt-4" style="border-top: 5px dotted grey; border-bottom: 5px dotted grey">
  //         <p class="mt-3 mb-3">${result.value}</p>
  //         </div>`,
  //         allowOutsideClick: false,
  //         showCancelButton: true,
  //         confirmButtonColor: '#073786',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Confirm',
  //         cancelButtonText: 'Change',
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           this.spinnerService.show();
  //           const data = {
  //             patient_id: this.selectedUser.id,
  //             client_id: this.selectedUser.custom.clientId,
  //             practice_id: this.selectedUser.custom.practiceId,
  //             body: message,
  //           };
  //           this.messageService
  //             .sendUserSMS(this.conversation.id, data)
  //             .then(() => {
  //               this.spinnerService.hide();
  //               Swal.fire({
  //                 icon: 'success',
  //                 title: 'SMS Sent!',
  //                 showConfirmButton: false,
  //                 timer: 2000,
  //               });
  //             })
  //             .catch((err) => {
  //               this.spinnerService.hide();
  //               Swal.fire({
  //                 icon: 'error',
  //                 title: 'Unsuccessful!',
  //                 text: 'Failed to send SMS, please try again.',
  //                 showConfirmButton: false,
  //                 timer: 2000,
  //               });
  //             });
  //         } else this.sendSMS(message);
  //       });
  //     }
  //   });
  // }
}
