import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { GenericResp, GET_ALL_DICTATIONS_RESP } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AudioDictationService {
  constructor(private http: HttpClient) {}

  async getAudioDictations(
    client_id: string,
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByProvider: string,
    is_archived: boolean
  ): Promise<GET_ALL_DICTATIONS_RESP> {
    return this.http
      .get<GET_ALL_DICTATIONS_RESP>(`${environment.AUDIO_DICTATION}/client/${client_id}/practice/${practice_id}`, {
        params: {
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          search,
          searchCriteria,
          filterByProvider,
          is_archived: `${!!is_archived}`,
        },
      })
      .toPromise();
  }

  async getMissingAudioDictations(
    client_id: string,
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    search: string,
    searchCriteria: string,
    filterByProvider: string,
    is_archived: boolean
  ): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .get<any>(`${environment.AUDIO_DICTATION}/client/${client_id}/practice/${practice_id}/missing-files`, {
        headers: {
          Authorization: `${user.stsTokenManager.accessToken}`,
        },
        params: {
          page: `${page}`,
          limit: `${limit}`,
          search,
          searchCriteria,
          filterByProvider,
          is_archived: 'false',
        },
      })
      .toPromise();
  }

  async sendMissingAudioDictationsNotifications(client_id: string, practice_id: string): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .post<any>(
        `${environment.AUDIO_DICTATION}/client/${client_id}/practice/${practice_id}/send-missing-notification`,
        {
          headers: {
            Authorization: `${user.stsTokenManager.accessToken}`,
          },
        }
      )
      .toPromise();
  }

  async deleteAudioDictation(audio_id: string): Promise<GenericResp> {
    return await this.http.delete<GenericResp>(`${environment.AUDIO_DICTATION}/${audio_id}`).toPromise();
  }

  async deleteUnsucessfulAudios(practice_id : string): Promise<GenericResp> {
    return await this.http.delete<GenericResp>(`${environment.AUDIO_DICTATION}/practice/${practice_id}/missing-dictations`).toPromise();
  }

  async updateAudioDictation(audio_id: string, is_archived: boolean): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.AUDIO_DICTATION}/${audio_id}/archive/${is_archived}`, null)
      .toPromise();
  }

  async updateAudioTranscription(id: string, transcription_text: string): Promise<GenericResp> {
    const body = {transcription_text} ; 
    return await this.http
      .put<GenericResp>(`${environment.AUDIO_DICTATION}/${id}`, body)
      .toPromise();
  } 
}
