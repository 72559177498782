import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClient) {}

  getAllStorageFiles(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.stsTokenManager.accessToken;
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  
   
    return this.http.get<any>(
      `${environment.BASE_URL}/insurance-forms`, 
      { headers } 
    );
  }

 uploadPdfFile(file: File, insurance: string): Observable<any> {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.stsTokenManager.accessToken;
  
  const header = new HttpHeaders({
    'Authorization': `Bearer ${token}`
  });
  
  const formData = new FormData();
  formData.append('file', file);         
  formData.append('insurance', insurance); 

  return this.http.post<any>(`${environment.BASE_URL}/insurance-forms/upload`, formData, { headers: header });
}

}
