<div class="page">
  <div class="page-title__bar">
    <div>
      <h2 class="title">Transcriptions</h2>
    </div>

    <div class="right-container">
      <div class="search__panel ">
        <input
          #searchBar
          type="search"
          (keyup.enter)="searchRecords($event)"
          [(ngModel)]="searchKeyword"
          (search)="searchRecords($event, true)"
          class="form-control change"
          placeholder="Search"
        />
        <div class="input-group-append" (click)="searchRecords($event)">
          <span class="input-group-text">
            <img src="../../../assets/icons/daily-screening.svg" alt="search" />
          </span>
      </div>
    </div>

    <div class="filter__panel btn-demo">
      <div class="d-flex align-items-center">
        <button class="btn add-user-btn" (click)="uploadTranscription()">Upload For Transcriptions</button>
      </div>
    </div>
    </div>
   
  </div>
  <div class="tab-content" id="myTabContent">
      <div class="right__panel calendar-data__wrapper">
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="tableHeaders"
            [data]="transcriptionTableData"
            [hasActions]="true"
            [deleteFunction]="deleteTranscription"
            [downloadTranscriptionFunction]="downloadTranscription"
            [downloadFunction]="downloadAudioFile"
            [pageSize]="10"
            [currentPage]="1"
          >
          </app-table-pagination-client>
        </div>
      </div>
  </div>
</div>
