import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from '@angular/core';
// import { imgRecord } from './forms';
import { SignaturePad } from 'angular2-signaturepad';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';
// import emailMask from 'text-mask-addons/dist/emailMask';
// import {
//   base64ToFile,
//   ImageCroppedEvent,
// } from 'ngx-image-cropper';

@Component({
  selector: 'lib-vsaPreviewForms',
  templateUrl: './vsa-preview-forms.component.html',
  styleUrls: ['./vsa-preview-forms.component.scss'],
})
export class VsaPreviewFormsComponent implements OnInit, OnChanges {
  @Input() formFields: any;
  @Input() renderPreview: any;
  @Input() renderResponse: any;
  @Input() patientData: any;
  @Input() task_id: string='';
  @Input() isPWAForm: boolean = false;
  @Output() saveForm = new EventEmitter<object>();
  @ViewChildren(SignaturePad) signaturePad: QueryList<SignaturePad>;

  styleArray: any[] = [];
  formArray: any[] = [];
  imgRecord: any[] = [];
  styles: any;
  signatureFile: { [x: string]: File | null } = {};
  subFieldFormArray: any[] = [];
  subFieldStyleArray: any[] = [];
  subFieldstyles: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor() {}

  ngOnInit(): void {
    if (
      this.renderPreview ||
      this.renderResponse ||
      this.isPWAForm ||
      this.formFields
    ) {
      this.setStyles();
      this.setSubFieldStyles();
    }

    this.formFields.forEach((field:any) => {
      if (field.field_type === 8) {
        if (field.field_responses && field.field_responses.length > 0) {
          field.responseArray = [];
          let responseItem = [];
          field.field_responses.forEach((element:any) => {
            responseItem = element.response.split('||c||');
            field.responseArray.push(responseItem);
          });
        }
      }
      if (field.field_type === 9 && field.showFields) {
        field.sub_fields.forEach((sub:any) => {
          if (sub.field_type === 8) {
            if (sub.field_responses && sub.field_responses.length > 0) {
              sub.responseArray = [];
              let responseItem = [];
              sub.field_responses.forEach((element:any) => {
                responseItem = element.response.split('||c||');
                sub.responseArray.push(responseItem);
              });
            }
          }
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  checkMultiOptions(field: any, opt: any) {
    return !!field.field_responses.filter((r:any) => r.response === opt.option)
      .length;
  }

  setDatagridValue (field_type:any,field_id:any,option_is:any,option:any,fieldOption:any,index:any,event:any){
  }

  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    canvasWidth: 300,
    canvasHeight: 70,
  };

  saveSignature(field_id:any, field_type:any) {
    this.signaturePad['_results'].forEach((element:any, index:any) => {
      if (element.elementRef.nativeElement.id === field_id) {
        let blob = this.dataURItoBlob(element.signaturePad.toDataURL());
        let file = new File(
          [blob],
          'signature(' + index + 1 + ')__' + this.task_id,
          {
            type: "'image/jpeg'",
          }
        );
        this.signatureFile[field_id] = file;
      }
    });
    this.setValues(field_type, field_id, '', this.signatureFile[field_id]);
  }

  clearSignature(field_id:any) {
    this.signatureFile[field_id] = null;
    this.formArray = this.formArray.filter((x) => x.field_id !== field_id);
    this.signaturePad['_results'].forEach((element:any) => {
      if (element.elementRef.nativeElement.id === field_id) {
        element.signaturePad.clear();
      }
    });
  }

  handleAddressChange(address: any, fieldType:any, fieldId:any, subField?: string) {
    if (subField) {
      this.setSubFieldValues(
        fieldId,
        fieldType,
        subField,
        '',
        address
      );
    } else this.setValues(fieldType, fieldId, '', address);
  }

  imageChangeEvent(field_id: any, field_type: any, event: any): void {
    const index = this.imgRecord.findIndex((img) => img.field_id === field_id);
    const file = event.target.files[0];
    const response = event;
    let reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event: any) => {
      if (index >= 0) {
        this.imgRecord[index].field_id = field_id;
        this.imgRecord[index].field_type = field_type;
        this.imgRecord[index].response = response;
        this.imgRecord[index].cropped = '';
        this.imgRecord[index].url = event.target.result;
        this.imgRecord[index].file = file;
      } else {
        this.imgRecord.push({
          field_id,
          field_type,
          response,
          cropped: '',
          url: event.target.result,
          file,
        });
      }
      this.setValues(field_type, field_id, '', file);
    };
  }

  findImageWithMask(field_id: any) {
    if (this.imgRecord && this.imgRecord?.length) {
      if (
        this.imgRecord[
          this.imgRecord.findIndex((img) => img.field_id === field_id)
        ]
      ) {
        const index = this.formFields.findIndex(
          (field:any) => field.id === field_id
        );

        if (this.formFields[index].style) {
          if (typeof this.formFields[index].style === 'string') {
            const style = JSON.parse(this.formFields[index].style);
            if (style.mask === 1) return 1;
          } else {
            const style = this.formFields[index].style;
            if (style.mask === 1) return 1;
          }
        }
        return 0;
      }
      return false;
    } else return false;
  }

  getImage(field_id: any) {
    if (this.imgRecord && this.imgRecord?.length) {
      const index = this.imgRecord.findIndex(
        (img) => img.field_id === field_id
      );
      return this.imgRecord[index].url;
    } else return false;
  }

  getImageForCropper(field_id: any) {
    if (this.imgRecord && this.imgRecord?.length) {
      return this.imgRecord[
        this.imgRecord.findIndex((img) => img.field_id === field_id)
      ].response;
    } else return false;
  }

  getCroppedImage(field_id: any) {
    if (this.imgRecord && this.imgRecord?.length) {
      if (
        this.imgRecord[
          this.imgRecord.findIndex((img) => img.field_id === field_id)
        ].cropped
      ) {
        return this.imgRecord[
          this.imgRecord.findIndex((img) => img.field_id === field_id)
        ].cropped;
      } else return false;
    } else return false;
  }

  // setCroppedImage(field_id: any, field_type: any, event: ImageCroppedEvent) {
  //   const index = this.imgRecord.findIndex((img) => img.field_id === field_id);
  //   this.imgRecord[index].cropped = event.base64;

  //   const canvas = event.base64;
  //   const img = base64ToFile(canvas);
  //   const file = new File([img], this.imgRecord[index].file.name + '(Cropped)');

  //   this.setValues(field_type, field_id, '', file);
  // }

  dataURItoBlob(dataURI:any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  textStyle(fieldId:any, index:any) {
    if (this.styleArray && this.styleArray[index]) {
      if (this.styleArray[index]['fieldId'] === fieldId) {
        return this.styleArray[index]['styleCSS'];
      } else return;
    } else return;
  }

  setValues(field_type:any, field_id:any, option_id:any, response:any, event?:any) {
    const index = this.formArray.findIndex(
      (field) => field.field_id === field_id
    );

    if (index >= 0) {
      //For Checkboxes
      if (field_type === 5) {
        if (!event.target.checked) {
          this.formArray = this.formArray.filter(
            (x) => x.response !== response
          );
        } else {
          this.formArray.push({ field_id, field_type, response });
        }
      } //For Other Fields
      else {
        this.formArray[index].field_id = field_id;
        this.formArray[index].field_type = field_type;
        this.formArray[index].response = response;
      }
    } else {
      this.formArray.push({ field_id, field_type, response });
    }
  }

  textAlignment(type:any) {
    if (type === 0) {
      return { 'text-align': 'left'};
    } else if (type === 1) {
      return { 'text-align': 'center' };
    } else if (type === 2) {
      return { 'text-align': 'right', 'margin-right': '15px' };
    }
    return { 'text-align': 'left' };
  }

  upsert(element:any) {
    // (1)
    const i = this.styleArray.findIndex(
      (_item) => _item.fieldId === element.fieldId
    );
    if (i > -1) this.styleArray[i] = element; // (2)
    else this.styleArray.push(element);
  }

  setStyles() {
    var masking:any;
    var alignment = {};
    if (this.formFields) {
      this.formFields.forEach((element:any) => {
        if (element.style != null || element.style != undefined) {
          try {
            if (typeof element.style === 'string') {
              this.styles = JSON.parse(element.style);
            } else {
              this.styles = element.style;
            }
            if (element.field_type === 3 && this.styles) {
              masking = this.maskPattern(this.styles.mask);
            }
            const styledProperty = {
              'font-weight': this.styles.bold ? 'bold' : 'normal',
              'text-decoration': this.styles.underline ? 'underline' : 'unset',
              'font-size': this.styles.font_size + 'px',
            };

            alignment = this.textAlignment(this.styles.align);

            this.upsert({
              fieldId: element.id,
              styleCSS: { ...styledProperty, ...alignment },
              mask: this.styles.mask,
              pattern: masking,
            });
          } catch (err) {
            this.styleArray.push({
              fieldId: element.id,
              styleCSS: {
                'font-weight': 'normal',
                'text-decoration': 'unset',
                'font-size': '14px',
              },
              mask: 0,
              pattern: masking,
            });
          }
        } else {
          this.styleArray.push({
            fieldId: element.id,
            styleCSS: {
              'font-weight': 'normal',
              'text-decoration': 'unset',
              'font-size': '14px',
            },
            mask: 0,
            pattern: masking,
          });
        }
      });
    }
  }

  getMasking(fieldId:any, index:any) {
    if (this.styleArray[index]['fieldId'] === fieldId) {
      return this.styleArray[index]['mask'];
    } else return 0;
  }

  maskPattern(mask:any) {
    if (mask == 0) {
      return;
    } else if (mask == 1) {
      return [/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/];
    } else if (mask == 2) {
      return [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    } else if (mask == 3) {
      return [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]; //phone number /\d{4}/
    }
    return;
  }

  getMaskPattern(fieldId:any, index:any) {
    if (this.styleArray[index]['fieldId'] === fieldId) {
      return this.styleArray[index]['pattern'];
    } else return 'text';
  }

  handleSaveForm() {
    this.saveForm.emit({
      form_responses: this.formArray,
    });
  }

  addNewRow(field:any) {
    if (field.rows) {
      field.rows = field.rows + 1;
    } else {
      field.rows = 2;
    }
  }

  getSubFieldMaskPattern(fieldId:any, index:any) {
    if (this.subFieldStyleArray[index]['subFieldId'] === fieldId) {
      return this.subFieldStyleArray[index]['pattern'];
    } else return 'text';
  }

  getSubFieldMasking(fieldId:any, index:any) {
    if (this.subFieldStyleArray[index]['subFieldId'] === fieldId) {
      return this.subFieldStyleArray[index]['mask'];
    } else return 0;
  }

  textSubFieldStyle(subFieldId:any, index:any) {
    if (this.subFieldStyleArray && this.subFieldStyleArray[index]) {
      if (this.subFieldStyleArray[index]['subFieldId'] === subFieldId) {
        return this.subFieldStyleArray[index]['styleCSS'];
      } else return;
    } else return;
  }

  upsertSubField(element:any) {
    // (1)
    const i = this.subFieldStyleArray.findIndex(
      (_item) => _item.subFieldId === element.subFieldId
    );
    if (i > -1) this.subFieldStyleArray[i] = element; // (2)
    else this.subFieldStyleArray.push(element);
  }
  // onFileUpload(event, fieldType, fieldId) {
  //   this.spinner.show();
  //   this.file = event.target.files;
  //   this.saveImage(fieldType, fieldId, this.file[0]);
  // }
  setSubFieldStyles() {
    var masking:any;
    var alignment = {};
    if (this.formFields) {
      this.formFields
        .filter((x:any) => x.field_type === 9)
        .forEach((fields:any) => {
          fields.sub_fields.forEach((element:any) => {
            if (element.style != null || element.style != undefined) {
              if (typeof element.style === 'string') {
                this.subFieldstyles = JSON.parse(element.style);
              } else {
                this.subFieldstyles = element.style;
              }
              if (element.field_type === 3 && this.subFieldstyles) {
                masking = this.maskPattern(this.subFieldstyles.mask);
              }
              const styledProperty = {
                'font-weight': this.subFieldstyles.bold ? 'bold' : 'normal',
                'text-decoration': this.subFieldstyles.underline
                  ? 'underline'
                  : 'unset',
                'font-size': this.subFieldstyles.font_size + 'px',
              };

              alignment = this.textAlignment(this.subFieldstyles.align);

              this.upsertSubField({
                subFieldId: element.id,
                styleCSS: { ...styledProperty, ...alignment },
                mask: this.subFieldstyles.mask,
                pattern: masking,
              });
            } else {
              this.subFieldStyleArray.push({
                subFieldId: element.id,
                styleCSS: {
                  'font-weight': 'normal',
                  'text-decoration': 'unset',
                  'font-size': '14px',
                },
                mask: 0,
                pattern: masking,
              });
            }
          });
        });
    }
  }

  setSubFieldDatagridValue(
    fieldId:any,
    type:any,
    subFieldId:any,
    optionId:any,
    option:any,
    options:any,
    index:any,
    event?:any
  ) {
    if (type == 8) {
      let formArrayOptions = this.subFieldFormArray.filter(
        (x) => x.id === subFieldId + ''
      );
      if (formArrayOptions.length > 0) {
        this.subFieldFormArray = this.subFieldFormArray.filter(
          (x) => x.id !== subFieldId + ''
        );
        formArrayOptions = formArrayOptions[0].options.filter(
          (x:any) => x.row !== index || x.id !== optionId
        );
        formArrayOptions.push({
          id: optionId,
          option: event.target.value,
          row: index,
        });
        if (formArrayOptions.length === 0) {
          this.subFieldFormArray = this.subFieldFormArray.filter(
            (x) => x.id !== subFieldId + ''
          );
        } else {
          this.subFieldFormArray.push({
            field_id: fieldId,
            id: subFieldId,
            response: event.target.value,
            options: formArrayOptions,
          });
        }
      } else {
        this.subFieldFormArray.push({
          field_id: fieldId,
          id: subFieldId,
          response: event.target.value,
          options: [{ id: optionId, option: event.target.value, row: index }],
        });
      }
    }
  }

  setSubFieldValues(fieldId:any, type:any, subFieldId:any, optionId:any, option:any, event?:any) {
    if (type === 2) {
      //--location
      this.subFieldFormArray = this.subFieldFormArray.filter(
        (x) => x.id !== subFieldId
      );
      this.subFieldFormArray.push({
        field_id: fieldId,
        id: subFieldId,
        response: option,
      });
    }
    if (type === 4) {
      //--file upload
      this.subFieldFormArray = this.subFieldFormArray.filter(
        (x) => x.id !== subFieldId
      );
      this.subFieldFormArray.push({
        field_id: fieldId,
        id: subFieldId,
        response: option,
      });
    }
    if (type === 6) {
      //--signature
      this.subFieldFormArray = this.subFieldFormArray.filter(
        (x) => x.id !== subFieldId
      );
      this.subFieldFormArray.push({
        field_id: fieldId,
        id: subFieldId,
        response: option,
      });
    }
    if (type === 3 || type === 7) {
      //---TextField & text Area
      this.subFieldFormArray = this.subFieldFormArray.filter(
        (x) => x.id !== subFieldId
      );
      this.subFieldFormArray.push({
        field_id: fieldId,
        id: subFieldId,
        response: event.target.value,
      });
    }
    if (type === 1) {
      //--Radio Button
      this.subFieldFormArray = this.subFieldFormArray.filter(
        (x) => x.id !== subFieldId
      );
      this.subFieldFormArray.push({
        field_id: fieldId,
        id: subFieldId,
        response: option,
      });
    }

    if (type === 5) {
      //--CheckBox
      let formArrayOption = this.subFieldFormArray.filter(
        (x) => x.id === subFieldId
      );
      if (formArrayOption.length > 0) {
        this.subFieldFormArray = this.subFieldFormArray.filter(
          (x) => x.id !== subFieldId
        );
        formArrayOption = formArrayOption[0].options.filter(
          (x:any) => x.id !== optionId + ''
        );
        if (event.target.checked) {
          formArrayOption.push({ id: optionId, option: option });
        }
        if (formArrayOption.length === 0) {
          this.subFieldFormArray = this.subFieldFormArray.filter(
            (x) => x.id !== subFieldId
          );
        } else {
          this.subFieldFormArray.push({
            field_id: fieldId,
            id: subFieldId,
            response: option,
            options: formArrayOption,
          });
        }
      } else {
        this.subFieldFormArray.push({
          field_id: fieldId,
          id: subFieldId,
          response: option,
          options: [{ id: optionId, option: option }],
        });
      }
    }
  }
}
