import { Component, OnInit, Input, EventEmitter, Output, input } from '@angular/core';
import { TalkService } from '../../services/talk.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RenameModalComponent } from '../rename-modal/rename-modal.component';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-conversation-management',
  templateUrl: './conversation-management.component.html',
  styleUrls: ['./conversation-management.component.scss'],
})
export class ConversationManagementComponent implements OnInit {
  @Input() data: any;
  @Input() selectedPatient : any;
  @Input() webUsers: any;
  @Input() admin: any;
  @Output() reloadMessages: EventEmitter<boolean> = new EventEmitter<boolean>();
  catagory: string;
  showDatepicker: boolean = false;
  startDate: string | null;
  endDate: string | null;

  email: any;
  constructor(
    private orderService: OrderService,
    private talkService: TalkService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  changeCatagory(event) {
    this.catagory = event.target.value;
  }

  changeEmail(event) {
    if (event.target.value) {
      this.email = event.target.value;
    }
  }

  downloadReport = async (): Promise<void> => {
    let currentDate = new Date();
    let endDate = new Date(this.endDate);
    if (endDate.getTime() > currentDate.getTime()) {
      this.toastr.error('End date is in the future !');
      return;
    }
    this.spinnerService.show();
    this.talkService.exportCSV(this.startDate, this.endDate).subscribe(
      (data) => {
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `conversations.csv`;
        link.click();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      }
    );
  };

  async updateConversation() {
    Swal.fire({
      title: 'Update Category ?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        await this.talkService
          .updateConversation(this.data.conversation.id, {
            catagory: this.catagory ? this.catagory : 'UC',
          })
          .then((dta) => {
            this.toastr.success('Category Assigned');
            this.reload();
          })
          .catch((error) => {
            this.toastr.error(error);
          })
          .finally(() => {
            this.spinnerService.hide();
          });
      }
    });
  }

  reload() {
    this.reloadMessages.emit(true);
  }

  async AssignUser() {
    Swal.fire({
      title: 'Assign chat',
      text: 'This will assign chat to the selected user, are you sure you want to continue?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        await this.talkService
          .assignConversation(this.data.conversation.id, {
            conversation: this.data.conversation,
            assignedTo: this.email ? this.email : this.webUsers[0],
          })
          .then((dta) => {
            const e = this.email ? this.email : this.webUsers[0];
            this.toastr.success('Assigned To ' + e);
            this.reload();
          })
          .catch((error) => {
            this.toastr.error(error);
          })
          .finally(() => {
            this.spinnerService.hide();
          });
      }
    });
  }

  async getConversationDetails() {
    this.spinnerService.show();
    await this.talkService
      .getConversationDetails()
      .then((dta) => {
        // this.toastr.success('Assigned To ' + this.email);
      })
      .catch((error) => {
        this.toastr.error(error);
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  updatePatient(): void {
    const renameRef = this.modalService.open(RenameModalComponent, {
      centered: true,
    });
    renameRef.componentInstance.first_name =
      this.selectedPatient.first_name;
    renameRef.componentInstance.last_name =
      this.selectedPatient.last_name;
    renameRef.componentInstance.detail = 'name'  
    renameRef.componentInstance.patient_id = this.selectedPatient.id;
    renameRef.result.then((result) => {
      if (result === 'Close click') {
        return;
      }
      console.log(result);
      this.spinnerService.show();
      this.orderService
        .updatePatient(
          this.selectedPatient.uniq_id,
          {first_name : result.first_name,
            last_name : result.last_name}
        )
        .then(() => {
          this.spinnerService.hide();
          this.toastr.success('Patient name updated sucessfully.');
          this.selectedPatient.first_name = result.first_name;
          this.selectedPatient.last_name = result.last_name;
        })
        .catch((error) => {
          this.spinnerService.hide();
          this.toastr.error(
            "Could't complete request due to an error, please try again later."
          );
          console.log(error);
        });
    });
  }

  changePhonePatient(): void {
    const renameRef = this.modalService.open(RenameModalComponent, {
      centered: true,
    });
    renameRef.componentInstance.cell_phone =
      this.selectedPatient.cell_phone;
    renameRef.componentInstance.detail = 'phone';  
    renameRef.componentInstance.patient_id = this.selectedPatient.id;
    renameRef.result.then((result) => {
      if (result === 'Close click') {
        return;
      }
      this.spinnerService.show();
      this.orderService
        .updatePatient(
          this.selectedPatient.uniq_id,
          {
            cell_phone : result.cell_phone
          }
        )
        .then(() => {
          this.spinnerService.hide();
          this.toastr.success('Patient phone updated sucessfully.');
          this.selectedPatient.cell_phone = result.cell_phone
        })
        .catch((error) => {
          this.spinnerService.hide();
          this.toastr.error(
            "Could't complete request due to an error, please try again later."
          );
          console.log(error);
        });
    });
  }

  // async AssignUser() {
  //   this.spinnerService.show();
  //   if (this.email) {
  //     await this.talkService
  //       .updateConversation(this.data.conversation.id, {
  //           assignedTo: this.email,
  //       })
  //       .then((dta) => {
  //         this.toastr.success('Assigned To ' + this.email);
  //         this.reload();
  //       })
  //       .catch((error) => {
  //         this.toastr.error(error);
  //       })
  //       .finally(() => {
  //         this.spinnerService.hide();
  //       });
  //   }
  // }
}
