import { ShowImageModalComponent } from './../../shared/components/show-image-modal/show-image-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { DateTime } from 'luxon';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Assistant, Attachment, AttachmentFirebase } from './../../shared/models/';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { VgAPI } from 'ngx-videogular';
import Swal from 'sweetalert2';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss'],
})
export class ContentManagementComponent implements OnInit {
  tableHeaders = ['No', 'Name', 'Date_/_Time'];
  attachmentCompleteTableData: Attachment[];
  assistantInfo: Assistant = null;
  selectedVideo: { url: string; key: string } = { url: '', key: '' }; // Dictation Selected for Video Playback
  videoPlayer: VgAPI;
  selectedMode: string = 'images';
  paginationPageSize = 8;
  userRole: string;
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: MatSlideToggle;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private contentManagementService: ContentManagementService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.spinnerService.show();
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getAttachments();
  }

  getAttachments(): void {
    this.spinnerService.show();
    this.firebaseService
      .getAttachments(this.assistantInfo.practiceId.toLowerCase(), this.selectedMode)
      .subscribe((data: AttachmentFirebase[]) => {
        this.spinnerService.hide();
        this.attachmentCompleteTableData = data.map((res: AttachmentFirebase, index: number) => {
          return {
            No: index + 1,
            Key: res.key,
            Name: res.name,
            File_Path: res.url,
            'Date_/_Time': DateTime.fromMillis(+res.created_timestamp)
              .setZone('America/Los_Angeles')
              .toLocaleString(DateTime.DATETIME_SHORT),
          };
        });
      });
  }

  uploadAttachment(): void {
    let fileChooserAcceptType = this.selectedMode === 'images' ? 'image/*' : 'video/*';

    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      this.firebaseService.uploadFileToStorage(file, this.assistantInfo.practiceId.toLowerCase(), this.selectedMode);
    });
    fileChooser.click();
  }

  deleteAttachment = (attachment: Attachment): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.contentManagementService.deleteAttachment(
          this.assistantInfo.practiceId.toLowerCase(),
          attachment.Key,
          attachment.File_Path,
          this.selectedMode
        );
      }
    });
  };

  downloadAttachment = (attachment: Attachment): void => {
    this.contentManagementService.getAttachmentUrl(attachment.File_Path).then((url: string) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        var blob = new Blob([xhr.response], { type: 'image/png' });
        var url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = attachment.Name || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
      };
      xhr.open('GET', url);
      xhr.send();
    });
  };

  playAttachment = (attachment: Attachment): void => {
    if (this.selectedMode === 'videos') this.playVideoAttachment(attachment);
    else this.showImageAttachment(attachment);
  };

  pauseAttachment = (attachment: Attachment): void => {
    if (this.selectedMode === 'videos') this.pauseVideoAttachment(attachment);
  };

  playVideoAttachment(attachment: Attachment): void {
    if (attachment.Key === this.selectedVideo.key) {
      this.videoPlayer.play();
    } else {
      this.contentManagementService.getAttachmentUrl(attachment.File_Path).then((url: string) => {
        this.selectedVideo = { url, key: attachment.Key };
      });
    }
  }

  showImageAttachment(attachment: Attachment): void {
    const modalRef = this.modalService.open(ShowImageModalComponent, {
      animation: true,
      centered: true,
      windowClass: 'imageModal',
    });

    modalRef.componentInstance.image = attachment.File_Path;

    modalRef.result
      .then((result) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  pauseVideoAttachment = (attachment: Attachment): void => {
    if (attachment.Key === this.selectedVideo.key) {
      this.videoPlayer.pause();
    }
  };

  onPlayerReady(api: VgAPI) {
    this.videoPlayer = api;
  }

  onChangeAttachmentSwitch() {
    if (this.attachmentSwitch.checked) {
      this.selectedMode = 'videos';
      this.paginationPageSize = 6;
    } else {
      this.selectedMode = 'images';
      this.paginationPageSize = 8;
    }

    this.getAttachments();
  }
}
