import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/services/order.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Appointment } from '../../models/appointment';
import { UserModel } from '../../models/userModel';
import { PracticeInfoForUser } from '../../models/practice';
import { UserManagementService } from '../../services/user-management.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.css'],
})
export class AddUserModalComponent implements OnInit {
  @Input() altTitle: any;
  @Input() inviteForm: boolean = false;
  @Input() token: any;
  @Input() User: any;
  userRole: any;
  userInfo: any;
  assistantInfo: any;

  inputModel: UserModel;
  addFormGroup: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private firebaseService: FirebaseService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userRole = await this.authService.getUserRole();
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(this.userInfo.uid, this.userInfo.displayName);
    if (!this.inviteForm) {
      this.addFormGroup = this.formBuilder.group({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        phoneNo: new FormControl(''),
        faxNo: new FormControl(''),
        address1: new FormControl(''),
        address2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zipCode: new FormControl(''),
        selectRole: new FormControl('', [Validators.required]),
      });
    } else
      this.addFormGroup = this.formBuilder.group({
        email: new FormControl('', [Validators.required, Validators.email]),
        selectRole: new FormControl('', [Validators.required]),
      });
    if (this.User?.email && !this.inviteForm) {
      this.addFormGroup.get('email').setValue(this.User.email);
      this.addFormGroup.get('email').disable();
      this.addFormGroup.get('password').disable();
      this.addFormGroup.get('firstName').disable();
      this.addFormGroup.get('lastName').disable();
      this.addFormGroup.get('phoneNo').disable();
      this.addFormGroup.get('faxNo').disable();
      this.addFormGroup.get('address1').disable();
      this.addFormGroup.get('address2').disable();
      this.addFormGroup.get('city').disable();
      this.addFormGroup.get('state').disable();
      this.addFormGroup.get('zipCode').disable();
    }
  }

  get email() {
    return this.addFormGroup.get('email');
  }

  get password() {
    return this.addFormGroup.get('password');
  }

  get selectRole() {
    return this.addFormGroup.get('selectRole');
  }

  saveUser() {
    this.spinnerService.show();
    const {
      email,
      password,
      firstName,
      lastName,
      phoneNo,
      faxNo,
      address1,
      address2,
      city,
      state,
      zipCode,
      selectRole,
    } = this.addFormGroup.value;
    const practiceData: PracticeInfoForUser = {
      practice_id: this.assistantInfo.practiceId.toLowerCase(),
      practice_name: this.assistantInfo.practiceName,
      dbFlag: this.assistantInfo.dbFlag,
      colorFlag: this.assistantInfo.colorFlag,
      client: {
        id: this.assistantInfo.client_id,
        description: this.assistantInfo.client_name,
      },
      role: selectRole,
    };

    this.userManagementService
      .addUser(
        email,
        password,
        practiceData,
        firstName,
        lastName,
        phoneNo,
        faxNo,
        address1,
        address2,
        city,
        state,
        zipCode,
        this.assistantInfo?.client_id,
        this.assistantInfo?.client_name
      )
      .then((resp) => {
        this.spinnerService.hide();
        if (resp.code === 200) {
          this.activeModal.close(resp);
          this.toastr.success(resp.message);
        } else if (resp.code === 500) {
          this.toastr.error(resp.error);
        }
      })
      .catch((error) => {
        this.toastr.error(error.error);
        this.spinnerService.hide();
      });
  }

  inviteUser() {
    this.spinnerService.show();
    const data = {
      email: this.email.value,
      role: this.selectRole.value,
    };

    this.userManagementService
      .inviteUser(data, this.token)
      .then((resp) => {
        this.spinnerService.hide();
        if (resp.code === 200) {
          this.activeModal.close(resp);
          this.toastr.success(resp.message);
        } else if (resp.code === 500) {
          this.toastr.error(resp.error.message);
        }
      })
      .catch((error) => {
        this.spinnerService.hide();
        this.toastr.error(error.error.message);
      });
  }

  UpdateUser() {
    this.spinnerService.show();
    const practice_id = this.assistantInfo.practiceId.toLowerCase();
    const { selectRole } = this.addFormGroup.value;
    this.userManagementService
      .updateUser(this.User.key, selectRole, practice_id)
      .then((resp) => {
        this.spinnerService.hide();
        if (resp.code !== 500) {
          this.activeModal.close(resp);
          this.toastr.success('updated !');
        } else if (resp.code === 500) {
          this.toastr.error('error');
        }
      })
      .catch((error) => {
        this.toastr.error(error.error);
        this.spinnerService.hide();
      });
  }
}
