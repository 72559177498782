import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Assistant } from '../shared/models';
import { AuthService } from '../shared/services/auth.service';
import { FirebaseService } from '../shared/services/firebase.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  assistantInfo: Assistant = null;
  existingEntriesChecked: boolean = false; // Flag to ignore existing device events in db
  showHideToggle: boolean;
  user: any;
  userRole: string;
  emailVerified: boolean;

  constructor(
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.showHideToggle = false;
  }

  toggle(event) {
    this.showHideToggle = event;
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.emailVerified = this.user.emailVerified;
      const data = await this.firebaseService.getAssistant(this.user.uid, this.user.displayName);

      if (data?.practiceId) {
        localStorage.setItem('PRACTICE_ID', data.practiceId);
        localStorage.setItem('practiceName', data.practiceName);
      }
    }
    this.userRole = await this.authService.getUserRole();

    if (this.userRole === 'practice_admin') this.firebaseService.registerAlertServiceForNewMotionEvent();
    else this.router.navigate([this.router.url]);
  }

  resendVerificationLink() {
    this.spinnerService.show();
    const data = {
      email: this.user.email,
    };
    this.authService
      .resendVerificationEmail(this.user.stsTokenManager.accessToken, data)
      .then(() => {
        this.spinnerService.hide();
        this.toastr.success('Email sent successfully.');
      })
      .catch((err) => {
        this.spinnerService.hide();
        console.error(err);
        this.toastr.error("Couldn't send the email, please try again later.");
      });
  }

  ngOnDestroy(): void {
    if (this.userRole === 'practice_admin') this.firebaseService.unregisterAlertServiceForNewMotionEvent();
  }
}
