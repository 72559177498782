import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  user = JSON.parse(localStorage.getItem('user'));
  constructor(private http: HttpClient) {}

  async getBranding(): Promise<any> {
    return await this.http
      .get<any>(`${environment.BASE_URL}/practice/branding/web`, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async setBranding(logo: any, primary_color: string, secondary_color: string): Promise<any> {
    const formData = new FormData();

    if (logo) {
      formData.append('logo', logo);
      formData.append('primary_color', primary_color);
      formData.append('secondary_color', secondary_color);
    }

    return await this.http
      .put<any>(`${environment.BASE_URL}/practice/branding`, formData, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }
}
