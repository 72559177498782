import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { GenericResp, GET_ALL_DICTATIONS_RESP } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VideoDictationService {
  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage
  ) {}

  async getVideoDictations(
    client_id: string,
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByProvider: string,
    is_archived: boolean
  ): Promise<GET_ALL_DICTATIONS_RESP> {
    return this.http
      .get<GET_ALL_DICTATIONS_RESP>(`${environment.VIDEO_DICTATION}/client/${client_id}/practice/${practice_id}`, {
        params: {
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          search,
          searchCriteria,
          filterByProvider,
          is_archived: `${!!is_archived}`,
        },
      })
      .toPromise();
  }

  async deleteVideoDictation(video_id: string): Promise<GenericResp> {
    return await this.http.delete<GenericResp>(`${environment.VIDEO_DICTATION}/${video_id}`).toPromise();
  }

  async updateVideoDictation(video_id: string, is_archived: boolean): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.VIDEO_DICTATION}/${video_id}/archive/${is_archived}`, null)
      .toPromise();
  }
}
