import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-storage-location',
  templateUrl: './storage-location.component.html',
  styleUrls: ['./storage-location.component.scss'],
})
export class StorageLocationComponent implements OnInit {
  storageFiles: any[] = [];
  tableHeaders = ['File Name', 'Insurance'];
  selectedFile: File | null = null;

  constructor(
    private storageService: StorageService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllStorageFiles();
  }

  onFileChange(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  getAllStorageFiles(): void {
    this.spinnerService.show();
    this.storageService.getAllStorageFiles().subscribe(
      (resp) => {
        this.storageFiles = resp.data.map((file: any) => ({
          id : file.id,
          file_url : file.file_url,
          file_name: file.file_name,
          insurance: file.insurance_name,
        }));
       
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Error fetching storage files');
      }
    );
  }


  uploadPdfFile(): void {
    Swal.fire({
      title: 'Enter Insurance Name',
      input: 'text',
      inputLabel: 'Insurance Name',
      inputPlaceholder: 'Enter the name of the insurance',
      showCancelButton: true,
      confirmButtonText: 'Upload File',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Insurance name is required!';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        // User provided the insurance name
        const insuranceName = result.value;
        this.showFileChooser(insuranceName); // Pass insurance name to file chooser
      }
    });
  }
  
  private showFileChooser(insuranceName: string): void {
    const fileChooserAcceptType = '.pdf';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType; // Only PDF files
  
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0]; // Get the first file only
      if (file) {
        this.spinnerService.show();
  
        // Add insurance name to the file upload request
        const formData = new FormData();
        formData.append('file', file);
        formData.append('insurance', insuranceName); // Append insurance name
  
        this.storageService.uploadPdfFile(file, insuranceName).subscribe(
          (resp) => {
            if (resp.data) {
              this.spinnerService.hide();
              this.toastr.success('File has been uploaded successfully');
              const fileObj = {
                file_name: resp.data.file_name,
                file_url: resp.data.file_url,
                id: resp.data.id,
              };
              this.getAllStorageFiles();
            }
          },
          (error) => {
            this.spinnerService.hide();
            this.toastr.error('File upload failed');
          }
        );
      }
    });
  
    fileChooser.click();
  }


  // deleteFile(fileId: string): void {
  //   this.storageFiles = this.storageFiles.filter((file) => file.id !== fileId);
  //   Swal.fire('Deleted', 'File deleted successfully', 'success');
  // }

  previewFile(resp: any): void {
    window.open(resp.file_url, '_blank');
  }
}
