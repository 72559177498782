export const environment = {
  production: false,
  SENTRY_ENV: 'test',
  FRONTEND_URL:'https://manage.vytlsft.com',
  BASE_URL: 'https://random.westus.cloudapp.azure.com',
  GET_PROVIDER_API: 'https://random.westus.cloudapp.azure.com/provider',
  GET_PRACTICE_BY_ID: 'https://random.westus.cloudapp.azure.com/practice',
  GET_ACTIVITY_LOG_API: 'https://random.westus.cloudapp.azure.com/settings/activitylogs',
  GET_PROVIDERS_FROM_DB: 'https://random.westus.cloudapp.azure.com/settings/providers',
  ADMIN_LOGIN: 'https://random.westus.cloudapp.azure.com/admin/login/credentials',
  TEST_DATABASE_CONNECTION: 'https://random.westus.cloudapp.azure.com/sqlmode/testconnection',
  CREATE_UPDATE_PRACTICE: 'https://random.westus.cloudapp.azure.com/admin/practice',
  CREATE_STATUS_FOR_PRACTICE: 'https://random.westus.cloudapp.azure.com/admin/practice/status',
  GET_PRACTICE_COLOR_LABELS: 'https://random.westus.cloudapp.azure.com/admin/practice/labels',
  GET_PROVIDERS: 'https://random.westus.cloudapp.azure.com/provider/practice',
  ADD_PROVIDER: 'https://random.westus.cloudapp.azure.com/provider/practice/add',
  EDIT_PROVIDER: 'https://random.westus.cloudapp.azure.com/provider/practice/edit',
  DELETE_PROVIDER: 'https://random.westus.cloudapp.azure.com/provider/practice/delete',
  Order_Page_Details: 'https://random.westus.cloudapp.azure.com/Order/order-page-details',
  Create_Order: 'https://random.westus.cloudapp.azure.com/Order',
  GET_PATIENT_ORDERS: 'https://random.westus.cloudapp.azure.com/web/order/patient',
  GET_PATIENT_ORDER_DETAILS: 'https://random.westus.cloudapp.azure.com/web/order/',
  GET_ALL_ORDER_STATUS: 'https://random.westus.cloudapp.azure.com/web/order/status',
  GET_ALL_ORDERS: 'https://random.westus.cloudapp.azure.com/web/orders',
  GET_CLIENT_DATA: 'https://random.westus.cloudapp.azure.com/web/client',
  GET_PAYER_MPPR: 'https://random.westus.cloudapp.azure.com/web/payer',
  GET_Client_Locations: 'https://random.westus.cloudapp.azure.com/location/web/client/',
  FIREBASE: 'https://random.westus.cloudapp.azure.com/firebase',
  MESSAGES: 'https://random.westus.cloudapp.azure.com/web/conversation',
  TOGGLE_ORDER: 'https://random.westus.cloudapp.azure.com/web/order/',
  UPDATE_ORDER_NOTES: 'https://random.westus.cloudapp.azure.com/order',
  NOTIFY_USER_OF_NEW_MESSAGES: 'https://random.westus.cloudapp.azure.com/web/conversation',
  USER_ACCOUNT: 'https://random.westus.cloudapp.azure.com/account',
  FORMS: 'https://random.westus.cloudapp.azure.com/forms',
  PATIENT: 'https://random.westus.cloudapp.azure.com/patient',
  APPOINTMENTS: 'https://random.westus.cloudapp.azure.com/appointments',
  VIDEO_DICTATION: 'https://random.westus.cloudapp.azure.com/dictation/videos',
  AUDIO_DICTATION: 'https://random.westus.cloudapp.azure.com/dictation/audios',
  PAYMENTS: 'https://random.westus.cloudapp.azure.com/payment',
  STATEMENTS: 'https://random.westus.cloudapp.azure.com/statement',
  AUTH: 'https://random.westus.cloudapp.azure.com/auth',
  LOCATION: 'https://random.westus.cloudapp.azure.com/location',
  UPLOAD_STATEMENTS: 'https://random.westus.cloudapp.azure.com/statement/file',
  GET_ALL_PATIENTS: 'https://random.westus.cloudapp.azure.com/web/patients/practice',
  AUTOPAY_STATEMENTS: 'https://random.westus.cloudapp.azure.com/statement/autopay',
  //Payers
  GET_PAYERS_BY_CLIENT: 'https://random.westus.cloudapp.azure.com/payers/client/', //CLIENTID GE
  PAYER_DATA: 'https://random.westus.cloudapp.azure.com/payers/', //:payer_id/contracts or mpd
  API_URL: 'https://random.westus.cloudapp.azure.com/',
  //MPD
  TALKJS_APP_ID: 'NmHNvw02',
  DNS: 'https://5b248b43d6a24c04b58632ae79156e05@o1001555.ingest.sentry.io/5963198',
  //AWS
  AWS_ACCESS_KEY_ID: 'AKIA3KSPMLZNZU4GKAKF',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'us-east-2',

  defaultColors: {
    patientComplete: '#229954',
    readyForProvider: '#C71585',
    status4: '#e06f45',
    status5: '#c1ce4c',
    status6: '#63cc46',
    status7: '#f94f4f',
    status11: '#FFD700',
    status12: '#EE82EE',
  },
  defaultDb: {
    dbUsername: 'bealand',
    dbPassword: 'Verahealth123#',
    dbName: 'PTracking',
    dbHost: 'acusql.database.windows.net',
    dbDialect: 'mssql',
    dbPort: 1433,
  },
  // dbConfig: {
  //   username: 'sa',
  //   password: 'admin',
  //   database: 'PTracking',
  //   host: 'localhost',
  //   dialect: 'mssql',
  //   port: 63270,
  // },
  dbConfig: {
    username: 'bealand',
    password: 'Verahealth123#',
    database: 'PTracking',
    host: 'acusql.database.windows.net',
    dialect: 'mssql',
    port: 1433,
  },
  // liveswitchConfig: {
  //   SERVER_IP: 'http://random.westus.cloudapp.azure.com:8080/sync',
  //   APPLICATION_ID: 'vytlsft-app-ls-server',
  //   USER_ID: 'first user',
  //   DEVICE_ID: '00000000-0000-0000-000000000000',
  //   CHANNEL_ID: '1212',
  //   SECRET_SHARED_KEY:
  //     '40eb0018672043ab841b05b845774f318e1187136f2f4db6a63b3d093478e185',
  // },
  liveswitchConfig: {
    SERVER_IP: 'https://cloud.liveswitch.io/',
    APPLICATION_ID: 'fea2660d-e3b7-44dc-9d3e-0bb051b841ee',
    USER_ID: 'first user',
    DEVICE_ID: '00000000-0000-0000-000000000000',
    CHANNEL_ID: '1212',
    SECRET_SHARED_KEY:
      '1344bb38f29848a5b293538f33d2decf9e1a34e99133444cb9c67e5b85a51434',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBjcE5b4w6YBl3mltqnb_J9qGIam48bx6k',
    authDomain: 'verahealth-app.firebaseapp.com',
    databaseURL: 'https://verahealth-app-default-rtdb.firebaseio.com',
    projectId: 'verahealth-app',
    storageBucket: 'verahealth-app.appspot.com',
    messagingSenderId: '545739176697',
    appId: '1:545739176697:web:90985da8cc792f79444028',
    measurementId: 'G-7JX0MDEWGG',
  },
  acumenFirebaseConfig: {
    apiKey: 'AIzaSyBdnmrB6YSCxbVud9EdvxLWzrj8T4rjdW8',
    authDomain: 'acumen-health.firebaseapp.com',
    databaseURL: 'https://acumen-health.firebaseio.com',
    projectId: 'acumen-health',
    storageBucket: 'acumen-health.appspot.com',
    messagingSenderId: '138001387476',
    appId: '1:138001387476:web:8e7c67e40f4ad5f8ca5041',
    measurementId: 'G-DKSK2C7LRK',
  },
};
