import { Component, OnInit } from '@angular/core';
import { TalkService } from '../../services/talk.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-get-user-message-activity-report',
  templateUrl: './get-user-message-activity-report.component.html',
  styleUrls: ['./get-user-message-activity-report.component.css'],
})
export class GetUserMessageActivityReportComponent implements OnInit {
  endDate: string | null;
  startDate: string | null;
  constructor(
    public activeModal: NgbActiveModal,
    private talkService: TalkService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  downloadReport = async (): Promise<void> => {
    let currentDate = new Date();
    let endDate = this.endDate ? new Date(this.endDate) : null;
    if (!this.startDate) {
      this.toastr.error('Please provide dates !');
      return;
    }
    if (endDate > currentDate) {
      this.toastr.error('End date is in the future !');
      return;
    }
    if (!endDate) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      this.endDate = formattedDate;
    }
    this.spinnerService.show();
    this.talkService.exportCSV(this.startDate, this.endDate).subscribe(
      (data) => {
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `chat_activity_report.csv`;
        link.click();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      }
    );
  };
}
