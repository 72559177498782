import { AuthService } from './../../shared/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/shared/services/order.service';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import Talk from 'talkjs';
import { TalkService } from '../../shared/services/talk.service';

import {
  PatientOrder,
  PatientOrdersTableData,
  GET_ALL_ORDERS_RESP,
  OrdersData,
  ServerSidePages,
  Assistant,
  GET_ALL_ORDER_STEPS_RESP,
} from 'src/app/shared/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { OrdersPopupComponent } from './orders-popup/orders-popup.component';
import { PaginationTableHeaders } from 'src/app/shared/models/generic';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';

const DATE: Date = new Date();

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss'],
})
export class OrderManagementComponent implements OnInit {
  private chatBox: Talk.Chatbox;
  orderTableHeaders: PaginationTableHeaders[] = [
    { title: 'patient_name', value: 'patient_name', sort: true, orderBy: '' },
    {
      title: 'provider_name',
      value: 'provider_name',
      sort: true,
      orderBy: '',
    },
    { title: 'order_summary', value: 'order_summary', sort: true, orderBy: '' },
    { title: 'date', value: 'scheduled_date', sort: true, orderBy: '' },
    { title: 'is_benefit_done', value: 'benefits', sort: false, orderBy: '' },
    { title: 'created_at', value: 'created_at', sort: true, orderBy: '' },
    {
      title: 'order_facility',
      value: 'order_facility',
      sort: true,
      orderBy: '',
    },
    {
      title: 'physician_notes',
      value: 'physician_notes',
      sort: true,
      orderBy: '',
    },
    { title: 'last_updated', value: 'last_updated', sort: false, orderBy: '' },
  ];
  orderStepsArray: any;
  selectedStep = '';
  assistantInfo: Assistant = null;
  selectedUserOrders: OrdersData[];
  selectedUserOrdersTableData: PatientOrdersTableData[];
  selectedOrder: PatientOrder;
  assistantUID: string;
  assistantPractice: string;
  selectedStatus: { description: string; status: string };
  tablePages: ServerSidePages;
  pageSize: number = 10;
  searchKeyword: string = '';
  searchCriteria: string = 'patient_name';
  chatShow: boolean;
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: MatSlideToggle;
  patient: any;
  conversation: any;
  user: any;
  userRole: string;

  @ViewChild('steps_table', { static: false }) stepsTable: ServerSidePages;
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  @HostListener('document:keydown.shift.arrowright') next() {
    this.nextPage();
  }
  @HostListener('document:keydown.shift.control.arrowright')
  end() {
    this.lastPage();
  }
  @HostListener('document:keydown.shift.arrowleft')
  prev() {
    this.prevPage();
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  start() {
    this.firstPage();
  }

  constructor(
    private firebaseService: FirebaseService,
    private talkService: TalkService,
    private orderService: OrderService,
    private toastr: ToastrService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private router: Router,
    private messageService: MessageService
  ) {
    this.chatShow = false;
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!!!this.user) this.authService.logout();
    this.userRole = await this.authService.getUserRole();

    if (this.userRole === 'practice_scribe') {
      this.router.navigate([`/dashboard/video-dictation`]);
    }
    if (this.userRole === 'location_admin' || this.userRole === 'location_user') {
      this.orderSteps();
    }

    // if (
    //   this.userRole === 'practice_admin' ||
    //   this.userRole === 'practice_benefits' ||
    //   this.userRole === 'practice_admin_sante_community' ||
    //   this.userRole === 'practice_general_user'
    // ) {
    this.assistantInfo = await this.firebaseService.getAssistant(this.user.uid, this.user.displayName);
    this.assistantUID = this.user.uid;
    this.assistantPractice = this.user.displayName;
    // }
    this.getPendingOrders();
  }

  getPendingOrders(page: number = 1, limit: number = this.pageSize, filterByStatus: any = {}, orderBy: any = {}): void {
    this.spinnerService.show();
    if (
      this.userRole === 'practice_admin' ||
      this.userRole === 'practice_benefits' ||
      this.userRole === 'practice_admin_sante_community' ||
      this.userRole === 'practice_admin_sante_community_order' ||
      this.userRole === 'practice_user_sante_community_order' ||
      this.userRole === 'practice_general_user'
    ) {
      this.orderService
        .getAllOrders(
          this.assistantPractice,
          page,
          limit,
          orderBy,
          this.searchKeyword,
          this.searchCriteria,
          filterByStatus,
          this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null
        )
        .then((resp: GET_ALL_ORDERS_RESP) => {
          this.tablePages = {
            currentPage: resp.data.currentPage,
            totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            nextPage: resp.data.nextPage,
            prevPage: resp.data.previousPage,
            firstPage: 1,
            total: resp.data.total,
          };
          this.selectedUserOrders = resp.data.data || [];
          const date = new Date();
          const currentDate = moment(date).startOf('days');

          this.selectedUserOrdersTableData = this.selectedUserOrders.map((order: OrdersData) => ({
            id: order.order_id,
            order_id: order.order_id,
            provider_name: order.provider.name,
            patient_name: `${order.patient.last_name}, ${order.patient.first_name}`,
            order_summary: order.order_summary,
            patient_phone: order.patient.cell_phone,
            physician_notes: order.physician_notes,
            date: (order.order_date && moment.parseZone(order.order_date).format('MM/DD/YYYY')) || '',
            order_facility: order.order_facility,
            order_date: (order.order_date && moment.parseZone(order.order_date).format('MM/DD/YYYY hh:mm a')) || '',
            is_benefit_done: order.is_benefit_done ? 'Yes' : 'No',
            created_at:
              moment.parseZone(order.created_at).format('MM/DD/YYYY') +
                '(' +
                currentDate.diff(moment(order.created_at).startOf('days'), 'days') +
                ' days ago)' || '',
            provider: order.provider,
            patient: {
              ...order.patient,
              date_of_birth: DateTime.fromISO(order.patient.date_of_birth).toLocaleString(DateTime.DATE_SHORT),
            },
            last_updated:
              (order.last_updated && moment.parseZone(order.last_updated).format('MM/DD/YYYY hh:mm A')) || 'N/A',
          }));
        })
        .catch(() => {
          this.toastr.error('Something went wrong. Please try again');
        })
        .finally(() => this.spinnerService.hide());
    } else if (this.userRole === 'location_admin' || this.userRole === 'location_user') {
      this.orderService
        .getAllOrdersForLocation(
          this.user.stsTokenManager.accessToken,
          page,
          limit,
          orderBy,
          this.searchKeyword,
          this.searchCriteria,
          filterByStatus,
          false
        )
        .then((resp: GET_ALL_ORDERS_RESP) => {
          this.tablePages = {
            currentPage: resp.data.currentPage,
            totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            nextPage: resp.data.nextPage,
            prevPage: resp.data.previousPage,
            firstPage: 1,
            total: resp.data.total,
          };
          this.selectedUserOrders = resp.data.data || [];
          const date = new Date();
          const currentDate = moment(date).startOf('days');

          this.selectedUserOrdersTableData = this.selectedUserOrders.map((order: OrdersData) => ({
            id: order.order_id,
            order_id: order.order_id,
            provider_name: order.provider.name,
            patient_name: `${order.patient.last_name}, ${order.patient.first_name}`,
            order_summary: order.order_summary,
            patient_phone: order.patient.cell_phone,
            physician_notes: order.physician_notes,
            date: (order.order_date && moment.parseZone(order.order_date).format('MM/DD/YYYY')) || '',
            order_facility: order.order_facility,
            order_date: (order.order_date && moment.parseZone(order.order_date).format('MM/DD/YYYY hh:mm a')) || '',
            is_benefit_done: order.is_benefit_done ? 'Yes' : 'No',
            created_at:
              moment.parseZone(order.created_at).format('MM/DD/YYYY') +
                '(' +
                currentDate.diff(moment(order.created_at).startOf('days'), 'days') +
                ' days ago)' || '',
            provider: order.provider,
            patient: {
              ...order.patient,
              date_of_birth: DateTime.fromISO(order.patient.date_of_birth).toLocaleString(DateTime.DATE_SHORT),
            },
          }));
        })
        .catch(() => {
          this.toastr.error('Something went wrong. Please try again');
        })
        .finally(() => this.spinnerService.hide());
    }
  }

  filterUsers(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') this.searchKeyword = '';

    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    this.getPendingOrders(1, this.pageSize, status, order);
  }

  onChangeSearchCriteria(event: any): void {
    this.searchCriteria = event.target.value;
  }

  archivedFunction = (data): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.orderService
          .toggleOrder(data.order_id, this.attachmentSwitch.checked ? false : true)
          .then((res) => {
            this.toastr.success('Request has been processed successfully');
            this.onChangeSwitch(this.tablePages.currentPage);
          })
          .catch((err) => {
            console.error(err);
            this.toastr.error(err.message);
          });
      }
    });
  };

  onChangeSwitch(page = 1) {
    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    this.getPendingOrders(page, this.pageSize, status, order);
  }

  orderBy = (column_name: string): void => {
    const index = this.orderTableHeaders.findIndex((header) => header.value === column_name);
    let status = {};

    this.orderTableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.orderTableHeaders[index].orderBy === 'ASC') this.orderTableHeaders[index].orderBy = 'DESC';
    else this.orderTableHeaders[index].orderBy = 'ASC';

    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }

    const order = {
      column_name,
      sort: this.orderTableHeaders[index].orderBy,
    };

    this.getPendingOrders(this.tablePages.currentPage, this.pageSize, status, order);
  };

  reloadTable = ($event: boolean = false): void => {
    if ($event === true) {
      let order,
        status = {};
      this.orderTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
      if (this.selectedStatus && this.selectedStatus.status) {
        status = this.selectedStatus;
      }
      if (this.tablePages.currentPage) this.getPendingOrders(this.tablePages.currentPage, this.pageSize, status, order);
    }
  };

  selectOrder = (order: PatientOrder): void => {
    if (!order) {
      this.selectedOrder = null;
      return;
    }
    this.selectedOrder = order;
  };

  lastPage = (): void => {
    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    if (this.tablePages.nextPage) this.getPendingOrders(this.tablePages.lastPage, this.pageSize, status, order);
  };

  nextPage = (): void => {
    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    if (this.tablePages.nextPage) this.getPendingOrders(this.tablePages.nextPage, this.pageSize, status, order);
  };

  prevPage = (): void => {
    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    if (this.tablePages.prevPage) this.getPendingOrders(this.tablePages.prevPage, this.pageSize, status, order);
  };

  firstPage = (): void => {
    let order,
      status = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.selectedStatus && this.selectedStatus.status) {
      status = this.selectedStatus;
    }
    if (this.tablePages.prevPage) this.getPendingOrders(this.tablePages.firstPage, this.pageSize, status, order);
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (typeof pageNumber === 'number' && pageNumber <= this.tablePages.totalPages && 0 < pageNumber) {
      let order,
        status = {};
      this.orderTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
      if (this.selectedStatus && this.selectedStatus.status) {
        status = this.selectedStatus;
      }
      this.getPendingOrders(pageNumber, this.pageSize, status, order);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  showChatBox(status) {
    this.chatShow = status;
  }
  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  async createInbox(patientRecord) {
    if (!this.chatShow === false) {
      if (this.patient?.id == patientRecord.patient.id.toLowerCase()) {
        this.chatShow = false;
        this.patient = '';
        this.conversation = '';
        return;
      }
      this.patient = {
        id: patientRecord.patient.id.toLowerCase(),
        username: patientRecord.patient.patient_name,
        custom: {
          practiceId: this.assistantInfo.practiceId,
          clientId: this.assistantInfo.client_id,
          first_name: patientRecord.patient.first_name,
          last_name: patientRecord.patient.last_name,
          date_of_birth: patientRecord.patient.date_of_birth,
          med_rec_nbr: patientRecord.patient.med_rec_nbr,
        },
        role: 'patient',
      };
      let practice = localStorage.getItem('PRACTICE_ID');
      let practiceName = localStorage.getItem('practiceName');
      const data = JSON.parse(localStorage.getItem('user'));
      const session = await this.talkService.createCurrentSession(practice.toLowerCase(), practiceName);
      this.conversation = await this.talkService.getOrCreateConversation(session, this.patient);
      this.chatBox = await this.talkService.createOrderInbox(session, this.patient);
      await this.delay(1000);
      this.chatBox.mount(this.talkjsContainer?.nativeElement);

      this.chatBox.createHtmlPanel({
        url: `message/notify-button?data=${JSON.stringify({
          conversation: {
            id: this.conversation.id,
          },
          ...this.patient,
        })}`,
        height: 27,
        show: true,
      });
    }
  }

  openAddOrder = (): void => {
    const orderAddRef = this.modalService.open(OrdersPopupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      animation: true,
      centered: true,
      size: 'xl',
    });
  };

  onStepChange(e: any) {
    this.selectedStep = e.target.value;
    if (e.target.value === '') {
      this.getPendingOrders();
    }
  }

  onChangeStatus(e: any) {
    if (e.target.value !== '') {
      this.orderTableHeaders.forEach((header) => {
        header.orderBy = '';
      });
      this.selectedStatus = {
        description: this.selectedStep,
        status: e.target.value,
      };
      this.getPendingOrders(this.tablePages.firstPage, this.pageSize, this.selectedStatus);
    } else {
      this.orderTableHeaders.forEach((header) => {
        header.orderBy = '';
      });
      this.selectedStatus = {
        description: '',
        status: '',
      };
      this.getPendingOrders(this.tablePages.firstPage);
    }
  }

  orderSteps(): void {
    this.spinnerService.show();
    this.orderService
      .getAllOrderStepsWithStatusByLocation()
      .then((resp: any) => {
        this.orderStepsArray = {
          Steps: resp.data.steps.map((step, index: 1) => ({
            id: index++,
            title: step.step_name,
          })),
          Status: resp.data.status.map((status) => ({
            id: status.id,
            title: status.description,
          })),
        };
        this.spinnerService.hide();
      })
      .catch(() => {
        this.spinnerService.hide();
      });
  }

  openFilterModal(): void {
    this.spinnerService.show();
    this.orderService
      .getAllOrderStepsWithStatus(this.assistantInfo.practiceId, this.assistantInfo.client_id)
      .then((resp: GET_ALL_ORDER_STEPS_RESP) => {
        let steps = [
          { id: 1, title: 'Authorization' },
          { id: 2, title: 'Cardiac Clearance' },
          { id: 3, title: 'Medical Clearance' },
          { id: 4, title: 'Patient Screening' },
          { id: 5, title: 'Placed Order' },
          { id: 6, title: 'Pre-Op Labs' },
          { id: 7, title: 'Scheduling' },
        ];

        steps.sort((a, b) => a.title.localeCompare(b.title));

        let statuses = resp.data.status.map((status) => ({
          id: status.id,
          title: status.description,
        }));
        statuses.sort((a, b) => a.title.localeCompare(b.title));

        const filterOptions = {
          Steps: steps,
          Status: statuses,
        };

        this.spinnerService.hide();
        const filterModal = this.modalService.open(FilterModalComponent, {
          centered: true,
        });

        filterModal.componentInstance.header = 'Step Status';
        filterModal.componentInstance.filterOptions = filterOptions;
        filterModal.componentInstance.selectStepStatus = true;
        if (this.selectedStatus) {
          filterModal.componentInstance.selectedStep = this.selectedStatus.description;
          filterModal.componentInstance.selectedStatus = this.selectedStatus.status;
        }

        filterModal.result
          .then((result) => {
            if (result) {
              this.orderTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.selectedStatus = {
                description: result.step,
                status: result.status,
              };
              this.getPendingOrders(this.tablePages.firstPage, this.pageSize, this.selectedStatus);
            } else {
              this.orderTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.selectedStatus = {
                description: '',
                status: '',
              };
              this.getPendingOrders(this.tablePages.firstPage);
            }
          })
          .catch((err) => {
            if (err != 0) console.error(err);
          });
      })
      .catch(() => {
        this.spinnerService.hide();
      });
  }

  sendSMS(body: string = ''): void {
    Swal.fire({
      title: 'Send SMS',
      icon: 'info',
      html: `@<b>${this.patient.username}</b>`,
      input: 'textarea',
      inputValue: body,
      inputAttributes: {
        required: 'true',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Please Enter the Message First.';
        }
      },
      inputPlaceholder: `Enter Message`,
      showCancelButton: true,
      confirmButtonColor: '#39d5d5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Send',
    }).then((result) => {
      if (result.isConfirmed) {
        let message = result.value;
        Swal.fire({
          title: 'Confirmation',
          icon: 'info',
          html: `Are you sure you want to send this SMS?
          <div class="mt-4" style="border-top: 5px dotted grey; border-bottom: 5px dotted grey">
          <p class="mt-3 mb-3">${result.value}</p>
          </div>`,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: '#39d5d5',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Change',
        }).then((result) => {
          if (result.isConfirmed) {
            this.spinnerService.show();
            const data = {
              patient_id: this.patient.id,
              client_id: this.patient.custom.clientId,
              practice_id: this.patient.custom.practiceId,
              body: message,
            };
            this.messageService
              .sendUserSMS(this.conversation.id, data)
              .then(() => {
                this.spinnerService.hide();
                Swal.fire({
                  icon: 'success',
                  title: 'SMS Sent!',
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                this.spinnerService.hide();
                Swal.fire({
                  icon: 'error',
                  title: 'Unsuccessful!',
                  text: 'Failed to send SMS, please try again.',
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          } else this.sendSMS(message);
        });
      }
    });
  }

  downloadCSV(filterByStatus: any = {}): void {
    this.spinnerService.show();

    let order = {};
    this.orderTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (typeof filterByStatus === 'string') {
      try {
        filterByStatus = JSON.parse(filterByStatus);
      } catch (e) {
        console.error('Invalid JSON format for filterByStatus:', filterByStatus);
        filterByStatus = {};
      }
    }

    if (this.selectedStatus && this.selectedStatus.status) {
      filterByStatus = {
        ...filterByStatus,
        ...this.selectedStatus,
      };
    }

    this.orderService
      .downloadCsv(
        this.assistantPractice,
        this.tablePages.currentPage,
        -1,
        order,
        this.searchKeyword,
        this.searchCriteria,
        filterByStatus,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null
      )
      .subscribe(
        (data) => {
          let downloadURL = window.URL.createObjectURL(data);
          let link = document.createElement('a');
          link.href = downloadURL;
          link.download = `orders.csv`;
          link.click();
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toastr.error('Something went wrong. Please try again');
        }
      );
  }
}
