import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FirebaseService } from '../shared/services/firebase.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private firebaseService: FirebaseService,
    private spinnerService: NgxSpinnerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('skip')) return next.handle(request);

    const token = localStorage.getItem('token');
    const isBlob = request.headers.get('is_blob');
    let authRequest = request;
    if (token) {
      const headersConfig: { [header: string]: string } = {
        Authorization: `Bearer ${token}`,
      };

      // If it's a blob request, don't modify headers, else clone with auth only.
      if (isBlob) {
        authRequest = request.clone({ setHeaders: headersConfig });
      } else {
        authRequest = request.clone({ setHeaders: headersConfig });
      }
    }

    return next.handle(authRequest).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return from(
            this.firebaseService.getCurrentUser()?.getIdToken(true) ||
              Promise.resolve('')
          ).pipe(
            switchMap((newToken) => {
              if (newToken) {
                localStorage.setItem('token', newToken);
                const headersConfig: { [header: string]: string } = {
                  Authorization: `Bearer ${newToken}`,
                };

                authRequest = request.clone({ setHeaders: headersConfig });
                return next.handle(authRequest);
              } else {
                this.handleLogout();
                return throwError(() => err);
              }
            })
          );
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  private handleLogout() {
    localStorage.clear();
    this.toastr.error('Session Expired.');
    this.spinnerService.hide();
    this.router.navigate(['/']);
  }
}
