import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Assistant, Provider, ServerSidePages } from 'src/app/shared/models';
import {
  AppointmentReportsData,
  AppointmentReportsTableData,
  GET_APPOINTMENT_REPORTS_RESP,
  AppointmentPrintData,
} from 'src/app/shared/models/appointment';
import { SmsAndCallInfoModal } from 'src/app/shared/components/sms-and-call-info-modal/sms-and-call-info-modal';
import { PaginationTableHeaders } from 'src/app/shared/models/generic';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import Swal from 'sweetalert2';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-appointment-reports',
  templateUrl: './appointment-reports.component.html',
  styleUrls: ['./appointment-reports.component.scss'],
})
export class AppointmentReportsComponent implements OnInit {
  appointmentReportsTableHeaders: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    { title: 'first_name', value: 'first_name', sort: false, orderBy: '' },
    { title: 'last_name', value: 'last_name', sort: false, orderBy: '' },
    {
      title: 'date_of_birth',
      value: 'date_of_birth',
      sort: false,
      orderBy: '',
    },
    { title: 'event', value: 'event', sort: false, orderBy: '' },
    {
      title: 'provider',
      value: 'provider_name',
      sort: true,
      orderBy: '',
    },
    {
      title: 'cell_phone',
      value: 'cell_phone',
      sort: false,
      orderBy: '',
    },
    {
      title: 'appt_date',
      value: 'appt_date',
      sort: true,
      orderBy: '',
    },
    {
      title: 'sms_sent_at (PST)',
      value: 'sms_sent_at',
      sort: false,
      orderBy: '',
    },
    {
      title: 'called_at (PST)',
      value: 'called_at',
      sort: false,
      orderBy: '',
    },
    {
      title: 'appt_time',
      value: 'appt_begin_time',
      sort: true,
      orderBy: '',
    },
  ];

  appointmentReportsTableData: AppointmentReportsTableData[];
  appomtmentPrintData: AppointmentPrintData[];
  userRole = '';
  pageSize: number = 12;
  searchKeyword: string = '';
  searchCriteria: string = 'provider_name';
  currentPage = 1;
  style: string = 'flex';
  tablePages: ServerSidePages;
  assistantInfo: Assistant = null;
  user: any;
  statusFilter = 'Confirmed';
  selectedProvider: { id: string; description: string };
  filterByDate: string = '';
  selectedTime: string = '';

  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: MatSlideToggle;

  @HostListener('document:keydown.shift.arrowright')
  next() {
    this.nextPage();
  }
  @HostListener('document:keydown.shift.control.arrowright')
  end() {
    this.lastPage();
  }
  @HostListener('document:keydown.shift.arrowleft')
  prev() {
    this.prevPage();
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  start() {
    this.firstPage();
  }

  constructor(
    private firebaseService: FirebaseService,
    private toastr: ToastrService,
    private appointmentService: AppointmentService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user'));
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();
    
    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
    }

    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getAppointmentReports();
  }

  getAppointmentReports(page: number = 1, limit: number = this.pageSize, orderBy: any = {}): void {
    const formattedTime = this.selectedTime ? this.selectedTime.replace(':', '') : '';
    const formattedDate = this.filterByDate ? moment(this.filterByDate).format('YYYYMMDD') : this.filterByDate;

    if (this.statusFilter === 'Cancelled') {
      const hasObjectWithId = this.appointmentReportsTableHeaders.some((obj) => obj.title === 'canceled_by');
      if (!hasObjectWithId) {
        this.appointmentReportsTableHeaders.push({
          title: 'canceled_by',
          value: 'canceled_by',
          sort: false,
          orderBy: '',
        });
      }
    } else {
      for (let i = this.appointmentReportsTableHeaders.length - 1; i >= 0; i--) {
        if (this.appointmentReportsTableHeaders[i].title === 'canceled_by') {
          this.appointmentReportsTableHeaders.splice(i, 1);
        }
      }
    }

    this.spinnerService.show();

    this.appointmentService
      .getAppointmentReports(
        page,
        limit,
        orderBy,
        this.statusFilter,
        this.selectedProvider?.id,
        this.searchKeyword,
        this.searchCriteria,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        formattedDate,
        formattedTime
      )
      .then((resp: GET_APPOINTMENT_REPORTS_RESP) => {
        this.tablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.appointmentReportsTableData = resp.data.data.map((appointment: AppointmentReportsData, index: number) => ({
          no: index + 1,
          id: appointment.id,
          first_name: appointment.patient.first_name,
          last_name: appointment.patient.last_name,
          date_of_birth:
            appointment.patient.date_of_birth && moment(appointment.patient.date_of_birth).format('MM/DD/YYYY'),
          event: appointment.event,
          provider_id: appointment.provider.provider_id,
          provider: appointment.provider.description,
          cell_phone: appointment.patient.cell_phone,
          appt_date: appointment.appt_date && moment(appointment.appt_date).format('MM/DD/YYYY'),
          'sms_sent_at (PST)':
            appointment.sms_sent_at &&
            moment.tz(appointment.sms_sent_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
          'called_at (PST)':
            appointment.called_at &&
            moment.tz(appointment.called_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
          appt_time:
            appointment.appt_time && appointment.appt_time.length === 4
              ? `${appointment.appt_time[0]}${appointment.appt_time[1]}:${appointment.appt_time[2]}${appointment.appt_time[3]}`
              : '',
          canceled_by: appointment.canceled_by,
          is_confirmed: appointment.is_confirmed,
          confirmed_by: appointment.confirmed_by,
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        console.log(err);
      })
      .finally(() => this.spinnerService.hide());
  }

  lastPage = (): void => {
    let order;
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.nextPage) this.getAppointmentReports(this.tablePages.lastPage, this.pageSize, order);
  };

  nextPage = (): void => {
    let order;
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.nextPage) this.getAppointmentReports(this.tablePages.nextPage, this.pageSize, order);
  };

  prevPage = (): void => {
    let order;
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.prevPage) this.getAppointmentReports(this.tablePages.prevPage, this.pageSize, order);
  };

  firstPage = (): void => {
    let order;
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.prevPage) this.getAppointmentReports(this.tablePages.firstPage, this.pageSize, order);
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (typeof pageNumber === 'number' && pageNumber <= this.tablePages.totalPages && 0 < pageNumber) {
      let order;
      this.appointmentReportsTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      this.getAppointmentReports(pageNumber, this.pageSize, order);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  orderBy = (column_name: string): void => {
    const index = this.appointmentReportsTableHeaders.findIndex((header) => header.value === column_name);

    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.appointmentReportsTableHeaders[index].orderBy === 'DESC')
      this.appointmentReportsTableHeaders[index].orderBy = 'ASC';
    else this.appointmentReportsTableHeaders[index].orderBy = 'DESC';

    const order = {
      column_name,
      sort: this.appointmentReportsTableHeaders[index].orderBy,
    };

    this.getAppointmentReports(this.tablePages.currentPage, this.pageSize, order);
  };

  onChangeSwitch(page = 1) {
    this.getAppointmentReports(page);
  }

  archiveAppointment = (appointment: AppointmentReportsTableData): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.appointmentService
          .archiveAppointment(
            appointment.id,
            this.attachmentSwitch.checked ? false : true,
            this.user.stsTokenManager.accessToken
          )
          .then(() => {
            this.spinnerService.hide();
            this.getAppointmentReports(this.tablePages.currentPage);
            this.toastr.success(
              this.attachmentSwitch.checked ? 'The dictation was unarchived.' : 'The dictation was archived.'
            );
          })
          .catch(() => {
            this.spinnerService.hide();
            this.toastr.error(
              this.attachmentSwitch.checked ? 'Failed to unarchive the dictation.' : 'Failed to archive the dictation.'
            );
          });
      }
    });
  };

  openFilterModal(): void {
    this.spinnerService.show();
    this.appointmentService
      .getProvidersHavingAppointments(this.user.stsTokenManager.accessToken)
      .then((resp) => {
        this.spinnerService.hide();
        const filterOptions = {
          filter: resp.data.map((n: Provider) => ({
            id: n.provider_id,
            title: n.description,
          })),
        };
        const filterModal = this.modalService.open(FilterModalComponent, {
          animation: true,
          centered: true,
        });

        filterModal.componentInstance.header = 'Providers';
        filterModal.componentInstance.filterOptions = filterOptions;
        if (this.selectedProvider) filterModal.componentInstance.selectedFilter = this.selectedProvider.description;

        filterModal.result
          .then((result) => {
            if (result) {
              this.appointmentReportsTableHeaders.forEach((header) => {
                header.orderBy = '';
              });

              this.selectedProvider = {
                description: result.title,
                id: result.id,
              };

              this.getAppointmentReports(this.tablePages.firstPage, this.pageSize);
            } else {
              this.appointmentReportsTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.selectedProvider = {
                description: '',
                id: '',
              };
              this.getAppointmentReports(this.tablePages.firstPage);
            }
          })
          .catch((err) => {
            if (err != 0) console.error(err);
          });
      })
      .catch(() => {
        this.spinnerService.hide();
      });
  }

  searchRecords(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') this.searchKeyword = '';

    let order;
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    this.getAppointmentReports(1, this.pageSize, order);
  }

  downloadCSV = (): void => {
    if (!this.appointmentReportsTableData?.length) {
      this.toastr.error('No Data Available');
      return;
    }

    this.spinnerService.show();

    let order = {};
    this.appointmentReportsTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    const formattedTime = this.selectedTime ? this.selectedTime.replace(':', '') : '';
    const formattedDate = this.filterByDate ? moment(this.filterByDate).format('YYYYMMDD') : this.filterByDate;
    this.appointmentService
      .downloadCSV(
        this.tablePages.currentPage,
        -1,
        order,
        this.statusFilter,
        this.selectedProvider?.id,
        this.searchKeyword,
        this.searchCriteria,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        formattedDate,
        formattedTime
      )
      .subscribe(
        (data) => {
          let downloadURL = window.URL.createObjectURL(data);
          let link = document.createElement('a');
          link.href = downloadURL;
          link.download = `appointment.csv`;
          link.click();
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.toastr.error('Something went wrong. Please try again');
        }
      );
  };

  async printData() {
    if (!this.appointmentReportsTableData?.length) {
      this.toastr.error('No Data Available');
      return;
    }
    this.spinnerService.show();
    const formattedDate = this.filterByDate ? moment(this.filterByDate).format('YYYYMMDD') : this.filterByDate;
    try {
      const formattedTime = this.selectedTime ? this.selectedTime.replace(':', '') : '';
      const resp: GET_APPOINTMENT_REPORTS_RESP = await this.appointmentService.getAppointmentReportsForPrint(
        1,
        {},
        this.statusFilter,
        this.selectedProvider?.id,
        this.searchKeyword,
        this.searchCriteria,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        formattedDate,
        formattedTime
      );

      this.appointmentReportsTableData = resp.data.data.map((appointment: AppointmentReportsData, index: number) => ({
        no: index + 1,
        id: appointment.id,
        first_name: appointment.patient.first_name,
        last_name: appointment.patient.last_name,
        date_of_birth:
          appointment.patient.date_of_birth && moment(appointment.patient.date_of_birth).format('MM/DD/YYYY'),
        event: appointment.event,
        provider_id: appointment.provider.provider_id,
        provider: appointment.provider.description,
        cell_phone: appointment.patient.cell_phone,
        appt_date: appointment.appt_date && moment(appointment.appt_date).format('MM/DD/YYYY'),
        'sms_sent_at (PST)':
          appointment.sms_sent_at &&
          moment.tz(appointment.sms_sent_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
        'called_at (PST)':
          appointment.called_at && moment.tz(appointment.called_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
        appt_time:
          appointment.appt_time.length === 4
            ? `${appointment.appt_time[0]}${appointment.appt_time[1]}:${appointment.appt_time[2]}${appointment.appt_time[3]}`
            : '',
        canceled_by: appointment.canceled_by,
        confirmed_by: appointment.confirmed_by,
      }));
      const dataWithHeaders: AppointmentPrintData[] = this.appointmentReportsTableData.map(
        (appointment: AppointmentReportsTableData) => {
          const headers: AppointmentPrintData = {
            first_name: appointment.first_name,
            last_name: appointment.last_name,
            date_of_birth: appointment.date_of_birth,
            provider: appointment.provider,
            event: appointment.event,
            appt_date: appointment.appt_date,
            appt_time: appointment.appt_time,
            status: this.statusFilter,
          };
          if (
            appointment.canceled_by !== undefined &&
            appointment.canceled_by !== null &&
            appointment.canceled_by !== '0'
          ) {
            headers.canceled_by = appointment.canceled_by;
          }

          if (
            appointment.confirmed_by !== undefined &&
            appointment.confirmed_by !== null &&
            appointment.confirmed_by !== '0'
          ) {
            headers.confirmed_by = appointment.confirmed_by;
          }

          return headers;
        }
      );

      this.printDataToWindow(dataWithHeaders);
    } catch (err) {
      this.spinnerService.hide();
      this.toastr.error('Something went wrong. Please try again');
      console.log(err);
    } finally {
      this.spinnerService.hide();
    }
  }

  printDataToWindow = (data: AppointmentPrintData[]): void => {
    let printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Appointment Reports</title>');
    printWindow.document.write(
      '<style>table { border-collapse: collapse; } table, th, td { border: 1px solid black; }</style>'
    );
    printWindow.document.write('<style>th { padding: 4px; }</style>');
    printWindow.document.write('<style>td { text-align: center; padding: 4px; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<h1>Appointment Reports</h1>');
    printWindow.document.write('<table border="1">');
    printWindow.document.write('<thead><tr>');

    const headers = Object.keys(data[0]);

    headers.forEach((header) => {
      const formattedHeader = header.replace(/_/g, ' ').toUpperCase();
      printWindow.document.write('<th>' + formattedHeader + '</th>');
    });

    printWindow.document.write('</tr></thead>');
    printWindow.document.write('<tbody>');

    data.forEach((appointment) => {
      printWindow.document.write('<tr>');
      headers.forEach((header) => {
        let value = appointment[header];
        if (!value || value === 'null' || value === 'Null' || value === 'undefined') {
          value = '';
        }
        printWindow.document.write('<td>' + value + '</td>');
      });
      printWindow.document.write('</tr>');
    });

    printWindow.document.write('</tbody></table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    this.spinnerService.hide();
  };

  onDateChange = () => {
    if (this.filterByDate !== '') {
      const selectedDate = moment(this.filterByDate);
      const formattedTime = this.selectedTime ? this.selectedTime.replace(':', '') : '';

      const formattedDate = moment(this.filterByDate).format('YYYYMMDD');
      if (formattedDate && formattedDate !== 'Invalid date') {
        this.spinnerService.show();
        this.appointmentService
          .getAppointmentReports(
            this.tablePages.currentPage,
            this.pageSize,
            {},
            this.statusFilter,
            this.selectedProvider?.id || '',
            this.searchKeyword,
            this.searchCriteria,
            this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
            formattedDate,
            formattedTime
          )
          .then((resp) => {
            this.tablePages = {
              currentPage: resp.data.currentPage,
              totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
              lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
              nextPage: resp.data.nextPage,
              prevPage: resp.data.previousPage,
              firstPage: 1,
              total: resp.data.total,
            };
            this.appointmentReportsTableData = resp.data.data.map(
              (appointment: AppointmentReportsData, index: number) => ({
                no: index + 1,
                id: appointment.id,
                first_name: appointment.patient.first_name,
                last_name: appointment.patient.last_name,
                date_of_birth:
                  appointment.patient.date_of_birth && moment(appointment.patient.date_of_birth).format('MM/DD/YYYY'),
                event: appointment.event,
                provider_id: appointment.provider.provider_id,
                provider: appointment.provider.description,
                cell_phone: appointment.patient.cell_phone,
                appt_date: appointment.appt_date && moment(appointment.appt_date).format('MM/DD/YYYY'),
                'sms_sent_at (PST)':
                  appointment.sms_sent_at &&
                  moment.tz(appointment.sms_sent_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
                'called_at (PST)':
                  appointment.called_at &&
                  moment.tz(appointment.called_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
                appt_time: `${appointment.appt_time[0]}${appointment.appt_time[1]}:${appointment.appt_time[2]}${appointment.appt_time[3]}`,
                canceled_by: appointment.canceled_by,
                is_confirmed: appointment.is_confirmed,
                confirmed_by: appointment.confirmed_by,
              })
            );
            this.spinnerService.hide();
          })
          .catch((error) => {
            console.error('Error fetching appointment reports:', error);
            this.toastr.error('Failed to fetch appointment reports. Please try again.');
            this.spinnerService.hide();
          });
      } else {
        this.getAppointmentReports();
      }
    } else if (this.filterByDate === '') {
      this.filterByDate = '';
      this.getAppointmentReports();
    }
  };

  disableTyping(event: KeyboardEvent) {
    event.preventDefault();
  }

  clearSelectedTime() {
    this.selectedTime = null;
    const formattedDate = moment(this.filterByDate).format('YYYYMMDD');
    if (formattedDate && formattedDate !== 'Invalid date') {
      this.spinnerService.show();
      this.appointmentService
        .getAppointmentReports(
          this.tablePages.currentPage,
          this.pageSize,
          {},
          this.statusFilter,
          this.selectedProvider?.id || '',
          this.searchKeyword,
          this.searchCriteria,
          this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
          formattedDate,
          ''
        )
        .then((resp) => {
          this.tablePages = {
            currentPage: resp.data.currentPage,
            totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
            nextPage: resp.data.nextPage,
            prevPage: resp.data.previousPage,
            firstPage: 1,
            total: resp.data.total,
          };
          this.appointmentReportsTableData = resp.data.data.map(
            (appointment: AppointmentReportsData, index: number) => ({
              no: index + 1,
              id: appointment.id,
              first_name: appointment.patient.first_name,
              last_name: appointment.patient.last_name,
              date_of_birth:
                appointment.patient.date_of_birth && moment(appointment.patient.date_of_birth).format('MM/DD/YYYY'),
              event: appointment.event,
              provider_id: appointment.provider.provider_id,
              provider: appointment.provider.description,
              cell_phone: appointment.patient.cell_phone,
              appt_date: appointment.appt_date && moment(appointment.appt_date).format('MM/DD/YYYY'),
              'sms_sent_at (PST)':
                appointment.sms_sent_at &&
                moment.tz(appointment.sms_sent_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
              'called_at (PST)':
                appointment.called_at &&
                moment.tz(appointment.called_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
              appt_time: `${appointment.appt_time[0]}${appointment.appt_time[1]}:${appointment.appt_time[2]}${appointment.appt_time[3]}`,
              canceled_by: appointment.canceled_by,
              is_confirmed: appointment.is_confirmed,
              confirmed_by: appointment.confirmed_by,
            })
          );
          this.spinnerService.hide();
        })
        .catch((error) => {
          console.error('Error fetching appointment reports:', error);
          this.toastr.error('Failed to fetch appointment reports. Please try again.');
          this.spinnerService.hide();
        });
    } else {
      this.getAppointmentReports();
    }
  }

  onTimeChange() {
    const formattedTime = this.selectedTime.replace(':', '');
    this.getReportsForSelectedDateTime(formattedTime);
  }

  getReportsForSelectedDateTime(formattedTime: string) {
    const formattedDate = this.filterByDate ? moment(this.filterByDate).format('YYYYMMDD') : this.filterByDate;
    this.spinnerService.show();
    this.appointmentService
      .getAppointmentReports(
        this.tablePages.currentPage,
        this.pageSize,
        {},
        this.statusFilter,
        this.selectedProvider?.id || '',
        this.searchKeyword,
        this.searchCriteria,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        formattedDate,
        formattedTime
      )
      .then((resp) => {
        this.tablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.appointmentReportsTableData = resp.data.data.map((appointment: AppointmentReportsData, index: number) => ({
          no: index + 1,
          id: appointment.id,
          first_name: appointment.patient.first_name,
          last_name: appointment.patient.last_name,
          date_of_birth:
            appointment.patient.date_of_birth && moment(appointment.patient.date_of_birth).format('MM/DD/YYYY'),
          event: appointment.event,
          provider_id: appointment.provider.provider_id,
          provider: appointment.provider.description,
          cell_phone: appointment.patient.cell_phone,
          appt_date: appointment.appt_date && moment(appointment.appt_date).format('MM/DD/YYYY'),
          'sms_sent_at (PST)':
            appointment.sms_sent_at &&
            moment.tz(appointment.sms_sent_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
          'called_at (PST)':
            appointment.called_at &&
            moment.tz(appointment.called_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
          appt_time: `${appointment.appt_time[0]}${appointment.appt_time[1]}:${appointment.appt_time[2]}${appointment.appt_time[3]}`,
          canceled_by: appointment.canceled_by,
          is_confirmed: appointment.is_confirmed,
          confirmed_by: appointment.confirmed_by,
        }));
        this.spinnerService.hide();
      })
      .catch((error) => {
        console.error('Error fetching appointment reports:', error);
        this.toastr.error('Failed to fetch appointment reports. Please try again.');
        this.spinnerService.hide();
      });
  }

  getReminderDetails = (record: any): void => {
    this.spinnerService.show();
    this.appointmentService
      .getAppointmentReminderDetails(record.cell_phone)
      .then((resp) => {
        this.spinnerService.hide();
        const reminderModal = this.modalService.open(SmsAndCallInfoModal, {
          size: 'xl',
          centered: true,
        });
        reminderModal.componentInstance.altTitle = 'Contact History';
        reminderModal.componentInstance.reminderData = {
          appt_date: record.appt_date,
          appt_time: record.appt_time,
          confirmedStatus: record.cell_phone,
          cellNumber: record.cell_phone,
          is_confirmed: record.is_confirmed,
          dateTimeSmsSent: record['sms_sent_at (PST)'],
          smsResult: resp.data.sms_details,
          dateTimeCallSent: record['called_at (PST)'],
          callResults: resp.data.call_details,
        };
      })
      .catch((error) => {
        console.log(error);
        this.spinnerService.hide();
        this.toastr.error('Something went wrong ! Please Try Again');
      });
  };
}
