import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  NgbDateStruct,
  NgbModal,
  NgbTimepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
import {
  ADD_ORDER_COMMENT_RESP,
  AllOrderStatusData,
  Assistant,
  BenefitPayerProcedure,
  CREATE_ORDER_BENEFIT_INSURANCE_RESP,
  CREATE_ORDER_BENEFIT_RESP,
  GenericResp,
  GET_ALL_ORDER_STATUS_RESP,
  GET_ORDER_ASSIGNED_FORMS_RESP,
  GET_ORDER_BENEFIT_PAYER_PROCEDURES,
  GET_ORDER_DETAILS_RESP,
  GET_PAYER_MPPR_RESP,
  GET_PROVIDERS_LIST_RESP,
  OrderAppointmentData,
  OrderAttachmentData,
  OrderBenefit,
  OrderCommentData,
  OrderDetail,
  OrderProvider,
  OrdersData,
  OrderSelectedStep,
  OrderStep,
  OrderStepData,
  OrderStepsCommentData,
  OrderTasksData,
  PatientOrder,
  PatientOrdersTableData,
  Payer,
  PayerMppr,
  Provider,
  ServerSidePages,
  UPDATE_ORDER_DETAIL_REQ,
  UPDATE_ORDER_RESP,
  UPDATE_ORDER_STEP_REQ,
  GET_ALL_NOTES_RESP,
  OrderNote,
  OrderInsuranceFormData,
  GET_ALL_INSURANCE_FORMS_RESP,
} from '../../models';
import * as Editor from '../../../../assets/textEditor/build/ckeditor';
import * as moment from 'moment';
import { OrderService } from '../../services/order.service';
import { AppService } from '../../services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RenameModalComponent } from '../rename-modal/rename-modal.component';
import { AddBenefitInsuranceModalComponent } from '../add-benefit-insurance-modal/add-benefit-insurance-modal.component';
import { AddAppointmentsComponent } from '../add-appointments/add-appointments.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { AuthorizationFormsComponent } from '../authorization-forms/authorization-forms.component';

const DATE: Date = new Date();
const add_new_Item = 'Add New Location';

@Component({
  selector: 'app-order-details-panel',
  templateUrl: './order-details-panel.component.html',
  styleUrls: ['./order-details-panel.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class OrderDetailsPanelComponent implements OnInit {
  @Input() headers: [];
  @Input() data: [];
  @Input() paginateId: string = '1';
  @Input() order: PatientOrder = null;
  @Input() assistantInfo: Assistant = null;
  @Input() assistantPractice: string;
  @Input() assistantUID: string;
  @Input() details: boolean = true;
  @Input() steps: boolean = true;
  @Input() benefits: boolean = true;
  @Input() appointments: boolean = true;
  @Input() tasks: boolean = true;
  @Input() attachments: boolean = true;
  @Output() reloadGrid = new EventEmitter<boolean>();
  selectedOrder: PatientOrder;
  orderBenefitProcedureTableHeaders: string[] = [
    'cpt',
    'contract',
    'mppr',
    'allowable',
    'contract_description',
    'action',
  ];
  orderCommentsTableHeaders: string[] = ['date', 'title', 'comment'];
  orderStepCommentsTableHeaders: string[] = ['date', 'title', 'comment'];
  orderStepNotesTableHeaders = [
    'Date Created',
    'Description',
    'Created By',
    'Contact Time',
  ];

  orderAppointmentsTableHeaders: string[] = [
    'id',
    'provider',
    'location',
    'event_type',
    'date',
    'time',
  ];
  attachementsTableHeader = ['file_name'];
  orderStepsTableHeaders: string[] = ['step', 'status'];
  orderFormsTableHeaders: string[] = [
    'task_description',
    'due_date',
    'assign_date',
    'completed',
    'form_title',
  ];
  selectedUserOrders: OrdersData[];
  selectedUserOrdersTableData: PatientOrdersTableData[];
  selectedOrderCommentsTableData: OrderCommentData[];
  selectedOrderStepsCommentsTableData: OrderStepsCommentData[];
  selectedNote: OrderNote | null = null;

  OrderStepsNotesData: OrderStepData[] = [];
  allOrderStepsNotesData: any[] = []; // For downloading
  InsuranceFormsData: any[] = [];
  selectedOrderAppointmentsTableData: OrderAppointmentData[];
  selectedAttachmentsTableData: OrderAttachmentData[];
  selectedOrderFormsData: OrderInsuranceFormData[];
  selectedOrderTasksTableData: OrderTasksData[];
  selectedOrderStepsTableData: OrderStepData[];
  selectedOrderDetails: OrderDetail;
  practiceProviders: GET_PROVIDERS_LIST_RESP[];
  selectedOrderProvider: OrderProvider;
  orderStatusAll: AllOrderStatusData[];
  selectedStep: OrderStepData;
  selectedOrderStep: OrderSelectedStep;
  selectedStatus: { description: string; status: string };
  estimateOutOfPocket: any;
  ampm: string = 'AM';
  canUpdateBenefit: boolean = false;
  time = { hour: 13, minute: 30 };
  orderDatePickerModel: NgbDateStruct = {
    year: DATE.getFullYear(),
    month: DATE.getMonth() + 1,
    day: DATE.getDate(),
  };
  orderTaskDueDatePickerModel: NgbDateStruct = {
    year: null,
    month: null,
    day: null,
  };
  tablePages: ServerSidePages;
  pageSize: number = 10;
  timeToggle: boolean = false;
  searchKeywork: string = '';
  searchCriteria: string = 'patient_name';
  benefitProcedureTableData: BenefitPayerProcedure[];
  totalAllowable: number;
  selectedPayer: Payer;
  selectedPayerMPPR: PayerMppr;
  clientLocations: any;
  orderDateUpdate: any;
  scheduled_date: any;
  chatShow: boolean;
  orderDate: any;
  showFacility: boolean = false;
  newFacility: string;
  patient_enrolled: boolean = false;
  selectedLocation: any;
  selectedAssignOrderForm: { id: string; title: string };
  closeResult = '';
  mask = [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public editor = Editor;
  notes: string = '';
  patient: any;
  conversation: any;
  userRole: string;
  isDragOver = false;
  isEditing: boolean = false;
  selectedNoteId: string | null = null;

  @ViewChild('steps_table', { static: false }) stepsTable: ServerSidePages;
  @Output() getPendingOrders = new EventEmitter<object>();

  @Input() deleteFunction: (args: any) => void = null;
  @Input() paginationLastPage: () => void = null;
  @ViewChild('authModal') authModal!: AuthorizationFormsComponent;

  pdfList: any[] = []; // This will store the list of fetched PDFs
  primaryPayerId: any;
  primaryPayerName: any;
  primaryPayerGroup: any;

  constructor(
    private orderService: OrderService,
    private appService: AppService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private config: NgbTimepickerConfig,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef
  ) {
    config.spinners = false;
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.showFacility = false;
    this.userRole = await this.authService.getUserRole();
    let d;
    let convertedTime;
    let hour;

    if (this.order) {
      this.selectedOrder = { id: this.order.order_id, ...this.order };
      if (this.order.order_date === null) {
        this.time = { hour: 13, minute: 30 };
      }
      if (this.order.order_date !== null) {
        d = moment.parseZone(this.order.order_date);
        d = new Date(Date.parse(d._i));
        convertedTime = moment(d.getHours() + ':' + d.getMinutes(), [
          'hh:mm',
        ]).format('hh:mm a');

        hour = convertedTime.substring(0, 2);
        this.ampm = convertedTime.substring(6, 8).toUpperCase();
        if (hour.length > 0) {
          this.time = {
            hour: Number(hour),
            minute: d.getMinutes(),
          };
        }
      }
      if (this.order.order_id) {
        this.resetOrderDetailsState();

        this.getOrderDetails();
        if (this.userRole === 'practice_admin') {
          this.appService
            .getProviders(this.assistantPractice)
            .then((resp: GET_PROVIDERS_LIST_RESP[]) => {
              this.practiceProviders = resp;
            });
        }

        this.orderService
          .getAllOrderStatus()
          .then((resp: GET_ALL_ORDER_STATUS_RESP) => {
            if (resp.code !== 200) return;
            this.orderStatusAll = resp.data;
          });
      }
    } else {
      this.selectedOrder = null;
      this.selectedOrderDetails = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  /* start// Patient Details */
  updatePatient(): void {
    const renameRef = this.modalService.open(RenameModalComponent, {
      centered: true,
    });
    renameRef.componentInstance.first_name =
      this.selectedOrder.patient.first_name;
    renameRef.componentInstance.last_name =
      this.selectedOrder.patient.last_name;
      renameRef.componentInstance.detail = 'name';
    renameRef.componentInstance.patient_id = this.selectedOrder.patient.id;
    renameRef.result.then((result) => {
      if (result === 'Close click') {
        return;
      }
      this.spinnerService.show();
      this.orderService
        .updatePatient(
          this.selectedOrder.patient.id,
          {
          first_name : result.first_name,
          last_name : result.last_name
          }
        )
        .then(() => {
          this.spinnerService.hide();
          this.toastr.success('Patient name updated sucessfully.');
          this.selectedOrder.patient.first_name = result.first_name;
          this.selectedOrder.patient.last_name = result.last_name;
        })
        .catch((error) => {
          this.spinnerService.hide();
          this.toastr.error(
            "Could't complete request due to an error, please try again later."
          );
          console.log(error);
        });
    });
  }
  /* end// Patient Details */

  /* start// Order Details */
  private getOrderDetails(): void {
    this.spinnerService.show();
    this.orderService
      .getPatientOrderDetails(this.selectedOrder.order_id)
      .then((resp: GET_ORDER_DETAILS_RESP) => {
        this.spinnerService.hide();
        this.selectedOrderDetails = resp.data;
        this.primaryPayerId = resp.data.primary_payer_id;
        this.primaryPayerName = resp.data.primary_payer_name;
        this.primaryPayerGroup = resp.data.primary_payer_group;
        this.selectedOrderDetails.client.payers.sort(function (a, b) {
          if (a.description < b.description) {
            return -1;
          }
          if (a.description > b.description) {
            return 1;
          }
          return 0;
        });
        // this.notes = resp.data.order_notes;
        this.patient_enrolled = resp.data.is_patient_enrolled;
        this.selectedOrderCommentsTableData =
          this.selectedOrderDetails.comments.map((comment) => ({
            comment_id: comment.comment_id,
            title: comment.title,
            comment: comment.comment,
            date: DateTime.fromISO(comment.created_at).toLocaleString(
              DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
            ),
          }));

        this.getClientLocations();

        this.selectedOrderAppointmentsTableData =
          this.selectedOrderDetails.appointments.map((appointment) => ({
            appointment_id: appointment.appointment_id,
            id: appointment.appointment_id,
            provider: appointment.provider?.description,
            location: appointment.location,
            event_type: appointment.event_type,
            date: moment
              .parseZone(appointment.appointment_date)
              .format('MM/DD/YYYY'),
            time: moment.parseZone(appointment.appointment_time).format('LT'),
          }));
        this.selectedOrderTasksTableData = this.selectedOrderDetails.forms.map(
          (task) => ({
            task_id: task.id,
            form_id: task.form.id,
            task_type: task.task_type,
            task_description: task.task_description,
            due_date: task.due_date
              ? DateTime.fromISO(task.due_date).toLocaleString(
                  DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
                )
              : '',
            assign_date: task.assign_date
              ? DateTime.fromISO(task.assign_date).toLocaleString(
                  DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
                )
              : '',
            completed: task.is_completed ? 'Yes' : 'No',
            form_title: task.form.title,
          })
        );

        this.selectedOrderProvider = {
          id: this.selectedOrderDetails.provider.provider_id,
          name: this.selectedOrderDetails.provider.description,
        };

        if (this.selectedOrder.order_date) {
          const date = moment.parseZone(this.selectedOrder.order_date);
          this.orderDatePickerModel = {
            year: date.year(),
            month: date.month() + 1,
            day: date.date(),
          };
        } else this.orderDatePickerModel = null;

        const benefit: OrderBenefit = this.selectedOrderDetails.benefits[0];
        if (benefit)
          this.evaluateEstOutOfPocket(
            +benefit.allowable,
            +benefit.deductable,
            +benefit.deductable_met,
            +benefit.coinsurance,
            +benefit.patient_responsibility
          );
        else this.estimateOutOfPocket = 0;
        this.selectedOrderStepsTableData = this.selectedOrderDetails.steps.map(
          (step: OrderStep) => ({
            id: step.step_id,
            step: this.transform(step.description),
            status: step.status.description || '',
            status_detail: step.status,
            comments: step.comments,
          })
        );
        this.selectedAttachmentsTableData =
          this.selectedOrderDetails.attachments.map(
            (resp: OrderAttachmentData) => ({
              order_id: this.selectedOrderDetails.order_id,
              file_name: resp.file_name,
              file_url: resp.file_url,
              id: resp.id,
            })
          );
        this.getOrderStepNotes(this.selectedOrderDetails.order_id);
        if (resp.code === 200) {
          if (
            this.userRole === 'location_user' ||
            this.userRole === 'location_admin'
          ) {
            this.orderService
              .updateOrderReadStatus(this.selectedOrder.order_id, true)
              .then((resp: any) => {});
          }
        }
        this.getOrderInsuranceForms(this.selectedOrderDetails.order_id);
      });
  }

  private resetOrderDetailsState(): void {
    this.selectedStep = null;
    this.selectedOrderStep = null;
    this.canUpdateBenefit = false;
    this.benefitProcedureTableData = [];
    this.totalAllowable = null;
    this.resetPagination();
  }

  private resetPagination() {
    this.stepsTable && (this.stepsTable.currentPage = 1);
  }

  getClientLocations() {
    let client_id; // = 'D170AF00-211A-48EC-BB62-885AAE4AA691';
    if (
      this.selectedOrderDetails.client &&
      this.selectedOrderDetails.client.id
    ) {
      client_id = this.selectedOrderDetails.client.id;
    }
    this.selectedLocation = {
      description: this.selectedOrder.order_facility,
    };
    this.spinnerService.show();
    this.orderService.getClientLocations(client_id).then((resp: any) => {
      this.spinnerService.hide();

      this.clientLocations = resp.data;
      this.addNewItem();
    });
  }

  addNewItem() {
    let obj = {
      location_id: 0,
      description: add_new_Item,
    };
    this.clientLocations.push(obj);
    if (this.clientLocations.length === 1) {
      this.showFacility = true;
    }
  }

  transform(value: string): string {
    if (value) {
      let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
    } else return value;
  }

  updateOrderDetails(
    order_summary: string,
    patient_phone: string,
    physician_notes: string
  ): void {
    this.selectedOrder.patient_phone = this.selectedOrder.patient_phone.replace(
      /\D+/g,
      ''
    );
    if (
      this.selectedOrder.patient_phone === '' ||
      this.selectedOrder.patient_phone.length < 10
    ) {
      this.toastr.error('Please Add (complete) phone number');
      return;
    }

    if (this.orderDatePickerModel != null && this.time != null) {
      if (this.ampm === 'PM') {
        this.time.hour === 12 ? (this.time.hour = 0) : this.time.hour + 12;
      }
      this.orderDateUpdate =
        this.orderDatePickerModel.year +
        '-' +
        this.orderDatePickerModel.month +
        '-' +
        this.orderDatePickerModel.day;
      this.orderDate = moment(this.orderDateUpdate);
      this.orderDate.hour(this.time.hour);
      this.orderDate.minute(this.time.minute);
      this.scheduled_date =
        this.orderDate.format('YYYY-MM-DDTHH:mm:ss') + '.000Z';
    } else {
      this.scheduled_date = null;
    }

    Swal.fire({
      title: 'Update Order Details',
      text: 'Are you sure you want to update order Details?',
      icon: 'warning',
      input: 'checkbox',
      inputValue: true,
      inputPlaceholder: 'Notify Patient',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const data: UPDATE_ORDER_DETAIL_REQ = {
          notify_patient: !!result.value,
          provider_id: this.selectedOrderProvider.id,
          order_summary,
          order_facility: this.newFacility,
          patient_phone: this.selectedOrder.patient_phone,
          physician_notes,
          scheduled_date: this.scheduled_date,
        };
        this.spinnerService.show();
        this.orderService
          .updatePatientOrderDetails(this.selectedOrder.order_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.getPendingOrders.emit();
            /* this.reloadFacility(); */
            Swal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'The Order was updated Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'Failed to update the Order, please try again later.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  }

  reloadFacility() {
    this.showFacility = false;
    let obj = {
      description: this.newFacility,
    };
    this.selectedLocation = {
      description: this.newFacility,
    };
    this.clientLocations.push(obj);
  }

  changeOrderProvider(event: any): void {
    const provider = JSON.parse(event.target.value);

    this.selectedOrderProvider = {
      id: provider.provider_id,
      name: provider.description,
    };
  }

  resetScheduledDate(): void {
    if (!this.selectedOrder.order_date) {
      this.toastr.info('No date is scheduled for the current order.');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Reset!',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          scheduled_date: '0',
          notify_patient: false,
        };

        this.spinnerService.show();
        this.orderService
          .updatePatientOrderDetails(this.selectedOrder.order_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.orderDatePickerModel = null;
            this.getPendingOrders.emit();
            Swal.fire({
              icon: 'success',
              title: 'Reset Complete!',
              text: 'The date was reset Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'Could not reset the date, please try again later.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  }

  changeLocation(event: any): void {
    const location = event.target.value;
    this.showFacility = false;
    if (location !== add_new_Item) {
      this.newFacility = location;
      this.selectedLocation = {
        description: location,
      };
    } else {
      this.showFacility = true;
    }
  }

  addComment = (): void => {
    const self = this;
    Swal.fire({
      titleText: 'Add Comment',
      html:
        '<h5>Title</h5> <input type="text" autocomplete="off" class="swalInput" id="title">' +
        '<h5>Comment</h5> <textarea class="swalInput" id="comment"></textarea>' +
        '<input type="checkbox" checked  style="margin-right:15px" id="notify-patient">Notify Patient',
      showConfirmButton: true,
      confirmButtonText: 'Save',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: function () {
        const title: string = $('input[id="title"]').val().toString();
        const comment: string = $('textarea[id="comment"]').val().toString();
        const notify_patient = $('input[id="notify-patient"]').is(':checked');

        if (title.length <= 0 || comment.length <= 0) return;

        const addOrderCommentPromise: Promise<ADD_ORDER_COMMENT_RESP> =
          self.orderService.addOrderComment(
            self.selectedOrder.order_id,
            notify_patient,
            title,
            comment,
            self.assistantUID
          );

        return addOrderCommentPromise
          .then((resp: ADD_ORDER_COMMENT_RESP) => {
            if (resp.code !== 200)
              return self.toastr.error("Could't complete request due to error");

            const comment: OrderCommentData = {
              comment_id: resp.data.id,
              title: resp.data.title,
              comment: resp.data.comment,
              date: DateTime.fromISO(resp.data.created_at).toLocaleString(
                DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
              ),
            };
            self.selectedOrderCommentsTableData.unshift(comment);
          })
          .catch(() => {
            Swal.showValidationMessage(`Request failed.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };

  facilityItem(event: any) {
    this.newFacility = event.target.value;
  }

  deleteOrder = (): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.orderService
          .deleteOrder(
            this.assistantInfo.client_id,
            this.selectedOrder.order_id
          )
          .then(() => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Order was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
            this.selectedOrder = null;
            this.selectedOrderDetails = null;
            this.getPendingOrders.emit();
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Order deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
  /* end// Order Details */

  /* start// Order Steps */
  selectStep = (step: OrderStepData): void => {
    if (!this.selectedStep || step?.id !== this.selectedStep?.id) {
      this.selectedStep = step;

      this.selectedOrderStep = {
        id: step.status_detail.id,
        name: step.status,
        comments: step.comments,
      };
      this.selectedOrderStepsCommentsTableData =
        this.selectedOrderStep.comments?.map((comment) => ({
          comment_id: comment.comment_id,
          title: comment.title,
          comment: comment.comment,
          date: DateTime.fromISO(comment.created_at).toLocaleString(
            DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
          ),
        }));
    }
  };

  changeOrderStepStatus(event: any): void {
    const status = JSON.parse(event.target.value);

    this.selectedOrderStep = {
      id: status.id,
      name: status.description,
    };
  }

  updateOrderStep(step_name: any): void {
    Swal.fire({
      title: 'Update Order Step',
      text: 'Are you sure you want to update order Step?',
      icon: 'warning',
      input: 'checkbox',
      inputValue: true,
      inputPlaceholder: 'Notify Patient',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const data: UPDATE_ORDER_STEP_REQ = {
          step_description: step_name.value || undefined,
          step_status: this.selectedOrderStep?.id,
          notify_patient: !!result.value,
        };
        this.orderService
          .updatePatientOrderStep(
            this.selectedOrder.order_id,
            this.selectedStep.id,
            data
          )
          .then((resp: UPDATE_ORDER_RESP) => {
            if (resp.code !== 200) return;

            this.toastr.success('Update Successful', 'Success');
            this.getOrderDetails();
          });
      }
    });
  }

  createForm(): void {
    this.spinnerService.show();
    this.storageService.getAllStorageFiles().subscribe(
      (resp) => {
        this.spinnerService.hide();
        if (resp.data && resp.data.length > 0) {
          this.pdfList = resp.data;
          // this.authModal.pdfList = this.pdfList;

          const modalRef = this.modalService.open(AuthorizationFormsComponent, {
            size: 'lg',
            centered: true,
          });

          this.cdr.detectChanges();

          modalRef.componentInstance.pdfList = this.pdfList;
          modalRef.componentInstance.orderDetails = this.selectedOrderDetails;
          modalRef.componentInstance.orderComments =
            this.selectedOrderCommentsTableData;
          modalRef.componentInstance.orderAppointments =
            this.selectedOrderAppointmentsTableData;
          modalRef.componentInstance.orderTasks =
            this.selectedOrderTasksTableData;
          modalRef.componentInstance.orderSteps =
            this.selectedOrderStepsTableData;
          modalRef.componentInstance.orderAttachments =
            this.selectedAttachmentsTableData;
        } else {
          this.toastr.warning('No PDFs available');
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Error fetching PDFs');
      }
    );
  }

  addStepComment = (): void => {
    const self = this;
    Swal.fire({
      titleText: 'Add Comment',
      html:
        '<h5>Title</h5> <input type="text" autocomplete="off" class="swalInput" id="title">' +
        '<h5>Comment</h5> <textarea class="swalInput" id="comment"></textarea>' +
        '<input type="checkbox" checked  style="margin-right:15px" id="notify-patient">Notify Patient',
      showConfirmButton: true,
      confirmButtonText: 'Save',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: function () {
        const title: string = $('input[id="title"]').val().toString();
        const comment: string = $('textarea[id="comment"]').val().toString();
        const notify_patient = $('input[id="notify-patient"]').is(':checked');

        if (title.length <= 0 || comment.length <= 0) return;

        const addOrderCommentPromise: Promise<ADD_ORDER_COMMENT_RESP> =
          self.orderService.addStepOrderComment(
            self.selectedOrder.order_id,
            self.selectedStep.id,
            notify_patient,
            title,
            comment,
            self.assistantUID
          );

        return addOrderCommentPromise
          .then((resp: ADD_ORDER_COMMENT_RESP) => {
            if (resp.code !== 200)
              return self.toastr.error("Could't complete request due to error");

            const comment: OrderCommentData = {
              comment_id: resp.data.id,
              title: resp.data.title,
              comment: resp.data.comment,
              date: DateTime.fromISO(resp.data.created_at).toLocaleString(
                DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
              ),
            };
            self.selectedOrderStepsCommentsTableData.unshift(comment);
          })
          .catch(() => {
            Swal.showValidationMessage(`Request failed.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };
  /* end// Order Steps */

  getOrderInsuranceForms = (order_id: string): void => {
    this.orderService
      .getOrderInsuranceForms(order_id)
      .then((resp: GET_ALL_INSURANCE_FORMS_RESP) => {
        if (resp.code === 200) {
          this.InsuranceFormsData = resp.data.map((insuranceForm) => {
            return {
              id: insuranceForm.id,
              order_id: insuranceForm.order_id,
              file_name: insuranceForm.file_name,
              file_url: insuranceForm.file_url,
            };
          });
        } else {
          this.toastr.error(resp.message);
        }
        this.spinnerService.hide();
      })
      .catch((error) => {
        this.toastr.error(error.message);
        this.spinnerService.hide();
      });
  };

  deleteInsuranceForm(id: string, order_id: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.orderService.deleteInsuranceForm(id, order_id).subscribe(
          (response) => {
            this.toastr.success('Form has been deleted.', 'Success');
            this.getOrderInsuranceForms(this.selectedOrderDetails.order_id);
            this.spinnerService.hide();
          },
          (error) => {
            this.spinnerService.hide();
            Swal.fire(
              'Error!',
              'Failed to delete the insurance form.',
              'error'
            );
            console.error('Error deleting insurance form:', error);
          }
        );
      }
    });
  }

  previewInsuranceForm = (form: any): void => {
    if (form.file_url) {
      window.open(form.file_url, '_blank');
    } else {
      this.toastr.error('No file available for preview.');
    }
  };

  getOrderStepNotes = (order_id: string): void => {
    this.spinnerService.show();
    this.orderService
      .getOrderStepNotes(order_id)
      .then((resp: GET_ALL_NOTES_RESP) => {
        if (resp.code === 200) {
          this.allOrderStepsNotesData = resp.data.map((note) => {
            const extractNameFromEmail = (
              email: string | undefined
            ): string => {
              if (!email) return '';
              const [name] = email.split('@');
              return name;
            };

            const { updated_by_email, created_by_email } = note;

            const emailToUse = updated_by_email || created_by_email;
            const nameToShow = extractNameFromEmail(emailToUse);

            return {
              id: note.id,
              order_id: note.order_id,
              note_date: moment.parseZone(note.note_date).format('MM-DD-YYYY'),
              note_time: moment.parseZone(note.note_time).format('LT'),
              description: note.description,
              created_by: nameToShow,
              updated_by_email: updated_by_email,
            };
          });

          this.OrderStepsNotesData = this.allOrderStepsNotesData.slice(0, 3);
        } else {
          this.toastr.error(resp.message);
        }
        this.spinnerService.hide();
      })
      .catch((err) => {
        this.toastr.error("Couldn't complete request due to error");
        this.spinnerService.hide();
      });
  };

  createOrderNote(): void {
    if (!this.notes) {
      this.toastr.info('Please enter some data in notes first.');
      return;
    }

    Swal.fire({
      title: 'Enter Date and Contact Time',
      html: `
    <label for="notesCreatedDate">Note Date:</label>
    <input id="notesCreatedDate" class="swal2-input" type="date">
    <label for="contactTime">Note Time:</label>
    <input id="contactTime" class="swal2-input" type="time">
  `,
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
      preConfirm: () => {
        const noteDate = (
          document.getElementById('notesCreatedDate') as HTMLInputElement
        ).value;
        const noteTime = (
          document.getElementById('contactTime') as HTMLInputElement
        ).value;

        if (!noteDate || !noteTime) {
          Swal.showValidationMessage('Please enter both date and time');
        }
        const combinedDateTime = DateTime.fromFormat(
          `${noteDate} ${noteTime}`,
          'yyyy-MM-dd HH:mm',
          { zone: 'utc' }
        );
        const formattedDateTime = combinedDateTime.toISO();

        return { noteDate, noteTime: formattedDateTime };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { noteDate, noteTime } = result.value;
        this.spinnerService.show();
        const data = {
          description: this.notes,
          note_date: noteDate,
          note_time: noteTime,
        };
        this.orderService
          .createOrderNote(this.selectedOrder.order_id, data)
          .then(() => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'Order Notes were Updated Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
            this.getOrderStepNotes(this.selectedOrder.order_id);
            this.notes = '';
          })
          .catch(() => {
            this.spinnerService.hide();
            this.toastr.error('Something went wrong. Please try again');
          });
      }
    });
  }

  deleteOrderNote(note_id: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      this.spinnerService.show();
      if (result.isConfirmed) {
        this.orderService
          .deleteOrderNote(note_id)
          .then((response) => {
            this.toastr.success('Order Note has been deleted.', 'Success');
            this.spinnerService.hide();
            this.getOrderStepNotes(this.selectedOrderDetails.order_id);
          })
          .catch((error) => {
            this.spinnerService.hide();
            console.error('Error deleting note:', error);
          });
      }
    });
  }

  downloadNotesCSV = (): void => {
    if (!this.allOrderStepsNotesData?.length) {
      this.toastr.error('No Data Available');
      return;
    }
    this.spinnerService.show();
    const stripHtmlTags = (html: string): string => {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    };
    const header = ['Order ID', 'Date', 'Contact Time', 'Comments', 'Employee'];
    const rows = this.allOrderStepsNotesData.map((note) => [
      note.order_id,
      note.note_date,
      note.note_time,
      stripHtmlTags(note.description),
      note.created_by,
    ]);

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      header.join(',') +
      '\n' +
      rows.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'notes.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.spinnerService.hide();
  };

  editNote(note: OrderNote): void {
    this.notes = note.description;
    this.selectedNote = note;
    this.selectedNoteId = note.id;
    this.isEditing = true;
  }

  saveUpdatedNote(): void {
    if (!this.notes) {
      this.toastr.info('Please enter some data in notes first.');
      return;
    }

    if (!this.selectedNoteId) {
      this.toastr.error('No note selected for updating.');
      return;
    }
    let noteDate = this.selectedNote.note_date;
    let noteTime = this.selectedNote.note_time;

    try {
      const isoDate = moment(noteDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
      const isoTime = moment(noteTime, 'hh:mm A').format('HH:mm');

      Swal.fire({
        title: 'Edit Date and Contact Time',
        html: `
        <label for="notesCreatedDate">Note Date:</label>
        <input id="notesCreatedDate" class="swal2-input" type="date" value="${isoDate}">
        <label for="contactTime">Note Time:</label>
        <input id="contactTime" class="swal2-input" type="time" value="${isoTime}">
      `,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
        preConfirm: () => {
          const noteDateInput = (
            document.getElementById('notesCreatedDate') as HTMLInputElement
          ).value;
          const noteTimeInput = (
            document.getElementById('contactTime') as HTMLInputElement
          ).value;

          if (!noteDateInput || !noteTimeInput) {
            Swal.showValidationMessage('Please enter both date and time');
            return null;
          }
          const combinedDateTime = DateTime.fromFormat(
            `${noteDateInput} ${noteTimeInput}`,
            'yyyy-MM-dd HH:mm',
            {
              zone: 'utc',
            }
          );
          const formattedDateTime = combinedDateTime.toISO();

          return {
            noteDate: noteDateInput,
            noteTime: formattedDateTime,
          };
        },
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          const { noteDate, noteTime } = result.value;
          this.spinnerService.show();
          const updatedNote = {
            description: this.notes,
            note_date: noteDate,
            note_time: noteTime,
            updated_at: new Date().toISOString(),
          };

          this.orderService
            .updateOrderNote(
              this.selectedOrder.order_id,
              this.selectedNoteId!,
              updatedNote
            )
            .then(() => {
              this.spinnerService.hide();
              Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: 'Order Note was updated successfully.',
                showConfirmButton: false,
                timer: 2000,
              });
              this.getOrderStepNotes(this.selectedOrder.order_id);
              this.notes = '';
              this.isEditing = false;
              this.selectedNoteId = null;
            })
            .catch(() => {
              this.spinnerService.hide();
              this.toastr.error('Something went wrong. Please try again');
            });
        }
      });
    } catch (error) {
      console.error('DateTime formatting error:', error);
      this.toastr.error('Error formatting date/time');
    }
  }

  /* start// Order Benefits */
  changeOrderBenefitInsurancePayer(event: any): void {
    this.selectedPayer = JSON.parse(event.target.value);
    if (!this.selectedPayer) return;

    this.spinnerService.show();
    this.orderService
      .getBenefitsPayerProcedures(
        this.selectedOrderDetails.order_id,
        this.selectedPayer.payer_id
      )
      .then((resp: GET_ORDER_BENEFIT_PAYER_PROCEDURES) => {
        this.spinnerService.hide();
        this.benefitProcedureTableData = resp.data.list;

        this.totalAllowable = 0;
        this.benefitProcedureTableData.forEach(
          (proc) => (this.totalAllowable += Number(proc.allowable))
        );
        this.totalAllowable = +this.totalAllowable.toFixed(2);
        this.orderService
          .getPayerMPPR(this.selectedPayer.payer_id)
          .then(
            (resp: GET_PAYER_MPPR_RESP) => (this.selectedPayerMPPR = resp.data)
          );
      })
      .catch((err) => {
        this.toastr.error("Could't complete request due to error");
        this.spinnerService.hide();
      });
  }

  addOrderBenefitInsurance = (): void => {
    const addBenefitsRef = this.modalService.open(
      AddBenefitInsuranceModalComponent,
      { centered: true }
    );
    addBenefitsRef.componentInstance.order_id =
      this.selectedOrderDetails.order_id;
    addBenefitsRef.componentInstance.client_id = this.selectedPayer.client_id;
    addBenefitsRef.componentInstance.payer_id = this.selectedPayer.payer_id;
    addBenefitsRef.componentInstance.proc_order =
      (this.benefitProcedureTableData?.length || 0) + 1;

    addBenefitsRef.closed.subscribe(
      (resp: CREATE_ORDER_BENEFIT_INSURANCE_RESP) => {
        if (resp.code !== 200) return;
        const {
          id,
          contract_id,
          proc_order,
          cpt_code: cpt,
          contract,
          mppr,
          allowable,
          contract_description,
        } = resp.data;
        const obj = {
          id,
          contract_id,
          proc_order,
          cpt,
          contract,
          mppr,
          allowable,
          contract_description,
        };
        this.benefitProcedureTableData = [
          ...this.benefitProcedureTableData,
          obj,
        ];
        this.totalAllowable = 0;
        this.benefitProcedureTableData.forEach(
          (proc) => (this.totalAllowable += Number(proc.allowable))
        );
        this.totalAllowable = +this.totalAllowable.toFixed(2);
      }
    );
  };

  calculateAllowable = (contract: number, mppr: number): number => {
    return this.orderService.calculateAllowable(contract, mppr);
  };

  reorderOrderBenefitInsurance = (data: BenefitPayerProcedure[]): void => {
    this.spinnerService.show();
    this.orderService
      .reorderBenefitsInsurance(this.selectedOrderDetails.order_id, data)
      .then((resp: UPDATE_ORDER_RESP) => {
        this.totalAllowable = 0;
        this.benefitProcedureTableData.forEach(
          (proc) => (this.totalAllowable += Number(proc.allowable))
        );
        this.totalAllowable = +this.totalAllowable.toFixed(2);
        this.spinnerService.hide();
        if (resp.code === 200)
          this.toastr.success('Reorder Successful', 'Success');
      })
      .catch((err) => {
        this.toastr.error("Could't complete request due to error");
        this.spinnerService.hide();
      });
  };

  deleteOrderBenefits = (benefits: any): void => {
    const self = this;

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.orderService
          .deletePatientOrderBenefitsInsurace(
            self.selectedOrder.order_id,
            benefits.id
          )
          .then((resp: GenericResp) => {
            if (resp.code !== 200)
              return self.toastr.error("Could't complete request due to error");

            self.toastr.success('Procedure Deleted.', 'Success.');

            self.benefitProcedureTableData =
              self.benefitProcedureTableData.filter(
                (item: any) => item.id !== benefits.id
              );
            this.spinnerService.show();
            this.orderService
              .getBenefitsPayerProcedures(
                this.selectedOrderDetails.order_id,
                this.selectedPayer.payer_id
              )
              .then((resp: GET_ORDER_BENEFIT_PAYER_PROCEDURES) => {
                this.spinnerService.hide();
                this.benefitProcedureTableData = resp.data.list;

                this.totalAllowable = 0;
                this.benefitProcedureTableData.forEach(
                  (proc) => (this.totalAllowable += Number(proc.allowable))
                );
                this.totalAllowable = +this.totalAllowable.toFixed(2);
                this.orderService
                  .getPayerMPPR(this.selectedPayer.payer_id)
                  .then(
                    (resp: GET_PAYER_MPPR_RESP) =>
                      (this.selectedPayerMPPR = resp.data)
                  );
              })
              .catch((err) => {
                this.toastr.error("Could't complete request due to error");
                this.spinnerService.hide();
              });
          });
      }
    });
  };

  evaluateEstOutOfPocketOnChange(
    allowable: number,
    deductable: number,
    deductable_met: number,
    coinsurance: number,
    patientResponsibility: number
  ): void {
    this.canUpdateBenefit = true;
    this.evaluateEstOutOfPocket(
      allowable,
      deductable,
      deductable_met,
      coinsurance,
      +patientResponsibility
    );
  }

  evaluateEstOutOfPocket(
    allowable: number,
    deductable: number,
    deductable_met: number,
    coinsurance: number,
    patientResponsibility: number
  ): void {
    let coinsurance_due;
    let deductable_due = deductable - deductable_met;
    if (deductable_due >= allowable) {
      this.estimateOutOfPocket = allowable;
    } else {
      coinsurance_due = (allowable - deductable_due) * (coinsurance / 100);
    }

    // coinsurance_due = (allowable - deductable_due) * (coinsurance / 100);

    // if (coinsurance_due < 0) coinsurance_due = 0;
    // if (deductable_due < 0) deductable_due = 0;

    this.estimateOutOfPocket =
      deductable_due + coinsurance_due + patientResponsibility;
    // this.estimateOutOfPocket =
    // Math.round((this.estimateOutOfPocket + Number.EPSILON) * 100) / 100;
    if (deductable_due >= allowable) {
      this.estimateOutOfPocket = allowable;
    }
    if (!Number.isInteger(this.estimateOutOfPocket)) {
      const decimalPart = this.estimateOutOfPocket.toString().split('.')[1];
      if (decimalPart.length > 2) {
        this.estimateOutOfPocket = this.estimateOutOfPocket.toFixed(2);
      }
    }
    // this.estimateOutOfPocket = ((allowable - (deductable - deductable_met)) * (coinsurance / 100) + (deductable - deductable_met)) + patientResponsibility;
    // this.estimateOutOfPocket = Math.round((this.estimateOutOfPocket + Number.EPSILON) * 100) / 100;
  }

  updateOrderBenefit(
    allowable: number,
    deductable: number,
    deductable_met: number,
    coinsurance: number,
    patient_responsibility: number
  ): void {
    if (
      this.canUpdateBenefit &&
      allowable >= 0 &&
      deductable >= 0 &&
      deductable_met >= 0 &&
      coinsurance >= 0
    ) {
      let swalArgs;
      if (this.estimateOutOfPocket === 0) {
        swalArgs = {
          title: 'Update Order Benefit',
          text: 'Are you sure you want to update order Benefits?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update',
        };
      } else if (this.estimateOutOfPocket > 0) {
        swalArgs = {
          title: 'Update Order Benefit',
          text: 'Are you sure you want to update order Benefits?',
          icon: 'warning',
          input: 'checkbox',
          inputValue: true,
          inputPlaceholder: 'Notify Patient',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update',
        };
      }
      Swal.fire(swalArgs).then((result) => {
        if (result.isConfirmed) {
          const data = {
            allowable,
            deductable,
            deductable_met,
            coinsurance,
            patient_responsibility,
            created_by:
              this.selectedOrderDetails?.benefits[0]?.created_by ||
              this.assistantUID,
            modified_by: this.assistantUID,
            notify_patient:
              this.estimateOutOfPocket > 0 ? !!result?.value : false,
            est_out_of_pocket: this.estimateOutOfPocket,
          };

          if (this.selectedOrderDetails?.benefits[0]?.benefits_id) {
            this.orderService
              .updatePatientOrderBenefit(
                this.selectedOrder.order_id,
                this.selectedOrderDetails?.benefits[0]?.benefits_id,
                data
              )
              .then((resp: UPDATE_ORDER_RESP) => {
                if (resp.code !== 200) return;

                this.toastr.success('Update Successful', 'Success');
                this.getOrderDetails();
                this.reloadGrid.next(true);
              });
          } else {
            this.orderService
              .createPatientOrderBenefit(this.selectedOrder.order_id, data)
              .then((resp: CREATE_ORDER_BENEFIT_RESP) => {
                if (resp.code !== 200) return;

                this.toastr.success('Update Successful', 'Success');
                this.getOrderDetails();
                this.reloadGrid.next(true);
              });
          }
        }
      });
    } else
      this.toastr.error('One or more values are invalid', 'Invalid Value(s)');
  }
  /* end// Order Benefits */

  /* start// Order Appointments */
  addOrderAppointment = (): void => {
    const addAppointmentRef = this.modalService.open(AddAppointmentsComponent, {
      centered: true,
    });
    addAppointmentRef.componentInstance.clientLocations = this.clientLocations;
    addAppointmentRef.componentInstance.practiceProviders =
      this.practiceProviders;
    addAppointmentRef.componentInstance.order_id = this.selectedOrder.order_id;
    addAppointmentRef.componentInstance.assistantUID = this.assistantUID;
    addAppointmentRef.result.then(
      (resp) => {
        if (resp) {
          const { data } = resp;
          const inputOptions = new Map();
          if (this.practiceProviders) {
            this.practiceProviders.forEach((provider: Provider) => {
              inputOptions.set(provider?.provider_id, provider?.description);
            });
          }
          const appointment: OrderAppointmentData = {
            appointment_id: data.id,
            id: data.id,
            provider: inputOptions.get(data.provider_id),
            location: data.location,
            event_type: data.event_type,
            date:
              moment.parseZone(data.appointment_date).format('MM/DD/YYYY') ||
              '',
            time: moment.parseZone(data.appointment_time).format('LT'),
          };
          this.selectedOrderAppointmentsTableData.unshift(appointment);
        }
        // on close
      },
      (reason) => {
        // on dismiss
      }
    );
  };

  deleteOrderAppointment = (appointment: OrderAppointmentData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      input: 'checkbox',
      inputValue: true,
      inputPlaceholder: 'Notify Patient',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.orderService
          .deletePatientOrderAppointment(
            this.selectedOrder.order_id,
            appointment.appointment_id,
            !!result.value
          )
          .then((resp: GenericResp) => {
            this.spinnerService.hide();
            this.toastr.success('Appointment Deleted.', 'Success.');
            this.selectedOrderAppointmentsTableData =
              this.selectedOrderAppointmentsTableData.filter(
                (item: OrderAppointmentData) =>
                  item.appointment_id !== appointment.appointment_id
              );
          })
          .catch((err) => {
            this.spinnerService.hide();
            this.toastr.error("Could't complete request due to an error");
          });
      }
    });
  };

  /* end// Order Appointments */

  /* start// Order Tasks */
  onOrderTaskSelectChange(event: any): void {
    this.selectedAssignOrderForm = JSON.parse(event.target.value);
  }

  assignFormToOrder(task_description: string): void {
    if (!this.selectedAssignOrderForm) {
      this.toastr.info('Please Select a form');
      return;
    }
    if (this.orderTaskDueDatePickerModel === null) {
      this.toastr.info('Please select due date');
      return;
    }
    Swal.fire({
      title: 'Task Assign',
      html: `Are you sure you want to assign the task to <br><strong>${this.selectedOrder.patient_name}</strong>?`,
      icon: 'question',
      input: 'checkbox',
      inputValue: true,
      inputPlaceholder: 'Notify Patient',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Assign',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.orderService
          .assignFormToOrder(
            this.selectedOrderDetails.client.id,
            this.selectedOrderDetails.order_id,
            {
              notify_patient: !!result.value,
              patient_id: this.selectedOrder.patient.id,
              form_id: this.selectedAssignOrderForm.id,
              task_description,
              due_date:
                (this.orderTaskDueDatePickerModel?.year &&
                  DateTime.fromObject(
                    this.orderTaskDueDatePickerModel
                  ).toUTC()) ||
                undefined,
            }
          )
          .then(() => {
            this.orderService
              .getOrderAssignedForms(
                this.selectedOrderDetails.client.id,
                this.selectedOrder.patient.id,
                this.selectedOrderDetails.order_id
              )
              .then((resp: GET_ORDER_ASSIGNED_FORMS_RESP) => {
                this.selectedOrderTasksTableData = resp.data.map((task) => ({
                  task_id: task.id,
                  form_id: task.form.id,
                  task_type: task.task_type,
                  task_description: task.task_description,
                  due_date: task.due_date
                    ? DateTime.fromISO(task.due_date).toLocaleString(
                        DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
                      )
                    : '',
                  assign_date: task.assign_date
                    ? DateTime.fromISO(task.assign_date).toLocaleString(
                        DateTime.DATE_SHORT + DateTime.TIME_SIMPLE
                      )
                    : '',
                  completed: task.is_completed ? 'Yes' : 'No',
                  form_title: task.form.title,
                }));
              });
          })
          .catch((err) => console.error(err))
          .finally(() => this.spinnerService.hide());
      } else {
        return;
      }
    });
  }
  /* end// Order Tasks */

  /* start// Order Attachments  */
  previewFunction = (attachment): void => {
    window.open(attachment.file_url, '_blank');
  };

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    let is_location_request = false;
    if (
      this.userRole === 'location_admin' ||
      this.userRole === 'location_user'
    ) {
      is_location_request = true;
    }
    if (files) {
      this.spinnerService.show();
      const fileArray = Array.from(files); // Convert FileList to File[]
      this.orderService
        .uploadAttachment(
          fileArray,
          '',
          '',
          this.selectedOrder && this.selectedOrder.order_id ? this.selectedOrder.order_id : '-1',
          is_location_request.toString(),
          '',
          '',
          '',
          this.selectedOrder.patient.id,
          false,
        )
        .subscribe(
          (resp) => {
            if (resp.data) {
              this.spinnerService.hide();
              this.toastr.success('File has been uploaded successfully');
              resp.data.forEach((element) => {
                const filesObj = {
                  order_id: this.selectedOrder.order_id,
                  file_name: element.file_name,
                  file_url: element.file_url,
                  id: element.id,
                };
                this.selectedAttachmentsTableData.unshift(filesObj);
              });
            }
          },
          (error) => {
            this.spinnerService.hide();
            this.toastr.error(error);
          }
        );
    }
  }

  uploadAttachment(): void {
    let is_location_request = this.userRole === 'location_admin' || this.userRole === 'location_user';
  
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.setAttribute('multiple', '');
    fileChooser.accept = '.pdf';
    fileChooser.addEventListener('change', (event: any) => {
      const files = event.target.files;
      if (files) {  
        this.spinnerService.show();
        this.orderService
          .uploadAttachment(
            files,
            '',
            '',
            this.selectedOrder.order_id,
            is_location_request.toString(),
            '',
            '',
            this.selectedOrder.date,
            this.selectedOrder.patient.id,
            false
          )
          .subscribe(
            (resp) => {
              this.spinnerService.hide();
              if (resp && resp.message === 'Success') {  
                  this.toastr.success('File has been uploaded successfully');
                  resp.data.forEach((element) => {
                    const filesObj = {
                      order_id: this.selectedOrder.order_id,
                      file_name: element.file_name,
                      file_url: element.file_url,
                      id: element.id,
                    };
                    this.selectedAttachmentsTableData.unshift(filesObj);
                  });
                
              }
            },
            (error) => {
              this.spinnerService.hide();
              this.toastr.error('Error occurred during upload.');
            }
          );
      }
    });
    fileChooser.click();
  }
  

  // uploadAttachment(): void {
  //   let is_location_request = false;
  //   if (
  //     this.userRole === 'location_admin' ||
  //     this.userRole === 'location_user'
  //   ) {
  //     is_location_request = true;
  //   }

  //   let fileChooserAcceptType = '.pdf';
  //   const fileChooser = document.createElement('input');
  //   fileChooser.type = 'file';
  //   fileChooser.setAttribute('multiple', '');
  //   fileChooser.accept = fileChooserAcceptType;
  //   fileChooser.addEventListener('change', (event: any) => {
  //     const file = event.target.files;
  //     if (file) {
  //       const onlyUpload = false;
  //       this.spinnerService.show();
  //       this.orderService
  //         .uploadAttachment(
  //           file,
  //           '',
  //           '',
  //           this.selectedOrder.order_id,
  //           is_location_request,
  //           onlyUpload
  //         )
  //         .subscribe(
  //           (resp) => {
  //             this.spinnerService.hide();
  //             console.log("JJJJJERREE");
  //             if (!onlyUpload) {
  //               if (resp.data) {
  //                 this.toastr.success('File has been uploaded successfully');
  //                 resp.data.forEach((element) => {
  //                   const filesObj = {
  //                     order_id: this.selectedOrder.order_id,
  //                     file_name: element.file_name,
  //                     file_url: element.file_url,
  //                     id: element.id,
  //                   };
  //                   this.selectedAttachmentsTableData.unshift(filesObj);
  //                 });
  //               }
  //             } else {
  //               // Handle onlyUpload success (optional)
  //               this.toastr.success('File uploaded but not added to the database.');
  //             }
  //           },
  //           (error) => {
  //             this.spinnerService.hide();
  //             this.toastr.error(error);
  //           }
  //         );
  //     }
  //   });
  //   fileChooser.click();
  // }

  deleteOrderAttachment = (attachment): void => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinnerService.show();
        this.orderService
          .deleteOrderAttachment(
            attachment.order_id,
            attachment.id,
            !!result.value
          )
          .then((resp: GenericResp) => {
            if (resp.code !== 200)
              return self.toastr.error("Could't complete request due to error");

            self.toastr.success('Attachment Deleted.', 'Success.');

            const index = self.selectedAttachmentsTableData.indexOf(attachment);
            self.selectedAttachmentsTableData.splice(index, 1);
            self.spinnerService.hide();
          });
      }
    });
  };
  /* end// Order Attachments */
}
