import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServerSidePages } from '../../models';

@Component({
  selector: 'app-table-pagination-client',
  templateUrl: './table-pagination-client.component.html',
  styleUrls: ['./table-pagination-client.component.scss'],
})
export class TablePaginationClientComponent implements OnInit {
  @Input() headers: [];
  @Input() data: [];
  @Input() paginateId: string = '1';
  @Input() hasActions: boolean = false;
  @Input() changeUserStaus: boolean = false;
  @Input() deleteFunction: (args: any) => void = null;
  @Input() resetPassword: (args: any) => void = null;
  @Input() toggleUserStatus: (...args: any) => void = null;
  @Input() userRoleDeleteFunction: (args: any) => void = null;
  @Input() editFunction: (args: any) => void = null;
  @Input() cloneFunction: (args: any) => void = null;
  @Input() previewFunction: (args: any) => void = null;
  @Input() responseFunction: (args: any) => void = null;
  @Input() assignFunction: (args: any) => void = null;
  @Input() downloadFunction: (args: any) => void = null;
  @Input() downloadTranscriptionFunction: (args: any) => void = null;
  @Input() archivedFunction: (args: any) => void = null;
  @Input() smsCallInfo: (args: any) => void = null;
  @Input() orderByFunction: (args: any) => void = null;
  @Input() playContent: (args: any) => void = null;
  @Input() pauseContent: (args: any) => void = null;
  @Input() selectContent: (...args: any) => void = null;
  @Input() addContent: (...args: any) => void = null;
  @Input() createContent: (args: any) => void = null;
  @Input() groupAssignFunction: (args: any) => void = null;
  @Input() showChatButton: (args: any) => boolean = null;
  @Output() createChat = new EventEmitter<boolean>();
  @Output() createInbox = new EventEmitter<any>();
  @Output() setTotalPages = new EventEmitter<any>();
  @Input() createContentButtonText: string = null;
  @Input() selectedContent: any = null;
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;
  @Input() showRowDisplay: boolean = true;
  @Input() isPaginationEnabled: boolean = true;
  @Input() serverSidePagination: ServerSidePages = null;
  @Input() paginationLastPage: () => void = null;
  @Input() paginationNextPage: () => void = null;
  @Input() paginationPrevPage: () => void = null;
  @Input() paginationFirstPage: () => void = null;
  @Input() paginationCustomPage: (pageNumber: number) => void = null;
  @Input() createContentButtonOnCondition: boolean = false;
  @Input() style: string = 'block';
  @Input() currentUser: string = '';
  totalPages: number;
  toggleChat: boolean = false;

  constructor() {
    this.toggleChat = true;
  }

  ngOnInit(): void {}

  ngOnChanges(): void {}

  getTotalPages() {
    if (this.totalPages && this.totalPages === Math.ceil(this.data.length / this.pageSize)) {
      return this.totalPages;
    } else if (this.data && this.pageSize) {
      this.totalPages = Math.ceil(this.data.length / this.pageSize);
      this.setTotalPages.emit(this.totalPages);
      return this.totalPages;
    } else return 0;
  }

  showChat(record) {
    this.createChat.emit(this.toggleChat);
    this.createInbox.emit(record);
  }

  getCurrentShowingRecords(pageSize: number, currentPage: number, totalRecords: number) {
    let last = pageSize * currentPage;
    console.log(last);
    const first = last - (pageSize - 1);
    if (last > totalRecords) last = totalRecords;
    return `${first} - ${last}`;
  }
}
