<div [ngClass]="preview ? 'container m0' : 'container'">
  <div class="d-flex justify-content-center">
    <div [ngClass]="preview ? 'form-stylev2' : 'form-style'">
      <div class="form-header d-flex justify-content-between">
        <img
          src="../../../../assets/icons/left-arrow.svg"
          ngbTooltip="Navigate Back to Forms"
          [openDelay]="500"
          [closeDelay]="300"
          (click)="navBack()"
          class="back-btn"
        />
        <div class="d-md-flex justify-content-center header-title" *ngIf="!editForm">Create Form</div>
        <div class="d-md-flex justify-content-center header-title" *ngIf="editForm">Update Form</div>
        <div class="d-flex align-items-center">
          <mat-slide-toggle
            #attachmentSwitch
            (change)="formPreview()"
            ngbTooltip="Live Form Preview"
            [openDelay]="200"
            [closeDelay]="500"
          ></mat-slide-toggle>
        </div>
      </div>

           <!-- Form Title -->
      <input
        class="form-control"
        style="margin-bottom: 20px; text-align: center"
        type="text"
        placeholder="Form Title"
        [(ngModel)]="formTitle"
      />

      <!-- Fields Rendered after adding Question -->
      <div class="d-md-flex row mb-4" *ngIf="formFields.length > 0" [@fade]>
        <div class="col" style="display: hidden"></div>
        <h5 class="col-7" style="text-align: center"><b>Questions</b></h5>
        <div class="form-check col">
          <input
            class="form-check-input"
            (change)="patientDetails($event)"
            type="checkbox"
            [checked]="show_patient_data"
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault"> Include Patient Data </label>
        </div>
      </div>

     <!-- Looping through FormFields and rendering -->
      <div cdkDropList (cdkDropListDropped)="dropField($event)" class="question-list">
        <div *ngFor="let field of formFields; let k = index" class="mb-3 mt-2">
          <div *ngIf="!field.is_deleted">
            <fieldset class="form-group question border mt-2 p-3" cdkDrag cdkDragBoundary=".container" [@fade]>
              <div class="question-placeholder" *cdkDragPlaceholder></div>
              <legend class="w-auto px-1 default-cursor">Question # {{ k + 1 }}</legend>
              <div class="drag-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div class="d-md-flex mt-2">
                <input
                  #description
                  type="text"
                  [ngClass]="field.description ? 'form-control flex-col1' : 'error form-control flex-col1'"
                  placeholder="Question"
                  [style.width.ch]="field.description.length > 38 ? field.description.length + 5 : 38"
                  (change)="onQuestionChange($event, field.id)"
                  [value]="field.description"
                />
                <select
                  class="form-control flex-col2"
                  ngbTooltip="Field Type"
                  container="body"
                  [openDelay]="400"
                  [closeDelay]="300"
                  (change)="!editForm ? onFieldTypeChange($event, field.id, 0) : onFieldTypeChange($event, field.id, 1)"
                >
                  <option
                    *ngFor="let type of FIELD_TYPES"
                    [value]="type.value"
                    [selected]="type.value === field.field_type"
                  >
                    {{ type.title }}
                  </option></select
                >&nbsp;&nbsp;

           <!-- Actions Rendered after adding Question -->
                <!--Mask Type-->    
                <div *ngIf="field.field_type === 3" class="mr-1">
                  <select
                    class="form-control"
                    (change)="onMaskChange($event, field.id)"
                    ngbTooltip="Select Input Mask"
                    container="body"
                    [openDelay]="400"
                    [closeDelay]="300"
                  >
                    <option
                      *ngFor="let type of MASK_TYPES"
                      [value]="type.value"
                      [selected]="type.value === field.style.mask"
                    >
                      {{ type.title }}
                    </option>
                  </select>
                </div>
                <div *ngIf="field.field_type === 4" class="mr-1">
                  <select
                    class="form-control"
                    (change)="onMaskChange($event, field.id)"
                    ngbTooltip="Select Image Mask"
                    container="body"
                    [openDelay]="400"
                    [closeDelay]="300"
                  >
                    <option
                      *ngFor="let type of IMG_MASK_TYPES"
                      [value]="type.value"
                      [selected]="type.value === field.style.mask"
                    >
                      {{ type.title }}
                    </option>
                  </select>
                </div>

                <!--Font Size-->
                <div class="mr-1">
                  <select
                    class="form-control"
                    (change)="onFontChange($event, field.id)"
                    ngbTooltip="Select Font Size"
                    container="body"
                    [openDelay]="500"
                    [closeDelay]="300"
                  >
                    <option
                      *ngFor="let size of FONT_SIZES"
                      [value]="size.value"
                      [selected]="size.value === field.style.font_size"
                    >
                      {{ size.value }}
                    </option>
                  </select>
                </div>

                <!--Bold,Underline,Map & Delete-->
                <!--Font Style Dropdown-->
                <div class="d-flex align-items-center mt0">
                  <div class="d-inline-block" ngbDropdown #style="ngbDropdown">
                    <button
                      class="btn primary-btn mr-1"
                      id="dropdownManual"
                      ngbTooltip="Font Styles"
                      [openDelay]="600"
                      [closeDelay]="300"
                      ngbDropdownAnchor
                      (focus)="style.open()"
                    >
                      <i
                        class="fa fa-font"
                        [ngClass]="
                          field.style.bold ||
                          field.style.underline ||
                          field.style.align === 1 ||
                          field.style.align === 2
                            ? 'style-toggled'
                            : 'tsp'
                        "
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual" style="min-width: 4px">
                      <div style="display: inline-flex !important">
                        <button
                          ngbDropdownItem
                          class="btn primary-btn"
                          (click)="styleField(field.id, 1, 0)"
                          [ngClass]="field.style.bold ? 'toggled' : 'tsp'"
                          ngbTooltip="Bold"
                          [openDelay]="500"
                          [closeDelay]="300"
                        >
                          <i class="fa fa-bold" aria-hidden="true"></i>
                        </button>

                        <button
                          ngbDropdownItem
                          class="btn primary-btn"
                          (click)="styleField(field.id, 2, 0)"
                          [ngClass]="field.style.underline ? 'toggled' : 'tsp'"
                          ngbTooltip="Underline"
                          [openDelay]="500"
                          [closeDelay]="300"
                        >
                          <i class="fa fa-underline" aria-hidden="true"></i>
                        </button>

                        <button
                          ngbDropdownItem
                          class="btn primary-btn"
                          (click)="styleField(field.id, 3, 0)"
                          [ngClass]="field.style.align === 0 ? 'toggled' : 'tsp'"
                          ngbTooltip="Align Left"
                          [openDelay]="500"
                          [closeDelay]="300"
                        >
                          <i class="fa fa-align-left" aria-hidden="true"></i>
                        </button>

                        <button
                          ngbDropdownItem
                          class="btn primary-btn"
                          (click)="styleField(field.id, 3, 1)"
                          [ngClass]="field.style.align === 1 ? 'toggled' : 'tsp'"
                          ngbTooltip="Align Center"
                          [openDelay]="500"
                          [closeDelay]="300"
                        >
                          <i class="fa fa-align-center" aria-hidden="true"></i>
                        </button>

                        <button
                          ngbDropdownItem
                          class="btn primary-btn"
                          (click)="styleField(field.id, 3, 2)"
                          [ngClass]="field.style.align === 2 ? 'toggled' : 'tsp'"
                          ngbTooltip="Align Right"
                          [openDelay]="500"
                          [closeDelay]="300"
                        >
                          <i class="fa fa-align-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!--Map & Delete-->
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      id="flexSwitchCheckDefault"
                      (change)="mapField(field.id)"
                      [checked]="field.is_mapped"
                      type="checkbox"
                    />
                    <label class="form-check-label" (click)="mapField(field.id)" style="cursor: pointer"
                      >Map Field</label
                    >
                  </div>

                  <div>
                    <button
                      class="btn del-btn"
                      (click)="!editForm ? deleteField(field.id, 1) : deleteField(field.id, 2)"
                      ngbTooltip="Delete"
                      container="body"
                      [openDelay]="500"
                      [closeDelay]="400"
                    >
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>

             <!-- Options Rendered after adding Question for type 1,5,7,8 & 9 -->
              <div
                *ngIf="
                  field.field_type === 1 ||
                  field.field_type === 5 ||
                  field.field_type === 7 ||
                  field.field_type === 8 ||
                  field.field_type === 9
                "
                [ngClass]="field.field_type === 8 ? 'd-md-flex mt-2' : 'd-md-flex mt-2 flex-wrap'"
              >
                <ng-container *ngFor="let fieldOption of field.options">
                  <div
                    *ngIf="!fieldOption.is_deleted"
                    [@fade]
                    [ngClass]="
                      field.field_type === 8 || field.field_type === 9
                        ? 'input-group mr-2 flex-col3'
                        : field.field_type === 7
                        ? 'input-group mr-2 flex-col6 mb-1'
                        : 'input-group mr-2 flex-col5 mt-1'
                    "
                  >
                    <input
                      [ngClass]="fieldOption.option ? 'form-control' : 'error form-control'"
                      placeholder="{{
                        field.field_type === 8 ? 'Header' : field.field_type === 7 ? 'Bullet Point' : 'Option'
                      }}"
                      type="text"
                      (change)="onOptionChange($event, field.id, fieldOption.id)"
                      [value]="fieldOption.option"
                    />
                    <div class="input-group-append">
                      <ng-container *ngIf="field.field_type != 9">
                        <button
                          class="btn btn-outline-secondary"
                          (click)="
                            !editForm
                              ? deleteOption(field.id, fieldOption.id, 1)
                              : deleteOption(field.id, fieldOption.id, 2)
                          "
                          ngbTooltip="Delete"
                          [openDelay]="500"
                          [closeDelay]="400"
                        >
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </ng-container>

                      <ng-container *ngIf="field.field_type === 9">
                        <span class="input-group-text d-flex" *ngIf="fieldOption.sequence_num === 1">Follow Up</span>
                        <span class="input-group-text d-flex" *ngIf="fieldOption.sequence_num === 2">End Here</span>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="field.field_type != 9">
                  <button
                    class="btn btn-light mt-1"
                    *ngIf="field.field_type != 8 && field.field_type != 7 && checkOption(field.id, 0)"
                    (click)="onOptionAdd(field.id, 0)"
                    ngbTooltip="Add Another Option"
                    placement="bottom"
                    [openDelay]="500"
                    [closeDelay]="300"
                    [@fade]
                  >
                    <div class="d-flex">Option <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                  </button>
                  <button
                    class="btn btn-light"
                    *ngIf="field.field_type === 8 && checkOption(field.id, 1)"
                    (click)="onOptionAdd(field.id, 1)"
                    ngbTooltip="Add Another Column Header"
                    placement="bottom"
                    [openDelay]="500"
                    [closeDelay]="300"
                    [@fade]
                  >
                    <div class="d-flex">Column <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                  </button>
                  <button
                    class="btn btn-light"
                    *ngIf="field.field_type === 7 && checkOption(field.id, 2)"
                    (click)="onOptionAdd(field.id, 2)"
                    ngbTooltip="Add Another Bullet Point"
                    placement="bottom"
                    [openDelay]="500"
                    [closeDelay]="300"
                    [@fade]
                  >
                    <div class="d-flex">Bullet Point <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                  </button>
                </ng-container>

                <ng-container *ngIf="field.field_type === 9">
                  <button
                    class="btn btn-light"
                    ngbTooltip="Add Follow Up Question"
                    (click)="onSubQuestionAdd(field.id)"
                    placement="bottom"
                    [openDelay]="500"
                    [closeDelay]="300"
                    [@fade]
                  >
                    <div class="d-flex">Sub Question <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                  </button>
                </ng-container>
              </div>

                <!--  =============== FOR ===============
                  =============== SUB ===============
                  =============== QUESTIONS ========= -->
              <!-- Follow up Questions Rendered after adding sub Question for type 9 -->
              <ng-container *ngIf="field.field_type === 9 && !field.is_deleted">
                <fieldset class="form-group subquestion border mt-2 p-3">
                  <legend class="w-auto px-1 default-cursor">Sub Questions</legend>
                  <div *ngFor="let subField of field.sub_fields" class="ml-1 mr-1">
                    <div *ngIf="!subField.is_deleted" [@fade]>
                      <div class="mb-4">
                        <div class="d-md-flex mt-2">
                          <input
                            #description
                            type="text"
                            [ngClass]="subField.description ? 'form-control flex-col1' : 'error form-control flex-col1'"
                            placeholder="Question"
                            [style.width.ch]="subField.description.length > 38 ? subField.description.length + 5 : 38"
                            (change)="onQuestionChange($event, field.id, subField.id)"
                            [value]="subField.description"
                          />
                          <select
                            class="form-control flex-col2"
                            ngbTooltip="Field Type"
                            container="body"
                            [openDelay]="400"
                            [closeDelay]="300"
                            (change)="
                              !editForm
                                ? onFieldTypeChange($event, field.id, 0, subField.id)
                                : onFieldTypeChange($event, field.id, 1, subField.id)
                            "
                          >
                            <ng-container *ngFor="let type of FIELD_TYPES">
                              <option
                                *ngIf="type.value != 9"
                                [value]="type.value"
                                [selected]="type.value === subField.field_type"
                              >
                                {{ type.title }}
                              </option></ng-container
                            ></select
                          >&nbsp;&nbsp;

 <!-- Actions Rendered after adding Question -->
                          <!--Mask Type-->
                          <div *ngIf="subField.field_type === 3" class="mr-1">
                            <select
                              class="form-control"
                              (change)="onMaskChange($event, field.id, subField.id)"
                              ngbTooltip="Select Input Mask"
                              container="body"
                              [openDelay]="400"
                              [closeDelay]="300"
                            >
                              <option
                                *ngFor="let type of MASK_TYPES"
                                [value]="type.value"
                                [selected]="type.value === subField.style.mask"
                              >
                                {{ type.title }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="field.field_type === 4" class="mr-1">
                            <select
                              class="form-control"
                              (change)="onMaskChange($event, field.id)"
                              ngbTooltip="Select Image Mask"
                              container="body"
                              [openDelay]="400"
                              [closeDelay]="300"
                            >
                              <option
                                *ngFor="let type of IMG_MASK_TYPES"
                                [value]="type.value"
                                [selected]="type.value === field.style.mask"
                              >
                                {{ type.title }}
                              </option>
                            </select>
                          </div>

                          <!--Font Size-->
                          <div class="mr-1">
                            <select
                              class="form-control"
                              (change)="onFontChange($event, field.id, subField.id)"
                              ngbTooltip="Select Font Size"
                              container="body"
                              [openDelay]="500"
                              [closeDelay]="300"
                            >
                              <option
                                *ngFor="let size of FONT_SIZES"
                                [value]="size.value"
                                [selected]="size.value === subField.style.font_size"
                              >
                                {{ size.value }}
                              </option>
                            </select>
                          </div>

                         <!--Bold,Underline,Map & Delete-->
                          <!--Font Style Dropdown-->
                          <div class="d-flex align-items-center mt0">
                            <div class="d-inline-block" ngbDropdown #subStyle="ngbDropdown">
                              <button
                                class="btn primary-btn mr-1"
                                id="dropdownManual"
                                ngbTooltip="Font Styles"
                                [openDelay]="600"
                                [closeDelay]="300"
                                ngbDropdownAnchor
                                (focus)="subStyle.open()"
                              >
                                <i
                                  class="fa fa-font"
                                  [ngClass]="
                                    subField.style.bold ||
                                    subField.style.underline ||
                                    subField.style.align === 1 ||
                                    subField.style.align === 2
                                      ? 'style-toggled'
                                      : 'tsp'
                                  "
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownManual" style="min-width: 4px">
                                <div style="display: inline-flex !important">
                                  <button
                                    ngbDropdownItem
                                    class="btn primary-btn"
                                    (click)="styleField(field.id, 1, 0, subField.id)"
                                    [ngClass]="subField.style.bold ? 'toggled' : 'tsp'"
                                    ngbTooltip="Bold"
                                    [openDelay]="500"
                                    [closeDelay]="300"
                                  >
                                    <i class="fa fa-bold" aria-hidden="true"></i>
                                  </button>

                                  <button
                                    ngbDropdownItem
                                    class="btn primary-btn"
                                    (click)="styleField(field.id, 2, 0, subField.id)"
                                    [ngClass]="subField.style.underline ? 'toggled' : 'tsp'"
                                    ngbTooltip="Underline"
                                    [openDelay]="500"
                                    [closeDelay]="300"
                                  >
                                    <i class="fa fa-underline" aria-hidden="true"></i>
                                  </button>

                                  <button
                                    ngbDropdownItem
                                    class="btn primary-btn"
                                    (click)="styleField(field.id, 3, 0, subField.id)"
                                    [ngClass]="subField.style.align === 0 ? 'toggled' : 'tsp'"
                                    ngbTooltip="Align Left"
                                    [openDelay]="500"
                                    [closeDelay]="300"
                                  >
                                    <i class="fa fa-align-left" aria-hidden="true"></i>
                                  </button>

                                  <button
                                    ngbDropdownItem
                                    class="btn primary-btn"
                                    (click)="styleField(field.id, 3, 1, subField.id)"
                                    [ngClass]="subField.style.align === 1 ? 'toggled' : 'tsp'"
                                    ngbTooltip="Align Center"
                                    [openDelay]="500"
                                    [closeDelay]="300"
                                  >
                                    <i class="fa fa-align-center" aria-hidden="true"></i>
                                  </button>

                                  <button
                                    ngbDropdownItem
                                    class="btn primary-btn"
                                    (click)="styleField(field.id, 3, 2, subField.id)"
                                    [ngClass]="subField.style.align === 2 ? 'toggled' : 'tsp'"
                                    ngbTooltip="Align Right"
                                    [openDelay]="500"
                                    [closeDelay]="300"
                                  >
                                    <i class="fa fa-align-right" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <!--Map & Delete-->

                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                id="flexSwitchCheckDefault"
                                (change)="mapField(field.id, subField.id)"
                                [checked]="subField.is_mapped"
                                type="checkbox"
                              />
                              <label
                                class="form-check-label"
                                (click)="mapField(field.id, subField.id)"
                                style="cursor: pointer"
                                >Map Field</label
                              >
                            </div>

                            <div>
                              <button
                                class="btn del-btn"
                                (click)="
                                  !editForm
                                    ? deleteField(field.id, 1, subField.id)
                                    : deleteField(field.id, 2, subField.id)
                                "
                                ngbTooltip="Delete"
                                container="body"
                                [openDelay]="500"
                                [closeDelay]="400"
                              >
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                       <!-- Options Rendered after adding Question for type 1,5,8 & 9 -->
                        <div
                          *ngIf="
                            subField.field_type === 1 ||
                            subField.field_type === 5 ||
                            subField.field_type === 8 ||
                            subField.field_type === 7 ||
                            subField.field_type === 9
                          "
                          [ngClass]="subField.field_type === 8 ? 'd-md-flex mt-2' : 'd-md-flex mt-2 flex-wrap'"
                        >
                          <ng-container *ngFor="let fieldOption of subField.options">
                            <div
                              *ngIf="!fieldOption.is_deleted"
                              [@fade]
                              [ngClass]="
                                subField.field_type === 8 || subField.field_type === 9
                                  ? 'input-group mr-2 flex-col3'
                                  : subField.field_type === 7
                                  ? 'input-group mr-2 flex-col6 mb-1'
                                  : 'input-group mr-2 flex-col5 mt-1'
                              "
                            >
                              <input
                                [ngClass]="fieldOption.option ? 'form-control' : 'error form-control'"
                                placeholder="{{
                                  subField.field_type === 8
                                    ? 'Header'
                                    : subField.field_type === 7
                                    ? 'Bullet Point'
                                    : 'Option'
                                }}"
                                type="text"
                                (change)="onOptionChange($event, field.id, fieldOption.id, subField.id)"
                                [value]="fieldOption.option"
                              />
                              <div class="input-group-append">
                                <ng-container *ngIf="subField.field_type != 9">
                                  <button
                                    class="btn btn-outline-secondary"
                                    (click)="
                                      !editForm
                                        ? deleteOption(field.id, fieldOption.id, 1, subField.id)
                                        : deleteOption(field.id, fieldOption.id, 2, subField.id)
                                    "
                                    ngbTooltip="Delete"
                                    [openDelay]="500"
                                    [closeDelay]="400"
                                  >
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                  </button>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                          <button
                            class="btn btn-light mt-1"
                            *ngIf="
                              subField.field_type != 8 &&
                              subField.field_type != 7 &&
                              checkOption(field.id, 0, subField.id)
                            "
                            (click)="onOptionAdd(field.id, 0, subField.id)"
                            ngbTooltip="Add Another Option"
                            placement="bottom"
                            [openDelay]="500"
                            [closeDelay]="300"
                            [@fade]
                          >
                            <div class="d-flex">Option <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                          </button>
                          <button
                            class="btn btn-light"
                            *ngIf="subField.field_type === 8 && checkOption(field.id, 1, subField.id)"
                            (click)="onOptionAdd(field.id, 1, subField.id)"
                            ngbTooltip="Add Another Column Header"
                            placement="bottom"
                            [openDelay]="500"
                            [closeDelay]="300"
                            [@fade]
                          >
                            <div class="d-flex">Column <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
                          </button>
                          <button
                            class="btn btn-light mt-1"
                            *ngIf="subField.field_type === 7 && checkOption(field.id, 2, subField.id)"
                            (click)="onOptionAdd(field.id, 2, subField.id)"
                            ngbTooltip="Add Another Bullet Point"
                            placement="bottom"
                            [openDelay]="500"
                            [closeDelay]="300"
                            [@fade]
                          >
                            <div class="d-flex">
                              Bullet Point <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </ng-container>
            </fieldset>
          </div>
          <div *ngIf="field.is_deleted">
            <fieldset
              class="form-group question delete-blur border mt-2 p-3"
              cdkDrag
              cdkDragBoundary=".container"
              [@fade]
            >
              <legend class="w-auto px-1 default-cursor">Question # {{ k + 1 }} ( To Be Deleted )</legend>
              <img
                src="/assets/icons/restore.svg"
                alt="restore"
                class="restore-btn"
                ngbTooltip="Restore Field"
                container="body"
                [openDelay]="400"
                [closeDelay]="300"
                (click)="restoreField(field.id)"
              />
              <div class="drag-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div class="d-md-flex mt-2">
                <input
                  type="text"
                  [ngClass]="field.description ? 'form-control flex-col1' : 'error form-control flex-col1'"
                  placeholder="Question"
                  [style.width.ch]="field.description.length > 38 ? field.description.length + 5 : 38"
                  [value]="field.description"
                  disabled
                />
                <select class="form-control flex-col2" disabled>
                  <option
                    *ngFor="let type of FIELD_TYPES"
                    [value]="type.value"
                    [selected]="type.value === field.field_type"
                  >
                    {{ type.title }}
                  </option>
                </select>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <!--  =============== SUB ===============
            =============== QUESTIONS ===============
            =============== END HERE ========= -->

      <!-- Question Type & Option Addition for specific types -->
      <!-- Question & Type -->
      <div class="d-md-flex justify-content-center mt-4 mb-2">
        <h5><b>New Question</b></h5>
      </div>
      <div class="d-md-flex mt-2 mb-3">
        <input class="form-control" value="{{ description }}" (change)="onDescChange($event)" placeholder="Question" />

        <select
          class="form-control"
          (change)="onFieldTypeSelectChange($event)"
          ngbTooltip="Select Question Type"
          [openDelay]="400"
          [closeDelay]="300"
        >
          <option *ngFor="let type of FIELD_TYPES" [value]="type.value" [selected]="type.value === 3">
            {{ type.title }}
          </option>
        </select>
      </div>

       <!-- Options for type 1(Radio),5(Checkbox),7(TextView),8(DataGrid) & 9(FollowUp) -->
      <div
        *ngIf="
          selectedFieldType === 1 ||
          selectedFieldType === 5 ||
          selectedFieldType === 8 ||
          selectedFieldType === 7 ||
          selectedFieldType === 9
        "
        [ngClass]="selectedFieldType === 8 ? 'd-md-flex mb-3' : 'd-md-flex mb-3 flex-wrap'"
      >
        <!-- Options rendered after adding -->
        <ng-container *ngFor="let fieldOption of fieldOptions">
          <div
            [ngClass]="
              selectedFieldType === 8 || selectedFieldType === 9
                ? 'input-group mr-2 flex-col4'
                : 'input-group mr-2 flex-col5 mt-1'
            "
          >
            <input
              type="text"
              class="form-control"
              (change)="changeOptionBeforeCreation($event, fieldOption.id)"
              placeholder="{{
                selectedFieldType === 8 ? 'Header' : selectedFieldType === 7 ? 'Bullet Point' : 'Option'
              }}"
              [value]="fieldOption.option"
            />
            <div class="input-group-append">
            <!-- For type 1,5,7 & 8 --> 
              <ng-container *ngIf="selectedFieldType != 9">
                <button
                  class="btn btn-outline-secondary"
                  (click)="deleteOptionBeforeCreation(fieldOption.id)"
                  ngbTooltip="Delete"
                  [openDelay]="500"
                  [closeDelay]="400"
                >
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </ng-container>

              <!-- For type 9 --> 
              <ng-container *ngIf="selectedFieldType === 9">
                <span class="input-group-text d-flex" *ngIf="fieldOption.sequence_num === 1">Follow Up</span>
                <span class="input-group-text d-flex" *ngIf="fieldOption.sequence_num === 2">End Here</span>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- For type 1,5,7 & 8 -->
        <ng-container *ngIf="selectedFieldType != 9">
          <button
            class="btn btn-light mt-1"
            *ngIf="fieldOptions.length < 30 && selectedFieldType != 8 && selectedFieldType != 7"
            (click)="addOption()"
            ngbTooltip="Add Another Option"
            placement="bottom"
            [openDelay]="500"
            [closeDelay]="300"
          >
            <div class="d-flex">Option <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
          </button>
          <button
            class="btn btn-light mt-1"
            *ngIf="fieldOptions.length < 6 && selectedFieldType === 7"
            (click)="addOption()"
            ngbTooltip="Add Another Point"
            placement="bottom"
            [openDelay]="500"
            [closeDelay]="300"
          >
            <div class="d-flex">Bullet Point <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
          </button>
          <button
            class="btn btn-light"
            *ngIf="fieldOptions.length < 6 && selectedFieldType === 8"
            (click)="addOption()"
            ngbTooltip="Add Another Column Header"
            placement="bottom"
            [openDelay]="500"
            [closeDelay]="300"
          >
            <div class="d-flex">Column <i class="fa fa-plus mt-1 ml-2" aria-hidden="true"></i></div>
          </button>
        </ng-container>
        <p *ngIf="selectedFieldType === 9" style="opacity: 50%; font-size: small">
          (You will be able to add sub questions after adding this question)
        </p>
      </div>

       <!-- Action Buttons -->
      <button
        class="btn btn-light"
        (click)="addField()"
        ngbTooltip="Save Entered Question"
        placement="bottom"
        [openDelay]="500"
        [closeDelay]="300"
      >
        Add Question <i class="fa fa-plus" aria-hidden="true"></i>
      </button>

      <div *ngIf="!editForm; else updateFormBtn" style="float: right">
        <button class="btn btn-info" (click)="this.description ? prompt() : createForm()">
          Create Form <i class="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>
      <ng-template #updateFormBtn>
        <button class="btn btn-info" style="float: right" (click)="this.description ? prompt() : updateForm()">
          Update Form <i class="fa fa-check" aria-hidden="true"></i>
        </button>
      </ng-template>
    </div>

    <!-- FORM -->
    <!-- PREVIEW -->
    <!-- STARTS HERE -->

    <div
      *ngIf="preview"
      [@fadeIn]
      class="screenP screen-6 screen-3 container"
      style="height: 750px !important; top: 5%; margin-left: 2%; margin-top: 40px; overflow-x: auto; overflow-y: hidden"
    >
      <div class="order-otr" style="margin: -20px 0 24px 0 !important">
        <p class="form-title">{{ formTitle || "Form Title" }}</p>
      </div>

      <div
        class="client-otr ml-4"
        style="
          height: 560px !important;
          margin-top: -1px !important;
          width: 80% !important;
          position: absolute !important;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <form>
          <div class="input-otr child" style="width: 95% !important" *ngIf="formFields">
            <lib-vsaPreviewForms
              [formFields]="formFields"
              [renderPreview]="true"
              [patientData]="show_patient_data"
            ></lib-vsaPreviewForms>

            <div *ngIf="formFields.length > 0" class="action-otr login-btn" style="padding-top: 22px !important">
              <button id="task-detail-btn" disabled class="btn-login hand-cursor mt-3 ml-4" type="button">
                SUBMIT
              </button>
            </div>
            <p class="mt-2" style="opacity: 50%; width: 100%; font-size: x-small">
              This is just a preview, actual render may not be the same.
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
