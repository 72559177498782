import { AdminPracticeForm } from './../../shared/models/admin';
import { environment } from './../../../environments/environment';
import { AdminService } from './../../shared/services/admin.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  Practice,
  ColorSchemeForm,
  DBConfig,
  ColorScheme,
} from 'src/app/shared/models';

@Component({
  selector: 'app-practice-form',
  templateUrl: './practice-form.component.html',
  styleUrls: ['./practice-form.component.scss'],
})
export class PracticeFormComponent implements OnInit {
  practiceForm: FormGroup;
  adminData: Practice;
  useCustomDB: boolean = false;
  testCustomDB: boolean = true;
  useCustomColors: boolean = false;
  colors: ColorSchemeForm = environment.defaultColors;
  dbConfig: DBConfig = environment.defaultDb;
  isSuperAdmin: boolean = false;
  hex: string = 'hex';
  defaultColorScheme: ColorSchemeForm = {
    patientComplete: '#229954',
    readyForProvider: '#C71585',
    status4: '#FF4500',
    status5: '#FFD700',
    status6: '#EE82EE',
    status7: '#f94f4f',
    status11: '#FFD700',
    status12: '#EE82EE',
  };
  defaultColors: ColorScheme[] = [
    { id: 1, description: 'Patient Complete', color: '#229954' },
    { id: 3, description: 'Ready For Provider', color: '#C71585' },
    { id: 4, description: 'status4', color: '#FF4500' },
    { id: 5, description: 'status5', color: '#50703f' },
    { id: 6, description: 'status6', color: '#107472' },
    { id: 7, description: 'status7', color: '#c27474' },
    { id: 11, description: 'status11', color: '#FFD700' },
    { id: 12, description: 'status12', color: '#EE82EE' },
  ];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.adminData = JSON.parse(this.route.snapshot.params['adminData']);
    if (this.adminData) this.initialConfig();
  }

  initialConfig(): void {
    if (this.adminData.practice_id === 'superAdmin') {
      this.isSuperAdmin = true;
      this.useCustomDB = false;
      this.testCustomDB = true;
      this.useCustomColors = false;
      this.initFormSuperAdmin();
    } else {
      this.initFormSuperAdmin();
      this.initPracticeAdmin();
    }
  }

  init(): void {
    const { username, password } = this.adminData;
    this.spinnerService.show();
    this.adminService
      .validateLoginInfo({ username, password, hashedPassword: 'true' })
      .then((resp) => {
        this.adminData = resp;
        this.initialConfig();
        this.spinnerService.hide();
      });
  }

  async initPracticeAdmin(): Promise<void> {
    if (this.adminData?.dbFlag === 'customDB') {
      this.useCustomDB = true;
      this.testCustomDB = false;
    } else {
      this.resetDbConfig();
    }

    let formData: AdminPracticeForm;
    if (this.adminData.colorFlag === 'customColors') {
      this.useCustomColors = true;
      this.adminService
        .getPracticeColorLabels({
          practice_id: this.adminData.practice_id,
        })
        .then((resp: any) => {
          const data: ColorScheme[] = resp.data;
          formData = {
            practice: { ...this.adminData },
            color: { ...data },
          };
          this.initForm(formData);
        });
    } else {
      this.useCustomColors = false;
      formData = {
        practice: { ...this.adminData },
        color: { ...this.defaultColors },
      };
      this.initForm(formData);
    }
  }

  initFormSuperAdmin(): void {
    this.practiceForm = this.formBuilder.group({
      practice_id: new FormControl('', [Validators.required]),
      name: new FormControl(''),
      colorSelectRadio: new FormControl('defaultColors'),
      dbSelectRadio: new FormControl('defaultDB'),
      dbUsername: new FormControl(''),
      dbPassword: new FormControl(''),
      dbName: new FormControl(''),
      dbHost: new FormControl(''),
      dbDialect: new FormControl(''),
      dbPort: new FormControl(''),
      practiceAdminUsername: new FormControl('', [Validators.required]),
      practiceAdminPassword: new FormControl('', [Validators.required]),
      patientComplete: new FormControl('Patient Complete'),
      readyForProvider: new FormControl('Ready For Provider'),
      status4: new FormControl(''),
      status5: new FormControl(''),
      status6: new FormControl(''),
      status7: new FormControl(''),
      status11: new FormControl(''),
      status12: new FormControl(''),
      patientCompleteColor: new FormControl(this.colors.patientComplete),
      readyForProviderColor: new FormControl(this.colors.readyForProvider),
      status4Color: new FormControl(this.colors.status4),
      status5Color: new FormControl(this.colors.status5),
      status6Color: new FormControl(this.colors.status6),
      status7Color: new FormControl(this.colors.status7),
      status11Color: new FormControl(this.colors.status11),
      status12Color: new FormControl(this.colors.status12),
    });
  }

  initForm(data: AdminPracticeForm): void {
    Object.keys(this.colors).forEach((key, index) => {
      this.colors[key] = data.color[index]?.color;
    });

    this.practiceForm = this.formBuilder.group({
      practice_id: new FormControl(data.practice.practice_id, [
        Validators.required,
      ]),
      name: new FormControl(data.practice.practice_name),
      colorSelectRadio: new FormControl(data.practice.colorFlag),
      dbSelectRadio: new FormControl(data.practice.dbFlag),
      dbUsername: new FormControl(data.practice.dbUsername),
      dbPassword: new FormControl(data.practice.dbPassword),
      dbName: new FormControl(data.practice.dbName),
      dbHost: new FormControl(data.practice.dbHost),
      dbDialect: new FormControl(data.practice.dbDialect),
      dbPort: new FormControl(data.practice.dbPort),
      practiceAdminUsername: new FormControl(data.practice.username, [
        Validators.required,
      ]),
      practiceAdminPassword: new FormControl(''),
      patientComplete: new FormControl(data.color[0].description),
      readyForProvider: new FormControl(data.color[1].description),
      status4: new FormControl(data?.color[2]?.description),
      status5: new FormControl(data?.color[3]?.description),
      status6: new FormControl(data?.color[4]?.description),
      status7: new FormControl(data?.color[5]?.description),
      status11: new FormControl(data?.color[6]?.description),
      status12: new FormControl(data?.color[7]?.description),
      patientCompleteColor: new FormControl(data.color[0].color),
      readyForProviderColor: new FormControl(data.color[1].color),
      status4Color: new FormControl(data?.color[2]?.color),
      status5Color: new FormControl(data?.color[3]?.color),
      status6Color: new FormControl(data?.color[4]?.color),
      status7Color: new FormControl(data?.color[5]?.color),
      status11Color: new FormControl(data?.color[6]?.color),
      status12Color: new FormControl(data?.color[7]?.color),
    });
  }

  get f(): any {
    return this.practiceForm.controls;
  }

  resetDbConfig(): void {
    this.adminData.dbUsername = '';
    this.adminData.dbPassword = '';
    this.adminData.dbName = '';
    this.adminData.dbHost = '';
    this.adminData.dbDialect = '';
    this.adminData.dbPort = null;
  }

  dbSelectionChange(event: boolean): void {
    if (event) this.testCustomDB = false;
    else this.testCustomDB = true;
    this.useCustomDB = event;
  }

  colorSchemeChange(event: boolean): void {
    this.useCustomColors = event;
  }

  testDatabaseConnection(): void {
    const dbConfig = {
      username: this.f.dbUsername.value,
      password: this.f.dbPassword.value,
      database: this.f.dbName.value,
      host: this.f.dbHost.value,
      dialect: this.f.dbDialect.value,
      port: this.f.dbPort.value,
    };

    this.spinnerService.show();
    this.adminService
      .testDatabaseConnection(dbConfig)
      .then(() => {
        this.spinnerService.hide();
        this.testCustomDB = true;
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error("Connection with DB couldn't be established.");
      });
  }

  submit(): void {
    const self = this;
    let reqBodyForStatusAPI = [];

    if (this.f.dbSelectRadio.value === 'customDB') {
      this.dbConfig = {
        dbUsername: this.f.dbUsername.value,
        dbPassword: this.f.dbPassword.value,
        dbName: this.f.dbName.value,
        dbHost: this.f.dbHost.value,
        dbDialect: this.f.dbDialect.value,
        dbPort: this.f.dbPort.value,
      };
    } else {
      this.dbConfig = environment.defaultDb;
    }

    if (this.f.colorSelectRadio.value === 'defaultColors') {
      const statusColors = this.defaultColorScheme;
      Object.keys(statusColors).forEach((key) => {
        let id: number;
        let description: string;

        switch (key) {
          case 'patientComplete': {
            id = 1;
            description = 'Patient Complete';
            break;
          }
          case 'readyForProvider': {
            id = 3;
            description = 'Ready For Provider';
            break;
          }
          case 'status4': {
            id = 4;
            description = '';
            break;
          }
          case 'status5': {
            id = 5;
            description = '';
            break;
          }
          case 'status6': {
            id = 6;
            description = '';
            break;
          }
          case 'status7': {
            id = 7;
            description = '';
            break;
          }
          case 'status11': {
            id = 11;
            description = '';
            break;
          }
          case 'status12': {
            id = 12;
            description = '';
            break;
          }
        }

        reqBodyForStatusAPI.push({
          id,
          practice_id: self.f.practice_id.value,
          description,
          color: statusColors[key],
        });
      });
    } else {
      const statusColors = this.colors;
      Object.keys(statusColors).forEach((key) => {
        let id: number;
        let description: string;
        let color: string;

        switch (key) {
          case 'patientComplete': {
            id = 1;
            description = self.f.patientComplete.value;
            color = statusColors.patientComplete;
            break;
          }
          case 'readyForProvider': {
            id = 3;
            description = self.f.readyForProvider.value;
            color = statusColors.readyForProvider;
            break;
          }
          case 'status4': {
            id = 4;
            description = self.f.status4.value;
            color = statusColors.status4;
            break;
          }
          case 'status5': {
            id = 5;
            description = self.f.status5.value;
            color = statusColors.status5;
            break;
          }
          case 'status6': {
            id = 6;
            description = self.f.status6.value;
            color = statusColors.status6;
            break;
          }
          case 'status7': {
            id = 7;
            description = self.f.status7.value;
            color = statusColors.status7;
            break;
          }
          case 'status11': {
            id = 11;
            description = self.f.status11.value;
            color = statusColors.status11;
            break;
          }
          case 'status12': {
            id = 12;
            description = self.f.status12.value;
            color = statusColors.status12;
            break;
          }
        }

        reqBodyForStatusAPI.push({
          id,
          practice_id: self.f.practice_id.value,
          description,
          color,
        });
      });
    }

    let passwordReqBody = {};
    this.f.practiceAdminPassword.value.length > 0
      ? (passwordReqBody = { password: this.f.practiceAdminPassword.value })
      : '';

    let reqBody = {
      practice_id: this.f.practice_id.value,
      practice_name: this.f.name.value,
      username: this.f.practiceAdminUsername.value,
      ...passwordReqBody,
      dbFlag: this.f.dbSelectRadio.value,
      colorFlag: this.f.colorSelectRadio.value,
      ...this.dbConfig,
    };

    this.spinnerService.show();
    this.adminService
      .createOrUpdatePractice(reqBody)
      .then((resp) => {
        if (!this.isSuperAdmin) this.adminData = resp.data;

        this.adminService
          .createStatusForPractice({ colors: reqBodyForStatusAPI })
          .then((res) => {
            this.spinnerService.hide();
            this.toastr.success('Process Completed Successfully');
            this.init();
          })
          .catch((err) => {
            this.spinnerService.hide();
            this.toastr.error(
              "Practice Creation Successful, but status colors couldn't be created"
            );
          });
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Error Creating/Updating Practice');
      });
  }

  openProvidersPage(): void {
    this.router.navigate(
      ['admin/practice/providers', { data: JSON.stringify(this.adminData) }],
      {
        skipLocationChange: true,
      }
    );
  }
}
