import { Component, OnInit } from '@angular/core';
import { TalkService } from '../../services/talk.service';
import { VideoDictationService } from '../../services/video-dictation.service';
import { Assistant, DictationData, DictationFirebaseData, Provider } from 'src/app/shared/models/';
import { FirebaseService } from '../../services/firebase.service';
import { AudioDictationService } from '../../services/audio-dictation.service';
import { NAVBAR_COMPONENTS } from '../../constants/navbar-config';
@Component({
  selector: 'app-horizontal-notification',
  templateUrl: './horizontal-notification.component.html',
  styleUrls: ['./horizontal-notification.component.css'],
})
export class HorizontalNotificationComponent implements OnInit {
  msg: any;
  msgLength: number = 0;
  audio: number = 0;
  video: number = 0;
  searchKeywork: string = '';
  searchCriteria: string = 'patient_name';
  assistantInfo: Assistant = null;
  navbarComponents = NAVBAR_COMPONENTS;
  showBar: boolean = false;
  ngbDropdownToggle: boolean = true;

  constructor(
    private talkService: TalkService,
    private videoDictationService: VideoDictationService,
    private firebaseService: FirebaseService,
    private audioDictationService: AudioDictationService
  ) {}

  ngOnInit(): void {
    this.init();
  }
  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.notifications();
  }

  private notifications() {
    this.messages();
    this.videoDictationCount();
    this.audioDictationCount();
    this.liveswitchWait();
  }

  private async messages() {
    const self = this;
    let practiceName = localStorage.getItem('practiceName');
    const data = JSON.parse(localStorage.getItem('user'));
    const session = await this.talkService.createCurrentSession(
      this.assistantInfo.practiceId.toLowerCase(),
      practiceName
    );
    session.unreads.on('change', function (unreadConversations) {
      self.msg = unreadConversations;
      self.msgLength = unreadConversations.length;
    });
  }

  private videoDictationCount(
    page: number = 1,
    limit: number = 0,
    filterByProvider: string = '',
    orderBy: any = {},
    isArchived: boolean = false
  ): void {
    this.videoDictationService
      .getVideoDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeywork,
        this.searchCriteria,
        filterByProvider,
        isArchived
      )
      .then((resp) => {
        this.video = resp.data.total;
      });
  }

  private audioDictationCount(
    page: number = 1,
    limit: number = 0,
    filterByProvider: string = '',
    orderBy: any = {}
  ): void {
    this.audioDictationService
      .getAudioDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeywork,
        this.searchCriteria,
        filterByProvider,
        false
      )
      .then((resp) => {
        this.audio = resp.data.total;
      });
  }

  private liveswitchWait() {
    //console.log("waiting...");
  }
}
