import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Assistant, GenericResp } from '../../models';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { GET_CREATE_SMS_SCRIPT_RESP, SMSScriptsTableData } from '../../models/smsScripts';
import { SMSService } from '../../services/sms.service';

@Component({
  selector: 'app-sms-scripts-modal',
  templateUrl: './sms-scripts-modal.component.html',
  styleUrls: ['./sms-scripts-modal.component.scss'],
})
export class SmsScriptsModalComponent implements OnInit {
  assistantInfo: Assistant = null;
  event: string;

  @Input() title: string;
  @Input() type: string;
  @Input() scriptData: SMSScriptsTableData;

  @ViewChild('script') script: ElementRef<HTMLTextAreaElement>;

  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private smsService: SMSService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.event = this.scriptData.event_type;
  }

  appendToScript(text: string) {
    const textarea = this.script.nativeElement;
    const cursorPosition = textarea.selectionStart;
    const currentValue = textarea.value;
    const space = ' ';
    const newValue = currentValue.slice(0, cursorPosition) + space + text + currentValue.slice(cursorPosition);
    textarea.value = newValue;
    this.scriptData.script = newValue;
    textarea.selectionStart = cursorPosition + space.length + text.length;
    textarea.selectionEnd = cursorPosition + space.length + text.length;
    textarea.focus();
  }

  createScript(): void {
    this.spinnerService.show();

    const { script_id, id, created_at, ...data } = this.scriptData;

    this.smsService
      .createSMSScript(data)
      .then((resp: GET_CREATE_SMS_SCRIPT_RESP) => {
        this.toastr.success('Script Created Successfully');
        this.scriptData = {
          id: 0,
          script_id: '',
          script: '',
          event_type: '',
          type: 'A',
          is_new_patient_script: false,
          created_at: '',
        };

        this.activeModal.close(resp.data);
      })
      .catch((err) => {
        if (err.error.code === 400) this.toastr.error(err.error.message);
        else this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  updateScript(): void {
    this.spinnerService.show();
    let data;

    data = {
      event_type: this.scriptData.event_type,
      type: this.scriptData.type,
      script: this.scriptData.script,
      is_new_patient_script: this.scriptData.is_new_patient_script,
    };

    this.smsService
      .updateSMSScript(this.scriptData.script_id, data)
      .then((resp: GenericResp) => {
        this.toastr.success('Script Updated Successfully');
        this.activeModal.close(this.scriptData);
      })
      .catch((err) => {
        if (err.error.code === 400) this.toastr.error(err.error.message);
        else this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }
}
