<div class="table-data__wrapper table-responsive">
  <table class="table table-data">
    <thead>
      <tr>
        <th *ngFor="let title of headers" scope="col">
          {{ title | replaceUnderscore }}
        </th>

        <th *ngIf="hasActions">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let record of data">
          <td *ngFor="let title of headers">
            {{ record[title] }}
          </td>

          <ng-container *ngIf="hasActions">
            <td>
              <button
                *ngIf="deleteFunction"
                class="btn del-btn"
                (click)="deleteFunction(record)"
              >
                <img src="/assets/icons/trash.svg" />
              </button>
              <span
                *ngIf="editFunction"
                class="btn primary-btn"
                (click)="editFunction(record)"
              >
                <i class="far fa-edit"></i>
              </span>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div class="c-pagination__wrapper">
    <div class="row-display">
      Displaying
      <span class="rows-record current">{{ data.length }}</span> records
    </div>
    <div class="next-prev__wrapper">
      <span class="nxt-prev-page-arrows">
        <span
          class="prev-btn"
          (click)="prevKeys?.length ? paginationPrevPage() : ''"
          [ngClass]="{ next: prevKeys?.length, first: !prevKeys?.length }"
        >
          <i class="fas fa-angle-left"></i>
        </span>
        <span
          class="nxt-btn"
          (click)="nextKey ? paginationNextPage() : ''"
          [ngClass]="{ next: nextKey, first: !nextKey }"
        >
          <i class="fas fa-angle-right"></i>
        </span>
      </span>
    </div>
  </div>
</div>
