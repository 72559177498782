import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {
  Assistant,
  FormDetails,
  FormFieldOptionsDetails,
  FormFieldsDetails,
  FormSubFieldsDetails,
  Style,
} from 'src/app/shared/models';
import { v4 as uuid } from 'uuid';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { FormsService } from 'src/app/shared/services/forms.service';
// import { SignaturePad } from 'angular2-signaturepad';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate(760)])]),
  ],
})
export class CreateFormComponent implements OnInit {
  FIELD_TYPES = [
    { title: 'Radio Button', value: 1 },
    { title: 'Location', value: 2 },
    { title: 'Text', value: 3 },
    { title: 'Image', value: 4 },
    { title: 'Checkbox', value: 5 },
    { title: 'Signature', value: 6 },
    { title: 'TextView', value: 7 },
    { title: 'Data Grid', value: 8 },
    { title: 'Follow Up', value: 9 },
  ];

  MASK_TYPES = [
    { title: 'None', value: 0 },
    { title: 'Email', value: 1 },
    { title: 'Date', value: 2 },
    { title: 'Phone #', value: 3 },
  ];
  IMG_MASK_TYPES = [
    { title: 'None', value: 0 },
    { title: 'Insurance Card', value: 1 },
  ];

  FONT_SIZES = [{ value: 10 }, { value: 12 }, { value: 14 }, { value: 16 }, { value: 18 }, { value: 20 }];

  assistantInfo: Assistant = null;
  selectedFieldType = 3;
  formFields: FormFieldsDetails[] = [];
  followUpQuestions: FormSubFieldsDetails[] = [];
  fieldOptions: FormFieldOptionsDetails[] = [];
  editOptions: FormFieldOptionsDetails[] = [];
  editForm: FormDetails;
  optionEmpty = 0;
  formTitle = '';
  sequenceNum = 1;
  subSequenceNum = 1;
  optSequenceNum = 1;
  deletedFields = 0;
  deletedSubFields = 0;
  deletedOptions = 0;
  description = '';
  preview = false;
  show_patient_data = false;
  mappedCount = 0;
  @ViewChild('attachmentSwitch', { static: false }) attachmentSwitch: MatSlideToggle;
  defaultStyle = {
    mask: 0,
    bold: false,
    align: 0,
    underline: false,
    font_size: 14,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private formService: FormsService,
    private toastr: ToastrService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    try {
      this.editForm = JSON.parse(this.route.snapshot.params['formData']);
      if (this.editForm) {
        this.formTitle = this.editForm.title;
        this.show_patient_data = this.editForm.show_patient_data;
        this.formFields = this.editForm.fields.map((field) => ({
          ...field,
          is_deleted: 0,
          is_mapped: +field.is_mapped,
          style: field.style ? (JSON.parse(field.style) as Style) : this.defaultStyle,
          options: field.options.map((option) => ({
            ...option,
            is_deleted: 0,
          })),
          sub_fields: field.sub_fields.map((sub) => ({
            ...sub,
            style: sub.style ? (JSON.parse(sub.style) as Style) : this.defaultStyle,
            is_mapped: +sub.is_mapped,
            is_deleted: +sub.is_deleted,
            options: sub.options.map((option) => ({
              ...option,
              is_deleted: 0,
            })),
          })),
        }));
      }

      this.spinnerService.hide();
    } catch (err) {
      () => this.toastr.error('Something went wrong. Please try again');
    }
    this.init();
  }

  async init(): Promise<void> {
    const assistant = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(assistant.uid, assistant.displayName);
  }

  dropField(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formFields, event.previousIndex, event.currentIndex);
  }

  formPreview() {
    if (this.attachmentSwitch.checked) {
      this.preview = true;
    } else {
      this.preview = false;
    }
  }

  /* id is for Questions & sub_id is for Sub-Questions */
  mapField(id: string, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);

      if (this.formFields[index].sub_fields[sub_index].is_mapped) {
        this.formFields[index].sub_fields[sub_index].is_mapped = 0;
        this.mappedCount--;
      } else {
        this.formFields[index].sub_fields[sub_index].is_mapped = 1;
        this.mappedCount++;
      }
    } else {
      if (this.formFields[index].is_mapped) {
        this.formFields[index].is_mapped = 0;
        this.mappedCount--;
      } else {
        this.formFields[index].is_mapped = 1;
        this.mappedCount++;
      }
    }
  }

  onFieldTypeSelectChange(event: any): void {
    this.selectedFieldType = +event.target.value;
    this.fieldOptions = [];

    if (this.selectedFieldType === 1 || this.selectedFieldType === 5 || this.selectedFieldType === 8) {
      this.fieldOptions.push({
        id: uuid(),
        option: '',
        sequence_num: this.fieldOptions.length + 1,
        is_deleted: 0,
      });
    }

    if (this.selectedFieldType === 9) {
      this.fieldOptions.push({
        id: uuid(),
        option: 'Yes',
        sequence_num: 1,
        is_deleted: 0,
      });
      this.fieldOptions.push({
        id: uuid(),
        option: 'No',
        sequence_num: 2,
        is_deleted: 0,
      });
    }
  }

  patientDetails(event: any): void {
    if (event.target.checked) this.show_patient_data = true;
    else this.show_patient_data = false;
  }

  onDescChange(event: any): void {
    this.description = event.target.value;
  }

  /* id is for Questions & sub_id is for Sub-Questions */
  onQuestionChange(event: any, id: string, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      this.formFields[index].sub_fields[sub_index].description = event.target.value;
    } else this.formFields[index].description = event.target.value;
  }

  onOptionChange(event: any, field_id: string, option_id: string, sub_field_id?: string): void {
    const f_index = this.formFields.findIndex((x) => x.id === field_id);
    if (sub_field_id) {
      const sub_f_index = this.formFields[f_index].sub_fields.findIndex((x) => x.id === sub_field_id);
      const o_index = this.formFields[f_index].sub_fields[sub_f_index].options.findIndex((x) => x.id === option_id);
      this.formFields[f_index].sub_fields[sub_f_index].options[o_index].option = event.target.value;
    } else {
      const o_index = this.formFields[f_index].options.findIndex((x) => x.id === option_id);
      this.formFields[f_index].options[o_index].option = event.target.value;
    }
  }

  onSubQuestionAdd(id: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    if (this.formFields[index].sub_fields.length > 0) {
      this.sequenceNum =
        this.formFields[index].sub_fields[this.formFields[index].sub_fields.length - 1].sequence_num + 1;
    }

    this.formFields[index].sub_fields.push({
      id: uuid(),
      description: '',
      sequence_num: this.sequenceNum,
      field_type: 3,
      options: [],
      style: this.defaultStyle,
      is_deleted: 0,
      is_mapped: 0,
    });

    this.sequenceNum = 1;
  }

  /* id is for Questions & sub_id is for Sub-Questions */
  /* Option 0 is for Options, Option 1 is for Column Headers, Option 2 is for Bullet */
  onOptionAdd(id: string, option: number, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    let o_last;

    if (this.formFields[index].field_type === 7 && !this.formFields[index].options.length) {
      o_last = 0;
    } else {
      o_last = this.formFields[index].options.length - 1;
    }

    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      let sub_o_last;
      if (
        this.formFields[index].sub_fields[sub_index].field_type === 7 &&
        !this.formFields[index].sub_fields[sub_index].options.length
      ) {
        sub_o_last = 0;
      } else {
        sub_o_last = this.formFields[index].sub_fields[sub_index].options.length - 1;
      }

      this.deletedOptions = 0;
      this.optSequenceNum = 1;

      if (this.formFields[index].sub_fields[sub_index].options.length > 0) {
        for (const i in this.formFields[index].sub_fields[sub_index].options) {
          this.optSequenceNum = this.formFields[index].sub_fields[sub_index].options[i].sequence_num + 1;
          if (this.formFields[index].sub_fields[sub_index].options[i].is_deleted === 1) {
            this.deletedOptions++;
          }
        }
      }

      if (this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 29 && option === 0) {
        this.toastr.info('Maximum of 10 options are allowed.');
        return;
      } else if (
        this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 5 &&
        option === 1
      ) {
        this.toastr.info('Maximum of 6 column headers are allowed.');
        return;
      } else if (
        this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 5 &&
        option === 2
      ) {
        this.toastr.info('Maximum of 3 Bullet Points are allowed.');
        return;
      } else {
        if (this.formFields[index].sub_fields[sub_index].field_type != 7) {
          if (
            !this.formFields[index].sub_fields[sub_index].options[sub_o_last].option &&
            !this.formFields[index].options[sub_o_last].is_deleted
          ) {
            if (!option) {
              this.toastr.info('Please enter an option first.');
            } else this.toastr.info('Please enter a header name first.');
            return;
          }
        }
        this.editOptions.push({
          id: uuid(),
          option: '',
          sequence_num: this.optSequenceNum,
          is_deleted: 0,
        });
        this.formFields[index].sub_fields[sub_index].options = [
          ...this.formFields[index].sub_fields[sub_index].options,
          ...this.editOptions,
        ];
        this.editOptions = [];
      }
    } else {
      this.deletedOptions = 0;
      this.optSequenceNum = 1;

      if (this.formFields[index].options.length > 0) {
        for (const i in this.formFields[index].options) {
          this.optSequenceNum = this.formFields[index].options[i].sequence_num + 1;
          if (this.formFields[index].options[i].is_deleted === 1) {
            this.deletedOptions++;
          }
        }
      }

      if (this.formFields[index].options.length - this.deletedOptions > 29 && option === 0) {
        this.toastr.info('Maximum of 10 options are allowed.');
        return;
      } else if (this.formFields[index].options.length - this.deletedOptions > 5 && option === 1) {
        this.toastr.info('Maximum of 6 column headers are allowed.');
        return;
      } else if (this.formFields[index].options.length - this.deletedOptions > 5 && option === 2) {
        this.toastr.info('Maximum of 3 Bullet Points are allowed.');
        return;
      } else {
        if (this.formFields[index].field_type != 7) {
          if (!this.formFields[index].options[o_last].option && !this.formFields[index].options[o_last].is_deleted) {
            if (!option) {
              this.toastr.info('Please enter an option first.');
            } else this.toastr.info('Please enter a header name first.');
            return;
          }
        }
        this.editOptions.push({
          id: uuid(),
          option: '',
          sequence_num: this.optSequenceNum,
          is_deleted: 0,
        });
        this.formFields[index].options = [...this.formFields[index].options, ...this.editOptions];
        this.editOptions = [];
      }
    }
  }

  /* id is for Questions & sub_id is for Sub-Questions */
  /* Opt 1 is for Form Creation, Opt 2 is for Form Updation */
  onFieldTypeChange(event: any, id: string, opt: number, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    let o_seq = 1;

    /* for Sub-Questions */
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      /* Form Creation  */
      if (!opt) {
        this.formFields[index].sub_fields[sub_index].field_type = +event.target.value;
        if (event.target.value == 1 || event.target.value == 5 || event.target.value == 8) {
          this.editOptions.push({
            id: uuid(),
            option: '',
            sequence_num: 1,
            is_deleted: 0,
          });
          this.formFields[index].sub_fields[sub_index].options = [];
          this.formFields[index].sub_fields[sub_index].options = [
            ...this.formFields[index].sub_fields[sub_index].options,
            ...this.editOptions,
          ];

          this.editOptions = [];
        } else {
          this.formFields[index].sub_fields[sub_index].options = [];
        }
      } /*  Form Updation */ else {
        if (this.formFields[index].sub_fields[sub_index].options.length > 0) {
          let o_last = this.formFields[index].sub_fields[sub_index].options.length - 1;
          o_seq = this.formFields[index].sub_fields[sub_index].options[o_last].sequence_num + 1;
        }
        this.formFields[index].sub_fields[sub_index].field_type = +event.target.value;

        if (event.target.value != 1 || event.target.value != 5 || event.target.value != 7 || event.target.value != 8) {
          for (const i in this.formFields[index].sub_fields[sub_index].options) {
            this.formFields[index].sub_fields[sub_index].options[i].is_deleted = 1;
          }
        }

        if (event.target.value == 1 || event.target.value == 5 || event.target.value == 8) {
          this.editOptions.push({
            id: uuid(),
            option: '',
            sequence_num: o_seq,
            is_deleted: 0,
          });
          this.formFields[index].sub_fields[sub_index].options = [
            ...this.formFields[index].sub_fields[sub_index].options,
            ...this.editOptions,
          ];
          this.editOptions = [];
        }

        if (event.target.value != 3) {
          this.formFields[index].sub_fields[sub_index].style.mask = 0;
        }
      }
    } else {
      /* for Questions */
      /* Form Creation */
      if (!opt) {
        this.formFields[index].field_type = +event.target.value;
        if (event.target.value == 1 || event.target.value == 5 || event.target.value == 8 || event.target.value == 9) {
          if (event.target.value == 9) {
            const seq = this.formFields[index].sub_fields.length;
            this.editOptions.push({
              id: uuid(),
              option: 'Yes',
              sequence_num: 1,
              is_deleted: 0,
            });
            this.editOptions.push({
              id: uuid(),
              option: 'No',
              sequence_num: 2,
              is_deleted: 0,
            });
            this.followUpQuestions.push({
              id: uuid(),
              description: '',
              sequence_num: seq,
              field_type: 3,
              options: [],
              style: this.defaultStyle,
              is_deleted: 0,
              is_mapped: 0,
            });

            this.formFields[index].sub_fields = [...this.formFields[index].sub_fields, ...this.followUpQuestions];
            this.followUpQuestions = [];
          } else {
            this.editOptions.push({
              id: uuid(),
              option: '',
              sequence_num: 1,
              is_deleted: 0,
            });
            this.formFields[index].sub_fields = [];
          }

          this.formFields[index].options = [];
          this.formFields[index].options = [...this.formFields[index].options, ...this.editOptions];
          this.editOptions = [];
        } else {
          this.formFields[index].options = [];
          this.formFields[index].sub_fields = [];
        }
      } else {
        /* Form Updation */
        if (this.formFields[index].options.length > 0) {
          o_seq = this.formFields[index].options[this.formFields[index].options.length - 1].sequence_num + 1;
        }
        this.formFields[index].field_type = +event.target.value;

        if (
          event.target.value != 1 ||
          event.target.value != 5 ||
          event.target.value != 8 ||
          event.target.value != 7 ||
          event.target.value != 9
        ) {
          for (const i in this.formFields[index].options) {
            this.formFields[index].options[i].is_deleted = 1;
          }
          for (const i in this.formFields[index].sub_fields) {
            this.formFields[index].sub_fields[i].is_deleted = 1;
            for (const k in this.formFields[index].sub_fields[i].options) {
              this.formFields[index].sub_fields[i].options[k].is_deleted = 1;
            }
          }
        }

        if (event.target.value == 1 || event.target.value == 5 || event.target.value == 8) {
          for (const i in this.formFields[index].options) {
            this.formFields[index].options[i].is_deleted = 1;
          }
          for (const i in this.formFields[index].sub_fields) {
            this.formFields[index].sub_fields[i].is_deleted = 1;
            for (const k in this.formFields[index].sub_fields[i].options) {
              this.formFields[index].sub_fields[i].options[k].is_deleted = 1;
            }
          }
          this.editOptions.push({
            id: uuid(),
            option: '',
            sequence_num: o_seq,
            is_deleted: 0,
          });
          this.formFields[index].options = [...this.formFields[index].options, ...this.editOptions];
          this.editOptions = [];
        }

        if (event.target.value == 9) {
          const seq = this.formFields[index].sub_fields.length;
          this.editOptions.push({
            id: uuid(),
            option: 'Yes',
            sequence_num: 1,
            is_deleted: 0,
          });
          this.editOptions.push({
            id: uuid(),
            option: 'No',
            sequence_num: 2,
            is_deleted: 0,
          });
          this.followUpQuestions.push({
            id: uuid(),
            description: '',
            sequence_num: seq,
            field_type: 3,
            options: [],
            style: this.defaultStyle,
            is_deleted: 0,
            is_mapped: 0,
          });
          this.formFields[index].sub_fields = [...this.formFields[index].sub_fields, ...this.followUpQuestions];
          this.formFields[index].options = [...this.formFields[index].options, ...this.editOptions];
          this.followUpQuestions = [];
          this.editOptions = [];
        }

        if (event.target.value != 3) {
          this.formFields[index].style.mask = 0;
        }
      }
    }
  }

  /* id is for Questions & sub_id is for Sub-Questions */
  onMaskChange(event: any, id: string, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);

    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      this.formFields[index].sub_fields[sub_index] = {
        ...this.formFields[index].sub_fields[sub_index],
        style: { ...this.formFields[index].sub_fields[sub_index].style, mask: +event.target.value },
      };
    } else {
      this.formFields[index] = {
        ...this.formFields[index],
        style: { ...this.formFields[index].style, mask: +event.target.value },
      };
    }
    this.formFields = [...this.formFields];
  }

  /* id is for Questions & sub_seq_id is for Sub-Questions */
  onFontChange(event: any, id: string, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      this.formFields[index].sub_fields[sub_index] = {
        ...this.formFields[index].sub_fields[sub_index],
        style: { ...this.formFields[index].sub_fields[sub_index].style, font_size: +event.target.value },
      };
    } else {
      this.formFields[index] = {
        ...this.formFields[index],
        style: { ...this.formFields[index].style, font_size: +event.target.value },
      };
    }
    this.formFields = [...this.formFields];
  }

  /* id is for Questions & sub_id is for Sub-Questions, opt is to choose Styles */
  styleField(id: string, opt: number, align: number, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id === id);

    /* For Sub Questions */
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      /* For Bold */
      if (opt === 1) {
        this.formFields[index].sub_fields[sub_index] = {
          ...this.formFields[index].sub_fields[sub_index],
          style: {
            ...this.formFields[index].sub_fields[sub_index].style,
            bold: !this.formFields[index].sub_fields[sub_index].style.bold,
          },
        };
      }

      /* For Underline */
      if (opt === 2) {
        this.formFields[index].sub_fields[sub_index] = {
          ...this.formFields[index].sub_fields[sub_index],
          style: {
            ...this.formFields[index].sub_fields[sub_index].style,
            underline: !this.formFields[index].sub_fields[sub_index].style.underline,
          },
        };
      }

      /* For Align */
      if (opt === 3) {
        this.formFields[index].sub_fields[sub_index] = {
          ...this.formFields[index].sub_fields[sub_index],
          style: { ...this.formFields[index].sub_fields[sub_index].style, align: align },
        };
      }
    } /* For Questions */ else {
      /* For Bold */
      if (opt === 1) {
        this.formFields[index] = {
          ...this.formFields[index],
          style: { ...this.formFields[index].style, bold: !this.formFields[index].style.bold },
        };
      }

      /* For Underline */
      if (opt === 2) {
        this.formFields[index] = {
          ...this.formFields[index],
          style: { ...this.formFields[index].style, underline: !this.formFields[index].style.underline },
        };
      }

      /* For Align */
      if (opt === 3) {
        this.formFields[index] = {
          ...this.formFields[index],
          style: { ...this.formFields[index].style, align: align },
        };
      }
    }
    this.formFields = [...this.formFields];
    return;
  }

  /* Option 0 is for Options, Option 1 is for Column Headers, Option 2 is for Bullet Points */
  checkOption(id: string, option: number, sub_id?: string) {
    const index = this.formFields.findIndex((x) => x.id === id);
    if (sub_id) {
      const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id === sub_id);
      this.deletedOptions = 0;

      if (this.formFields[index].sub_fields[sub_index].options.length > 0) {
        for (const i in this.formFields[index].sub_fields[sub_index].options) {
          if (this.formFields[index].sub_fields[sub_index].options[i].is_deleted) {
            this.deletedOptions++;
          }
        }

        if (!option) {
          if (this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 29) {
            return false;
          }
          return true;
        } else if (option === 2) {
          if (this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 5) {
            return false;
          }
          return true;
        } else {
          if (this.formFields[index].sub_fields[sub_index].options.length - this.deletedOptions > 5) {
            return false;
          }
          return true;
        }
      } else return true;
    } else {
      this.deletedOptions = 0;
      if (this.formFields[index].options.length > 0) {
        for (const i in this.formFields[index].options) {
          if (this.formFields[index].options[i].is_deleted) {
            this.deletedOptions++;
          }
        }

        if (!option) {
          if (this.formFields[index].options.length - this.deletedOptions > 29) {
            return false;
          }
          return true;
        } else if (option === 1) {
          if (this.formFields[index].options.length - this.deletedOptions > 5) {
            return false;
          }
          return true;
        } else {
          if (this.formFields[index].options.length - this.deletedOptions > 5) {
            return false;
          }
          return true;
        }
      } else return true;
    }
  }

  changeOptionBeforeCreation(event: any, id: string): void {
    const o_index = this.fieldOptions.findIndex((x) => x.id === id);
    this.fieldOptions[o_index].option = event.target.value;
  }

  deleteOptionBeforeCreation(id: string): void {
    const o_index = this.fieldOptions.findIndex((x) => x.id === id);
    if (this.fieldOptions.length == 1 && this.selectedFieldType != 7) {
      this.fieldOptions[o_index].option = '';
      this.toastr.info('Minimum of one Option/Header is required.');
    } else {
      this.fieldOptions = this.fieldOptions.filter((option) => option.id != id);
    }
  }

  addOption(): void {
    if (this.selectedFieldType === 7 && !this.fieldOptions.length) {
      this.fieldOptions.push({
        id: uuid(),
        option: '',
        sequence_num: 1,
        is_deleted: 0,
      });
    } else {
      const index = this.fieldOptions.length - 1;

      if (this.fieldOptions[index].option) {
        this.fieldOptions.push({
          id: uuid(),
          option: '',
          sequence_num: this.fieldOptions[index].sequence_num + 1,
          is_deleted: 0,
        });
      } else {
        if (this.selectedFieldType === 8) {
          this.toastr.info('Please enter a Header Name first.');
        } else if (this.selectedFieldType === 7) {
          this.toastr.info('Please enter a Bullet Point first.');
        } else {
          this.toastr.info('Please enter an Option first.');
        }
      }
    }

    return;
  }

  addField(): void {
    for (const i in this.fieldOptions) {
      if (!this.fieldOptions[i].option) {
        this.optionEmpty = 1;
      }
    }
    if (!this.description) {
      this.toastr.info('Please enter a Question first.');
      return;
    }

    if (
      this.optionEmpty &&
      (this.selectedFieldType === 1 ||
        this.selectedFieldType === 5 ||
        this.selectedFieldType === 7 ||
        this.selectedFieldType === 8 ||
        this.selectedFieldType === 9)
    ) {
      this.optionEmpty = 0;
      if (this.selectedFieldType != 8 && this.selectedFieldType != 7) {
        this.toastr.info('Please fill out all the option fields.');
      } else if (this.selectedFieldType === 7) {
        this.toastr.info('Please fill out all the bullet point fields.');
      } else {
        this.toastr.info('Please fill out all the header name fields.');
      }
      return;
    }

    if (this.formFields.length > 0) {
      this.sequenceNum = this.formFields[this.formFields.length - 1].sequence_num + 1;
    }

    if (this.formFields.length > 0) {
      this.sequenceNum = this.formFields[this.formFields.length - 1].sequence_num + 1;
    }

    if (this.selectedFieldType === 9) {
      this.followUpQuestions.push({
        id: uuid(),
        description: '',
        sequence_num: 1,
        field_type: 3,
        options: [],
        style: this.defaultStyle,
        is_deleted: 0,
        is_mapped: 0,
      });
    }

    this.formFields.push({
      id: uuid(),
      description: this.description,
      sequence_num: this.sequenceNum,
      field_type: this.selectedFieldType,
      options:
        this.selectedFieldType === 1 ||
        this.selectedFieldType === 5 ||
        this.selectedFieldType === 7 ||
        this.selectedFieldType === 8 ||
        this.selectedFieldType === 9
          ? this.fieldOptions
          : [],
      sub_fields: this.selectedFieldType === 9 ? this.followUpQuestions : [],
      style: this.defaultStyle,
      is_deleted: 0,
      is_mapped: 0,
    });

    this.fieldOptions = [];
    this.followUpQuestions = [];

    if (this.selectedFieldType === 9) {
      this.fieldOptions.push({
        id: uuid(),
        option: 'Yes',
        sequence_num: this.fieldOptions.length + 1,
        is_deleted: 0,
      });
      this.fieldOptions.push({
        id: uuid(),
        option: 'No',
        sequence_num: this.fieldOptions.length + 1,
        is_deleted: 0,
      });
    } else {
      this.fieldOptions.push({
        id: uuid(),
        option: '',
        sequence_num: this.fieldOptions.length + 1,
        is_deleted: 0,
      });
    }

    this.description = '';
    this.sequenceNum = 1;
  }

  restoreField(id: string): void {
    const index = this.formFields.findIndex((field) => field.id === id);
    this.formFields[index].is_deleted = 0;
    this.deletedFields--;
  }

  /* Opt 1 is for Form Creation, Opt 2 is for Form Updation */
  deleteField(id: string, opt: number, sub_id?: string): void {
    const index = this.formFields.findIndex((x) => x.id == id);
    this.deletedSubFields = 0;
    this.formFields[index].sub_fields.forEach((field) => {
      if (field.is_deleted) this.deletedSubFields++;
    });

    if (opt == 1) {
      if (sub_id) {
        if (this.formFields[index].sub_fields.length - this.deletedSubFields <= 1) {
          this.toastr.info('Minimum of one follow up question is required.');
          return;
        }
        this.formFields[index].sub_fields = this.formFields[index].sub_fields.filter((field) => field.id != sub_id);
        this.toastr.success('Field Deleted Successfully.');
      } else {
        this.formFields = this.formFields.filter((field) => field.id != id);
        this.toastr.success('Field Deleted Successfully.');
      }
    } else if (opt == 2) {
      if (sub_id) {
        const sub_index = this.formFields[index].sub_fields.findIndex((x) => x.id == sub_id);
        if (this.formFields[index].sub_fields.length - this.deletedSubFields <= 1) {
          this.toastr.info('Minimum of one follow up question is required.');
          return;
        } else {
          this.formFields[index].sub_fields[sub_index].is_deleted = 1;
        }
      } else {
        if (this.formFields.length - this.deletedFields <= 1) {
          Swal.fire({
            icon: 'warning',
            text: 'Are you sure you want to delete the form entirely?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            confirmButtonColor: 'red',
          }).then((result) => {
            if (result.isConfirmed) {
              this.spinnerService.show();
              this.deletedFields = 0;
              this.formService
                .deleteForm(this.assistantInfo.client_id, this.editForm.id)
                .then(() => {
                  this.spinnerService.hide();
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'The Form was deleted Successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.router.navigate(['dashboard/forms']);
                })
                .catch((err) => {
                  this.spinnerService.hide();
                  Swal.fire({
                    icon: 'error',
                    title: 'Unsuccessful!',
                    text: 'The Form deletion process has failed.',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
            }
          });
        } else {
          this.formFields[index].is_deleted = 1;
          this.deletedFields++;
        }
      }
    }
    return;
  }

  /* Opt 1 is for Form Creation, Opt 2 is for Form Updation */
  deleteOption(field_id: string, option_id: string, opt: number, sub_field_id?: string): void {
    const f_index = this.formFields.findIndex((x) => x.id === field_id);
    let o_index = this.formFields[f_index].options.findIndex((x) => x.id === option_id);
    const o_last = this.formFields[f_index].options.length - 1;

    if (opt == 1) {
      /* For Sub questions */
      if (sub_field_id) {
        const sub_f_index = this.formFields[f_index].sub_fields.findIndex((x) => x.id === sub_field_id);
        o_index = this.formFields[f_index].sub_fields[sub_f_index].options.findIndex((x) => x.id === option_id);

        if (
          this.formFields[f_index].sub_fields[sub_f_index].options.length == 1 &&
          this.formFields[f_index].sub_fields[sub_f_index].field_type != 7
        ) {
          this.formFields[f_index].sub_fields[sub_f_index].options[o_index].option = '';
          this.toastr.info('Minimum of one Option/Header is required.');
        } else {
          this.formFields[f_index].sub_fields[sub_f_index].options = this.formFields[f_index].sub_fields[
            sub_f_index
          ].options.filter((field) => field.id != option_id);
          this.toastr.success('Option/Header/Bullet deleted successfully.');
        }
      } else {
        /* For Questions */
        if (this.formFields[f_index].options.length == 1 && this.formFields[f_index].field_type != 7) {
          this.formFields[f_index].options[o_index].option = '';
          this.toastr.info('Minimum of one Option/Header is required.');
        } else {
          this.formFields[f_index].options = this.formFields[f_index].options.filter((field) => field.id != option_id);
          this.toastr.success('Option/Header/Bullet deleted successfully.');
        }
      }
    }

    if (opt == 2) {
      /* For Sub questions */
      if (sub_field_id) {
        const sub_f_index = this.formFields[f_index].sub_fields.findIndex((x) => x.id === sub_field_id);
        o_index = this.formFields[f_index].sub_fields[sub_f_index].options.findIndex((x) => x.id === option_id);
        this.deletedOptions = 0;
        if (this.formFields[f_index].sub_fields[sub_f_index].options.length > 1) {
          for (const i in this.formFields[f_index].sub_fields[sub_f_index].options) {
            if (this.formFields[f_index].sub_fields[sub_f_index].options[i].is_deleted === 1) {
              this.deletedOptions++;
            }
          }
        }
        if (
          this.formFields[f_index].sub_fields[sub_f_index].options.length - this.deletedOptions === 1 &&
          this.formFields[f_index].sub_fields[sub_f_index].field_type != 7
        ) {
          this.formFields[f_index].sub_fields[sub_f_index].options[o_index].option = '';
          this.toastr.info('Minimum of one Option/Header is required.');
        } else {
          this.formFields[f_index].sub_fields[sub_f_index].options[o_index].is_deleted = 1;
        }
      } else {
        /* For Questions */
        this.deletedOptions = 0;
        if (this.formFields[f_index].options.length > 1) {
          for (const i in this.formFields[f_index].options) {
            if (this.formFields[f_index].options[i].is_deleted === 1) {
              this.deletedOptions++;
            }
          }
        }
        if (
          this.formFields[f_index].options.length - this.deletedOptions === 1 &&
          this.formFields[f_index].field_type != 7
        ) {
          this.formFields[f_index].options[o_index].option = '';
          this.toastr.info('Minimum of one Option/Header is required.');
        } else {
          this.formFields[f_index].options[o_index].is_deleted = 1;
        }
      }
    }

    return;
  }

  navBack(): void {
    if (this.formTitle || this.formFields.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'The changes you made to the Form could be lost.',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        confirmButtonColor: 'red',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['dashboard/forms']);
        }
      });
    } else {
      this.router.navigate(['dashboard/forms']);
    }
  }

  prompt(): void {
    Swal.fire({
      icon: 'info',
      title: 'Confirmation',
      text: "You didn't save the last question you entered, are you sure you want to proceed?",
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      confirmButtonColor: 'blue',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.editForm) this.updateForm();
        else this.createForm();
      }
    });
  }

  createForm(): void {
    if (!this.formTitle) {
      this.toastr.info('Please Enter the name for the new Form.');
      return;
    }

    if (this.formFields.length < 1) {
      this.toastr.info('Please enter a Question first.');
      return;
    }

    for (const i in this.formFields) {
      if (this.formFields[i].description === '') {
        this.toastr.info('Please do not leave any questions empty.');
        return;
      }
      for (const o in this.formFields[i].options) {
        if (this.formFields[i].options[o].option === '') {
          this.toastr.info('Please do not leave any options/headers/bullets empty.');
          return;
        }
      }
      for (const k in this.formFields[i].sub_fields) {
        if (this.formFields[i].sub_fields[k].description === '') {
          this.toastr.info('Please do not leave any questions empty.');
          return;
        }
        for (const l in this.formFields[i].sub_fields[k].options) {
          if (this.formFields[i].sub_fields[k].options[l].option === '') {
            this.toastr.info('Please do not leave any options/headers/bullets empty.');
            return;
          }
        }
      }
    }

    this.sequenceNum = 0;
    this.formFields.forEach((field) => {
      if (!field.is_deleted) field.sequence_num = ++this.sequenceNum;
    });

    const data = {
      form_title: this.formTitle,
      show_patient_data: this.show_patient_data,
      fields: this.formFields,
      is_mapped: this.mappedCount > 0 ? 1 : 0,
    };
    this.spinnerService.show();
    this.formService
      .createClientForm(this.assistantInfo.practiceId, this.assistantInfo.client_id, data)
      .then(() => {
        this.toastr.success('Form Created Successfully');
        this.router.navigate(['dashboard/forms']);
      })
      .catch(() => this.toastr.error('Something went wrong. Please try again'))
      .finally(() => this.spinnerService.hide());
  }

  updateForm(): void {
    if (!this.formTitle) {
      this.toastr.info('Please Enter the name for the updated Form.');
      return;
    }

    if (this.formFields.length < 1) {
      this.toastr.info('Please enter a question first.');
      return;
    }

    for (const i in this.formFields) {
      if (this.formFields[i].description == '' && !this.formFields[i].is_deleted) {
        this.toastr.info('Please do not leave any questions empty.');
        return;
      }
      for (const o in this.formFields[i].options) {
        if (this.formFields[i].options[o].option == '' && !this.formFields[i].options[o].is_deleted) {
          this.toastr.info('Please do not leave any options/headers/bullets empty.');
          return;
        }
      }
      for (const k in this.formFields[i].sub_fields) {
        if (this.formFields[i].sub_fields[k].description === '' && !this.formFields[i].sub_fields[k].is_deleted) {
          this.toastr.info('Please do not leave any questions empty.');
          return;
        }
        for (const l in this.formFields[i].sub_fields[k].options) {
          if (
            this.formFields[i].sub_fields[k].options[l].option === '' &&
            !this.formFields[i].sub_fields[k].options[l].is_deleted
          ) {
            this.toastr.info('Please do not leave any options/headers/bullets empty.');
            return;
          }
        }
      }
    }

    this.sequenceNum = 0;
    this.formFields.forEach((field) => {
      if (!field.is_deleted) field.sequence_num = ++this.sequenceNum;
    });
    const data = {
      form_title: this.formTitle,
      show_patient_data: +this.show_patient_data,
      fields: this.formFields,
      is_mapped: this.formFields.findIndex((field) => field.is_mapped) >= 0 ? 1 : 0,
    };
    this.spinnerService.show();
    this.formService
      .updateForm(this.assistantInfo.practiceId, this.assistantInfo.client_id, this.editForm.id, data)
      .then(() => {
        this.toastr.success('Form Updated Successfully');
        this.router.navigate(['dashboard/forms']);
      })
      .catch(() => this.toastr.error('Something went wrong. Please try again'))
      .finally(() => this.spinnerService.hide());
  }
}
