<div class="modal-header">
  <h4 class="modal-title">Available PDFs</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body scrollable-modal">
  <div class="table-data__wrapper table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Insurance</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of pdfList"
            (click)="onPdfClick(item)"
            [class.selected]="item === selectedPdf"
            [class.hover]="item === hoveredPdf"
            (mouseenter)="hoveredPdf = item"
            (mouseleave)="hoveredPdf = null">
          <td>{{ item.file_name || "None specified" }}</td>
          <td>{{ item.insurance_name || "None specified" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
</div>