import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-messages-response-modal',
  templateUrl: './messages-response-modal.component.html',
  styleUrls: ['./messages-response-modal.component.css'],
})
export class MessagesResponseModalComponent implements OnInit {
  @Input() type: string;
  @ViewChild('message') message: ElementRef<HTMLTextAreaElement>;
  @ViewChild('selectedField') selectedField: ElementRef<HTMLTextAreaElement>;

  selectedFieldFocused: boolean = false;
  messageFieldFocused: boolean = false;
  csvHeaders: string[] = [];
  selectedFile: File;
  totalRows: number = 0;
  csvData: string;
  csvRows: string[][] = [];
  showMessageBox: boolean = false;

  constructor(
    private spinnerService: NgxSpinnerService,
    private messageService: MessageService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  downloadCsvTemplate(): void {
    const headingsString = 'Name,first_name,last_name,cell_phone,rendering,description,V1,V2,V3,V4,V5';
    const headings = headingsString.split(',');
    const csvContent = headings.join(',') + '\n';
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'template.csv';
    link.click();
  }

  uploadCsvFile(): void {
    let fileChooserAcceptType = '.csv';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.readAsText(this.selectedFile);
        reader.onload = (event) => {
          this.csvData = event.target.result as string;
          this.parseCsvData(this.csvData);
          this.displayCsvHeaders();
        };
      }
    });
    fileChooser.click();
  }
  parseCsvData(csvData: string): void {
    const rows = csvData.split('\n');
    this.csvRows = rows.map((row) => {
      if (row.trim() !== '') {
        const cells = [];
        let currentCell = '';
        let insideQuotes = false;

        for (let i = 0; i < row.length; i++) {
          const char = row[i];
          if (char === ',' && !insideQuotes) {
            cells.push(currentCell.trim());
            currentCell = '';
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentCell += char;
          }
        }
        cells.push(currentCell.trim());
        return cells;
      }
    });
    this.csvRows = this.csvRows.filter((row) => !!row);
    this.totalRows = this.csvRows.length - 1;
  }

  onSelectedFieldFocus(): void {
    this.selectedFieldFocused = true;
    this.messageFieldFocused = false;
  }

  onMessageFieldFocus(): void {
    this.messageFieldFocused = true;
    this.selectedFieldFocused = false;
  }

  displayCsvHeaders(): void {
    if (this.csvRows.length > 0) {
      this.csvHeaders = this.csvRows[0];
    }
  }
  insertSelectedHeader(header: string): void {
    if (this.selectedFieldFocused) {
      if (this.selectedField.nativeElement.value.trim().length > 0) {
        this.selectedField.nativeElement.value += `{${header}} `;
      } else {
        this.selectedField.nativeElement.value = `{${header}} `;
      }
  
      const selectedFieldIndex = this.csvHeaders.indexOf(header);
      if (selectedFieldIndex !== -1) {
        const selectedFieldValues = this.csvRows.slice(1, 6).map((row) => row[selectedFieldIndex]);
        const cleanPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '');
        const isNumeric = (str: string) => /^\d+$/.test(str);
        const hasTenCharacters = (str: string) => str.length === 10;
        const isValidUSPhoneNumber = (str: string) => /^(?:\+1)?\d{10}$/.test(str);
        const isValidInternationalPhoneNumber = (str: string) => /^\+\d{11,15}$/.test(str);
  
        const containsValidPhoneNumbers = selectedFieldValues.some((value) => {
          const cleanedValue = cleanPhoneNumber(value);
          const rawValue = value.trim();
          return (
            (isNumeric(cleanedValue) && hasTenCharacters(cleanedValue)) ||
            isValidUSPhoneNumber(cleanedValue) ||
            isValidInternationalPhoneNumber(rawValue)
          );
        });
  
        if (!containsValidPhoneNumbers) {
          this.toastr.warning(`The column you selected doesn't have valid phone numbers.`);
          this.selectedField.nativeElement.value = '';
          return;
        }
  
        if (header && header.trim() !== '') {
          this.showMessageBox = true;
        }
      }
    } else if (this.messageFieldFocused) {
      this.message.nativeElement.value += `{${header}} `;
    }
  }
  

  sendMessage(): void {
    if (!this.selectedField || !this.message) {
      this.toastr.error('SelectedField or message is not available.');
      return;
    }
    let messageBody = this.message.nativeElement.value;

    let selectedFieldName = this.selectedField.nativeElement.value.trim();
    selectedFieldName = selectedFieldName.replace(/{|}/g, '');

    if (!selectedFieldName || !messageBody.trim()) {
      this.toastr.error('Please select fields and enter a message before sending.');
      return;
    }
    const selectedFieldIndex = this.csvHeaders.indexOf(selectedFieldName);

    if (!this.selectedFile) {
      this.toastr.error('Please upload a CSV file.');
      return;
    }
    if (selectedFieldIndex === -1) {
      this.toastr.error('Selected field not found in CSV headers.');
      return;
    }

    if (selectedFieldIndex >= this.csvRows[0].length) {
      this.toastr.error('Selected field index is out of bounds.');
      return;
    }

    Swal.fire({
      title: 'Sending Messages',
      text: `Are you sure you want to send messages to ${this.totalRows} recipients?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: ' Send ',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.messageService.uploadMessagesCsv(this.selectedFile, messageBody, selectedFieldName).subscribe(
          (resp) => {
            this.spinnerService.hide();
            this.toastr.success(`${this.totalRows} messages queued.`);
            this.activeModal.close();
          },
          (error) => {
            this.spinnerService.hide();
            this.toastr.error(error);
          }
        );
      }
    });
  }
}
