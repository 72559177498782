import { FirebaseService } from './firebase.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';
import { Assistant, UnlockCode } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UnlockCodesService {
  constructor(
    private db: AngularFireDatabase,
    private firebaseService: FirebaseService
  ) {}

  getUnlockCodes(practiceId: string): any {
    return this.db
      .list(`/vsa-clients/${practiceId.toLowerCase()}/unlockCodes/`)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }))
        )
      );
  }

  uploadUnlockCode(practiceId: string, objToUpload: any): any {
    const user = JSON.parse(localStorage.getItem('user'));

    const basePath = `/vsa-clients/${practiceId.toLowerCase()}/unlockCodes/`;
    return this.db.database
      .ref()
      .child(basePath)
      .push(objToUpload)
      .then(() => {
        this.firebaseService.showConfirmationDialog(
          'Unlock Code Added',
          'success'
        );
      })
      .catch((err) => {
        this.firebaseService.showConfirmationDialog(
          "Unlock Lock couln't be updated",
          'error'
        );
      });
  }

  deleteUnlockCode(record: UnlockCode, assistantInfo: Assistant): any {
    return this.db.database
      .ref()
      .child(
        `/vsa-clients/${assistantInfo.practiceId.toLowerCase()}/unlockCodes/${record.Key}`
      )
      .remove();
  }
}
