import { NavbarComponentModel } from '../models';

export const NAVBAR_COMPONENTS: NavbarComponentModel[] = [
  {
    title: 'Dashboard',
    activePath: '/dashboard',
    svgSrc: '/assets/icons/dashboard.svg',
    activeSvgSrc: '/assets/icons/dashboard-active.svg',
  },
  {
    title: 'Unlock Codes',
    activePath: '/dashboard/unlockcodes',
    svgSrc: '/assets/icons/alerts.svg',
    activeSvgSrc: '/assets/icons/alerts-active.svg',
  },
  {
    title: 'Activity Logs',
    activePath: '/dashboard/activity',
    svgSrc: '/assets/icons/daily-screening.svg',
    activeSvgSrc: '/assets/icons/daily-screening-active.svg',
  },
  {
    title: 'User Management',
    activePath: '/dashboard/users',
    svgSrc: '/assets/icons/user-management.svg',
    activeSvgSrc: '/assets/icons/user-management-active.svg',
  },
  {
    title: 'Video Dictation',
    activePath: '/dashboard/video-dictation',
    svgSrc: '/assets/icons/video-dictation.svg',
    activeSvgSrc: '/assets/icons/video-dictation-active.svg',
  },
  {
    title: 'Audio Dictation',
    activePath: '/dashboard/audio-dictation',
    svgSrc: '/assets/icons/dictation.svg',
    activeSvgSrc: '/assets/icons/dictation-active.svg',
  },
  {
    title: 'Content Management',
    activePath: '/dashboard/content',
    svgSrc: '/assets/icons/content-management.svg',
    activeSvgSrc: '/assets/icons/content-management-active.svg',
  },
  {
    title: 'Order Management',
    activePath: '/dashboard/orders',
    svgSrc: '/assets/icons/order-management.svg',
    activeSvgSrc: '/assets/icons/order-management-active.svg',
  },
  // {
  //   title: 'Documents',
  //   activePath: '/dashboard/documents',
  //   svgSrc: '/assets/icons/documents.svg',
  //   activeSvgSrc: '/assets/icons/documents-active.svg',
  // },
  {
    title: 'Forms',
    activePath: '/dashboard/forms',
    svgSrc: '/assets/icons/forms.svg',
    activeSvgSrc: '/assets/icons/forms-active.svg',
  },
  {
    title: 'Messages',
    activePath: '/dashboard/messages',
    svgSrc: '/assets/icons/messages.svg',
    activeSvgSrc: '/assets/icons/messages-active.svg',
  },
  {
    title: 'Statements',
    activePath: '/dashboard/statements',
    svgSrc: '/assets/icons/statements.svg',
    activeSvgSrc: '/assets/icons/statements-active.svg',
  },
  {
    title: 'Payments',
    activePath: '/dashboard/payments',
    svgSrc: '/assets/icons/payments.svg',
    activeSvgSrc: '/assets/icons/payments-active.svg',
  },
  {
    title: 'Payer Benefits',
    activePath: '/dashboard/payer-benefits',
    svgSrc: '/assets/icons/benefits.svg',
    activeSvgSrc: '/assets/icons/benefits-active.svg',
  },

  {
    title: 'Transcriptions',
    activePath: '/dashboard/transcriptions',
    svgSrc: '/assets/icons/transcription.svg',
    activeSvgSrc: '/assets/icons/transcription-active.svg',
  },

  {
    title: 'Insurance Forms',
    activePath: '/dashboard/storage-location',
    svgSrc: '/assets/icons/documents.svg',
    activeSvgSrc: '/assets/icons/documents-active.svg',
  },

  {
    title: 'Appointments Report',
    activePath: '/dashboard/appointment-reports',
    svgSrc: '/assets/icons/documents.svg',
    activeSvgSrc: '/assets/icons/documents-active.svg',
  },
  {
    title: 'SMS Scripts',
    activePath: '/dashboard/sms-scripts',
    svgSrc: '/assets/icons/sms-scripts.svg',
    activeSvgSrc: '/assets/icons/sms-scripts-active.svg',
  },
  // {
  //   title: 'SMS Billing',
  //   activePath: '/dashboard/sms-billing',
  //   svgSrc: '/assets/icons/sms-billing.svg',
  //   activeSvgSrc: '/assets/icons/sms-billing-active.svg',
  // },
  {
    title: 'Branding',
    activePath: '/dashboard/branding',
    svgSrc: '/assets/icons/branding.svg',
    activeSvgSrc: '/assets/icons/brandingActive.svg',
  },
];
