import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GET_ALL_PAYMENTS_RESP } from '../models/payments';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  user = JSON.parse(localStorage.getItem('user'));

  constructor(private http: HttpClient) {}

  async getAllPayments(
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    is_archived: boolean,
    startDate?: string,
    endDate?: string
  ): Promise<GET_ALL_PAYMENTS_RESP> {
    return this.http
      .get<GET_ALL_PAYMENTS_RESP>(`${environment.PAYMENTS}/practice/${practice_id}`, {
        params: {
          is_archived: `${!!is_archived}`,
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          search,
          searchCriteria,
          startDate: startDate || '',
          endDate: endDate || '',
        },
      })
      .toPromise();
  }

  async getAllPaidPayments(
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    is_archived: boolean,
    auto_pay: boolean,
    startDate?: string,
    endDate?: string
  ): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .get<any>(`${environment.STATEMENTS}/paid-payments/`, {
        headers: {
          Authorization: `${user.stsTokenManager.accessToken}`,
        },
        params: {
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          is_archived: `${is_archived}`,
          search,
          searchCriteria,
          auto_pay: `${auto_pay}`,
          startDate: startDate || '',
          endDate: endDate || '',
        },
      })
      .toPromise();
  }

  async archivePaymentReport(benefit_id: string, is_archived: boolean): Promise<any> {
    return this.http
      .put<any>(`${environment.BASE_URL}/order-benefits/${benefit_id}/archive/${is_archived}`, null, {
        headers: {
          Authorization: `${this.user.stsTokenManager.accessToken}`,
        },
      })
      .toPromise();
  }

  async archivePaidPaymentReport(id: string, is_archived: boolean): Promise<any> {
    return this.http
      .put<any>(`${environment.BASE_URL}/payment/${id}/archive/${is_archived}`, null, {
        headers: {
          Authorization: `${this.user.stsTokenManager.accessToken}`,
        },
      })
      .toPromise();
  }

  async exportPaymentsToCsv(
    category: string,
    is_archived: boolean,
    auto_pay: boolean,
    is_orders_archived: boolean,
    startDate?: string,
    endDate?: string
  ) {
    let url;
    if (category === 'statements') url = `${environment.BASE_URL}/statement/paid-payments/csv`;
    else if (category === 'orders') url = `${environment.BASE_URL}/payment/paid-payments/csv`;
    else return;

    return this.http
      .get(url, {
        headers: {
          Authorization: `${this.user.stsTokenManager.accessToken}`,
        },
        responseType: 'blob',
        params: {
          is_archived: `${category === 'orders' ? is_orders_archived : is_archived}`,
          auto_pay: `${auto_pay}`,
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
        },
      })
      .toPromise();
  }
}
