<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ altTitle || "Add User" }}</h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addFormGroup" (ngSubmit)="!inviteForm ? saveUser() : inviteUser()">
  <div class="modal-body">
    <div *ngIf="!inviteForm && !User">
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Email:</div>
        <div class="col-md-4 pr-0">
          <input type="email" class="form-control" id="email" formControlName="email" />
          <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            <small class="text-danger" *ngIf="email?.errors?.required">Email address is required.</small>
            <small class="text-danger" *ngIf="email?.errors?.email">Please enter a valid email address.</small>
          </div>
        </div>

        <div class="col-md-2 mt-1 text-right">Password:</div>
        <div class="col-md-4 pl-0">
          <input type="password" class="form-control" id="password" formControlName="password" />
          <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
            <small class="text-danger" *ngIf="password?.errors?.required">Password is required.</small>
            <small class="text-danger" *ngIf="password?.errors?.minlength"
              >Your password should atleast have 6 characters.</small
            >
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">First Name:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="firstName" formControlName="firstName" />
        </div>
        <div class="col-md-2 mt-1 text-right">Last Name:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="lastName" formControlName="lastName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Phone #:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="phoneNo" formControlName="phoneNo" />
        </div>
        <div class="col-md-2 mt-1 text-right">Fax #:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="faxNo" formControlName="faxNo" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Address 1:</div>
        <div class="col-md-10">
          <input type="text" class="form-control" id="address1" formControlName="address1" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Address 2:</div>
        <div class="col-md-10">
          <input type="text" class="form-control" id="address2" formControlName="address2" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">City:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="city" formControlName="city" />
        </div>
        <div class="col-md-2 mt-1 text-right">State:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="state" formControlName="state" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Zip Code:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="zipCode" formControlName="zipCode" />
        </div>
        <div *ngIf="this.userRole" class="col-md-2 mt-1 text-right">User Role:</div>
        <div
          *ngIf="
            this.userRole === 'practice_admin_sante_community' ||
            this.userRole === 'practice_admin_sante_community_order'
          "
          class="col-md-4 pl-0"
        >
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_admin_sante_community">Office Admin</option>
            <option value="practice_user_sante_community">Office User</option>
            <option value="practice_admin_sante_community_order">Office Admin With Order</option>
            <option value="practice_user_sante_community_order">Office User With Order</option>
          </select>
          <div *ngIf="selectRole?.dirty || selectRole?.touched">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
        <div *ngIf="this.userRole === 'practice_admin'" class="col-md-4 pl-0">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_benefits">Practice Benefits</option>
            <option value="practice_scribe">Practice Scribe</option>
            <option value="practice_general_user">Practice General User</option>
            <option value="practice_message_user">Practice Message User</option>
            <option value="practice_message_admin">Practice Message Admin</option>
            <option value="practice_admin">Practice Admin</option>

          </select>
          <div *ngIf="selectRole?.invalid && (selectRole?.dirty || selectRole?.touched)">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
        <div *ngIf="this.userRole === 'practice_message_admin'" class="col-md-4 pl-0">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_message_user">Practice Message User</option>
            <option value="practice_message_admin">Practice Message Admin</option>
          </select>
          <div *ngIf="selectRole?.invalid && (selectRole?.dirty || selectRole?.touched)">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="inviteForm && !User">
      <div class="form-group">
        <label for="userEmail">Email Address:</label>
        <input
          type="email"
          class="form-control"
          autocomplete="off"
          id="email"
          aria-describedby="email"
          placeholder="Enter Email Address here"
          formControlName="email"
        />
        <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
          <small class="text-danger" *ngIf="email?.errors?.required">Email address is required.</small>
          <small class="text-danger" *ngIf="email?.errors?.email">Please enter a valid email address.</small>
        </div>
        <div *ngIf="this.userRole" class="">User Role:</div>
        <div *ngIf="this.userRole === 'location_admin'">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="location_admin">Location Admin</option>
            <option value="location_user">Location User</option>
          </select>
        </div>
        <div *ngIf="selectRole?.dirty || selectRole?.touched">
          <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole address is required.</small>
        </div>
      </div>
    </div>
    <div *ngIf="User">
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Email:</div>
        <div class="col-md-4 pr-0">
          <input type="email" class="form-control" id="email" formControlName="email" (value)="(User.email)" />
          <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            <small class="text-danger" *ngIf="email?.errors?.required">Email address is required.</small>
            <small class="text-danger" *ngIf="email?.errors?.email">Please enter a valid email address.</small>
          </div>
        </div>

        <div class="col-md-2 mt-1 text-right">Password:</div>
        <div class="col-md-4 pl-0">
          <input type="password" class="form-control" id="password" formControlName="password" />
          <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
            <small class="text-danger" *ngIf="password?.errors?.required">Password is required.</small>
            <small class="text-danger" *ngIf="password?.errors?.minlength"
              >Your password should atleast have 6 characters.</small
            >
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">First Name:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="firstName" formControlName="firstName" />
        </div>
        <div class="col-md-2 mt-1 text-right">Last Name:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="lastName" formControlName="lastName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Phone #:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="phoneNo" formControlName="phoneNo" />
        </div>
        <div class="col-md-2 mt-1 text-right">Fax #:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="faxNo" formControlName="faxNo" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Address 1:</div>
        <div class="col-md-10">
          <input type="text" class="form-control" id="address1" formControlName="address1" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Address 2:</div>
        <div class="col-md-10">
          <input type="text" class="form-control" id="address2" formControlName="address2" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">City:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="city" formControlName="city" />
        </div>
        <div class="col-md-2 mt-1 text-right">State:</div>
        <div class="col-md-4 pl-0">
          <input type="text" class="form-control" id="state" formControlName="state" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 mt-1 text-right">Zip Code:</div>
        <div class="col-md-4 pr-0">
          <input type="text" class="form-control" id="zipCode" formControlName="zipCode" />
        </div>
        <div *ngIf="this.userRole" class="col-md-2 mt-1 text-right">User Role:</div>
        <div
          *ngIf="
            this.userRole === 'practice_admin_sante_community' ||
            this.userRole === 'practice_admin_sante_community_order'
          "
          class="col-md-4 pl-0"
        >
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_admin_sante_community">Office Admin</option>
            <option value="practice_user_sante_community">Office User</option>
            <option value="practice_admin_sante_community_order">Office Admin With Order</option>
            <option value="practice_user_sante_community_order">Office User With Order</option>
          </select>
          <div *ngIf="selectRole?.dirty || selectRole?.touched">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
        <!-- <div *ngIf="this.userRole === 'practice_sante_message_admin'" class="col-md-4 pl-0">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_sante_message_admin">Office Admin</option>
            <option value="practice_sante_message_user">Office User</option>
          </select>
          <div *ngIf="selectRole?.dirty || selectRole?.touched">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div> -->
        <div *ngIf="this.userRole === 'practice_admin'" class="col-md-4 pl-0">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_benefits">Practice Benefits</option>
            <option value="practice_scribe">Practice Scribe</option>
            <option value="practice_general_user">Practice General User</option>
            <option value="practice_message_user">Practice Message User</option>
            <option value="practice_message_admin">Practice Message Admin</option>
            <option value="practice_admin">Practice Admin</option>

          </select>
          <div *ngIf="selectRole?.invalid && (selectRole?.dirty || selectRole?.touched)">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
        <div *ngIf="this.userRole === 'practice_message_admin'" class="col-md-4 pl-0">
          <select class="form-control" id="selectRole" formControlName="selectRole">
            <option value="practice_message_user">Practice Message User</option>
            <option value="practice_message_admin">Practice Message Admin</option>
          </select>
          <div *ngIf="selectRole?.invalid && (selectRole?.dirty || selectRole?.touched)">
            <small class="text-danger" *ngIf="selectRole?.errors?.required">userRole is required.</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      [disabled]="!addFormGroup.valid"
      class="btn btn-primary"
      (click)="!User ? (!inviteForm ? saveUser() : inviteUser()) : UpdateUser()"
    >
      {{ altTitle || "Add User" }}<i class="fa fa-plus ml-2" aria-hidden="true"></i>
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Close</button>
  </div>
</form>
