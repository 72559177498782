import { AdminService } from './../shared/services/admin.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private router: Router,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  get f(): any {
    return this.loginForm.controls;
  }

  submit(): Promise<any> {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const loginCreds = {
      username: this.f.username.value,
      password: this.f.password.value,
    };
    this.spinnerService.show();
    this.adminService
      .validateLoginInfo(loginCreds)
      .then((data) => {
        this.spinnerService.hide();
        this.router.navigate(
          [
            'admin/practice/update-form/change',
            { adminData: JSON.stringify(data) },
          ],
          {
            skipLocationChange: true,
          }
        );
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error(err.error);
      });
  }
}
