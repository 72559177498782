import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from '../../services/pdf.service';
import { PDFViewerComponent } from '../pdfviewer/pdfviewer.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-authorization-forms',
  templateUrl: './authorization-forms.component.html',
  styleUrls: ['./authorization-forms.component.css'],
})
export class AuthorizationFormsComponent implements OnInit, AfterViewInit {
  @Input() pdfList: any[] = [];
  @Input() orderDetails: any;
  @Input() orderComments: any[];
  @Input() orderAppointments: any[];
  @Input() orderTasks: any[];
  @Input() orderSteps: any[];
  @Input() orderAttachments: any[];
  tableHeaders = ['File Name', 'Insurance'];
  selectedPdf: any;
  hoveredPdf:any = null;
  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private pdfService: PdfService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  onPdfClick(item: any): void {
    this.spinnerService.show();
    this.pdfService
    .sendPdfDetails(this.orderDetails.order_id, item.file_url).subscribe(
      (file) => {
        this.spinnerService.hide(); 
        const modalRef = this.modalService.open(PDFViewerComponent, {
            size: 'lg',
            centered: true
        });

        this.cdr.detectChanges();
        modalRef.componentInstance.orderDetails = this.orderDetails;
        modalRef.componentInstance.pdfBlob = file;
        this.activeModal.close();  
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
