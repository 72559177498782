import { NgxSpinnerService } from 'ngx-spinner';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  Assistant,
  FormDetails,
  FormGroupsResp,
  FormGroupsTableData,
  FormsTableData,
  GET_ALL_CLIENT_FORMS_RESP,
  GET_FORMS_GROUPS_RESP,
  GET_FORM_DETAILS_RESP,
} from 'src/app/shared/models';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormsService } from 'src/app/shared/services/forms.service';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { PreviewFormModalComponent } from 'src/app/shared/components/preview-form-modal/preview-form-modal.component';
import { FormResponseModalComponent } from 'src/app/shared/components/form-response-modal/form-response-modal.component';
import { FormRulesModalComponent } from 'src/app/shared/components/form-rules-modal/form-rules-modal.component';
import { FormGroupsModalComponent } from 'src/app/shared/components/form-groups-modal/form-groups-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  listTableHeaders = ['id', 'title', 'total_questions', 'group', 'created_at'];
  groupTableHeaders = ['id', 'title', 'total_forms', 'created_at'];
  formsTableData: FormsTableData[];
  formGroupsTableData: FormGroupsTableData[];
  formDetails: FormDetails[];
  assistantInfo: Assistant = null;
  formGroups = false;
  groupsUpdated = false;
  noData = false;
  currentPage: number = 1;
  totalPages: number = 0;
  userRole: string;
  @ViewChild('attachmentSwitch', { static: false }) attachmentSwitch: MatSlideToggle;

  @HostListener('document:keydown.shift.arrowright')
  next() {
    if (this.currentPage < this.totalPages) this.currentPage += 1;
    else this.toastr.info('No next page available.');
  }
  @HostListener('document:keydown.shift.control.arrowright')
  end() {
    if (this.currentPage != this.totalPages) this.currentPage = this.totalPages;
    else this.toastr.info('Already on the last page.');
  }
  @HostListener('document:keydown.shift.arrowleft')
  prev() {
    if (this.currentPage > 1) this.currentPage -= 1;
    else this.toastr.info('No previous page available.');
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  start() {
    if (this.currentPage != 1) this.currentPage = 1;
    else this.toastr.info('Already on the first page.');
  }
  @HostListener('document:keydown.shift.f')
  create() {
    this.createForm();
  }
  @HostListener('document:keydown.shift.g')
  createGroup() {
    this.createFormGroup();
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private formService: FormsService,
    private toastr: ToastrService,
    private firebaseService: FirebaseService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);

    this.spinnerService.show();
    this.formService
      .getAllClientForms(this.assistantInfo.practiceId, this.assistantInfo.client_id)
      .then((resp: GET_ALL_CLIENT_FORMS_RESP) => {
        this.formsTableData = resp.data.map((form, index: number) => ({
          id: ++index,
          form_id: form.id,
          client_id: form.client_id,
          group_id: form.group_id,
          title: form.title,
          group: form.group?.description,
          total_questions: form.num_questions,
          created_at: DateTime.fromISO(form.created_at).toLocaleString(DateTime.DATE_SHORT),
        }));

        if (this.formsTableData.length === 0) {
          this.noData = true;
        }
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        this.noData = false;
      })
      .finally(() => this.spinnerService.hide());
  }

  setTotalPages(pages): void {
    this.totalPages = pages;
  }

  onChangeSwitch(): void {
    if (this.attachmentSwitch.checked) {
      this.formGroups = true;
      this.currentPage = 1;
      if (!this.formGroupsTableData || this.groupsUpdated) {
        this.groupsUpdated = false;
        this.getFormGroups();
      }
    } else {
      if (!this.formsTableData) this.ngOnInit();
      this.currentPage = 1;
      this.formGroups = false;
    }
  }

  createForm(): void {
    this.router.navigate(['dashboard/forms/create-form']);
  }

  createFormGroup(): void {
    Swal.fire({
      title: 'Form Group Creation',
      text: 'Please enter name for the Form Group.',
      icon: 'info',
      input: 'text',
      inputPlaceholder: `Enter Name Here`,
      showCancelButton: true,
      confirmButtonColor: '#073786',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Create',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        const data = {
          description: result.value,
        };
        this.formService
          .createFormGroup(this.assistantInfo.client_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Created!',
              text: 'The Form Group was created Successfully.',
              showConfirmButton: false,
              timer: 2000,
            }).finally(() => {
              if (this.attachmentSwitch.checked) this.getFormGroups();
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'Form Group creation has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  }

  getFormGroups(): void {
    this.spinnerService.show();
    this.formService
      .getAllFormGroups(this.assistantInfo.client_id)
      .then((resp: GET_FORMS_GROUPS_RESP) => {
        this.formGroupsTableData = resp.data.map((group: FormGroupsResp, index: number) => ({
          id: ++index,
          client_id: group.client_id,
          group_id: group.id,
          title: group.description,
          total_forms: group.total_forms || '0',
          created_at: DateTime.fromISO(group.created_at).toLocaleString(DateTime.DATE_SHORT),
        }));
        if (this.formsTableData.length === 0) {
          this.noData = true;
        }
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        this.noData = true;
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  editForm = (record: FormsTableData): void => {
    this.spinnerService.show();
    this.formService
      .getClientForm(record.form_id, record.client_id)
      .then((resp: GET_FORM_DETAILS_RESP) => {
        this.formDetails = resp.data;

        this.router.navigate(['dashboard/forms/create-form', { formData: JSON.stringify(this.formDetails) }], {
          skipLocationChange: true,
        });
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      });
  };

  formResponses = (record: FormsTableData): void => {
    const formRespRef = this.modalService.open(FormResponseModalComponent, { centered: true, size: 'xl' });
    formRespRef.componentInstance.title = record.title;
    formRespRef.componentInstance.form_id = record.form_id;
  };

  previewForm = (record: FormsTableData): void => {
    this.spinnerService.show();
    this.formService
      .getClientForm(record.form_id, record.client_id)
      .then((resp: GET_FORM_DETAILS_RESP) => {
        this.formDetails = resp.data;
        const previewRef = this.modalService.open(PreviewFormModalComponent, { centered: true });
        previewRef.componentInstance.renderPreview = this.formDetails;
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      });
  };

  assignGroup = (record: FormsTableData): void => {
    const formGroupsRef = this.modalService.open(FormGroupsModalComponent, { centered: true, size: 'lg' });
    formGroupsRef.componentInstance.title = record.title;
    formGroupsRef.componentInstance.form_id = record.form_id;
    formGroupsRef.componentInstance.group_title = record.group;
    formGroupsRef.componentInstance.group_id = record.group_id;
    formGroupsRef.componentInstance.assignGroup = true;
    formGroupsRef.result
      .then((result) => {
        if (result) {
          this.ngOnInit();
          this.groupsUpdated = true;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  assignForms = (record: FormGroupsTableData): void => {
    const formGroupsRef = this.modalService.open(FormGroupsModalComponent, { centered: true, size: 'lg' });
    formGroupsRef.componentInstance.group_title = record.title;
    formGroupsRef.componentInstance.group_id = record.group_id;
    formGroupsRef.componentInstance.assignForms = true;
    formGroupsRef.result.catch(() => {
      this.getFormGroups();
      this.ngOnInit();
    });
  };

  assignRules = (record: FormsTableData): void => {
    const formRulesRef = this.modalService.open(FormRulesModalComponent, { centered: true, size: 'lg' });
    formRulesRef.componentInstance.title = record.title;
    formRulesRef.componentInstance.form_id = record.form_id;
  };

  cloneForm = (record: FormsTableData): void => {
    Swal.fire({
      title: 'Clone Form',
      text: 'Please enter name for the new Form.',
      icon: 'info',
      input: 'text',
      inputPlaceholder: `Copy of ${record.title}`,
      showCancelButton: true,
      confirmButtonColor: '#073786',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Clone',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        const data = {
          title: result.value,
        };
        this.formService
          .cloneForm(record.client_id, record.form_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Cloned!',
              text: 'The Form was cloned Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form cloning process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  editFormGroup = (record: FormGroupsTableData): void => {
    Swal.fire({
      title: 'Rename Form Group',
      text: 'Please enter new name for the form group.',
      icon: 'info',
      input: 'text',
      inputPlaceholder: `${record.title}`,
      showCancelButton: true,
      confirmButtonColor: '#073786',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Rename',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        const data = {
          description: result.value,
        };
        this.formService
          .updateFormGroup(record.client_id, record.group_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'The Form Group was renamed Successfully.',
              showConfirmButton: false,
              timer: 2000,
            }).finally(() => {
              this.getFormGroups();
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form Group renaming process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  deleteForm = (record: FormsTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.formService
          .deleteForm(record.client_id, record.form_id)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Form was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  deleteFormGroup = (record: FormGroupsTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.formService
          .deleteFormGroup(record.client_id, record.group_id)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Form Group was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            }).finally(() => {
              this.getFormGroups();
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form Group deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
}
