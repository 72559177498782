<div class="modal-header">
  <h4 class="modal-title">Add Appointment</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row form-group">
    <div class="col-md-4 text-right mt-1">Location:</div>
    <div class="col-md-6">
      <select
        class="form-control"
        (change)="changeLocation($event)"
        *ngIf="clientLocations"
        [(ngModel)]="inputModel.location"
      >
        <option
          *ngFor="let loction of clientLocations"
          [value]="loction.description"
        >
          {{ loction.description }}
        </option>
      </select>
    </div>
  </div>
  <div class="row form-group" *ngIf="showFacility">
    <div class="col-md-4 text-right mt-1">&nbsp;</div>
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="inputModel.facility"
      />
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-4 text-right mt-1">Event Type:</div>
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        autocomplete="off"
        id="event_type"
        [(ngModel)]="inputModel.event_type"
      />
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-4 text-right" style="padding-top: 5px">
      Appointment Date:
    </div>
    <div class="input-group col-md-6">
      <!-- [(ngModel)]="inputModel.appointment_date"  -->
      <input
        class="form-control"
        name="dp"
        ngbDatepicker
        #d="ngbDatepicker"
        #appointment_date
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d.toggle()"
          type="button"
        >
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-4 text-right mt-1">Appointment Time:</div>
    <div class="input-group col-md-6" style="padding-top: 10px">
      <ngb-timepicker [(ngModel)]="app_time" [meridian]="true"></ngb-timepicker>
      <!-- <pre>Selected time: {{time | json}}</pre> -->
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-4 text-right">&nbsp;</div>
    <label class="col-md-6">
      <input
        type="checkbox"
        value="Notify Patient"
        checked
        [(ngModel)]="inputModel.notify_patient"
      />
      Notify Patient
    </label>
  </div>
  <div class="row form-group" *ngIf="practiceProviders">
    <div class="col-md-4 text-right mt-1">Provider:</div>
    <div class="col-md-6">
      <select class="form-control" [(ngModel)]="inputModel.provider_id">
        <option selected value="" disabled hidden>Select Provider</option>
        <option
          *ngFor="let provider of practiceProviders"
          [value]="provider.provider_id"
        >
          {{ provider.description }}
        </option>
      </select>
    </div>
  </div>
  <div class="text-center mt-4" style="color: red" *ngIf="errors">
    <i class="fa fa-exclamation fa-lg mr-1" aria-hidden="true"></i
    >{{ this.errors }}
  </div>
</div>

<div class="modal-footer">
  <!-- (click)="createBenefitInsurance()" -->
  <button
    type="button"
    class="btn btn-primary action-btn"
    (click)="validateAppointmentData()"
  >
    Save
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
