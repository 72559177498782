import { AppService } from './../../shared/services/app.service';
import { VideoDictationService } from './../../shared/services/video-dictation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  Assistant,
  GET_ALL_DICTATIONS_RESP,
  Provider,
  ServerSidePages,
  DictationData,
  VideoDictationTableData,
} from 'src/app/shared/models/';
import Swal from 'sweetalert2';
import { VgAPI } from 'ngx-videogular';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ToastrService } from 'ngx-toastr';
import { PaginationTableHeaders } from 'src/app/shared/models/generic';
import * as moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-dictation',
  templateUrl: './video-dictation.component.html',
  styleUrls: ['./video-dictation.component.scss'],
})
export class VideoDictationComponent implements OnInit {
  videoTableHeaders: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    {
      title: 'name',
      value: 'patient_name',
      sort: true,
      orderBy: '',
    },
    { title: 'MRN', value: 'MRN', sort: false, orderBy: '' },
    {
      title: 'provider',
      value: 'provider_name',
      sort: true,
      orderBy: '',
    },
    { title: 'file_name', value: 'file_name', sort: false, orderBy: '' },
    { title: 'date_uploaded', value: 'date_uploaded', sort: false, orderBy: '' },
    {
      title: 'type',
      value: 'dictation_type',
      sort: true,
      orderBy: '',
    },
    {
      title: 'number',
      value: 'dictation_number',
      sort: false,
      orderBy: '',
    },
  ];
  videoDictationTableData: VideoDictationTableData[];
  assistantInfo: Assistant = null;
  selectedProvider: { description: string; id: string };
  selectedVideo: { url: string; id: string } = { url: '', id: '' }; // Dictation Selected for Video Playback
  videoPlayer: VgAPI;
  pageSize: number = 10;
  searchKeyword: string = '';
  searchCriteria: string = 'patient_name';
  currentPage = 1;
  showHideOrder: boolean = false;
  style: string = 'flex';
  tablePages: ServerSidePages;
  userRole: string;
  CreateOrderData: { provider_id: string; patient_id: string; practice_id: string } = null;
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: MatSlideToggle;

  @HostListener('document:keydown.shift.arrowright')
  next() {
    this.nextPage();
  }
  @HostListener('document:keydown.shift.control.arrowright')
  end() {
    this.lastPage();
  }
  @HostListener('document:keydown.shift.arrowleft')
  prev() {
    this.prevPage();
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  start() {
    this.firstPage();
  }

  constructor(
    private firebaseService: FirebaseService,
    private appService: AppService,
    private toastr: ToastrService,
    private videoDictationService: VideoDictationService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
    }

    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getVideoDictations();
  }

  getVideoDictations(
    page: number = 1,
    limit: number = this.pageSize,
    filterByProvider: string = '',
    orderBy: any = {}
  ): void {
    this.spinnerService.show();

    this.videoDictationService
      .getVideoDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeyword,
        this.searchCriteria,
        filterByProvider,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null
      )
      .then((resp: GET_ALL_DICTATIONS_RESP) => {
        this.spinnerService.hide();
        this.tablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.videoDictationTableData = resp.data.data.map((video: DictationData, index: number) => ({
          no: index + 1,
          id: video.id,
          file_path: video.file_url,
          file_name: this.assignFileName(video.file_url),
          name: video.patient.patient_name,
          patient_id: video.patient.patient_id,
          MRN: video.patient.med_rec_nbr,
          provider: video.provider.description,
          provider_id: video.provider.provider_id,
          type: video.dictation_type === 'V' ? 'Video' : video.dictation_type === 'O' ? 'Order' : 'Video/Order',
          number: video.dictation_number?.toString() || '--',
          patient_row_id: video.patient.patient_row_id,
          date_uploaded:
            moment(Number(this.assignFileName(video.file_url).split('.mp4')[0])).format('MM-DD-YYYY HH:mm') ===
            'Invalid date'
              ? moment.parseZone(video.created_at).format('MM-DD-YYYY HH:mm')
              : moment(Number(this.assignFileName(video.file_url).split('.mp4')[0])).format('MM-DD-YYYY HH:mm'),
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        console.log(err);
      })
      .finally(() => this.spinnerService.hide());
  }

  assignFileName(file_url: string): string {
    const split_url = file_url.split('/');
    const file_name = split_url[split_url.length - 1].split('?');

    return file_name[0];
  }

  lastPage = (): void => {
    let order,
      provider = '';
    this.videoTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.nextPage) this.getVideoDictations(this.tablePages.lastPage, this.pageSize, provider, order);
  };

  nextPage = (): void => {
    let order,
      provider = '';
    this.videoTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.nextPage) this.getVideoDictations(this.tablePages.nextPage, this.pageSize, provider, order);
  };

  prevPage = (): void => {
    let order,
      provider = '';
    this.videoTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.prevPage) this.getVideoDictations(this.tablePages.prevPage, this.pageSize, provider, order);
  };

  firstPage = (): void => {
    let order,
      provider = '';
    this.videoTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.prevPage) this.getVideoDictations(this.tablePages.firstPage, this.pageSize, provider, order);
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (typeof pageNumber === 'number' && pageNumber <= this.tablePages.totalPages && 0 < pageNumber) {
      let order,
        provider = '';
      this.videoTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      if (this.selectedProvider && this.selectedProvider.id) {
        provider = this.selectedProvider.id;
      }
      this.getVideoDictations(pageNumber, this.pageSize, provider, order);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  orderBy = (column_name: string): void => {
    const index = this.videoTableHeaders.findIndex((header) => header.value === column_name);
    let provider = '';

    this.videoTableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.videoTableHeaders[index].orderBy === 'DESC') this.videoTableHeaders[index].orderBy = 'ASC';
    else this.videoTableHeaders[index].orderBy = 'DESC';

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }

    const order = {
      column_name,
      sort: this.videoTableHeaders[index].orderBy,
    };

    this.getVideoDictations(this.tablePages.currentPage, this.pageSize, provider, order);
  };

  searchRecords(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') this.searchKeyword = '';

    let order,
      provider = '';
    this.videoTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    this.getVideoDictations(1, this.pageSize, provider, order);
  }

  deleteVideoDictation = (dictation: VideoDictationTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.videoDictationService
          .deleteVideoDictation(dictation.id)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Dictation was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Dictation deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  downloadVideoDictation = (dictation): void => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    const url = dictation.file_path;
    xhr.onload = () => {
      var blob = new Blob([xhr.response], { type: 'video/mp4' });
      var url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = `${dictation.name}-${dictation['file_name']}` || 'download';
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
    };
    xhr.open('GET', url);
    xhr.send();
  };

  playVideoDictation = (dictation): void => {
    if (dictation.id === this.selectedVideo.id) {
      this.videoPlayer.play();
    } else {
      this.selectedVideo = { url: dictation.file_path, id: dictation.id };
    }
  };

  pauseVideoDictation = (dictation): void => {
    if (dictation.id === this.selectedVideo.id) {
      this.videoPlayer.pause();
    }
  };

  onPlayerReady(api: VgAPI) {
    this.videoPlayer = api;
  }

  archivedFunction = (dictation: DictationData): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.videoDictationService
          .updateVideoDictation(dictation.id, this.attachmentSwitch.checked ? false : true)
          .then(() => {
            this.spinnerService.hide();
            this.getVideoDictations(this.tablePages.currentPage);
            this.toastr.success(
              this.attachmentSwitch.checked ? 'The dictation was unarchived.' : 'The dictation was archived.'
            );
          })
          .catch(() => {
            this.spinnerService.hide();
            this.toastr.error(
              this.attachmentSwitch.checked ? 'Failed to unarchive the dictation.' : 'Failed to archive the dictation.'
            );
          });
      }
    });
  };

  onChangeSwitch(page = 1) {
    this.getVideoDictations(page);
  }

  openFilterModal(): void {
    this.spinnerService.show();
    this.appService
      .getProvidersHavingDictations(this.assistantInfo.client_id, this.assistantInfo.practiceId, 'video')
      .then((resp) => {
        this.spinnerService.hide();
        const filterOptions = {
          filter: resp.data.map((n: Provider) => ({
            id: n.provider_id,
            title: n.description,
          })),
        };
        const filterModal = this.modalService.open(FilterModalComponent, {
          animation: true,
          centered: true,
        });

        filterModal.componentInstance.header = 'Providers';
        filterModal.componentInstance.filterOptions = filterOptions;
        if (this.selectedProvider) filterModal.componentInstance.selectedFilter = this.selectedProvider.description;

        filterModal.result
          .then((result) => {
            if (result) {
              this.videoTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.getVideoDictations(this.tablePages.firstPage, this.pageSize, result.id);
              this.selectedProvider = {
                description: result.title,
                id: result.id,
              };
            } else {
              this.videoTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.selectedProvider = {
                description: '',
                id: '',
              };
              this.getVideoDictations(this.tablePages.firstPage);
            }
          })
          .catch((err) => {
            if (err != 0) console.error(err);
          });
      })
      .catch(() => {
        this.spinnerService.hide();
      });
  }

  toggleOrder(event: boolean) {
    this.showHideOrder = event;
  }

  createOrder = (record): void => {
    if (record.patient_id) {
      this.CreateOrderData = {
        provider_id: record.provider_id,
        patient_id: record.patient_id,
        practice_id: this.assistantInfo.practiceId,
      };
      this.showHideOrder = true;
    }
  };
}
