<!-- For Group Assignment to Forms -->
<ng-container *ngIf="assignGroup">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Group For: <strong>{{ title }}</strong></h4>
    <button
      type="button"
      class="close"
      style="color: white"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="createFlag" [@fadeInOut]>
      <fieldset class="form-group mt-3 mb-4 p-3">
        <legend class="w-auto px-1">Form Group Creation</legend>
        <div class="d-flex">
          <input
            class="form-control mr-3"
            [(ngModel)]="group_name"
            placeholder="Enter the name for the new form group"
          />
        </div>
        <button class="btn btn-primary btn-fr mt-3" style="margin-right: 18px" (click)="createFormGroup()">
          Create<i class="fa fa-floppy-o ml-2" aria-hidden="true"></i>
        </button>
        <button class="btn btn-danger btn-fr mr-3 mt-3" (click)="cancelSwitch()">
          Cancel<i class="fa fa-times ml-2" aria-hidden="true"></i>
        </button>
      </fieldset>
    </div>

    <div class="page-title__bar">
      <h5 class="title">
        Assigned Group: <strong>{{ group_title || "None" }}</strong>
      </h5>

      <div class="user-actions" *ngIf="!createFlag" [@fadeInOut]>
        <button class="btn action-btn" (click)="createSwitch()">
          Create Form Group<i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="d-flex mt-5 mb-5 group-list">
      <h6>Change Group:</h6>
      <select class="form-control ml-2" [disabled]="groupList.length < 1" (change)="onGroupChange($event)">
        <option *ngIf="groupList.length < 1; else selectGroup" value="" disabled hidden selected>
          No other group exists, consider creating a new one.
        </option>
        <ng-template #selectGroup>
          <option value="" disabled hidden selected>Select a Group</option>
          <option *ngFor="let group of groupList" [value]="group.id" [selected]="group.id === selectedGroup">
            {{ group.title }}
          </option>
        </ng-template>
      </select>
    </div>
    <div class="user-actions mb-2">
      <button class="btn remove-btn" (click)="assignFormGroup()">Unassign Group</button>
      <button class="btn apply-btn" (click)="assignFormGroup(true)">Assign New Group</button>
    </div>
  </div>
</ng-container>

<!-- For Form Assignment To Groups -->
<ng-container *ngIf="assignForms">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Assigned Forms For: <strong>{{ group_title }}</strong>
    </h4>
    <button
      type="button"
      class="close"
      style="color: white"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="createFlag" [@fadeInOut]>
      <fieldset class="form-group mt-3 mb-4 p-3">
        <legend class="w-auto px-1">Form Group Assignment</legend>
        <div class="d-flex">
          <select class="form-control" (change)="onFormChange($event)">
            <option value="" disabled hidden selected>Select a Form</option>
            <option *ngFor="let form of formList" [value]="form.id">
              {{ form.title }}
            </option>
          </select>
        </div>
        <button class="btn btn-primary btn-fr mt-3" (click)="assignFormGroup(true, true)">
          Assign<i class="fa fa-floppy-o ml-2" aria-hidden="true"></i>
        </button>
        <button class="btn btn-danger btn-fr mr-3 mt-3" (click)="cancelSwitch()">
          Cancel<i class="fa fa-times ml-2" aria-hidden="true"></i>
        </button>
      </fieldset>
    </div>

    <div class="page-title__bar">
      <h5 class="title">Assigned Forms</h5>

      <div class="user-actions" *ngIf="!createFlag" [@fadeInOut]>
        <button class="btn action-btn" (click)="assignSwitch()">
          Assign New Form<i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div
      class="table-data__wrapper table-responsive calendar-data--table"
      *ngIf="assignedFormsTableData.length; else emptyList"
    >
      <app-table-pagination-client
        [headers]="tableHeaders"
        [data]="assignedFormsTableData"
        [pageSize]="4"
        [currentPage]="1"
        [hasActions]="true"
        [deleteFunction]="unassignFormGroup"
        [cloneFunction]="cloneForm"
        [editFunction]="editForm"
        [previewFunction]="previewForm"
        [responseFunction]="formResponses"
        [assignFunction]="assignRules"
        [paginateId]="2"
      >
      </app-table-pagination-client>
    </div>
    <ng-template #emptyList>
      <div class="d-flex justify-content-center">
        <h6>
          <img src="../../../../assets/no-result-search.png" class="emptyPic" />
          <p>No forms have been assigned this group yet.</p>
        </h6>
      </div>
    </ng-template>
  </div>
</ng-container>
