import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private http: HttpClient) {}
  public pdfui :any;
  sendPdfDetails(order_id: string, file_url: string): Observable<Blob> {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.stsTokenManager.accessToken;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  
    return this.http.post<Blob>(
      `${environment.BASE_URL}/order/${order_id}/insurance-form`,
      { file_url },
      {
        headers: headers, 
        responseType: 'blob' as 'json' 
      }
    );
  }

  uploadUpdatedForm(order_id:string,file: File, fileName: string): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.stsTokenManager.accessToken;

    let headers = new HttpHeaders()
    .set('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'multipart/form-data');
    
    const formData = new FormData();
    formData.append('file', file); 
    formData.append('file_name', fileName); 
  
    return this.http.post<any>(`${environment.BASE_URL}/order/${order_id}/insurance-form/save`, formData, { headers: headers });
  }
}
