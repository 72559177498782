<div class="scripts-screen">
  <h2 class="scripts-heading">SMS Scripts</h2>
  <div class="scripts-container">
    <!-- SCRIPTS FOR NEW PATIENTS -->
    <div class="title-bar">
      <h2>Initial Enrollment (only sent with first reminder)</h2>
      <button class="btn action-btn" (click)="addScript(true)">
        Add Script <i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
      </button>
    </div>
    <ng-container *ngIf="!noDataForNewPatients; else emptyNewPatientsTable">
      <app-table-pagination-client
        [paginateId]="1"
        [headers]="scriptsTableHeaders"
        [data]="newPatientsTableData"
        [hasActions]="true"
        [deleteFunction]="deleteScript"
        [editFunction]="editScript"
        [pageSize]="3"
        (setTotalPages)="setTotalPagesForNewScripts($event)"
        [currentPage]="currentPageOfNewScripts"
      >
      </app-table-pagination-client
    ></ng-container>
    <ng-template #emptyNewPatientsTable>
      <div class="d-flex justify-content-center">
        <h6>
          <img
            src="/assets/no-result-search.png"
            style="width: 220px; height: 200px; display: block"
          />No existing SMS scripts found, you may create a new one.
        </h6>
      </div>
    </ng-template>

    <!-- SCRIPTS FOR EXISTING PATIENTS -->
    <div class="title-bar mt-4">
      <h2>All Subsequent Reminders</h2>
      <button class="btn action-btn" (click)="addScript(false)">
        Add Script <i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
      </button>
    </div>
    <ng-container
      *ngIf="!noDataForExistingPatients; else emptyExistingPatientsTable"
    >
      <app-table-pagination-client
        [paginateId]="2"
        [headers]="scriptsTableHeaders"
        [data]="existingPatientsTableData"
        [hasActions]="true"
        [editFunction]="editScript"
        [deleteFunction]="deleteScript"
        [pageSize]="3"
        (setTotalPages)="setTotalPagesForExistingScripts($event)"
        [currentPage]="currentPageOfExistingScripts"
      >
      </app-table-pagination-client
    ></ng-container>
    <ng-template #emptyExistingPatientsTable>
      <div class="d-flex justify-content-center">
        <h6>
          <img
            src="../../../../assets/no-result-search.png"
            style="width: 220px; height: 200px; display: block"
          />No existing SMS scripts found, you may create a new one.
        </h6>
      </div>
    </ng-template>
  </div>
</div>
