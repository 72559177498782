import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  assignedFormsResp,
  Assistant,
  FormGroupList,
  formResponseResp,
  formResponseTableData,
  GET_ASSIGNED_FORMS_RESP,
  GET_FORMS_RESPONSES_RESP,
  GET_FORM_DETAILS_RESP,
} from '../../models';
import { FirebaseService } from '../../services/firebase.service';
import { FormsService } from '../../services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { PreviewFormModalComponent } from 'src/app/shared/components/preview-form-modal/preview-form-modal.component';
import { FormResponseModalComponent } from 'src/app/shared/components/form-response-modal/form-response-modal.component';
import { FormRulesModalComponent } from 'src/app/shared/components/form-rules-modal/form-rules-modal.component';

@Component({
  selector: 'app-form-groups-modal',
  templateUrl: './form-groups-modal.component.html',
  styleUrls: ['./form-groups-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: '0' }), animate('0.1s 300ms ease-in', style({ opacity: '1' }))]),
    ]),
  ],
})
export class FormGroupsModalComponent implements OnInit {
  assistantInfo: Assistant = null;
  tableHeaders = ['no', 'title', 'created_at'];
  createFlag: boolean = false;
  group_name: string = '';
  groupList: FormGroupList[] = [];
  formList: any[] = [];
  assignedFormsTableData: any[] = [];
  selectedForm: string;
  selectedGroup: string;
  formDetails: any[] = [];
  formResponseTableData: formResponseTableData[];
  @Input() title: string;
  @Input() form_id: string;
  @Input() group_title: string;
  @Input() group_id: string;
  @Input() assignGroup: boolean = false;
  @Input() assignForms: boolean;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private firebaseService: FirebaseService,
    private modalService: NgbModal,
    private formService: FormsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    if (this.assignGroup) this.getFormGroups();
    else if (this.assignForms) this.getAssignedForms();
  }

  getAssignedForms(): void {
    this.formService
      .getFormsAssignedToGroup(this.assistantInfo.client_id, this.group_id)
      .then((resp: GET_ASSIGNED_FORMS_RESP) => {
        this.assignedFormsTableData = resp.data.map((form: assignedFormsResp, index: number) => ({
          no: ++index,
          id: form.id,
          title: form.title,
          created_at: DateTime.fromISO(form.created_at).toLocaleString(DateTime.DATE_SHORT),
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  getFormGroups(): void {
    this.formService
      .getAllFormGroups(this.assistantInfo.client_id)
      .then((resp) => {
        this.groupList = resp.data.map((group) => ({
          id: group.id,
          title: group.description,
        }));
        this.groupList = this.groupList.filter((group) => group.id != this.group_id);
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }
  unassignFormGroup = (record: assignedFormsResp): void => {
    this.selectedForm = record.id;
    this.assignFormGroup(false, true);
  };

  assignFormGroup(assign: boolean = false, alt?: boolean): void {
    if (!assign) {
      if (!this.group_id) {
        this.toastr.error('No Group is assigned to this form.');
        return;
      }
      this.selectedGroup = '0';
    } else if (!this.selectedGroup && !alt) {
      this.toastr.info('Please select a group first.');
      return;
    } else if (!this.selectedForm && alt) {
      this.toastr.info('Please select a form first.');
      return;
    }

    if (alt) {
      this.form_id = this.selectedForm;
      if (!this.selectedGroup) this.selectedGroup = this.group_id;
    }

    this.spinnerService.show();
    const data = {
      form_id: this.form_id,
    };
    this.formService
      .assignFormGroup(this.assistantInfo.client_id, this.selectedGroup, data)
      .then(() => {
        this.spinnerService.hide();
        if (alt) {
          this.toastr.success(
            assign ? 'The Form Group was assigned Successfully.' : 'The Form Group was unassigned Successfully.'
          );
          this.getAssignedForms();
          if (this.selectedGroup != '0') this.createFlag = !this.createFlag;
        } else {
          Swal.fire({
            icon: 'success',
            title: assign ? 'Assigned!' : 'Unassigned!',
            text: assign ? 'The Form Group was assigned Successfully.' : 'The Form Group was unassigned Successfully.',
            showConfirmButton: false,
            timer: 2000,
          }).finally(() => {
            let reload = true;
            this.activeModal.close(reload);
          });
        }
      })
      .catch((err) => {
        this.spinnerService.hide();
        if (alt) {
          this.toastr.error('The Form Group Assignemnet process has failed.');
          this.getAssignedForms();
          if (this.selectedGroup != '0') this.createFlag = !this.createFlag;
        } else {
          this.activeModal.close();
          Swal.fire({
            icon: 'error',
            title: 'Unsuccessful!',
            text: 'The Form Group Assignemnet process has failed.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .finally(() => {
        this.selectedForm = '';
        this.selectedGroup = '';
      });
  }

  createSwitch(): void {
    this.createFlag = !this.createFlag;
  }

  onFormChange(event: any): void {
    this.selectedForm = event.target.value;
  }

  onGroupChange(event: any): void {
    this.selectedGroup = event.target.value;
  }

  assignSwitch(): void {
    this.spinnerService.show();
    this.formService
      .getAllClientForms(this.assistantInfo.practiceId, this.assistantInfo.client_id)
      .then((resp: any) => {
        this.formList = resp.data;
        this.formList = this.formList.filter((form) => form.group_id === null);
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
    this.createFlag = !this.createFlag;
  }

  cancelSwitch(): void {
    this.group_name = '';
    this.createFlag = !this.createFlag;
  }

  createFormGroup(): void {
    if (!this.group_name) {
      this.toastr.error('Please enter name for the new group.');
      return;
    }

    this.spinnerService.show();
    const data = {
      description: this.group_name,
    };

    this.formService
      .createFormGroup(this.assistantInfo.client_id, data)
      .then((resp: any) => {
        this.toastr.success('New Form Group Created Successfully');
        this.group_name = '';
        this.createFlag = !this.createFlag;
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.getFormGroups();
        this.spinnerService.hide();
      });
  }

  cloneForm = (record: assignedFormsResp): void => {
    Swal.fire({
      title: 'Clone Form',
      text: 'Please enter name for the new Form.',
      icon: 'info',
      input: 'text',
      inputPlaceholder: `Copy of ${record.title}`,
      showCancelButton: true,
      confirmButtonColor: '#39d5d5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Clone',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        const data = {
          title: result.value,
        };
        this.formService
          .cloneForm(this.assistantInfo.client_id, record.id, data)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Cloned!',
              text: 'The Form was cloned Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form cloning process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  editForm = (record: assignedFormsResp): void => {
    this.spinnerService.show();
    this.formService
      .getClientForm(record.id, this.assistantInfo.client_id)
      .then((resp: GET_FORM_DETAILS_RESP) => {
        this.formDetails = resp.data;
        this.activeModal.close();
        this.router.navigate(['dashboard/forms/create-form', { formData: JSON.stringify(this.formDetails) }], {
          skipLocationChange: true,
        });
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      });
  };

  previewForm = (record: assignedFormsResp): void => {
    this.spinnerService.show();
    this.formService
      .getClientForm(record.id, this.assistantInfo.client_id)
      .then((resp: GET_FORM_DETAILS_RESP) => {
        this.formDetails = resp.data;
        const previewRef = this.modalService.open(PreviewFormModalComponent);
        previewRef.componentInstance.previewForm = this.formDetails;
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      });
  };

  assignRules = (record: assignedFormsResp): void => {
    const formRulesRef = this.modalService.open(FormRulesModalComponent, { centered: true, size: 'lg' });
    formRulesRef.componentInstance.title = record.title;
    formRulesRef.componentInstance.form_id = record.id;
  };

  formResponses = (record: assignedFormsResp): void => {
    const formRespRef = this.modalService.open(FormResponseModalComponent, { centered: true, size: 'lg' });
    formRespRef.componentInstance.title = record.title;
    formRespRef.componentInstance.form_id = record.id;
  };
}
