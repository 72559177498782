import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { ToastrService } from 'ngx-toastr';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranscriptionService {
  private s3: S3;

  constructor(private toastr: ToastrService) {
    this.s3 = new S3({
      accessKeyId: env.AWS_ACCESS_KEY_ID,
      secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
      region: env.AWS_REGION,
    });
  }

  public getFilesFromS3(): Promise<any> {
    const params = {
      Bucket: 'vytltestbucket',
    };

    return new Promise((resolve, reject) => {
      this.s3.listObjects(params, (error, data) => {
        if (error) {
          reject(error);
        } else {
          const wavFiles = data.Contents.filter((file) => file.Key.endsWith('.wav'));
          resolve(wavFiles);
        }
      });
    });
  }

  checkTranscriptionFileExistence(bucketName: string, key: string): Promise<boolean> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3
      .headObject(params)
      .promise()
      .then(() => true) // Transcription file exists
      .catch((error: any) => {
        console.log("error = >>", error);
        if (error.code === 'NotFound') {
          return false; // Transcription file does not exist
        }
        throw error; // Error occurred while checking the existence
      });
  }

  downloadFile(bucketName: string, key: string): Promise<Buffer> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3
      .getObject(params)
      .promise()
      .then((data: S3.GetObjectOutput) => {
        return data.Body as Buffer;
      });
  }

  deleteFile(bucketName: string, key: string): Promise<void> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3
      .deleteObject(params)
      .promise()
      .then(() => {
        this.toastr.success('File Deleted Successfully!');
      })
      .catch((error: any) => {
        console.error('Error deleting file:', error);
        throw error;
      });
  }

  uploadFileToS3(file: File, bucketName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucketName,
        Key: file.name,
        Body: file
      };

      this.s3.upload(params, (err: any, data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
}
