import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericResp, GET_PATIENT_APPOINTMENTS_RESP } from '../models';
import { GET_APPOINTMENT_REPORTS_RESP } from '../models/appointment';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  user = JSON.parse(localStorage.getItem('user'));
  constructor(private http: HttpClient, private db: AngularFireDatabase) {}

  async getPatientAppointments(patient_id: string): Promise<GET_PATIENT_APPOINTMENTS_RESP> {
    return this.http
      .get<GET_PATIENT_APPOINTMENTS_RESP>(`${environment.APPOINTMENTS}/patient/${patient_id}`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
      })
      .toPromise();
  }

  async getAppointmentReports(
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    filter: string,
    filterByProvider = '',
    search: string,
    searchCriteria: string,
    is_archived: boolean,
    filterByDate: string | null,
    filterByTime: string | null
  ): Promise<GET_APPOINTMENT_REPORTS_RESP> {
    return this.http
      .get<GET_APPOINTMENT_REPORTS_RESP>(`${environment.APPOINTMENTS}/practice`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
        params: {
          page: `${page}`,
          limit: `${limit}`,
          filter: `${filter}`,
          filterByProvider: `${filterByProvider}`,
          search,
          searchCriteria,
          orderBy: JSON.stringify(orderBy),
          is_archived: `${!!is_archived}`,
          filterByDate: `${filterByDate}`,
          filterByTime: `${filterByTime}`,
        },
      })
      .toPromise();
  }

  async getAppointmentReportsForPrint(
    page: number = 1,
    orderBy: {},
    filter: string,
    filterByProvider = '',
    search: string,
    searchCriteria: string,
    is_archived: boolean,
    filterByDate: string | null,
    filterByTime: string | null
  ): Promise<GET_APPOINTMENT_REPORTS_RESP> {
    return this.http
      .get<GET_APPOINTMENT_REPORTS_RESP>(`${environment.APPOINTMENTS}/practice`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
        params: {
          page: `${page}`,
          filter: `${filter}`,
          filterByProvider: `${filterByProvider}`,
          search,
          searchCriteria,
          orderBy: JSON.stringify(orderBy),
          is_archived: `${!!is_archived}`,
          filterByDate: `${filterByDate}`,
          filterByTime: `${filterByTime}`,
        },
      })
      .toPromise();
  }

  async archiveAppointment(appointment_id: string, is_archived: boolean, token: string): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.APPOINTMENTS}/${appointment_id}/archive/${is_archived}`, null, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }

  async getProvidersHavingAppointments(token: string): Promise<any> {
    return await this.http
      .get<any>(`${environment.GET_PROVIDER_API}/appointments`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }

  async getAppointmentReminderDetails(phone_number: string): Promise<any> {
    return await this.http
      .get<any>(`${environment.APPOINTMENTS}/reminder/${phone_number}/details`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
      })
      .toPromise();
  }

  downloadCSV(
    page: number = 1,
    limit: number = -1,
    orderBy: {},
    filter: string,
    filterByProvider = '',
    search: string,
    searchCriteria: string,
    is_archived: boolean,
    filterByDate: string | null,
    filterByTime: string | null
  ): Observable<Blob> {
    const params = {
      page: `${page}`,
      filter: `${filter}`,
      filterByProvider: `${filterByProvider}`,
      search,
      searchCriteria,
      orderBy: JSON.stringify(orderBy),
      is_archived: `${!!is_archived}`,
      filterByDate: `${filterByDate}`,
      filterByTime: `${filterByTime}`,
    };

    if (limit !== -1) {
      params['limit'] = limit;
    }

    return this.http.get(`${environment.APPOINTMENTS}/practice/export-csv`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
      responseType: 'blob',
      params,
    });
  }
}
