import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Input,
} from '@angular/core';
import license from '../../../../assets/FoxitSDK/examples/license-key.js';
import { PdfService } from 'src/app/shared/services/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-foxitpdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PDFViewerComponent implements OnInit {
  @Input() orderDetails: any;
  @Input() pdfBlob: Blob | null = null;
  constructor(
    private element: ElementRef,
    private pdfService: PdfService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {}

  loadUIExtension(): Promise<any> {
    return import('@foxitsoftware/foxit-pdf-sdk-for-web-library').then(
      (UIExtension) => {
        if (!window['UIExtensionInstance']) {
          window['UIExtensionInstance'] = UIExtension;
        }
        return window['UIExtensionInstance'];
      }
    );
  }

  ngOnInit() {
    this.loadUIExtension().then((UIExtension) => {
      this.spinnerService.show();
       if(this.pdfService.pdfui === undefined){
        this.pdfService.pdfui = new UIExtension.PDFUI({
          viewerOptions: {
            libPath: '/foxit-lib',
            jr: {
              ...license,
              fontPath: location.origin + '/assets/external/brotli/',
            },
          },
          appearance: UIExtension.appearances.adaptive,
          renderTo: this.element.nativeElement,
          addons: '/foxit-lib/uix-addons/allInOne.js',
        })
       }else{
        this.pdfService.pdfui = new UIExtension.PDFUI({
          viewerOptions: {
            libPath: '/foxit-lib',
            jr: {
              ...license,
              fontPath: location.origin + '/assets/external/brotli/',
            },
          },
          appearance: UIExtension.appearances.adaptive,
          renderTo: this.element.nativeElement,
        })
       }
        this.pdfService.pdfui
        .waitForInitialization()
        .then(() => {
          this.spinnerService.hide()
          this.showFileFnFoxit(this.pdfBlob)
        });
    });
  }

  showFileFnFoxit(blob: any) {
    if (this.pdfService.pdfui) {
      this.pdfService.pdfui
        .openPDFByFile(blob, {
          fileName: 'foxit file' + new Date(),
        })
        .then(() => {
          console.log('PDF loaded successfully!');
        })
        .catch((err: any) => {
          console.error('Failed to load PDF:', err);
        });
    } else {
      console.error('PDFUI not initialized.');
    }
  }

saveFileFnFoxit() {
  if (this.pdfService.pdfui) {
    Swal.fire({
      title: 'Enter a name for the PDF',
      input: 'text',
      inputLabel: 'File Name',
      inputPlaceholder: 'Enter file name',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const fileName = result.value;
        this.spinnerService.show();
        this.pdfService.pdfui
          .getPDFViewer()
          .then((viewer: any) => viewer.getCurrentPDFDoc())
          .then((doc: any) => doc.getFile())
          .then((pdfBlob: any) => {
            this.pdfService
              .uploadUpdatedForm(
                this.orderDetails.order_id,
                pdfBlob,
                fileName 
              )
              .subscribe(
                () => {
                  this.spinnerService.hide();
                  this.toastr.success('PDF Saved successfully');
                  this.activeModal.close();
                },
                (err) => {
                  this.spinnerService.hide();
                  this.toastr.error(
                    "Couldn't complete request due to an error, please try again later."
                  );
                  console.error('Failed to save PDF:', err);
                }
              );
          })
          .catch((err: any) => {
            this.spinnerService.hide();
            this.toastr.error(
              "Couldn't complete request due to an error, please try again later."
            );
            console.error('Failed to save PDF:', err);
          });
      } else {
        this.spinnerService.hide();
      }
    });
  } else {
    this.spinnerService.hide();
    this.toastr.error(
      'PDFUI not initialized, please reload or try again later.'
    );
    console.error('PDFUI not initialized.');
  }
}

  downloadFileFnFoxit() {
    if (this.pdfService.pdfui) {
      this.pdfService.pdfui
        .getPDFViewer()
        .then((viewer: any) => viewer.getCurrentPDFDoc())
        .then((doc: any) => doc.getFile())
        .then((pdfBlob: Blob) => {
          const blobUrl = URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = 'updated_file.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(blobUrl);
        })
        .catch((err: any) => {
          console.error('Failed to save PDF:', err);
        });
    } else {
      console.error('PDFUI not initialized.');
    }
  }
}