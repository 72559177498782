<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Verahealth</title>
  </head>
  <body>
    <div class="wrapper">
      <div class="container">
        <section class="login__wrapper">
          <div class="login__area">
            <div class="logo">
              <img
                src="../../../assets/Verahealth-Logo.png"
                srcset="../../../assets/Verahealth-Logo@2x.png"
                alt="logo"
              />
            </div>
            <h1>Admin Login</h1>
            <div class="form__wrapper">
              <form [formGroup]="loginForm" (submit)="submit()">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    placeholder="Username"
                  />
                </div>
                <div class="form-group mb-1">
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    placeholder="**********"
                  />
                </div>
                <div class="mb-30">
                  <a class="link__style pb-30 point" [routerLink]="['/login']"
                    >Back to Client Login</a
                  >
                </div>
                <button
                  type="button"
                  type="submit"
                  class="btn btn-primary login__btn"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </body>
</html>
