<div class="page-title__bar">
  <h2 class="title">Forms</h2>

  <div class="switch__panel">
    <div class="d-flex align-items-center">
      <p class="mb-1 ml-2 mr-2">List</p>
      <mat-slide-toggle color="#073786" #attachmentSwitch (change)="onChangeSwitch()"></mat-slide-toggle>
      <p class="mb-1 mr-2 ml-2">Groups</p>
    </div>
  </div>
  <div class="user-actions">
    <button (click)="createForm()" class="btn action-btn">
      Create Form<i class="fa fa-list-alt fa-lg ml-2" aria-hidden="true"></i>
    </button>
    <button (click)="createFormGroup()" class="btn action-btn mr-3">
      Create Form Group<i class="fa fa-object-group fa-lg ml-2" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="table-data__wrapper table-responsive calendar-data--table" *ngIf="!noData; else emptyTable">
  <app-table-pagination-client
    *ngIf="!formGroups"
    [headers]="listTableHeaders"
    [data]="formsTableData"
    [hasActions]="true"
    [deleteFunction]="deleteForm"
    [cloneFunction]="cloneForm"
    [editFunction]="editForm"
    [previewFunction]="previewForm"
    [responseFunction]="formResponses"
    [assignFunction]="assignRules"
    [groupAssignFunction]="assignGroup"
    (setTotalPages)="setTotalPages($event)"
    [pageSize]="6"
    [currentPage]="currentPage"
  >
  </app-table-pagination-client>

  <app-table-pagination-client
    *ngIf="formGroups"
    [headers]="groupTableHeaders"
    [data]="formGroupsTableData"
    [hasActions]="true"
    [deleteFunction]="deleteFormGroup"
    [editFunction]="editFormGroup"
    [assignFunction]="assignForms"
    (setTotalPages)="setTotalPages($event)"
    [pageSize]="6"
    [currentPage]="currentPage"
  >
  </app-table-pagination-client>
</div>
<ng-template #emptyTable>
  <div class="d-flex justify-content-center" style="margin-top: 5%; margin-bottom: 7%">
    <h6 *ngIf="noData && !formGroups">
      <img src="../../../../assets/no-result-search.png" style="width: 320px; height: 300px; display: block" />No
      existing forms found, you may create a new one.
    </h6>
    <h6 *ngIf="noData && formGroups">
      <img src="../../../../assets/no-result-search.png" style="width: 320px; height: 300px; display: block" />No
      existing form groups found, you may create a new one.
    </h6>
  </div>
</ng-template>
