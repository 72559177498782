import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import liveswitch from 'fm.liveswitch';
import { inspect } from 'util';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardService,
  CanvasWhiteboardShapeOptions,
  CanvasWhiteboardUpdate,
} from 'ng2-canvas-whiteboard';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { environment } from '../../../environments/environment';
import { ClientInfo, WaitingUsersInfo, WaitingTime, RecordingConfig } from '../../shared/models/index';
import { FirebaseService } from '../../shared/services/firebase.service';
import { AppService } from '../../shared/services/app.service';
import { AuthService } from '../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as getUuidByString from 'uuid-by-string';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import fmLiveswitch from 'fm.liveswitch';

const SERVER_IP = environment.liveswitchConfig.SERVER_IP;
const APPLICATION_ID = environment.liveswitchConfig.APPLICATION_ID;
const audio = true;
const video = true;

@Component({
  selector: 'app-home',
  viewProviders: [CanvasWhiteboardComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('callVideoView', { static: true })
  callVideoView: ElementRef<HTMLElement>;
  @ViewChild('localVideoView', { static: false })
  localVideoView: ElementRef<HTMLElement>;
  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;
  @ViewChildren('remoteVideoView')
  remoteVideoViews: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('videoSwitch', { static: false })
  videoSwitch: MatSlideToggle;
  @ViewChild('imageSwitch', { static: false })
  imageSwitch: MatSlideToggle;
  @ViewChild('whiteboardSwitch', { static: false })
  whiteboardSwitch: MatSlideToggle;
  @ViewChild('fullScreenSwitch', { static: false })
  fullScreenSwitch: MatSlideToggle;

  client: liveswitch.Client;
  token: string;
  localMedia = new liveswitch.LocalMedia(audio, video);
  remoteMedia = new liveswitch.RemoteMedia(audio, video);
  remoteMediaAll: liveswitch.RemoteMedia[] = []; // remoteMedia of each Client
  layoutManagerLocal: liveswitch.DomLayoutManager;
  layoutManagerRemote: liveswitch.DomLayoutManager;
  layoutManagerCall: liveswitch.DomLayoutManager;
  channel: liveswitch.Channel;
  remoteViews: any = [];
  remoteClientInfos: liveswitch.ClientInfo[] = [];
  sfuDownConnection: liveswitch.SfuDownstreamConnection; // Connection for getting live streaming views of clients.
  callConnection: liveswitch.SfuUpstreamConnection; // Connection for 1-to-1 call with client
  sfuCallDownconnection: liveswitch.SfuDownstreamConnection;
  callChannel: liveswitch.Channel;
  inCallUser: ClientInfo;
  inCallUserClientInfo: liveswitch.ClientInfo;
  callConnected = false;
  showLocalViewUserIcon = true;
  isWhiteboardShown = false;
  showCallControls = false;
  videoFiles: any[];
  imageFiles: any[];
  selectedImage: any;
  selectedVideo: any;
  waitingTime = {};
  expandCallView: boolean = false;
  inCallUserInfo: { name: string; duration: number; time: string };
  callDurationInterval: any;
  userRole: string;

  USER_ID: string;
  CHANNEL_ID: string;
  ASSISTANT_ID: string;
  PRACTICE_ID: string;
  showHideOrder: boolean;
  panelOpenState = false;
  patient_row_id: string;
  provider_id: string;
  practiceName: string;
  user: any;
  // @ViewChild('accordion', { read: false, static: false }) Accordion: MatAccordion;
  constructor(
    private appService: AppService,
    private firebaseService: FirebaseService,
    private canvasWhiteboardService: CanvasWhiteboardService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    fmLiveswitch.LogConfiguration.setDefaultLogLevel(liveswitch.LogLevel.Info);
    liveswitch.Log.setLogLevel(liveswitch.LogLevel.Info);
    liveswitch.Log.registerProvider(new liveswitch.ConsoleLogProvider());
    this.showHideOrder = false;
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();
    switch (this.userRole) {
      case 'practice_admin_sante_community': {
        if (this.user.email === 'cmpwest@santehealth.net') {
          this.router.navigate([`/dashboard/messages`]);
          break;
        }
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'practice_admin_sante_community_order': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'practice_user_sante_community_order': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    if (!!loggedInUser) {
      if (this.userRole === 'practice_admin') {
        const data = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
        this.isWhiteboardShown = false;
        this.CHANNEL_ID = getUuidByString(data.practiceId.toLowerCase(), 3);
        this.USER_ID = data.email;
        this.ASSISTANT_ID = data.providerId;
        this.PRACTICE_ID = data.practiceId.toLowerCase();
        this.practiceName = data.practiceName;

        this.initClientConf(this.CHANNEL_ID);
        this.registerClient(true);
        this.handleLocalMedia();
      }
    } else {
      this.logout();
    }
  }

  toggleOrder(event) {
    this.showHideOrder = event;
  }

  sendResponseToDevice(response) {
    if (response) {
      let parseRes = JSON.parse(response);
      if (parseRes.is_consent_required) {
        let obj = {
          command: 'digital-consent-cmd',
          order_id: parseRes.order_id,
          order_summary: parseRes.order_summary,
        };
        this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, JSON.stringify(obj));
      }
    }
  }
  ngOnDestroy(): void {
    this.localMedia.stop();
    if (this.client) this.unRegisterClient(this.client);
    if (this.channel) this.channel.leave();
  }

  initClientConf(channelId: string): void {
    this.client = new liveswitch.Client(
      SERVER_IP,
      APPLICATION_ID,
      this.USER_ID,
      null, // device_id => null to auto-generate
      null, // client-id => null to auto-generate
      ['role1', 'role2']
    );

    this.token = liveswitch.Token.generateClientRegisterToken(
      APPLICATION_ID,
      this.client.getUserId(),
      this.client.getDeviceId(),
      this.client.getId(),
      this.client.getRoles(),
      [new liveswitch.ChannelClaim(channelId)],
      environment.liveswitchConfig.SECRET_SHARED_KEY
    );
  }

  registerClient(isSFU: boolean): void {
    this.client
      .register(this.token)
      .then((channels) => {
        this.channel = channels[0];
        console.log('Connected to Channel: ', this.channel.getId());
        this.getDataFromFirebase();

        // initial state
        channels[0].getRemoteClientInfos().forEach((remoteClientInfo) => {
          console.log(
            'AdminClient: ' + remoteClientInfo.getUserId() + ' is already in (' + remoteClientInfo.getId() + ').'
          );

          if (!remoteClientInfo.getUserId().includes('@') && isSFU) {
            this.createSfuConnection(this.channel, remoteClientInfo);
            this.setUserWaitingTime(remoteClientInfo);
            this.setIntervalForWaitingUser(remoteClientInfo);
          }
        });

        // changes
        channels[0].addOnRemoteClientJoin((remoteClientInfo) => {
          console.log('AdminClient: ' + remoteClientInfo.getUserId() + ' joined (' + remoteClientInfo.getId() + ').');

          if (!remoteClientInfo.getUserId().includes('@') && isSFU) {
            this.createSfuConnection(this.channel, remoteClientInfo);
            this.setUserWaitingTime(remoteClientInfo);
            this.setIntervalForWaitingUser(remoteClientInfo);
          }
        });

        channels[0].addOnRemoteClientLeave((remoteClientInfo) => {
          console.log('AdminClient: ' + remoteClientInfo.getUserId() + ' left (' + remoteClientInfo.getId() + ').');

          if (this.callConnected && remoteClientInfo.getUserId() === this.inCallUser.userId) {
            this.endCall();
          }

          if (!!this.waitingTime[remoteClientInfo.getUserId()]?.interval) {
            clearInterval(this.waitingTime[remoteClientInfo.getUserId()].interval);
            this.waitingTime[remoteClientInfo.getUserId()] = null;
          }
        });

        channels[0].addOnRemoteUpstreamConnectionOpen((remoteClientInfo) => {
          if (!isSFU) {
            this.openUpStreamConnection(this.channel, remoteClientInfo.createClientInfo());
            this.openDownStreamConnection(this.channel, remoteClientInfo);

            this.inCallUser = {
              userId: remoteClientInfo.getUserId(),
              userAlias: remoteClientInfo.getUserAlias(),
              deviceId: remoteClientInfo.getDeviceId(),
              clientId: this.inCallUserClientInfo.getId(),
            };
          }
        });
      })
      .fail((ex) => {
        console.log('Registration failed');
      });
  }

  unRegisterClient(client: liveswitch.Client): void {
    client
      .unregister()
      .then((result) => {
        console.log('unregistration succeeded');
      })
      .fail((ex) => {
        console.log('unregistration failed');
      });
  }

  handleLocalMedia(): void {
    this.localMedia
      .start()
      .then((lm) => {
        console.log('Media Capture Started.');
        this.viewLocalMedia();
      })
      .fail((ex) => {
        console.log(ex.message);
      });
  }

  viewLocalMedia(): void {
    this.layoutManagerLocal = new liveswitch.DomLayoutManager(this.localVideoView.nativeElement);

    this.layoutManagerLocal.setLocalView(this.localMedia.getView());
    this.showLocalViewUserIcon = false;
  }

  viewRemoteMedia(): void {
    this.remoteVideoViews.forEach((directive, index) => {
      this.layoutManagerRemote = new liveswitch.DomLayoutManager(directive.nativeElement);
      this.layoutManagerRemote.addRemoteView(this.remoteMediaAll[index].getId(), this.remoteMediaAll[index].getView());
    });
  }

  openDownStreamConnection(channel: liveswitch.Channel, remoteClientInfo: liveswitch.ConnectionInfo) {
    this.viewCallMedia();
    let audioStream = new liveswitch.AudioStream(null, this.remoteMedia);
    let videoStream = new liveswitch.VideoStream(null, this.remoteMedia);
    this.sfuCallDownconnection = channel.createSfuDownstreamConnection(remoteClientInfo, audioStream, videoStream);
    liveswitch.Log.warn('audioStreamDownConnection => ' + inspect(audioStream));
    liveswitch.Log.warn('sfuDownStreamConnection => ' + inspect(this.sfuCallDownconnection));
    this.sfuCallDownconnection
      .open()
      .then((result) => {
        console.log('Downstream Connection Established');
      })
      .fail((ex) => {
        console.log('An Error occurred in creating downstream connection.', ex);
      });
  }

  openUpStreamConnection(channel: liveswitch.Channel, remoteClientInfo: liveswitch.ClientInfo): void {
    this.inCallUserClientInfo = remoteClientInfo;

    const audioStream = new liveswitch.AudioStream(this.localMedia, null);
    const videoStream = new liveswitch.VideoStream(this.localMedia, null);

    this.callConnection = channel.createSfuUpstreamConnection(audioStream, videoStream);
    this.prepareConnection();
    this.enableStereoOpusOnChrome();
    liveswitch.Log.warn('Audio Stream upStreamConnection => ' + inspect(audioStream));
    liveswitch.Log.warn('sfu Upstream Call Connection => ' + inspect(this.callConnection));
    this.callConnection.addOnStateChange((c) => {
      if (c.getState() === liveswitch.ConnectionState.Closing || c.getState() === liveswitch.ConnectionState.Failing) {
        this.layoutManagerRemote.removeRemoteView(this.remoteMedia.getId());
      }
    });

    this.callConnection
      .open()
      .then(() => {
        console.log('Upstream Connection Established.');
        this.viewCallMedia();
      })
      .fail((ex) => {
        console.log('Exception: ' + ex);
      });
  }

  createSfuConnection(channel: liveswitch.Channel, remoteClientInfo: liveswitch.ClientInfo): void {
    channel.addOnRemoteUpstreamConnectionOpen((remoteConnectionInfo: liveswitch.ConnectionInfo) => {
      this.remoteMedia = new liveswitch.RemoteMedia();

      if (!!!this.remoteViews.find((x) => x.getUserId() === remoteConnectionInfo.getUserId())) {
        this.remoteViews.push(remoteConnectionInfo);
        this.remoteMediaAll.push(this.remoteMedia);
      }

      this.remoteClientInfos.push(remoteClientInfo);
      const localMedia = new liveswitch.LocalMedia(false, false); // Check-1
      const videoStream = new liveswitch.VideoStream(localMedia, this.remoteMedia);

      this.sfuDownConnection = channel.createSfuDownstreamConnection(remoteConnectionInfo, null, videoStream);
      this.sfuDownConnection
        .open()
        .then((result) => {
          console.log('Downstream connection established.');
          this.viewRemoteMedia();
        })
        .fail((ex) => {
          console.log('Downstream Connection Exception: ', ex);
        });

      this.sfuDownConnection.addOnStateChange((c) => {
        if (
          c.getState() === liveswitch.ConnectionState.Closing ||
          c.getState() === liveswitch.ConnectionState.Failing
        ) {
          const foundView = this.remoteViews.find((x) => x.getId() === c.getRemoteConnectionId());
          const foundViewIndex = this.remoteViews.indexOf(foundView);
          this.remoteVideoViews.forEach((view, index) => {
            if (index === foundViewIndex) {
              new liveswitch.DomLayoutManager(view.nativeElement).removeRemoteView(
                this.remoteMediaAll[foundViewIndex].getId()
              );
            }
          });

          this.remoteViews = this.remoteViews.filter((item) => item !== foundView);
          this.remoteMediaAll = this.remoteMediaAll.filter((item, index) => index !== foundViewIndex);
          this.remoteClientInfos = this.remoteClientInfos.filter((item, index) => index !== foundViewIndex);
        }
      });
    });
  }

  prepareConnection(): void {
    const audioStream = this.callConnection.getAudioStream();
    if (audioStream) {
      audioStream.setOpusDisabled(false);
      audioStream.setG722Disabled(false);
      audioStream.setPcmuDisabled(false);
      audioStream.setPcmaDisabled(false);
    }

    const videoStream = this.callConnection.getVideoStream();
    if (videoStream) {
      videoStream.setVp8Disabled(false);
      videoStream.setVp9Disabled(false);
      videoStream.setH264Disabled(false);
      videoStream.setH265Disabled(false);
    }
  }

  viewCallMedia(): void {
    this.layoutManagerCall = new liveswitch.DomLayoutManager(this.callVideoView.nativeElement);

    this.layoutManagerCall.addRemoteView(this.remoteMedia.getId(), this.remoteMedia.getView());
    this.callConnected = true;
  }

  setSessionInfo(): void {
    if (!this.callDurationInterval) {
      this.callDurationInterval = setInterval(() => {
        this.inCallUserInfo.time = moment()
          .hour(0)
          .minute(0)
          .second(this.inCallUserInfo.duration++)
          .format('HH: mm: ss');
      }, 1000);
    }
  }

  async initiateCall(connectionInfo: liveswitch.ConnectionInfo): Promise<void> {
    if (this.callConnected) await this.endCall();

    console.log('Establishing Connection with Client.');
    const channelId = connectionInfo.getClientId(); // Setting ClientID as channelId for call Connection.

    console.log('Initiating Call: ', connectionInfo.getUserId() + ' ' + connectionInfo.getDeviceId());
    this.channel.sendDeviceMessage(connectionInfo.getUserId(), connectionInfo.getDeviceId(), channelId);

    this.inCallUserInfo = {
      name: connectionInfo.getUserAlias(),
      duration: 0,
      time: '00 : 00 : 00',
    };

    let currentCallUserId = connectionInfo.getUserId();
    if (currentCallUserId && this.waitingTime[currentCallUserId]) {
      this.patient_row_id = this.waitingTime[currentCallUserId].patient_row_id;
      this.provider_id = this.waitingTime[currentCallUserId].providerId;
    }

    this.setSessionInfo();
    this.switchChannel(channelId, false);
  }

  initCallClientConf(channelId: string): void {
    const token: string = liveswitch.Token.generateClientJoinToken(
      APPLICATION_ID,
      this.USER_ID,
      null,
      null,
      new liveswitch.ChannelClaim(channelId),
      environment.liveswitchConfig.SECRET_SHARED_KEY
    );

    this.client
      .join(channelId, token)
      .then((channel) => {
        this.callChannel = channel;
        channel.addOnRemoteClientLeave((remoteClientInfo) => {
          console.log('CallChannel: ' + remoteClientInfo.getUserId() + ' left (' + remoteClientInfo.getId() + ').');

          if (this.callConnected && remoteClientInfo.getUserId() === this.inCallUser.userId) {
            this.toastr.show('Patient Left the session. Ending session now.');
            this.endCall();
          }
        });

        channel.addOnRemoteUpstreamConnectionOpen((remoteClientInfo) => {
          this.openUpStreamConnection(this.callChannel, remoteClientInfo.createClientInfo());
          this.openDownStreamConnection(this.callChannel, remoteClientInfo);

          this.inCallUser = {
            userId: remoteClientInfo.getUserId(),
            userAlias: remoteClientInfo.getUserAlias(),
            deviceId: remoteClientInfo.getDeviceId(),
            clientId: this.inCallUserClientInfo.getId(),
          };
        });
      })
      .fail((err) => {
        console.error(err);
      });
  }

  switchChannel(channelId: string, isSFU: boolean): void {
    this.initCallClientConf(channelId);
  }

  async endCall(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, `call-end-cmd`);

      this.callConnection
        .close()
        .then((result) => {
          console.log('Call Ended.');

          clearInterval(this.callDurationInterval);
          this.callDurationInterval = null;
          this.inCallUserInfo = null;

          this.layoutManagerCall.removeRemoteView(this.remoteMedia.getId());
          this.client.leave(this.callChannel.getId());
          this.initializeState();
          resolve(1);
        })
        .fail((ex) => {
          console.log('An error occurred in ending call.');
          reject(ex);
        });
    });
  }

  initializeState(): void {
    this.callConnected = false;
    this.showCallControls = false;
    this.isWhiteboardShown = false;
    this.toggleOrder(false);

    this.whiteboardSwitch.checked = false;
    this.videoSwitch.checked = false;
    this.imageSwitch.checked = false;
    this.fullScreenSwitch.checked = false;

    this.localMedia.start().then(() => {
      console.log('Local Media started.');
      this.layoutManagerLocal = new liveswitch.DomLayoutManager(this.localVideoView.nativeElement);

      this.layoutManagerLocal.setLocalView(this.localMedia.getView());
      this.showLocalViewUserIcon = false;
    });
  }

  enableStereoOpusOnChrome(): void {
    this.callConnection.addOnLocalDescription((conn, sessionDescription) => {
      const sdpMessage = sessionDescription.getSdpMessage();
      const audioDescription = sdpMessage.getAudioDescription();
      const rtpMapAttribute = audioDescription.getRtpMapAttribute('opus', 48000, '2');
      if (rtpMapAttribute) {
        const formatParametersAttribute = rtpMapAttribute.getRelatedFormatParametersAttribute();
        formatParametersAttribute.setFormatSpecificParameter('stereo', '1');
      }
    });
  }

  onChangeImageSwitch(): void {
    if (this.imageSwitch.checked) {
      this.videoSwitch.checked ? (this.videoSwitch.checked = false) : (this.whiteboardSwitch.checked = false);

      // Sending client device, image data so that client can show the image to user
      this.callChannel.sendDeviceMessage(
        this.inCallUser.userId,
        this.inCallUser.deviceId,
        `image:${this.selectedImage.url}`
      );
    } else {
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'image-close-cmd');

      if (this.isWhiteboardShown) {
        this.whiteboardSwitch.checked = true;
      }
    }
  }

  onChangeImageSelect(): void {
    if (this.callConnected && this.imageSwitch.checked) {
      // Sending client device, image data so that client can show the image to user
      this.callChannel.sendDeviceMessage(
        this.inCallUser.userId,
        this.inCallUser.deviceId,
        `image:${this.selectedImage.url}`
      );
    }
  }

  onChangeVideoSwitch(): void {
    if (this.videoSwitch.checked) {
      this.imageSwitch.checked ? (this.imageSwitch.checked = false) : (this.whiteboardSwitch.checked = false);

      // Sending client device, video data so that client can show the image to user
      this.callChannel.sendDeviceMessage(
        this.inCallUser.userId,
        this.inCallUser.deviceId,
        `video:${this.selectedVideo.url}`
      );
    } else {
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'video-close-cmd');

      if (this.isWhiteboardShown) {
        this.whiteboardSwitch.checked = true;
      }
    }
  }

  onChangeVideoSelect(): void {
    if (this.callConnected && this.videoSwitch.checked) {
      // Sending client device, video data so that client can show the image to user
      this.callChannel.sendDeviceMessage(
        this.inCallUser.userId,
        this.inCallUser.deviceId,
        `video:${this.selectedVideo.url}`
      );
    }
  }

  switchFullScreenMode(event: boolean): any {
    if (event) {
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'fs-open-cmd');
    } else {
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'fs-close-cmd');
    }
  }

  async switchToWhiteBoard(event: any): Promise<any> {
    let localMedia;
    this.isWhiteboardShown = event;

    // switch off video and image switches
    this.videoSwitch.checked ? (this.videoSwitch.checked = false) : (this.imageSwitch.checked = false);

    // Wait for canvas to load
    await this.appService.sleep(0);

    if (!this.isWhiteboardShown) {
      localMedia = new liveswitch.LocalMedia(audio, video);

      // Send message to manage view according to whiteboard
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'wb-close-cmd');
    } else {
      localMedia = new liveswitch.LocalMedia(audio, this.canvasWhiteboard.canvas.nativeElement.captureStream());

      // Send message to manage view according to whiteboard
      this.callChannel.sendDeviceMessage(this.inCallUser.userId, this.inCallUser.deviceId, 'wb-open-cmd');
    }

    localMedia.start().then(() => {
      console.log('Local Media started.');
      this.layoutManagerLocal = new liveswitch.DomLayoutManager(this.localVideoView.nativeElement);

      this.layoutManagerLocal.setLocalView(localMedia.getView());
      this.showLocalViewUserIcon = false;
    });

    this.callConnection
      .close()
      .then((result) => {
        console.log('Connection Closed.');
      })
      .fail((ex) => {
        console.log('An error occurred in ending call.');
      });

    const audioStream = new liveswitch.AudioStream(localMedia, this.remoteMedia);
    const videoStream = new liveswitch.VideoStream(localMedia, this.remoteMedia);
    this.callConnection = this.callChannel.createSfuUpstreamConnection(audioStream, videoStream);

    this.prepareConnection();
    this.enableStereoOpusOnChrome();

    this.callConnection
      .open()
      .then((result) => {
        console.log('Connection opened.');
        this.initWhiteBoard();
        this.viewCallMedia();
      })
      .fail((ex) => {
        console.log('An error occurred in opening connection.');
      });
  }

  initWhiteBoard(): void {
    const updateOptions: CanvasWhiteboardShapeOptions = new CanvasWhiteboardShapeOptions();
    updateOptions.fillStyle = 'rgba(0,0,0,0)';
    updateOptions.lineCap = 'round';
    updateOptions.lineJoin = 'round';
    updateOptions.lineWidth = 5;
    updateOptions.shouldFillShape = true;
    updateOptions.strokeStyle = 'rgba(0,0,0,0)';
    const updates: CanvasWhiteboardUpdate[] = [
      new CanvasWhiteboardUpdate(0, 0, 0, '6615c08a-59d2-7933-d375-56c15d1a8359', 'FreeHandShape', updateOptions),
      new CanvasWhiteboardUpdate(0, 0, 2, '6615c08a-59d2-7933-d375-56c15d1a8359', undefined, undefined),
    ];

    for (let i = 0; i <= 5; i++) {
      setTimeout(() => {
        this.canvasWhiteboardService.drawCanvas(updates);
      }, i * 1000);
    }
  }

  toggleCallControls(): void {
    this.showCallControls = !this.showCallControls;
  }

  uploadImage(event): void {
    const file = event.target.files[0];
    this.firebaseService.uploadFileToStorage(file, this.PRACTICE_ID, 'images');
  }

  uploadVideo(event): void {
    const file = event.target.files[0];
    this.firebaseService.uploadFileToStorage(file, this.PRACTICE_ID, 'videos');
  }

  getDataFromFirebase(): void {
    this.firebaseService.getAttachments(this.PRACTICE_ID, 'images').subscribe((data) => {
      this.imageFiles = data;
      this.selectedImage = this.imageFiles[0];
    });

    this.firebaseService.getAttachments(this.PRACTICE_ID, 'videos').subscribe((data) => {
      this.videoFiles = data;
      this.selectedVideo = this.videoFiles[0];
    });
  }

  setUserWaitingTime(remoteClientInfo): void {
    const clientUserId = remoteClientInfo.getUserId();
    const waitingTimeObj: WaitingTime = {
      timer: '',
      timestamp: 0,
      interval: 0,
      provider: '...',
    };
    this.waitingTime[clientUserId] = { ...waitingTimeObj };

    this.firebaseService
      .getWaitingUsersInfo(this.CHANNEL_ID, remoteClientInfo.getUserId())
      .subscribe((data: WaitingUsersInfo) => {
        this.waitingTime[clientUserId].timestamp = Number(data.timestamp);
        if (data.providerName) {
          this.waitingTime[clientUserId].provider = data.providerName;
          this.waitingTime[clientUserId].patient_row_id = data.patient_row_id;
          this.waitingTime[clientUserId].providerId = data.providerId;
        }
      });
  }

  setIntervalForWaitingUser(remoteClientInfo: liveswitch.ClientInfo): void {
    const clientUserId = remoteClientInfo.getUserId();

    this.waitingTime[clientUserId].interval = setInterval(() => {
      if (!!this.waitingTime[clientUserId]) {
        const currTime = new Date().getTime();
        const waitingTimeInMillis = currTime - this.waitingTime[clientUserId].timestamp;
        const waitingTime = moment.duration(waitingTimeInMillis);
        let y = waitingTime.hours() > 0 ? this.setTimeFormat(waitingTime.hours()) + ':' : '';
        y += this.setTimeFormat(waitingTime.minutes()) + ':' + this.setTimeFormat(waitingTime.seconds());

        this.waitingTime[clientUserId].timer = y;
      }
    }, 1000);
  }

  setTimeFormat(time: number): string {
    if (time < 10) {
      return `0${time}`;
    }

    return `${time}`;
  }

  logout(): void {
    this.authService.logout();
  }

  onViewExpandClick(): void {
    this.expandCallView = !this.expandCallView;
    this.appService.switchNavbarVisibility(!this.expandCallView);
  }
}
