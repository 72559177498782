<div class="page-title__bar">
  <h2 class="title">User Management</h2>

  <div class="user-actions">
    <div *ngIf="userRole === 'practice_admin'" class="input-group" (keyup.enter)="filterUsers(searchBar.value)">
      <input #searchBar type="search" class="form-control change" placeholder="Search By Email" />
      <div class="input-group-append" (click)="filterUsers(searchBar.value)" style="cursor: pointer">
        <span class="input-group-text">
          <img src="../../../assets/icons/daily-screening.svg" alt="search" />
        </span>
      </div>
    </div>
    <button class="btn add-user-btn" (click)="addUser()">
      {{ userRole === "user" ? "Add User" : "Invite User" }}<i class="fa fa-plus ml-2" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
    <div class="right__panel calendar-data__wrapper">
      <div class="table-data__wrapper table-responsive calendar-data--table">
        <app-table-pagination-client
          [headers]="userRole === 'practice_admin' ? tableHeaders : referralTableHeaders"
          [data]="usersData"
          [hasActions]="true"
          [toggleUserStatus]="toggleUserStatus"
          [changeUserStaus]="true"
          [userRoleDeleteFunction]="
          userRole === 'practice_message_admin'
            ? null
            : (userRole === 'practice_admin' || userRole === 'practice_admin_sante_community' || userRole === 'practice_admin_sante_community_order')
            ? deleteUser
            : deleteLocationUser
        "
          [pageSize]="pageSize"
          [resetPassword]="resetPassword"
          [editFunction]="editUser"
          (setTotalPages)="setTotalPages($event)"
          [currentPage]="currentPage"
          [pageSize]="pageSize"
          [paginateId]="1"
          [pageSize]="10"
          [currentPage]="1"
          [currentUser]="currentUser"
        >
        </app-table-pagination-client>
      </div>
    </div>
  </div>
</div>
