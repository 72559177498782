<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <strong>SMS Script</strong>
  </h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <fieldset class="form-group mt-3 mb-4 p-3">
      <legend class="w-auto px-1">{{ title }}</legend>
      <form (submit)="type === 'create' ? createScript() : updateScript()">
        <!-- Script Field -->
        <div class="text-area">
          <div class="title-bar">
            <h6>Script:</h6>
            <span class="hint" *ngIf="scriptData.type != 'R'"
              >(The buttons below can be used to used to append dynamic variables)</span
            >
          </div>

          <textarea
            #script
            ngbAutofocus
            class="form-control"
            [(ngModel)]="scriptData.script"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Enter the new script"
          ></textarea>
          <div class="script-actions" *ngIf="scriptData.type != 'R'">
            <button type="button" class="btn btn-light" (click)="appendToScript('${patient_first_name}')">
              Patient First Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${patient_last_name}')">
              Patient Last Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_location}')">
              Location Adress
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_city}')">City</button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${provider_name}')">
              Provider Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${provider_first_name}')">
              Provider First Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${provider_last_name}')">
              Provider Last Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${practice_name}')">
              Practice Name
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${client_description}')">
              Client Description
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${appt_scheduled_date}')">
              Appointment Date
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${appt_scheduled_date_short}')">
              Appointment Date Short
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${appt_scheduled_time}')">
              Appointment Time
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_address_2}')">
              Location Address 2
            </button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_zip}')">Zip</button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_phone}')">Phone</button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_state}')">State</button>
            <button type="button" class="btn btn-light" (click)="appendToScript('${facility_description}')">
              Facility Name
            </button>
            <button
              *ngIf="scriptData.type != 'V'"
              type="button"
              class="btn btn-light"
              (click)="appendToScript('${patient_pwa_url}')"
            >
              PWA URL
            </button>
          </div>
        </div>

        <!-- Event Field -->
        <div class="d-flex mt-5">
          <div class="input-group">
            <h6>Event:</h6>
            <input
              class="form-control mr-3"
              [(ngModel)]="scriptData.event_type"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Enter Event Type"
            />
          </div>

          <!-- Type Field -->
          <div class="input-group">
            <h6>Type:</h6>
            <select class="form-control ml-1" [(ngModel)]="scriptData.type" [ngModelOptions]="{ standalone: true }">
              <option value="" disabled hidden selected>Select Type</option>
              <option disabled hidden value="O">Order SMS</option>
              <option value="A">Appointment SMS</option>
              <option value="V">Appointment Call</option>
              <option value="R">Autoresponder Script</option>
              <option value="C">Confirmation Script</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary btn-fr mt-3 action-btn"
          [disabled]="!scriptData.script || !scriptData.event_type || !scriptData.type"
        >
          Save<i class="fa fa-floppy-o ml-2" aria-hidden="true"></i>
        </button>
      </form>

      <button class="btn btn-danger btn-fr mt-3" (click)="activeModal.dismiss('Cross click')">
        Cancel<i class="fa fa-times ml-2" aria-hidden="true"></i>
      </button>
    </fieldset>
  </div>
</div>
