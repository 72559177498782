<div class="page-title__bar">
  <h2 class="title">Templates</h2>
</div>

<div class="right-container">
  <div class="filter__panel btn-demo d-flex align-items-center justify-content-end">
    <button class="btn add-user-btn" (click)="uploadPdfFile()">Upload PDF</button>
  </div>
</div>

<div class="tab-content" id="myTabContent">
  <div class="right__panel calendar-data__wrapper">
    <div class="table-data__wrapper table-responsive calendar-data--table">
      <app-table-pagination-client
        [headers]="['file_name', 'insurance']"
        [data]="storageFiles"
        [hasActions]="true"
        [previewFunction]="previewFile"
        [pageSize]="10"
        [currentPage]="1"
      >
      </app-table-pagination-client>
    </div>
  </div>
</div>
