import { DateTime } from 'luxon';
import { GetPracticeByIdApiResp, PracticeInfoForUser } from './../models/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { GET_REGISTERED_LOCATION_DATA } from '../models/auth';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  userRole: string = '';

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private firebaseService: FirebaseService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private http: HttpClient
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.setUserRole();
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  login(email: string, password: string): void {
    this.spinnerService.show();
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((success) => {
        this.spinnerService.hide();
        this.router.navigate(['dashboard']);
      })
      .catch((error) => {
        this.showToast(error.message, 'error');
        this.spinnerService.hide();
      });
  }

  logout(): void {
    this.spinnerService.show();
    this.afAuth.signOut().then(() => {
      this.spinnerService.hide();
      // this.firebaseService = null;
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  // sendResetEmail(email:string){

  //   const actionCodeSettings = {
  //     url: `${environment.FRONTEND_URL}/reset-password`,
  //     handleCodeInApp: true,
  //   };

  //   this.spinnerService.show();
  //   this.afAuth
  //     .sendPasswordResetEmail(email,actionCodeSettings)
  //     .then(() => {
  //       this.spinnerService.hide();
  //       this.showToast('Password reset email sent. Please check your inbox.', 'success');
  //     })
  //     .catch((error) => {
  //       this.spinnerService.hide();
  //       this.showToast(error.message, 'error');
  //     });
  // }

  sendResetEmail(email: string) {
    const body = { email };
    return this.http.post<any>(`${environment.BASE_URL}/auth/web/reset-password`, body);
  }
  

  resetPassword(resetCode:string,newPassword:string) {
      this.afAuth
        .confirmPasswordReset(resetCode, newPassword)
        .then(() => {
          this.showToast('Password has been reset successfully!', 'success');
          this.router.navigate(['/login']);
        })
        .catch((error) => {
          this.showToast(error.message, 'error');
        });
  }

  async doesUserExist(email: string): Promise<boolean> {
    this.spinnerService.show();

    return this.afAuth
      .fetchSignInMethodsForEmail(email)
      .then((data) => {
        this.spinnerService.hide();
        if (data.length === 0) {
          return false;
        }

        return true;
      })
      .catch((error) => {
        this.spinnerService.hide();
        this.showToast(error.message, 'error');
        return false;
      });
  }

  checkPracticeIdAndRegister(practiceId: string, email: string, password: string): any {
    const self = this;
    this.spinnerService.show();
    return this.http.post(`${environment.GET_PRACTICE_BY_ID}/`, { practice_id: practiceId }).subscribe({
      next(resp: GetPracticeByIdApiResp): void {
        self.spinnerService.hide();

        switch (resp.code) {
          case 200:
            self.registerUser(email, password, resp.data);
            break;
          case 404:
            self.showToast('Practice Not Found', 'error');
            break;
          case 500:
            self.showToast("Couldn't complete request due to server error", 'error');
        }
      },
      error(err): void {
        self.spinnerService.hide();
        self.showToast("Couldn't complete request", 'error');
      },
    });
  }

  registerUser(email: string, password: string, practiceData: PracticeInfoForUser): void {
    this.spinnerService.show();
    this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential: any) => {
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            user
              .updateProfile({
                displayName: practiceData.practice_id.toLowerCase(),
              })
              .then(() => {
                this.spinnerService.hide();
                const data = { uid: userCredential.user.uid, role: 'practice_admin' };
                this.firebaseService.updateUserRole(data);
                this.addUserToDB(userCredential.user.uid, userCredential.user.email, practiceData);
              })
              .catch((error) => {
                this.spinnerService.hide();
                this.showToast(error.message, 'error');
              });
          }
        });
      })
      .catch((error) => {
        this.spinnerService.hide();
        this.showToast(error.message, 'error');
      });
  }

  addUserToDB(uid: string, email: string, practiceData: PracticeInfoForUser): void {
    const { colorFlag, practice_id, practice_name, dbFlag } = practiceData;
    const client_id = practiceData.client?.id;
    const client_name = practiceData.client?.description;
    const userInfo = {
      email,
      practiceId: practice_id,
      practiceName: practice_name,
      colorFlag,
      dbFlag,
      client_id,
      client_name,
      createdAt: DateTime.now().toMillis(),
    };

    const dbRef = this.db.database.ref(`vsa-assistants/${practiceData.practice_id.toLowerCase()}`).child(uid);
    const self = this;
    this.spinnerService.show();
    dbRef
      .set(userInfo)
      .then((data) => {
        self.spinnerService.hide();
        self.showToast('User Registered', 'success');
        localStorage.removeItem('user');
        this.afAuth.signOut();
        this.router.navigate(['login']);
      })
      .catch((error) => {
        self.spinnerService.hide();
        self.showToast(error.message, 'error');
      });
  }

  showToast(message, type): void {
    Swal.fire({
      icon: type,
      title: `${message}`,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  async getUserRole(): Promise<any> {
   
    if (this.userRole) return this.userRole;
  
   
    return await this.returnUserRole();
  }
  
  async returnUserRole(): Promise<any> {
  
    const currentUser = await this.afAuth.currentUser;
    const token = await currentUser?.getIdTokenResult();
  
    if (token?.claims?.role) {
      this.userRole = token.claims.role;
    } else {
      this.userRole = 'practice_admin';
    }
   
    localStorage.setItem('role', JSON.stringify(this.userRole));
  
    return this.userRole;
  }

  async setUserRole(): Promise<any> {
    (await this.afAuth.currentUser).getIdTokenResult().then((token) => {
      if (token.claims?.role) {
        this.userRole = token.claims.role;
      } else this.userRole = 'practice_admin';
      localStorage.setItem('role', JSON.stringify(this.userRole));
    });
  }

  async doesLocationAdminExist(location_id: string): Promise<any> {
    return await this.http.get<any>(`${environment.AUTH}/location/${location_id}/account`).toPromise();
  }

  async doesLocationUserExist(location_id: string, user_id: string): Promise<any> {
    return await this.http.get<any>(`${environment.AUTH}/location/${location_id}/user/${user_id}/account`).toPromise();
  }

  async registerLocationAdmin(location_id: string, data: any): Promise<GET_REGISTERED_LOCATION_DATA> {
    return await this.http
      .post<GET_REGISTERED_LOCATION_DATA>(`${environment.AUTH}/location/${location_id}/user-account`, data)
      .toPromise();
  }

  async registerLocationUser(location_id: string, user_id: string, data: any): Promise<GET_REGISTERED_LOCATION_DATA> {
    return await this.http
      .post<GET_REGISTERED_LOCATION_DATA>(
        `${environment.AUTH}/location/${location_id}/user/${user_id}/user-account`,
        data
      )
      .toPromise();
  }

  async resendVerificationEmail(token: string, data: { email: string }): Promise<any> {
    return await this.http
      .post<any>(`${environment.AUTH}/location/send-verify-email`, data, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }
}
