import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericResp, NotifyUserReq } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: HttpClient) {}
  private selectedUserSMSRestricted: boolean;

  getRestrictedStatus(): boolean {
    return this.selectedUserSMSRestricted;
  }

  setRestrictedStatus(status: boolean) {
    this.selectedUserSMSRestricted = status;
  }

  async sendUserSMS(conversation_id: string, data: any): Promise<GenericResp> {
    return await this.http.post<GenericResp>(`${environment.MESSAGES}/${conversation_id}/send-sms`, data).toPromise();
  }

  async notifyUserOfNewMessages(conversation_id: string, data: NotifyUserReq): Promise<GenericResp> {
    return this.http.post<GenericResp>(`${environment.MESSAGES}/${conversation_id}/notify`, data).toPromise();
  }

  async resetPIN(client_id: string, patient_id: string): Promise<GenericResp> {
    return await this.http
      .delete<GenericResp>(`${environment.USER_ACCOUNT}/client/${client_id}/patient/${patient_id}/reset/pin`)
      .toPromise();
  }

  uploadMessagesCsv(file: File, messageBody: string, phone_number_key: string): Observable<any> {
    let header = new HttpHeaders();
    const user = JSON.parse(localStorage.getItem('user'));
    header.append('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('messageBody', messageBody);
    formData.append('phone_number_key', phone_number_key);
    return this.http.post<any>(`${environment.BASE_URL}/webhook/csv`, formData, {
      headers: { Authorization: `${user.stsTokenManager.accessToken}` },
    });
  }

  async sendLinkToPatient(patient_id: string): Promise<any> {
    return await this.http.get<any>(`${environment.BASE_URL}/${patient_id}/get-link`).toPromise();
  }
}
