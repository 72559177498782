<div class="page">
  <div class="page-title__bar">
    <div>
      <h2 class="title">Statements</h2>
    </div>

    <div class="filter__panel btn-demo">
      <div class="d-flex align-items-center">
        <button class="btn add-user-btn" (click)="uploadAttachment()">Upload Statement</button>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
      <div class="right__panel calendar-data__wrapper">
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="tableHeaders"
            [data]="attachmentCompleteTableData"
            [pageSize]="paginationPageSize"
            [currentPage]="1"
            [paginateId]="1"
          >
          </app-table-pagination-client>
        </div>
      </div>
    </div>
  </div>
  <div class="page-title__bar">
    <div>
      <h2 class="title">Autopay File</h2>
    </div>

    <div class="filter__panel btn-demo">
      <div class="d-flex align-items-center">
        <button class="btn add-user-btn" (click)="uploadCSV()">Upload File</button>
      </div>
    </div>
  </div>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
      <div class="right__panel calendar-data__wrapper">
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="autopayTableHeaders"
            [data]="autopayCompleteTableData"
            [pageSize]="paginationPageSize"
            [currentPage]="1"
            [paginateId]="2"
          >
          </app-table-pagination-client>
        </div>
      </div>
    </div>
  </div>
</div>
