import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ToastrService } from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DeviceEvent, GenericResp } from '../models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { getAuth, onIdTokenChanged, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  assistantInfo = null;
  existingEntriesChecked: boolean = false;
  dbRef: firebase.default.database.Reference = null;
  private currentUser: User | null = null;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    const firebaseConfig = environment.firebaseConfig;

    const app = initializeApp(firebaseConfig);
    const authInstance = getAuth(app);

    if (localStorage.getItem('user')) {
      this.setAssistantInfo();
    }

    onIdTokenChanged(authInstance, (user) => {
      if (user) {
        this.currentUser = user;
        user.getIdToken(true).then((token) => {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
        });
      } else {
        this.currentUser = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    });
  }

  getCurrentUser(): User | null {
    return this.currentUser;
  }

  setAssistantInfo(): any {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return this.db
        .object(`/vsa-assistants/${user.displayName}/${user.uid}`)
        .snapshotChanges()
        .pipe(
          map((c) => ({ key: c.payload.key, ...(c.payload.val() as object) }))
        );
    }
  }

  /* Type can be 'Images', or 'Videos' */
  uploadFileToStorage(file: any, userId: string, fileType: string): any {
    const filePath = `vsa/${fileType}/${userId}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    let fileUrl: string;

    this.storage
      .upload(filePath, file)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((url) => {
            if (url) {
              fileUrl = url;
            }

            const objToUpload = {
              name: file.name,
              url: fileUrl,
              created_timestamp: DateTime.now().toMillis(),
            };
            this.uploadToDatabase(objToUpload, fileType, userId);
          });
        })
      )
      .subscribe();
  }

  /* Type will separate collection where the file will be stored.
    Type can be 'image' or 'video' */
  uploadToDatabase(objToUpload, type, userId): void {
    const basePath = `/vsa-attachments/${type}/${userId}/`;
    this.db
      .list(basePath)
      .push(objToUpload)
      .then((data) => {
        this.showConfirmationDialog('File Uploaded', 'success');
      })
      .catch((err) => {
        this.showConfirmationDialog('File could not be uploaded', 'error');
      });
  }

  getAttachments(userId: string, fileType: string): any {
    return this.db
      .list(`/vsa-attachments/${fileType}/${userId}/`)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }))
        )
      );
  }

  getWaitingUsersInfo(channelId: string, userId: string): any {
    return this.db
      .object(`/vsa-waiting-users/${channelId}/${userId}`)
      .snapshotChanges()
      .pipe(
        map((c) => ({ key: c.payload.key, ...(c.payload.val() as object) }))
      );
  }

  async getAssistant(uid: string, practiceId: string): Promise<any> {
    if (this.assistantInfo) return this.assistantInfo;

    this.assistantInfo = await this.getAssistantInfo(uid, practiceId);
    return this.assistantInfo;
  }

  async getAssistantInfo(uid: string, practiceId: string): Promise<any> {
    const eventRef = this.db.database.ref(
      `/vsa-assistants/${practiceId}/${uid}`
    );
    let snapshot = await eventRef.once('value');

    return snapshot.val();
  }

  async registerAlertServiceForNewMotionEvent(): Promise<any> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    await this.getAssistant(loggedInUser.uid, loggedInUser.displayName);

    const self = this;
    this.dbRef = this.db.database.ref(
      `/vsa-motion-events/${this.assistantInfo.practiceId.toLowerCase()}/`
    );

    this.dbRef.on('child_added', (snapshot) => {
      if (!this.existingEntriesChecked) return;

      const motionEvent: DeviceEvent = snapshot.val();
      const motionEventTime = DateTime.fromMillis(+motionEvent.timestamp)
        .setZone('America/Los_Angeles')
        .toLocaleString(DateTime.DATETIME_SHORT);

      self.toastr.success(
        `Motion Detected in Room '${motionEvent.roomId}' at ${motionEventTime}`,
        'Active Device',
        {
          progressBar: true,
        }
      );
    });

    this.dbRef.once('value', (res) => {
      self.existingEntriesChecked = true;
    });
  }

  unregisterAlertServiceForNewMotionEvent(): void {
    this.assistantInfo = null;
    this.existingEntriesChecked = false;
    this.dbRef.off('child_added');
    this.dbRef.off('value');
    this.dbRef = null;
  }

  showConfirmationDialog(title: string, dialogType: any): void {
    Swal.fire({
      position: 'top-end',
      icon: dialogType,
      title: `${title}`,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  async updateUserRole(data: {
    uid: string;
    role: string;
  }): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.BASE_URL}/firebase/user/role`, data)
      .toPromise();
  }
}
