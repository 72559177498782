import { NoNegNumberPipe } from './pipes/no-neg-number.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { TableComponent } from './components/table/table.component';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';
import { TablePaginationClientComponent } from './components/table-pagination-client/table-pagination-client.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { VgControlsModule, VgCoreModule } from 'ngx-videogular';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { ShowImageModalComponent } from './components/show-image-modal/show-image-modal.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { customFieldFormatPipe } from './pipes/custom-field-format.pipe';
import { CdkTableModule } from '@angular/cdk/table';
import { TableDraggableComponent } from './components/table-draggable/table-draggable.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddBenefitInsuranceModalComponent } from './components/add-benefit-insurance-modal/add-benefit-insurance-modal.component';
import { AddAppointmentsComponent } from './components/add-appointments/add-appointments.component';
import { NotifyButtonComponent } from './components/notify-button/notify-button.component';
import { NotificationPillsComponent } from './components/notification-pills/notification-pills.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PreviewFormModalComponent } from './components/preview-form-modal/preview-form-modal.component';
import { FormResponseModalComponent } from './components/form-response-modal/form-response-modal.component';
import { HorizontalNotificationComponent } from './components/horizontal-notification/horizontal-notification.component';
// import { VsaPreviewFormsModule } from 'vsa-preview-forms';
import { FormRulesModalComponent } from './components/form-rules-modal/form-rules-modal.component';
import { FormGroupsModalComponent } from './components/form-groups-modal/form-groups-modal.component';
import { OrderDetailsPanelComponent } from './components/order-details-panel/order-details-panel.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'; // install 
import { RenameModalComponent } from './components/rename-modal/rename-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SmsScriptsModalComponent } from './components/sms-scripts-modal/sms-scripts-modal.component';
// import { SmsBillingComponent } from '../dashboard/sms-billing/sms-billing.component';
import { VsaPreviewFormsComponent } from './components/vsa-preview-forms/vsa-preview-forms.component';
import { SmsAndCallInfoModal } from './components/sms-and-call-info-modal/sms-and-call-info-modal';
import { TwoDigitDecimaNumberDirective } from './pipes/two-digit-decimal.number.directive';
import { ConversationManagementComponent } from './components/conversation-management/conversation-management.component';
import { GetUserMessageActivityReportComponent } from './components/get-user-message-activity-report/get-user-message-activity-report.component';
import { QuickResponseModalComponent } from './components/quick-response-modal/quick-response-modal.component';
import { MessagesResponseModalComponent } from './components/messages-response-modal/messages-response-modal.component';
import { SendMessageModalComponent } from './components/send-message-modal/send-message-modal.component';
import { AuthorizationFormsComponent } from './components/authorization-forms/authorization-forms.component';
import { PDFViewerComponent } from './components/pdfviewer/pdfviewer.component';


const maskConfig = {
    validation: false,
  };



@NgModule({ declarations: [
    VsaPreviewFormsComponent,
        SmsAndCallInfoModal,
        NavbarComponent,
        HeaderComponent,
        TableComponent,
        ReplaceUnderscorePipe,
        NoNegNumberPipe,
        customFieldFormatPipe,
        TablePaginationClientComponent,
        FilterModalComponent,
        ShowImageModalComponent,
        TableDraggableComponent,
        AddBenefitInsuranceModalComponent,
        AddAppointmentsComponent,
        NotifyButtonComponent,
        NotificationPillsComponent,
        PreviewFormModalComponent,
        FormResponseModalComponent,
        HorizontalNotificationComponent,
        FormRulesModalComponent,
        FormGroupsModalComponent,
        OrderDetailsPanelComponent,
        RenameModalComponent,
        SmsScriptsModalComponent,
        // SmsBillingComponent,
        TwoDigitDecimaNumberDirective,
        ConversationManagementComponent,
        GetUserMessageActivityReportComponent,
        QuickResponseModalComponent,
        MessagesResponseModalComponent,
        SendMessageModalComponent,
        AuthorizationFormsComponent,
        PDFViewerComponent
    ],
    exports: [
        VsaPreviewFormsComponent,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatSidenavModule,
        ToastrModule,
        VgCoreModule,
        VgControlsModule,
        NavbarComponent,
        HeaderComponent,
        TableComponent,
        NoNegNumberPipe,
        customFieldFormatPipe,
        NgxPaginationModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TablePaginationClientComponent,
        ShowImageModalComponent,
        ColorPickerModule,
        NgbModule,
        CdkTableModule,
        TableDraggableComponent,
        DragDropModule,
        MatTableModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        SignaturePadModule,
        AddBenefitInsuranceModalComponent,
        AddAppointmentsComponent,
        OrderDetailsPanelComponent,
        RenameModalComponent,
        SmsAndCallInfoModal,
        TwoDigitDecimaNumberDirective,
        ConversationManagementComponent,
    ], imports: [CommonModule,
        FormsModule,
        ColorPickerModule,
        ReactiveFormsModule,
        VgCoreModule,
        VgControlsModule,
        NgxSpinnerModule,
        MatSidenavModule,
        ToastrModule.forRoot(),
        NgxPaginationModule,
        RouterModule,
        NgbModule,
        CdkTableModule,
        DragDropModule,
        MatTableModule,
        MatIconModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        SignaturePadModule,
        NgxMaskDirective,
        NgxMaskPipe,
        // VsaPreviewFormsModule,
        CKEditorModule,
    ], providers: [provideHttpClient(withInterceptorsFromDi()),provideNgxMask(maskConfig)] })
export class SharedModule {}
