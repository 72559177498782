<div *ngIf="true">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1" *ngIf="this.data?.conversation?.id">
      <a class="ngbNavLink" ngbNavLink>Categories</a>
      <ng-template ngbNavContent>
        <div *ngIf="this.data && this.selectedPatient" class="d-flex">
          <h6>
            Patient Name:
            <strong>{{ this.selectedPatient?.last_name }} , {{this.selectedPatient?.first_name}}</strong>
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            Phone:
            <strong>{{ this.selectedPatient.cell_phone }}</strong>
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="changePhonePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB:
            <strong>{{ this.selectedPatient.date_of_birth | date : 'MMMM d,y' }}</strong>
          </h6>
          <h6 class="ml-5">
            Current Category:
            <strong>{{
              this.data.conversation.custom?.catagory?.length > 0
                ? this.data.conversation.custom.catagory
                : "Not Specified"
            }}</strong>
          </h6>
        </div>
        <div class="order-details col-md-12 text-left pl-0">
          <div class="row pb-1">
            <div class="col-md-4 text-right order-div-col mt-1">Update Category:</div>
            <div class="col-md-6 pl-0 mt-2">
              <select class="order-inputs" (change)="changeCatagory($event)">
                <option value="UC">UC</option>
                <option value="New Pt.">New Pt.</option>
                <option value="Est. Pt.">Est. Pt.</option>
                <option value="Rx">Rx</option>
                <option value="Cx">Cx</option>
                <option value="R/S">R/S</option>
                <option value="Confirm">Confirm</option>
                <option value="TH">TH</option>
                <option value="PT">PT</option>
                <option value="Imaging">Imaging</option>
                <option value="Referrals">Referrals</option>
                <option value="Fax">Fax</option>
                <option value="No Reply">No Reply</option>
                <option value="Not Need Help">Not Need Help</option>
                <option value="PP/VS">PP/VS</option>
                <option value="Notes">Notes</option>
                <option value="Insurance">Insurance</option>
                <option value="Sx/inj">Sx/inj</option>
                <option value="Call">Call</option>
                <option value="Labs">Labs</option>
                <option value="EDD">EDD</option>
                <option value="Med. Rec.">Med. Rec.</option>
              </select>
            </div>
            <button class="btn btn-primary" (click)="updateConversation()">Update</button>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="this.data?.conversation?.id && admin">
      <a class="ngbNavLink" ngbNavLink>Assign Conversation</a>
      <ng-template ngbNavContent>
        <h6>
          Currently Assigned to:
          <strong>{{
            this.data.conversation.custom.assignedTo ? this.data.conversation.custom.assignedTo : "Not Specified"
          }}</strong>
        </h6>
        <div *ngIf="this.data?.conversation?.id && this.data?.conversation?.id" class="d-flex">
          <div class="order-details col-md-12 text-left pl-0">
            <div class="row pb-1">
              <div class="col-md-4 text-right order-div-col mt-1">Assign Chat To:</div>
              <p *ngIf="this.webUsers?.length < 1">loading ....</p>
              <div *ngIf="this.webUsers?.length > 0" class="col-md-6 pl-0 mt-2">
                <select class="order-inputs" (change)="changeEmail($event)">
                  <option *ngFor="let user of this.webUsers" [value]="user">
                    {{ user }}
                  </option>
                </select>
              </div>
              <button class="btn btn-primary ml-2" (click)="AssignUser()">Update</button>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
