<div class="right__panel">
  <main class="container-fluid">
    <div class="row">
      <!-- Left Panel -->
      <div class="left-panel col-md-8">
        <!-- In-Call User View -->
        <div
          class="remote-user remote-user-call"
          [ngClass]="expandCallView ? 'remote-user-call-expand' : 'remote-user-call-collapse'"
        >
          <!-- Functions -->
          <div class="call-functions">
            <i *ngIf="!expandCallView" class="fas fa-expand grey" (click)="onViewExpandClick()"></i>
            <i *ngIf="expandCallView" class="fas fa-compress grey" (click)="onViewExpandClick()"></i>
            <i class="far fa-window-restore grey" (click)="toggleCallControls()"></i>
            <i *ngIf="callConnected" class="fa fa-first-order grey" (click)="toggleOrder(!this.showHideOrder)"></i>
            <!-- <i class="fas fa-compress-alt grey"></i> -->
          </div>

          <!-- End Call -->
          <div *ngIf="callConnected" class="end-call">
            <button class="btn btn-danger" (click)="endCall()">End Call</button>
          </div>

          <!-- Show User Icon incase of No Call, else show Call View-->
          <div #callVideoView class="user-icon-call main-call-view">
            <div *ngIf="!callConnected" class="user-icon-position">
              <i class="fas fa-user grey"></i>
            </div>
          </div>
        </div>

        <div class="row mx-0 live-streaming-users">
          <div class="col-md-3" *ngFor="let v of remoteViews">
            <div
              #remoteVideoView
              (click)="initiateCall(v)"
              class="remote-user-live-streaming user-icon user-icon-live-streaming hover-transition"
            ></div>
          </div>
        </div>
      </div>

      <!-- Right Panel -->
      <div class="right-panel col-md-4" [ngClass]="!showHideOrder ? 'showElement' : 'hideElement'">
        <!-- Call Controls -->
        <div class="call-controls-wrapper container" [ngClass]="showCallControls ? 'showElement' : 'hideElement'">
          <div class="control-item">
            <select [(ngModel)]="selectedVideo" (change)="onChangeVideoSelect()">
              <option *ngFor="let video of videoFiles" [ngValue]="video">
                {{ video.name }}
              </option>
            </select>

            <label for="fileChooserVideo" class="btn btn-primary uploadButton">+</label>
            <input type="file" accept="video/*" name="videoFile" id="fileChooserVideo" (change)="uploadVideo($event)" />

            <mat-slide-toggle color="cyan" *ngIf="callConnected" #videoSwitch (change)="onChangeVideoSwitch()"></mat-slide-toggle>
          </div>

          <!-- Whiteboard -->
          <div class="control-item" *ngIf="callConnected">
            <h4>Whiteboard</h4>
            <mat-slide-toggle
              color="cyan"
              *ngIf="callConnected"
              #whiteboardSwitch
              (change)="switchToWhiteBoard($event)"
            ></mat-slide-toggle>
          </div>

          <div class="control-item" *ngIf="callConnected">
            <h4>Full-Screen Mode</h4>
            <mat-slide-toggle
              color="cyan"
              *ngIf="callConnected"
              #fullScreenSwitch
              (change)="switchFullScreenMode($event)"
            ></mat-slide-toggle>
          </div>

          <div class="control-item">
            <select [(ngModel)]="selectedImage" (change)="onChangeImageSelect()">
              <option *ngFor="let image of imageFiles" [ngValue]="image">
                {{ image.name }}
              </option>
            </select>

            <label for="fileChooserImage" class="btn btn-primary uploadButton">+</label>
            <input type="file" accept="image/*" name="imageFile" id="fileChooserImage" (change)="uploadImage($event)" />

            <mat-slide-toggle color="cyan" *ngIf="callConnected" #imageSwitch (change)="onChangeImageSwitch()"></mat-slide-toggle>
          </div>
        </div>

        <!-- Admin View -->
        <div class="admin-view grey" [ngClass]="!isWhiteboardShown ? 'showElement' : 'hideElement'">
          <div class="admin-wrapper">
            <h4 style="margin: 0px">Assistant</h4>
          </div>

          <div #localVideoView class="remote-user-live-streaming user-icon">
            <i *ngIf="showLocalViewUserIcon" class="fas fa-user user-icon-admin grey"></i>
          </div>
        </div>

        <div class="admin-view whiteboard-view grey" *ngIf="isWhiteboardShown">
          <h4>Admin</h4>
          <canvas-whiteboard
            #canvasWhiteboard
            [drawButtonClass]="'drawButtonClass'"
            [drawButtonText]="'Toggle Draw'"
            [clearButtonClass]="'clearButtonClass'"
            [clearButtonText]="'Clear'"
            [colorPickerEnabled]="false"
            [lineWidth]="5"
            [shouldDownloadDrawing]="false"
          >
          </canvas-whiteboard>
        </div>

        <div class="row border-below"></div>

        <div *ngIf="callConnected" class="grey" style="text-align: center">
          <h4 style="margin-top: 15px">Current Session</h4>
          <h5>User: {{ inCallUserInfo.name }}</h5>
          <h5>
            Duration: <strong>{{ inCallUserInfo.time }}</strong>
          </h5>
          <div class="row border-below-session-info"></div>
        </div>

        <!-- User List -->
        <div class="user-list">
          <ul *ngFor="let v of remoteViews; let i = index">
            <li class="justify-content-space-between">
              <h5 class="grey">
                <strong>
                  {{ "[" + waitingTime[v.getUserId()]?.provider + "] " }}
                </strong>
                {{
                  remoteViews[i]?.getUserAlias() +
                    " is waiting for " +
                    (waitingTime[v.getUserId()]?.timer | noNegNumber) +
                    " minutes"
                }}
              </h5>
              <button (click)="initiateCall(v)" class="btn btn-dark">Start Session</button>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="showHideOrder" class="col-md-4">
        <app-order
          (showHideOrder)="toggleOrder($event)"
          (orderId)="sendResponseToDevice($event)"
          [practice_id]="PRACTICE_ID"
          [provider_id]="provider_id"
          [patient_id]="patient_row_id"
        ></app-order>
      </div>
    </div>
  </main>
</div>
