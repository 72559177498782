export class Appointment {
  provider_id: string = '';
  location: string;
  facility: string;
  event_type: string;
  appointment_date: Date | string;
  appointment_time: Date | string;
  created_by: string;
  notify_patient: boolean;
}

export interface GET_APPOINTMENT_REPORTS_RESP {
  code: number;
  message: string;
  data?: {
    previousPage: number | null;
    currentPage: number;
    nextPage: number | null;
    total: number;
    limit: number;
    data: AppointmentReportsData[];
  };
  error?: any;
}

export interface AppointmentReportsData {
  id: string;
  sms_sent_at: string;
  called_at: string;
  canceled_by: string;
  is_confirmed: string;
  confirmed_by: string;
  patient: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
    cell_phone: string;
  };
  provider: {
    provider_id: string;
    description: string;
  };
  event: string;
  appt_date: string;
  appt_time: string;
}

export interface AppointmentReportsTableData {
  canceled_by: any;
  confirmed_by: any;
  no: number;
  id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  provider_id: string;
  provider: string;
  event: string;
  appt_date: string;
  appt_time: string;
}

export interface AppointmentPrintData {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  provider: string;
  event: string;
  appt_date: string;
  appt_time: string;
  status: string;
  canceled_by?: string;
  confirmed_by?: string;
}