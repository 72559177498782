import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranscriptionService } from 'src/app/shared/services/transcriptions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transcriptions',
  templateUrl: './transcriptions.component.html',
  styleUrls: ['./transcriptions.component.scss'],
})
export class TranscriptionsComponent implements OnInit {
  tableHeaders = ['no', 'transcription_name'];
  transcriptionTableData: any[];
  data = [];
  searchKeyword: string = '';
  originalTranscriptionTableData: any[]; // Declare a variable to store the original data

  constructor(
    private transcriptionService: TranscriptionService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let practice = localStorage.getItem('PRACTICE_ID');
    if (practice === 'Sierra pac') {
      this.getWavFiles();
    }
  }

  getWavFiles() {
    this.spinner.show();
    this.transcriptionService
      .getFilesFromS3()
      .then((wavFiles: any[]) => {
        this.transcriptionTableData = wavFiles.map((file, index) => ({
          no: index + 1,
          transcription_name: file.Key,
        }));
        this.originalTranscriptionTableData = [...this.transcriptionTableData]; // Save original data
        this.spinner.hide();
      })
      .catch((error: any) => {
        console.error('Error retrieving WAV files:', error);
      });
  }

  deleteTranscription = (data) => {
    const fileName = data.transcription_name;

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.transcriptionService.deleteFile('vytltestbucket', fileName).then(() => {
          this.getWavFiles();
        });
      }
    });
  };

  downloadTranscription = (data: any) => {
    const fileName = data.transcription_name;
    const transcriptionName = fileName.replace(/\.(wav|mp3|flac)/g, '-transcription');
    this.spinner.show();

    this.transcriptionService
      .checkTranscriptionFileExistence('vytlaudiobucket', `transcribed/${transcriptionName}.json`)
      .then((exists: boolean) => {
        if (exists) {
          this.transcriptionService
            .downloadFile('vytlaudiobucket', `transcribed/${transcriptionName}.json`)
            .then((fileContent: ArrayBuffer) => {
              const text = new TextDecoder('utf-8').decode(new Uint8Array(fileContent));
              const jsonData = JSON.parse(text);
              const transcript = jsonData.results.transcripts[0].transcript;
              this.saveTranscriptionFile(transcript, transcriptionName);
            })
            .catch((error: any) => {
              console.error('Error downloading transcription file:', error);
            })
            .finally(() => this.spinner.hide());
        } else {
          this.toastr.info(
            'Transcription file not found. The transcription process might not be completed yet, please try again later.'
          );
          this.spinner.hide();
        }
      })
      .catch((error: any) => {
        this.spinner.hide()
        console.error('Error checking transcription file existence:', error);
      });
  };

  saveTranscriptionFile(transcript: string, fileName: string): void {
    const blob = new Blob([transcript], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.txt`; 
    link.click();
    window.URL.revokeObjectURL(url);
  }

  downloadAudioFile = (data: any) => {
    const fileName = data.transcription_name;
    this.spinner.show();
    this.transcriptionService
      .checkTranscriptionFileExistence('vytltestbucket', fileName)
      .then((exists: boolean) => {
        if (exists) {
          this.transcriptionService
          .downloadFile('vytltestbucket', fileName)
          .then((fileContent: ArrayBuffer) => {
            this.saveAudioFile(fileContent, fileName);
          })
          .catch((error: any) => {
   
            console.error('Error downloading Audio file:', error);
          })
          .finally(() => this.spinner.hide());
        } else {
          this.toastr.info(
            'Audio file not found. Please try again later.'
          );
        }
      })
      .catch((error: any) => {
        this.spinner.hide()
        console.error('Error checking Audio file existence:', error);
      });
  };
  
  saveAudioFile(fileContent: ArrayBuffer, fileName: string): void {
    const blob = new Blob([fileContent], { type: 'audio/mpeg' }); // Specify the appropriate MIME type for MP3 format
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; 
    link.click();
    window.URL.revokeObjectURL(url);
  }

  uploadTranscription() {
    const bucketName = 'vytltestbucket';
    const fileChooserAcceptType = '.wav';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      this.spinner.show();
      this.transcriptionService
        .uploadFileToS3(file, bucketName)
        .then((data) => {
          this.toastr.info('File uploaded successfully!');
          this.getWavFiles();
        })
        .catch((error) => {
          this.toastr.info('Error uploading file:');
          console.error('Error uploading file:', error);
        })
        .finally(() => this.spinner.hide());
    });
    fileChooser.click();
  }
  searchRecords(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') {
      this.searchKeyword = '';
    }

    if (!this.searchKeyword.trim()) {
      this.transcriptionTableData = [...this.originalTranscriptionTableData];
      return;
    }

    this.transcriptionTableData = this.originalTranscriptionTableData.filter((file) =>
      file.transcription_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
    ); 
  }
}
