<ng-container *ngIf="mainMenu">
  <p class="action-btn" (click)="confirmReset()" style="color: red">Reset PIN</p>
  <p *ngIf="!userData.is_sms_restricted" class="action-btn" (click)="confirmNotify()">Notify User?</p>
  <p class="action-btn" (click)="confirmStatus()" style="color: red; text-decoration: underline red">
    {{ userData.is_sms_restricted ? "Unblock Messages" : "Block Messages" }}
  </p>
</ng-container>

<div *ngIf="notifySuccess || notifyFail || noUnread" class="confirm-tab">
  <p class="confirm-text ml-2" *ngIf="notifySuccess">
    User notified of new messages<i class="fa fa-check ml-2" aria-hidden="true"></i>
  </p>
  <p class="confirm-text ml-2" *ngIf="notifyFail">
    Failed to notify user<i class="fa fa-times ml-2" aria-hidden="true"></i>
  </p>
  <p class="confirm-text ml-2" *ngIf="noUnread">
    No Unread messages found<i class="fa fa-times ml-2" aria-hidden="true"></i>
  </p>
  <p class="action-btn" style="margin-top: 3px" (click)="return()">Return</p>
</div>

<div *ngIf="notifyConfirm" class="confirm-tab">
  <p class="confirm-text ml-2">Notify user of unread messages?</p>
  <button type="button" class="btn btn-danger btn-sm confirm-btn" (click)="return()">No</button>
  <button type="button" class="btn btn-light btn-sm confirm-btn" (click)="notifyUser()">Yes</button>
</div>

<div *ngIf="statusConfirm" class="confirm-tab">
  <p class="confirm-text ml-2">
    {{ userData.is_sms_restricted ? "Unblock messages for this user?" : "Block future SMS to user?" }}
  </p>
  <button type="button" class="btn btn-danger btn-sm confirm-btn" (click)="return()">No</button>
  <button
    type="button"
    class="btn btn-light btn-sm confirm-btn"
    (click)="userData.is_sms_restricted ? changeRestrictedStatus() : changeRestrictedStatus(true)"
  >
    Yes
  </button>
</div>

<div *ngIf="resetConfirm" class="confirm-tab">
  <p class="confirm-text ml-2">Are you sure you want to reset PIN?</p>
  <button type="button" class="btn btn-danger btn-sm confirm-btn" (click)="return()">No</button>
  <button type="button" class="btn btn-light btn-sm confirm-btn" (click)="resetPIN()">Yes</button>
</div>

<div *ngIf="statusSuccess || statusFail || resetSuccess || resetFail || userNotFound" class="confirm-tab">
  <p class="confirm-text ml-2" *ngIf="statusSuccess">
    {{ userData.is_sms_restricted ? "User's SMS restricted." : "User's SMS unrestricted."
    }}<i class="fa fa-check ml-2" aria-hidden="true"></i>
  </p>
  <p class="confirm-text ml-2" *ngIf="statusFail">
    Failed to change restriction status.<i class="fa fa-times ml-2" aria-hidden="true"></i>
  </p>
  <p class="confirm-text ml-2" *ngIf="resetSuccess">
    PIN reset successful<i class="fa fa-check ml-2" aria-hidden="true"></i>
  </p>
  <p class="confirm-text ml-2" *ngIf="resetFail">PIN reset failed<i class="fa fa-times ml-2" aria-hidden="true"></i></p>
  <p class="confirm-text ml-2" *ngIf="userNotFound">
    User data not found<i class="fa fa-times ml-2" aria-hidden="true"></i>
  </p>
  <p class="action-btn" style="margin-top: 3px" (click)="return()">Return</p>
</div>

<mat-progress-bar mode="indeterminate" class="progress" *ngIf="progress"></mat-progress-bar>
