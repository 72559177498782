import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  @Input() header: string;
  @Input() datePicker: boolean;
  @Input() filterOptions: any;
  @Input() selectStepStatus: boolean = false; //For Order Step Status case specifically
  @Input() selectedFilter: string = '';
  @Input() selectedStep: string;
  @Input() selectedStatus: string;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, public calendar: NgbCalendar) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {}

  applyFilter(filter: string): void {
    this.activeModal.close(filter);
  }

  applyOrderFilter(): void {
    // For Order Step Status specifically
    if (this.selectedStep && this.selectedStatus) {
      const filter = {
        step: this.selectedStep,
        status: this.selectedStatus,
      };
      this.activeModal.close(filter);
    } else this.toastr.error('Please select a Step and a relevant Status for it.');
  }

  applySelectFilter(): void {
    //For Date picker case specifically
    if (this.fromDate && this.toDate) {
      const filter = { startDate: this.fromDate, endDate: this.toDate };
      this.activeModal.close(filter);
    } else {
      this.toastr.error('Please enter a valid date range.');
    }
  }

  removeFilter(): void {
    this.activeModal.close(null);
  }

  onStepChange(event: any): void {
    this.selectedStep = event.target.value;
  }

  onStatusChange(event: any): void {
    this.selectedStatus = event.target.value;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
}
