import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/services/order.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BenefitPayerProcedure, CREATE_ORDER_BENEFIT_INSURANCE_RESP } from '../../models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-benefit-insurance-modal',
  templateUrl: './add-benefit-insurance-modal.component.html',
  styleUrls: ['./add-benefit-insurance-modal.component.scss'],
})
export class AddBenefitInsuranceModalComponent implements OnInit {
  @Input() order_id: string;
  @Input() client_id: string;
  @Input() payer_id: string;
  @Input() proc_order: number;
  benefitInsurance: BenefitPayerProcedure;

  model: any;
  searching = false;
  searchFailed = false;

  constructor(
    public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) => {
        if (term.length < 2 || term.length > 5) {
          this.searching = false;
          return of([]);
        }

        return this.orderService
          .getCPTAutoCompleteForBenefitInsurance(this.client_id, this.payer_id, term, this.proc_order)
          .pipe(
            tap(() => (this.searchFailed = false)),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          );
      }),
      tap(() => (this.searching = false))
    );

  ngOnInit(): void {}

  onTextChange(event) {
    this.model = event;
    if (this.model.length !== 5) {
      this.benefitInsurance = null;
      return;
    }

    this.orderService
      .getPayerInsuranceByCPT(this.client_id, this.payer_id, this.model, this.proc_order)
      .subscribe((data) => {
        if (data) this.benefitInsurance = data;
      });
  }

  createBenefitInsurance() {
    if (!this.benefitInsurance) return;

    this.spinnerService.show();
    this.orderService
      .createOrderBenefitInsurance(this.order_id, { payer_id: this.payer_id, ...this.benefitInsurance })
      .then((data: CREATE_ORDER_BENEFIT_INSURANCE_RESP) => {
        this.spinnerService.hide();
        data.data.contract_description=this.benefitInsurance.contract_description;
        this.activeModal.close(data);
      })
      .catch(() => {
        this.spinnerService.hide();
        this.toastr.error("Could't complete request due to error");
      });
  }
}
