import { AppService } from './../../shared/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  DbConfigBackend,
  Practice,
  ProviderTableRecord,
  GenericResp,
  ADD_PROVIDER_RESP,
} from 'src/app/shared/models';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-provider-management',
  templateUrl: './provider-management.component.html',
  styleUrls: ['./provider-management.component.scss'],
})
export class ProviderManagementComponent implements OnInit {
  tableHeaders = ['ID', 'Name', 'Practice'];
  providerTableData: ProviderTableRecord[];
  configData: Practice;
  dbConfig: DbConfigBackend;
  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private appService: AppService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.configData = JSON.parse(this.route.snapshot.params['data']);
    if (this.configData) this.init();
  }

  init(): void {
    if (this.configData.dbFlag === 'defaultDB')
      this.dbConfig = this.appService.getDefaultDB();
    else this.dbConfig = this.formatDbObj();
    this.formatDbObj();
    this.getProviders();
  }

  formatDbObj(): DbConfigBackend {
    return this.appService.formatDbObj(
      this.configData.dbUsername,
      this.configData.dbPassword,
      this.configData.dbName,
      this.configData.dbHost,
      this.configData.dbDialect,
      this.configData.dbPort
    );
  }

  getProviders(): void {
    this.spinnerService.show();
    this.adminService
      .getProviders(this.configData.practice_id, this.dbConfig)
      .subscribe((resp) => {
        this.spinnerService.hide();
        if (resp.code === 200) {
          this.providerTableData = resp.data.map((m) => ({
            ID: m.provider_id,
            Name: m.description,
            Practice: m.practice_id,
          }));
        } else if (resp.code === 500) {
          this.toastr.error("Couldn't fetch data due to server error");
        }
      });
  }

  addProvider(): void {
    const self = this;
    Swal.fire({
      titleText: 'Add Provider',
      html:
        '<h5>Provider ID </h5> <input type="text" autocomplete="off" class="swalInput" id="uuid">' +
        '<h5>Provider Name </h5> <input type="text" autocomplete="off" class="swalInput" id="name">',
      showConfirmButton: true,
      confirmButtonText: 'Save',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: function () {
        const uuid = $('input[id="uuid"]').val().toString();
        const providerName: string = $('input[id="name"]').val().toString();
        let addProviderPromise: Promise<ADD_PROVIDER_RESP>;

        if (uuid.length === 0) {
          addProviderPromise = self.adminService.addProvider(
            providerName,
            self.configData.practice_id,
            self.dbConfig
          );
        } else {
          const uuidPattern = new RegExp(
            '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
          );
          if (uuidPattern.test(uuid)) {
            addProviderPromise = self.adminService.addProvider(
              providerName,
              self.configData.practice_id,
              self.dbConfig,
              uuid
            );
          } else {
            Swal.showValidationMessage(
              'Invalid UUID. Please input in correct format -- xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
            );
            return;
          }
        }

        return addProviderPromise
          .then((resp: ADD_PROVIDER_RESP) => {
            if (resp.code === 200) {
              const provider: ProviderTableRecord = {
                ID: resp.data.provider_id,
                Name: resp.data.description,
                Practice: resp.data.practice_id,
              };
              self.providerTableData.push(provider);
            } else {
              console.error(resp.error);
              self.toastr.error("Could't complete request due to error");
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  }

  editProvider = (record: ProviderTableRecord): void => {
    const self = this;
    Swal.fire({
      title: 'Enter Provider Name',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      inputValue: record.Name,
      showCancelButton: true,
      confirmButtonText: 'Edit Provider',
      showLoaderOnConfirm: true,
      preConfirm: (providerName: string) => {
        return self.adminService
          .editProvider(
            record.ID,
            providerName,
            self.configData.practice_id,
            self.dbConfig
          )
          .then((resp: ADD_PROVIDER_RESP) => {
            if (resp.code === 200) {
              const provider: ProviderTableRecord = {
                ID: resp.data.provider_id,
                Name: resp.data.description,
                Practice: resp.data.practice_id,
              };
              const index = self.providerTableData.findIndex(
                (x) => x.ID === provider.ID
              );
              self.providerTableData[index] = provider;
            } else {
              console.error(resp.error);
              self.toastr.error("Could't complete request due to error");
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };

  deleteProvider = (record: ProviderTableRecord) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.adminService
          .deleteProvider(record.Practice, record.ID, this.dbConfig)
          .then((resp: GenericResp) => {
            this.spinnerService.hide();
            if (resp.code === 200) {
              this.providerTableData = this.providerTableData.filter((n) => {
                return record !== n;
              });
              this.toastr.success('Provider Deleted');
            } else {
              this.toastr.error("Could't complete operation due to error");
            }
          })
          .catch((err) => {
            this.spinnerService.hide();
            this.toastr.error("Could't complete operation due to error");
          });
      }
    });
  };

  navigateBackToPracticeForm(): void {
    this.router.navigate(
      [
        'admin/practice/update-form/change',
        { adminData: JSON.stringify(this.configData) },
      ],
      {
        skipLocationChange: true,
      }
    );
  }
}
