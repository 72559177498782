import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../../models/userModel';
import { FormGroup } from '@angular/forms';
import { AppointmentService } from '../../services/appointment.service';
import { AppointmentReminderDetails } from '../../models/reminder';

@Component({
  selector: 'sms-and-call-info-modal',
  templateUrl: './sms-and-call-info-modal.html',
  styleUrls: ['./sms-and-call-info-modal.css'],
})
export class SmsAndCallInfoModal implements OnInit {
  @Input() reminderData: AppointmentReminderDetails;
  @Input() altTitle: string = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  checkAnsweredBy = (status: string) => {
    switch (status) {
      case 'machine_end_beep':
        return 'Voice Mail';
      case null:
        return 'Not Specified';
      default:
        return status;
    }
  };
}
